import { api } from "@/api";


export default {
    state: {
        settingsReferenceGenders: {},
        isSettingsReferenceGendersLoading: false,
    },
    actions: {
        async fetchSettingsReferenceGender({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceGendersLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const gender = await api.getReferenceBiopsyGender(queryParams, token);
                    commit("changeSettingsReferenceGenders", gender.results);
                    commit("updateSettingsReferenceGendersLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferenceGendersLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async addSettingsReferenceGenders({ dispatch, commit }, gender ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const genderData = await api.postReferenceBiopsyGender(gender, token);
                    if (typeof genderData === "object") {
                        commit("addNewSettingsReferenceGender", genderData);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async editSettingsReferenceGenders({ dispatch, commit }, gender ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const genderData = await api.patchReferenceBiopsyGender(gender, token);
                    if (typeof genderData === "object") {
                        commit("updateSettingsGenders", genderData);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    mutations: {
        changeSettingsReferenceGenders(state, settingsReferenceGenders) {
            state.settingsReferenceGenders = settingsReferenceGenders;
        },
        updateSettingsReferenceGendersLoader(state, condition) {
            state.isSettingsReferenceGendersLoading = condition;
        },
        addNewSettingsReferenceGender(state, gender) {
            state.settingsReferenceGenders.unshift(gender);
        },
        updateSettingsGenders(state, gender) {
            const index = state.settingsReferenceGenders.findIndex(item => item.id === gender.id);
            if (index !== -1) {
                const updatedGender = { ...gender }; // Создаем копию объекта gender
                const uniqueId = Object.values(updatedGender).join('_'); // Создаем уникальный идентификатор на основе значений объекта
                updatedGender.uniqueId = uniqueId; // Добавляем новое поле uniqueId к объекту
                state.settingsReferenceGenders.splice(index, 1, updatedGender); // Заменяем объект в массиве
            }
        },
    },
    getters: {
        getSettingsReferenceGenders(state) {
            return state.settingsReferenceGenders;
        },
        getSettingsReferenceGendersLoaderStatus(state) {
            return state.isSettingsReferenceGendersLoading;
        },
    }
}