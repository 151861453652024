export default {
    state: {
        lifetimeCaseInformationFiles: [],
    },
    actions: {
        addLifetimeCaseInformationFiles({ commit }, lifetimeCaseInformationFiles) {
            commit("changeLifetimeCaseInformationFiles", lifetimeCaseInformationFiles);
        },
        deleteLifetimeCaseInformationFile({ commit }, lifetimeCaseInformationFileIndex) {
            commit("changeDeleteLifetimeCaseInformationFile", lifetimeCaseInformationFileIndex);
        }
    },
    mutations: {
        changeLifetimeCaseInformationFiles(state, lifetimeCaseInformationFiles) {
            state.lifetimeCaseInformationFiles = lifetimeCaseInformationFiles;
        },
        changeDeleteLifetimeCaseInformationFile(state, lifetimeCaseInformationFileIndex) {
            state.lifetimeCaseInformationFiles.splice(lifetimeCaseInformationFileIndex, 1);
        },
    },
    getters: {
        getLifetimeInformationFiles(state) {
            return state.lifetimeCaseInformationFiles;
        }
    },
}