import { api } from "@/api";

export default {
    state: {
        settingsEmployeePositions: {},
        settingsEmployeePositionsTotalPages: null,
        isSettingsEmployeePositionsLoading: false,
        newSettingsEmployeePosition: null
    },
    actions: {
        async fetchSettingsEmployeePositions({ dispatch, commit }, queryParams) {
            commit("updateSettingsEmployeePositionsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const positions = await api.getSettingsReferenceEmployeesPostion(queryParams, token);
                    commit("changeSettingsEmployeePositions", positions.results);
                    commit("changeSettingsEmployeePositionsTotalPages", positions.total_pages);
                    commit("updateSettingsEmployeePositionsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsEmployeePositionsLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
                commit("updateSettingsEmployeePositionsLoader", false);
            }
        },
        async fetchMoreSettingsEmployeePositions({ dispatch, commit }, queryParams) {
            commit("updateSettingsEmployeePositionsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const positions = await api.getSettingsReferenceEmployeesPostion(queryParams, token);
                    commit("updateMoreSettingsEmployeePositions", positions.results);
                    commit("updateSettingsEmployeePositionsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsEmployeePositionsLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("updateSettingsEmployeePositionsLoader", false);
            }

        },
        async addSettingsEmployeePositions({ dispatch, commit }, position ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const positionData = await api.postSettingsReferenceEmployeesPostion(position, token);
                    if (typeof positionData === "object") {
                        commit("addNewSettingsEmployeePositions", positionData);
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return false;
                }
            } catch (error) {
                console.log(error);
                return false;
            }
        },

        async editSettingsEmployeePositions({ dispatch, commit }, [position, positionId] ) {
            const token = await dispatch("getAccessToken");
            if (token) {
                const positionData = await api.patchSettingsReferenceEmployeesPostion(position, positionId, token);
                if (typeof positionData === "object") {
                    commit("addNewSettingsEmployeePositions", positionData);
                    return true;
                } else {
                    return false;
                }
            } else {
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                return false;
            }
        },
    },
    mutations: {
        changeSettingsEmployeePositions(state, settingsEmployeePositions) {
            state.settingsEmployeePositions = settingsEmployeePositions;
        },
        updateMoreSettingsEmployeePositions(state, settingsEmployeePositions) {
            state.settingsEmployeePositions = [...state.settingsEmployeePositions, ...settingsEmployeePositions];
        },
        changeSettingsEmployeePositionsTotalPages(state, settingsEmployeePositionsTotalPages) {
            state.settingsEmployeePositionsTotalPages = settingsEmployeePositionsTotalPages;
        },
        updateSettingsEmployeePositionsLoader(state, condition) {
            state.isSettingsEmployeePositionsLoading = condition;
        },
        updateSettingsEmployeePositions(state, position) {
            const index = state.settingsEmployeePositions.findIndex(item => item.id === position.id);
            if (index !== -1) {
                const updatedLocalization = { ...position }; // Создаем копию объекта position
                const uniqueId = Object.values(updatedLocalization).join('_'); // Создаем уникальный идентификатор на основе значений объекта
                updatedLocalization.uniqueId = uniqueId; // Добавляем новое поле uniqueId к объекту
                state.settingsEmployeePositions.splice(index, 1, updatedLocalization); // Заменяем объект в массиве
            }
        },
        addNewSettingsEmployeePositions(state, position) {
            state.newSettingsEmployeePosition = position;
        },
    },
    getters: {
        getSettingsEmployeePositions(state) {
            return state.settingsEmployeePositions;
        },
        getSettingsEmployeePositionsTotalPages(state) {
            return state.settingsEmployeePositionsTotalPages;
        },
        getSettingsEmployeePositionsLoaderStatus(state) {
            return state.isSettingsEmployeePositionsLoading;
        },
        getNewSettingsEmployeePosition(state) {
            return state.newSettingsEmployeePosition;
        }
    }
}