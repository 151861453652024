export default {
    state: {
        mortemAutopsyCommentBtnStatus: false,
        mortemAutopsyComment: null,
    },
    actions: {
        updateMortemAutopsyCommentBtnStatus({ commit }, condition) {
            commit("changeMortemAutopsyCommentBtnStatus", condition);
        },
        updateAutopsyCommentField({ commit }, payload) {
            commit('changeAutopsyCommentField', payload);
        }
    },
    mutations: {
        changeMortemAutopsyCommentBtnStatus(state, condition) {
            state.mortemAutopsyCommentBtnStatus = condition;
        },
        changeAutopsyCommentField(state, { key, value }) {
            state[key] = value;
        }
    },
    getters: {
        getMortemAutopsyCommentBtnStatus(state) {
            return state.mortemAutopsyCommentBtnStatus;
        },
        getAutopsyCommentField: (state) => (key) => {
            return state[key];
        }
    }
}