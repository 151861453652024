export const referenceLocalizationFields = {
    data() {
        return {
            referenceLocalizationTableDescriptionData: [
                { field: "active", header: "Активна", isSort: true, columnWidth: "90px"},
                { field: "name", header: "Название локализации", isSort: true, columnWidth: "300px"},
                { field: "anatomic_field", header: "Анатомическая область", columnWidth: "200px"},
                { field: "synonyms", header: "Синонимы", columnWidth: "150px"},
                { field: "snomed", header: "SNOMED-CT", columnWidth: "110px"},
                { field: "comment", header: "Комментарий", columnWidth: "300px"},
            ],
        }
    },
}