export default {
    state: {
        mortemAutopsyAbdomenBtnStatus: false,
        mortemCaseAbdomenOrgansLocation: null,
        mortemCaseAbdomenPeritoneumLayers: null,
        mortemCaseAbdomenFusion: null,
        mortemCaseAbdomenFreeFluid: null,
        mortemCaseAbdomenDiaphragm: null,
        mortemCaseAbdomenLiver: null,
        mortemCaseAbdomenSpleen: null,
        mortemCaseAbdomenBigSeal: null,
        mortemCaseAbdomenStomach: null,
        mortemCaseAbdomenIntestines: null,
        mortemCaseAbdomenBladder: null,
        mortemCaseAbdomenAppendix: null,
    },
    actions: {
        updateMortemAutopsyAbdomenBtnStatus({ commit }, condition) {
            commit("changeMortemAutopsyAbdomenBtnStatus", condition);
        },
        updateAutopsyAbdomenField({ commit }, payload) {
            commit('changeAutopsyAbdomenField', payload);
        }
    },
    mutations: {
        changeMortemAutopsyAbdomenBtnStatus(state, condition) {
            state.mortemAutopsyAbdomenBtnStatus = condition;
        },
        changeAutopsyAbdomenField(state, { key, value }) {
            state[key] = value;
        }
    },
    getters: {
        getMortemAutopsyAbdomenBtnStatus(state) {
            return state.mortemAutopsyAbdomenBtnStatus;
        },
        getAutopsyAbdomentField: (state) => (key) => {
            return state[key];
        }
    }
}