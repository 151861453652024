export const referenceMedicalServicesFields = {
    data() {
        return {
            referenceMedicalServicesTableDescriptionData: [
                { field: "active", header: "Активна", isSort: true, columnWidth: "90px"},
                { field: "service_code", header: "Код услуги", isSort: true, columnWidth: "120px"},
                { field: "full_name", header: "Полное название", columnWidth: "650px"},
                { field: "short_name", header: "Краткое название", columnWidth: "260px"},
            ],
        }
    },
}