import { api } from "@/api";


export default {
    state: {
        settingsReferenceSamplePreparationDefects: {},
        isSettingsReferenceSamplePreparationDefectsLoading: false,
        settingsReferenceSamplePreparationDefectsTypes: [],
    },
    actions: {
        async fetchSettingsSamplePreparationDefects({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceSamplePreparationDefectsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const defects = await api.getReferenceSamplePreparationDefects(queryParams, token);
                    commit("changeSettingsReferenceSamplePreparationDefects", defects.results);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            } finally {
                commit("updateSettingsReferenceSamplePreparationDefectsLoader", false);
            }
        },
        async fetchSettingsSamplePreparationDefectsTypes({ dispatch, commit }, queryParams) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const types = await api.getReferenceSamplePreparationDefectsTypes(queryParams, token);
                    commit("changeSettingsReferenceSamplePreparationDefectsTypes", types);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async addSettingsReferenceSamplePreparationDefects({ dispatch, commit }, defect ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const defectData = await api.postReferenceSamplePreparationDefects(token, defect);
                    if (typeof defectData === "object") {
                        commit("addNewSettingsReferenceSamplePreparationDefects", defectData);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async editSettingsReferenceSamplePreparationDefects({ dispatch, commit }, defect ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const defectData = await api.patchReferenceSamplePreparationDefects(token, defect);
                    if (typeof defectData === "object") {
                        commit("updateSettingsReferenceSamplePreparationDefects", defectData);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    mutations: {
        changeSettingsReferenceSamplePreparationDefects(state, settingsReferenceSamplePreparationDefects) {
            state.settingsReferenceSamplePreparationDefects = settingsReferenceSamplePreparationDefects;
        },
        changeSettingsReferenceSamplePreparationDefectsTypes(state, settingsReferenceSamplePreparationDefectsTypes) {
            state.settingsReferenceSamplePreparationDefectsTypes = settingsReferenceSamplePreparationDefectsTypes;
        },
        updateSettingsReferenceSamplePreparationDefectsLoader(state, condition) {
            state.isSettingsReferenceSamplePreparationDefectsLoading = condition;
        },
        updateSettingsReferenceSamplePreparationDefects(state, samplePreparationDefect) {
            const index = state.settingsReferenceSamplePreparationDefects.findIndex(item => item.id === samplePreparationDefect.id);
            if (index !== -1) {
                const updatedSamplePreparationDefect = { ...samplePreparationDefect }; // Создаем копию объекта samplePreparationDefect
                const uniqueId = Object.values(updatedSamplePreparationDefect).join('_'); // Создаем уникальный идентификатор на основе значений объекта
                updatedSamplePreparationDefect.uniqueId = uniqueId; // Добавляем новое поле uniqueId к объекту
                state.settingsReferenceSamplePreparationDefects.splice(index, 1, updatedSamplePreparationDefect); // Заменяем объект в массиве
            }
        },
        addNewSettingsReferenceSamplePreparationDefects(state, samplePreparationDefect) {
            state.settingsReferenceSamplePreparationDefects.unshift(samplePreparationDefect);
        },
    },
    getters: {
        getSettingsReferenceSamplePreparationDefects(state) {
            return state.settingsReferenceSamplePreparationDefects;
        },
        getSettingsReferenceSamplePreparationDefectsTypes(state) {
            return state.settingsReferenceSamplePreparationDefectsTypes;
        },
        getSettingsReferenceSamplePreparationDefectsLoaderStatus(state) {
            return state.isSettingsReferenceSamplePreparationDefectsLoading;
        },
    }
}