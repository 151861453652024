import { api } from "@/api";
export default {
    state: {
        profileData: {},
        userId: null
    },
    actions: {
        async fetchProfileData({ commit }) {
            const accessTokenData = localStorage.accessTokenData;
            if (accessTokenData && JSON.parse(accessTokenData).access) {
                try {
                    const userId = localStorage.userData;
                    const profileData = await api.getUser(JSON.parse(userId), JSON.parse(accessTokenData).access, JSON.parse(accessTokenData).access);
                    if (profileData === 401 || profileData === 400) {
                        localStorage.removeItem("accessTokenData");
                        localStorage.removeItem("userAuth");
                        localStorage.removeItem("vuex");
                        document.cookie = `refreshTokenData=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
                        return;
                    }
                    localStorage.removeItem("vuex");
                    commit("addProfileData", profileData);
                } catch (e) {
                    console.error(e);
                    if (e.status === 401 || e.status === 400) {
                        localStorage.removeItem("accessTokenData");
                        localStorage.removeItem("userAuth");
                        document.cookie = `refreshTokenData=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
                    }
                }
            }
        },
        async addUsetData({ dispatch }, userData) {
            try {
                const token = await dispatch('getAccessToken');
                if (token) {
                    const user = await api.postUser(userData, token);
                    if (typeof user === 'object') {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return false;
                }
            } catch (error) {
                console.log(error);
                return false;
            }
        },       
        async updatePartialUserDataById({ dispatch }, updatedUserData) {
            if (JSON.parse(localStorage.accessTokenData).access) {
                try {
                    console.log(updatedUserData, JSON.parse(localStorage.accessTokenData).access);
                    api.patchUser(updatedUserData, JSON.parse(localStorage.accessTokenData).access).
                    then(() => {
                        dispatch("fetchProfileData");
                    });

                } catch (e) {
                    console.error(e, e.message);
                    return e;
                }
            }
        },
        updateProfileData({ commit }, profileData) {
            commit("addProfileData", profileData);
        },
        updateUserId({ commit }, id) {
            commit("addUserId", id);
        }
    },
    mutations: {
        addProfileData(state, profileData) {
            state.profileData = profileData;
        },
        addUserId(state, id) {
            state.userId = id;
        },
    },
    getters: {
        getProfileData(state) {
            return state.profileData;
        },
        getUserId(state) {
            return state.userId;
        },
    }
}