export default {
    state: {
        lifetimeRegisterData: null,
        lifetimeFieldsFillTransferToRegisterStatus: false,
    },
    actions: {
        async updateLifetimeCaseRegisterStatus({ dispatch, state }) {
            // if (!state.lifetimeFieldsFillTransferToRegisterStatus) {// пока что в lifetimeFieldsFillTransferToReceptionStatus временно передается true
            //     return;
            // }
            await dispatch("updateLifetimeCaseStatus", state.lifetimeRegisterData);
        },
        updateLifetimeCaseReceptionInformationDataById() {
            //TODO реализовать экшн, когда добавят апи
        },
        updateLifetimeRegisterData({ commit }, registerData) {
            commit("changeLifetimeRegisterData", registerData);
        },
        // checkRequiredFieldsForTransferToReceptionStatus({ commit }) {
        //     //TODO Делать запрос на сервер, когда добавят отдельное апи для проверки заполнения обязательных полей
        //     commit("changeLifetimeFieldsFillTransferToRegisterStatus", true);// на данный момент проверка всегда успешна
        // },
    },
    mutations: {
        changeLifetimeRegisterData(state, registerData) {
            state.lifetimeRegisterData = registerData;
        },
        // changeLifetimeFieldsFillTransferToRegisterStatus(state, status) {
        //     state.lifetimeFieldsFillTransferToRegisterStatus = status;
        // },
    },
    getters: {
        getLifetimeRegisterData(state) {
            return state.lifetimeRegisterData;
        },
        // getLifetimeFieldsFillTransferToRegisterStatus(state) {
        //     return state.lifetimeFieldsFillTransferToRegisterStatus;
        // },
    },
}