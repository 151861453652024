export default {
    state: {
        mortemCaseComment: null,
        mortemCommentBtnStatus: null,
    },
    actions: {
        updateMortemCaseComment({ commit }, comment) {
            commit("changeMortemCaseComment", comment);
        },
        updateMortemCommentBtnStatus({ commit }, mortemCommentBtnStatus) {
            commit("changeMortemCommentBtnStatus", mortemCommentBtnStatus);
        },
    },
    mutations: {
        changeMortemCaseComment(state, comment) {
            state.mortemCaseComment = comment;
        },
        changeMortemCommentBtnStatus(state, mortemCommentBtnStatus) {
            state.mortemCommentBtnStatus = mortemCommentBtnStatus;
        },
    },
    getters: {
        getMortemCaseComment(state) {
            return state.mortemCaseComment;
        },
        getMortemCommentBtnStatus(state) {
            return state.mortemCommentBtnStatus;
        }
    }
}