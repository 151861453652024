export default {
    state: {
        interfaceMenuShowBtn: false,
        scrollArrowTopButtonStatus: false
    },
    actions: {
        updateMenuShowBtnStatus({ commit }){
            commit("changeMenuShowStatusBtn");
        },
        updateScrollArrowTopButtonStatus({ commit }, status){
            commit("changeScrollArrowTopButtonStatus", status);
        },
    },
    mutations: {
        changeMenuShowStatusBtn(state) {
            state.interfaceMenuShowBtn = !state.interfaceMenuShowBtn;
        },
        changeScrollArrowTopButtonStatus(state, status) {
            state.scrollArrowTopButtonStatus = status;
        },
    },
    getters: {
        getInterfaceMenuShowBtn(state) {
            return state.interfaceMenuShowBtn;
        },
        getScrollArrowTopButtonStatus(state) {
            return state.scrollArrowTopButtonStatus;
        },
    }
}
