export default {
    state: {
        mortemAutopsySkullCavityBtnStatus: false,
        mortemCaseSkullCavitySoftCoverings: null,
        mortemCaseSkullCavityBones: null,
        mortemCaseSkullCavitySoftMembranes: null,
        mortemCaseSkullCavityArachnoidMembranes: null,
        mortemCaseSkullCavityHardMembranes: null,
        mortemCaseSkullCavityBloodFilling: null,
        mortemCaseSkullCavityBrainVolume: null,
        mortemCaseSkullCavityBrainMass: null,
        mortemCaseSkullCavityBrainConsistency: null,
        mortemCaseSkullCavityBrainMatter: null,
        mortemCaseSkullCavityBrainVentricles: null,
        mortemCaseSkullCavityBrainCerebellum: null,
        mortemCaseSkullCavityBrainMedulla: null,
        mortemCaseSkullCavityChoroidPlexuses: null,
    },
    actions: {
        updateMortemAutopsySkullCavityBtnStatus({ commit }, condition) {
            commit("changeMortemAutopsySkullCavityBtnStatus", condition);
        },
        updateAutopsySkullCavityField({ commit }, payload) {
            commit('changeAutopsySkullCavityField', payload);
        }
    },
    mutations: {
        changeMortemAutopsySkullCavityBtnStatus(state, condition) {
            state.mortemAutopsySkullCavityBtnStatus = condition;
        },
        changeAutopsySkullCavityField(state, { key, value }) {
            state[key] = value;
        }
    },
    getters: {
        getMortemAutopsySkullCavityBtnStatus(state) {
            return state.mortemAutopsySkullCavityBtnStatus;
        },
        getAutopsySkullCavityField: (state) => (key) => {
            return state[key];
        }
    }
}