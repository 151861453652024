import { api } from "@/api";
import { debounceLoader } from "@/helpers/debounceLoader";


export default {
    state: {
        settingsReferenceBiopsyTypePage: 1,
        settingsReferenceBiopsyTypePageSize: 50,
        settingsReferenceBiopsyTypes: {},
        settingsReferenceBiopsyTypesTotalPages: null,
        isSettingsReferenceBiopsyTypesLoading: false,
    },
    actions: {
        async fetchSettingsReferenceBiopsyType({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceBiopsyTypesLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const biopsyTypes = await debounceLoader(
                        api.getSettingsReferenceBiopsyType(queryParams, token),
                        "settingsReference"
                    );
                    commit("changeSettingsReferenceBiopsyTypes", biopsyTypes.results);
                    commit("changeSettingsReferenceBiopsyTypesTotalPages", biopsyTypes.total_pages);
                    commit("updateSettingsReferenceBiopsyTypesLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferenceBiopsyTypesLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async fetchMoreSettingsReferenceBiopsyTypes({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceBiopsyTypesLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const biopsyTypes = await api.getSettingsReferenceBiopsyType(queryParams, token);
                    commit("updateMoreSettingsReferenceBiopsyTypes", biopsyTypes.results);
                    commit("updateSettingsReferenceBiopsyTypesLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferenceBiopsyTypesLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("updateSettingsReferenceBiopsyTypesLoader", false);
            }

        },
        async addSettingsReferenceBiopsyTypes({ dispatch, commit }, biopsyType ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const biopsyTypeData = await api.postSettingsReferenceBiopsyType(biopsyType, token);
                    if (typeof biopsyTypeData === "object") {
                        commit("addNewSettingsReferenceBiopsyTypes", biopsyTypeData);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async editSettingsReferenceBiopsyTypes({ dispatch, commit }, biopsyType ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const biopsyTypeData = await api.patchSettingsReferenceBiopsyType(biopsyType, token);
                    if (typeof biopsyTypeData === "object") {
                        commit("updateSettingsReferenceBiopsyTypes", biopsyTypeData);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    mutations: {
        changeSettingsReferenceBiopsyTypes(state, settingsReferenceBiopsyTypes) {
            state.settingsReferenceBiopsyTypes = settingsReferenceBiopsyTypes;
        },
        updateMoreSettingsReferenceBiopsyTypes(state, settingsReferenceBiopsyTypes) {
            state.settingsReferenceBiopsyTypes = [...state.settingsReferenceBiopsyTypes, ...settingsReferenceBiopsyTypes];
        },
        changeSettingsReferenceBiopsyTypesTotalPages(state, settingsReferenceBiopsyTypesTotalPages) {
            state.settingsReferenceBiopsyTypesTotalPages = settingsReferenceBiopsyTypesTotalPages;
        },
        updateSettingsReferenceBiopsyTypesLoader(state, condition) {
            state.isSettingsReferenceBiopsyTypesLoading = condition;
        },
        updateSettingsReferenceBiopsyTypes(state, biobsyType) {
            const index = state.settingsReferenceBiopsyTypes.findIndex(item => item.id === biobsyType.id);
            if (index !== -1) {
                const updatedBiopsyType = { ...biobsyType }; // Создаем копию объекта biobsyType
                const uniqueId = Object.values(updatedBiopsyType).join('_'); // Создаем уникальный идентификатор на основе значений объекта
                updatedBiopsyType.uniqueId = uniqueId; // Добавляем новое поле uniqueId к объекту
                state.settingsReferenceBiopsyTypes.splice(index, 1, updatedBiopsyType); // Заменяем объект в массиве
            }
        },
        addNewSettingsReferenceBiopsyTypes(state, biobsyType) {
            const updatedBiopsyType = { ...biobsyType }; // Создаем копию объекта biobsyType
            const uniqueId = Object.values(updatedBiopsyType).join('_'); // Создаем уникальный идентификатор на основе значений объекта
            updatedBiopsyType.uniqueId = uniqueId; // Добавляем новое поле uniqueId к объекту
            state.settingsReferenceBiopsyTypes.unshift(updatedBiopsyType);
        },
    },
    getters: {
        getSettingsReferenceBiopsyTypes(state) {
            return state.settingsReferenceBiopsyTypes;
        },
        getSettingsReferenceBiopsyTypesTotalPages(state) {
            return state.settingsReferenceBiopsyTypesTotalPages;
        },
        getSettingsReferenceBiopsyTypesLoaderStatus(state) {
            return state.isSettingsReferenceBiopsyTypesLoading;
        },
    }
}