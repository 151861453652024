import { isObjectEmpty } from "@/helpers/isObjectEmpty";
import { lifetimeCasesTabsApi } from "@/api";

export default {
    state: {
        lifetimeBiopsyStudiesAddBtnEventStatus: true,
        lifetimePreviousBiopsyStudiesForm: {},
        lifetimePreviousBiopsyStudies: [],
        lifetimePreviousBiopsyStudiesUploaded: [],
        lifetimePreviousBiopsyStudiesItemById: {},
        editLifetimeBiopsyStudiesItemId: null,
        lifetimeDiseaseData: null,
        lifetimeDiseaseComment: null,
        lifetimePreviousBiopsyStudiesDataForChangeFromParentStatus: false
        // lifetimeDiseaseData
        // {
        //     disease_icd_code: "",
        //     disease_diagnosis: "",
        //     disease_research_task: "",
        //     disease_additional_clinical_info: "",
        // }
    },
    actions: {
        async updateLifetimeCaseDiseaseDataById({ dispatch, rootGetters, state }) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }

                const updatedDiseaseData = {
                    ...state.lifetimeDiseaseData,
                    disease_icd_code: rootGetters.getIcdCodeReferenceSelectedItemId,
                    previous_cases: state.lifetimePreviousBiopsyStudies
                };
                dispatch("addToastMessage", {code: 200, message: null});
                dispatch("fetchLifetimeCaseDiseaseDataById", [token, updatedDiseaseData]);
            });
        },
        async updateLifetimeCaseCommentDataById({ dispatch, state }) {
            //TODO: Добавить в этом месте проверку на заполненность всех полей для перевода случая в статус прием
            dispatch("updateLifetimeCaseStatus", state.lifetimeDiseaseComment);
        },
        async fetchLifetimeCaseDiseaseDataById({ dispatch, rootGetters }, [token, updatedDiseaseData]) {
            try {
                const diseaseData = await lifetimeCasesTabsApi.putLifetimeCaseDiseaseById(rootGetters.getLifetimeCaseId, updatedDiseaseData, token);
                if (typeof diseaseData === "number") {
                    return;
                }
                if (diseaseData) {
                    dispatch("addToastMessage", {code: 200, message: null});
                    dispatch("fetchLifetimeCaseById", [token, rootGetters.getLifetimeCaseId]);
                }
            } catch (e) {
                console.error(e);
                dispatch("setExceptionAccordingResponseCode", e.status);
            }
        },
        async fetchLifetimeCaseCommentDataById({ dispatch, rootGetters }, [token, updatedCommentData]) {
            try {
                const commentData = await lifetimeCasesTabsApi.putLifetimeCaseCommentById(rootGetters.getLifetimeCaseId, updatedCommentData, token);
                if (typeof commentData === "number") {
                    return;
                }
                if (commentData) {
                    dispatch("fetchLifetimeCaseById", [token, rootGetters.getLifetimeCaseId]);
                    dispatch("addToastMessage", {code: 200, message: null});
                }
            } catch (e) {
                console.error(e);
                dispatch("setExceptionAccordingResponseCode", e.status);
            }
        },
        updateLifetimeBiopsyStudiesForm({ commit }, lifetimePreviousBiopsyStudiesForm) {
            commit("changeLifetimeBiopsyStudiesForm", lifetimePreviousBiopsyStudiesForm);
        },
        updateLifetimeBiopsyStudies({ state, commit, rootGetters }, [lifetimePreviousBiopsyStudies, status]) {
            switch (status) {
                case "add":
                    if (isObjectEmpty(state.lifetimePreviousBiopsyStudiesForm)) {
                        return;
                    }
                    commit("addLifetimeBiopsyStudies", state.lifetimePreviousBiopsyStudiesForm);
                    commit("changeLifetimeBiopsyStudiesForm", null);
                    return;
                case "addFromPatient":
                    if (lifetimePreviousBiopsyStudies.length === 0 && rootGetters.getLifetimeCasesPatientDataIpaSearch?.previous_cases) {
                        lifetimePreviousBiopsyStudies = rootGetters.getLifetimeCasesPatientDataIpaSearch.previous_cases;
                    }
                    commit("changeLifetimePreviousBiopsyStudiesUploaded", lifetimePreviousBiopsyStudies);
                    return;
                case "edit":
                    commit("editLifetimeBiopsyStudies", [state.lifetimePreviousBiopsyStudiesForm, rootGetters.getPreviousOrganizationReferenceFieldChangeStatus]);
                    commit("changeLifetimePreviousBiopsyStudiesDataForChangeFromParentStatus", false);
                    return;
                case "delete":
                    commit("deleteItemFromLifetimePreviousBiopsyStudies", lifetimePreviousBiopsyStudies);
                    return;
                default:
                    commit("changeLifetimeBiopsyStudies", lifetimePreviousBiopsyStudies);
                    return;
            }
        },
        updateLifetimeBiopsyStudiesAddBtnEventStatus({ commit }, status) {
            commit("changeLifetimeBiopsyStudiesAddBtnEventStatus", status);
        },
        updateLifetimeBiopsyStudiesItemById({ commit }, id) {
            commit("changeLifetimeBiopsyStudiesItemById", id);
        },
        updateLifetimePreviousBiopsyStudiesItemById({ commit }, lifetimePreviousBiopsyStudiesItemById) {
            commit("changeLifetimePreviousBiopsyStudiesItemById", lifetimePreviousBiopsyStudiesItemById);
        },
        updateEditLifetimeBiopsyStudiesItemId({ commit }, id) {
            commit("changeEditLifetimeBiopsyStudiesItemId", id);
        },
        updateLifetimeDiseaseData({ commit }, diseaseData) {
            commit("changeLifetimeDiseaseData", diseaseData);
        },
        updateLifetimeDiseaseComment({ commit }, diseaseComment) {
            commit("changeLifetimeDiseaseComment", diseaseComment);
        },
        updateLifetimePreviousBiopsyStudiesUploaded({ commit }, lifetimePreviousBiopsyStudiesUploaded) {
            commit("changeLifetimePreviousBiopsyStudiesUploaded", lifetimePreviousBiopsyStudiesUploaded);
        },
        updateLifetimePreviousBiopsyStudiesDataForChangeFromParentStatus({ commit }, status) {
            commit("changeLifetimePreviousBiopsyStudiesDataForChangeFromParentStatus", status);
        },
        updateLifetimePreviousBiopsyCompletionDate({ commit }, lifetimePreviousBiopsyCompletionDate) {
            commit("changeLifetimePreviousBiopsyCompletionDate", lifetimePreviousBiopsyCompletionDate);
        },
        clearLifetimeDiseaseData({ commit }) {
            commit("changeLifetimeBiopsyStudies", []);
            commit("changeLifetimePreviousBiopsyStudiesUploaded", []);
            commit("changeLifetimeDiseaseData", null);
            commit("changeLifetimeDiseaseComment", null);
            commit("changeLifetimeBiopsyStudiesAddBtnEventStatus", true);
            commit("changeLifetimePreviousBiopsyStudiesItemById", {});
            commit("changeLifetimeBiopsyStudiesForm", null);
            commit("changeLifetimePreviousBiopsyStudiesDataForChangeFromParentStatus", false);
        },
    },
    mutations: {
        changeLifetimeBiopsyStudiesForm(state, lifetimePreviousBiopsyStudiesForm) {
            state.lifetimePreviousBiopsyStudiesForm = lifetimePreviousBiopsyStudiesForm;
        },
        changeLifetimeBiopsyStudiesAddBtnEventStatus(state, status) {
            state.lifetimeBiopsyStudiesAddBtnEventStatus = status;
        },
        addLifetimeBiopsyStudies(state, lifetimePreviousBiopsyStudy) {
            state.lifetimePreviousBiopsyStudies = [...state.lifetimePreviousBiopsyStudies, lifetimePreviousBiopsyStudy];
        },
        changeLifetimePreviousBiopsyStudiesUploaded(state, lifetimePreviousBiopsyStudiesUploaded) {
            state.lifetimePreviousBiopsyStudiesUploaded = lifetimePreviousBiopsyStudiesUploaded;
        },
        changeLifetimeBiopsyStudies(state, lifetimePreviousBiopsyStudies) {
            state.lifetimePreviousBiopsyStudies = lifetimePreviousBiopsyStudies;
        },
        editLifetimeBiopsyStudies(state, [newItem, status]) {
            let editObj = Object.assign({}, state.lifetimePreviousBiopsyStudiesItemById, newItem);

            if (status && editObj.organization_title) {
                delete editObj?.organization_title;
            }

            state.lifetimePreviousBiopsyStudies.splice(state.editLifetimeBiopsyStudiesItemId, 1, editObj);
        },
        deleteItemFromLifetimePreviousBiopsyStudies(state, id) {
            state.lifetimePreviousBiopsyStudies.splice(id, 1);
        },
        changeLifetimeBiopsyStudiesItemById(state, id) {
            let item = state.lifetimePreviousBiopsyStudies.filter((item, index) => {
                if (index === id) {
                    return item;
                }
            });
            state.lifetimePreviousBiopsyStudiesItemById = item[0];
        },
        changeLifetimePreviousBiopsyStudiesItemById(state, lifetimePreviousBiopsyStudiesItemById) {
            state.lifetimePreviousBiopsyStudiesItemById = lifetimePreviousBiopsyStudiesItemById;
        },
        changeEditLifetimeBiopsyStudiesItemId(state, id) {
            state.editLifetimeBiopsyStudiesItemId = id;
        },
        changeLifetimeDiseaseData(state, diseaseData) {
            state.lifetimeDiseaseData = diseaseData;
        },
        changeLifetimeDiseaseComment(state, diseaseComment) {
            state.lifetimeDiseaseComment = diseaseComment;
        },
        changeLifetimePreviousBiopsyStudiesDataForChangeFromParentStatus(state, status) {
            state.lifetimePreviousBiopsyStudiesDataForChangeFromParentStatus = status;
        }
    },
    getters: {
        getLifetimePreviousBiopsyStudiesForm(state) {
            return state.lifetimePreviousBiopsyStudiesForm;
        },
        getLifetimeBiopsyStudiesAddBtnEventStatus(state) {
            return state.lifetimeBiopsyStudiesAddBtnEventStatus;
        },
        getLifetimePreviousBiopsyStudies(state) {
            return state.lifetimePreviousBiopsyStudies;
        },
        getLifetimePreviousBiopsyStudiesUploaded(state) {
            return state.lifetimePreviousBiopsyStudiesUploaded;
        },
        getLifetimePreviousBiopsyStudiesItemById(state) {
            return state.lifetimePreviousBiopsyStudiesItemById;
        },
        getEditLifetimeBiopsyStudiesItemId(state) {
            return state.editLifetimeBiopsyStudiesItemId;
        },
        getLifetimeDiseaseData(state) {
            return state.lifetimeDiseaseData;
        },
        getLifetimeDiseaseComment(state) {
            return state.lifetimeDiseaseComment;
        },
        getLifetimePreviousBiopsyStudiesDataForChangeFromParentStatus(state) {
            return state.lifetimePreviousBiopsyStudiesDataForChangeFromParentStatus;
        }
    }
}