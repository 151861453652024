<template>
    <DataTable
        v-model:expandedRows="expandedRows"
        :value="tableDataReorder.length !== 0 ? tableDataReorder : tableData"
        :dataKey="'uniqueId' || 'id'"
        scrollable
        :class="dataTableClasses"
        :scrollHeight="tableScrollHeight ? tableScrollHeight : '1000px'"
        :pt="ptOption ? ptOption : getTablePTOptions"
        :loading="isLoading"
        metaKeySelection
        @rowReorder="onRowReorder"
    >
        <template #empty>
            <div
                class="empty__data_block"
            >
                Данные отсутствуют
            </div>
        </template>
        <Column
            v-if="isUseReorder"
            style="width: 40px; padding: 0 12px;"
            rowReorder
            :reorderableColumn="false"
            :pt="getColumnPTOptions"
        />
        <Column
            v-if="arrayNameForExpansion"
            expander
            headerStyle="width: 44px;"
            bodyStyle="width: 44px; border-bottom: 1px solid #E8E7EA; border-top: none"
            :pt="getColumnPTOptions"
        />
        <Column
            v-for="col, index in tableStructure"
            :key="`key-${col.field}-${index}`"
            :field="col.field"
            :pt="getColumnPTOptions"
            :headerStyle="`width: ${col.columnWidth}; max-width: ${col.columnWidth}`"
            :bodyStyle="`width: ${col.columnWidth}; max-width: ${col.columnWidth}; border-bottom: 1px solid #E8E7EA; border-top: none`"
        >
            <template #header>
                <div
                    class="table__journal_header--cell-content"
                    @click="isHeaderClickedAction(col.field)"
                >
                    <p
                        class="table__journal_cell--title"
                    >
                        {{ col.header }}
                    </p>
                    <div
                        v-if="col.isSort"
                        class="sort__icon_container"
                    >
                        <i
                            :class="[
                                'sort__default_up',
                                {sort__default_ascending: columnNameActive !== col.field ||(columnNameActive === col.field && !isHeaderClicked)},
                                {sort__active_ascending: columnNameActive === col.field && isHeaderClicked}
                            ]"
                            v-html="getIconTemplate('sort-default-up')"
                        ></i>
                        <i
                            :class="[
                                'sort__default_down',
                                {sort__default_descending: columnNameActive !== col.field || (columnNameActive === col.field && isHeaderClicked)},
                                {sort__active_descending: columnNameActive === col.field && !isHeaderClicked}
                            ]"
                            v-html="getIconTemplate('sort-default-down')"
                        ></i>
                    </div>
                </div>
            </template>
            <template #body="slotProps">
                <p
                    v-if="slotProps.field === 'active' || slotProps.field === 'is_active'"
                    class="table__cell_checkbox"
                >
                    <AppCheckbox
                        key="form-lifetime-cases-material-app-row-app-row-form-group-app-checkbox-biopsy-container-status"
                        :checkboxId="`checkbox_${slotProps.data.id}`"
                        :checkboxClasses="['form__control', 'form__control_checkbox', 'form__group_leftcheckbox', 'brand__checkbox']"
                        :checkboxName="`checkbox_${slotProps.data.id}`"
                        :checkboxLabelClasses="['label__checkbox']"
                        :dataForChangeFromParent="slotProps.data.active || slotProps.data.is_active"
                        :dataForChangeFromParentStatus="true"
                        :collectDataToStore="actionByCheckboxClick"
                        :actionByCheckboxClick="additionalActionByCheckboxClick"
                        @actionByCheckboxClick="collectDataByCheckboxClick(slotProps.data)"
                    />
                </p>
                <div 
                    v-else-if="slotProps.field === 'download'"
                    class="table__cell"
                    style="display: flex; align-items: center; justify-content: center;"
                >
                    <i
                        style="cursor: pointer;"
                        @click="additionalRowClick(index)"
                        v-html="getIconTemplate('icon-download')"
                    ></i>
                </div>
                <div
                    v-else
                    class="table__cell"
                    :style="tableImage ? { display: 'flex', gap: '12px' } : tableImage"
                    @click.capture="getActionByRowClick(slotProps.data)"
                >
                    <i
                        v-if="tableImage"
                        v-html="getIconTemplate(tableImage)"
                    ></i>
                    <p
                        v-tooltip.top="{
                            value: `${slotProps.data[slotProps.field]}`,
                            disabled: isShowTooltip,
                            showDelay: 600,
                            hideDelay: 200,
                            autoHide: false
                        }"
                        class="table__cell_text"
                        :class="{ 'information__link': slotProps.field === 'name' && linkClassName }"
                        @mouseover="setShowTooltipFlag"
                        @mouseout="unsetShowTooltipFlag"
                    >
                        {{ col.deepObjectTitle ? slotProps.data[slotProps.field]?.[col.deepObjectTitle] : slotProps.data[slotProps.field] }}
                    </p>
                </div>
            </template>
        </Column>
        <Column
            v-for="col, index in tableSlotStructure"
            :key="`key-${col.field}-${index}`"
            :field="col.field"
            :pt="getSlotColumnPTOptions(col.isAlignCenter)"
            :headerStyle="`width: ${col.columnWidth}`"
            :bodyStyle="`max-width: ${col.columnWidth}`"
        >
            <template #header>
                {{ col.header }}
            </template>
            <template #body="slotProps">
                <slot :name="col.field" :data="slotProps.data">
                    {{ slotProps.data[slotProps.field] }}
                </slot>
            </template>
        </Column>
        <Column
            :headerStyle="`width: 0`"
        >
            <template #body="{data, index}">
                <AppTableButtonBlock
                    v-show="buttonBlockDisplayStatus"
                    key="app-data-table-app-table-button-block"
                    :descriptionAction="getDescriptionItemInTableAction"
                    :editAction="editingItemInTableAction"
                    :deleteAction="deleteItemFromTableAction"
                    :argumentForAction="getArgumentForAction(data, index)"
                    :updateButtonShowStatus="!editingItemInTableAction"
                    :deleteButtonShowStatus="!deleteItemFromTableAction"
                />
            </template>
        </Column>
        <template #expansion="slotProps">
            <DataTable
                :value="slotProps.data[arrayNameForExpansion]"
                :pt="{
                    wrapper: {class: 'table__wrapper'},
                    bodyRow: {class: 'table__row'},
                    headerRow: {class: 'table__header--row'},
                }"
            >
                <Column
                    headerStyle="width: 44px"
                    bodyStyle="width: 44px; border-bottom: 1px solid #E8E7EA; border-top: none"
                    :pt="getColumnPTOptions"
                />
                <Column
                    v-for="col, index in tableStructureForExpansion"
                    :key="`key-${col.field}-${index}`"
                    :field="col.field"
                    :pt="getSlotColumnPTOptions(col.isAlignCenter)"
                    :headerStyle="`width: ${col.columnWidth}`"
                    :bodyStyle="`width: ${col.columnWidth}`"
                >
                    <template #body="slotProps">
                        <div class="table__cell">
                            {{ slotProps.data[slotProps.field] }}
                        </div>
                    </template>
                </Column>
            </DataTable>
        </template>
    </DataTable>
</template>

<script>
import { icons, tooltip } from "@/mixins";
import AppTableButtonBlock from "@/components/AppTableButtonBlock.vue";
import AppCheckbox from '@/components/AppCheckbox.vue';

export default {
    name: "AppDataTable",
    mixins: [icons, tooltip],
    emits: ["loadMoreData", "sortBy", "collectDataByCheckboxClick"],
    props: {
        tableData: [Array, Object],
        tableStructure: Array,
        tableSlotStructure: Array,
        dataTableClasses: [Array, Object],
        tableScrollHeight: String,
        buttonBlockDisplayStatus: Boolean,
        deleteItemFromTableAction: Function,
        editingItemInTableAction: Function,
        getDescriptionItemInTableAction: Function,
        isUseFlagIndexArgument: Boolean,
        isHiddenTableHeader: Boolean,
        getNextDataListAccordingScrollPosition: Function,
        actionByRowClick: Function,
        actionByCheckboxClick: Function,
        additionalActionByCheckboxClick: Function,
        isSortableTable: Boolean,
        tableRowIdKey: String,
        isUseReorder: Boolean,
        tableImage: String,
        additionalRowClick: Function,
        linkClassName: Boolean,
        isLoading: Boolean,
        ptOption: Object,
        isScrollToTableTop: Boolean,
        arrayNameForExpansion: String,
        tableStructureForExpansion: Array,
    },
    data() {
        return {
            isHeaderClicked: false,
            columnNameActive: "",
            selectedRow: [],
            tableDataReorder: [],
            expandedRows: [],
            selectedExpandedRows: [],
        }
    },
    components: {
        AppTableButtonBlock,
        AppCheckbox
    },
    computed: {
        getTablePTOptions() {
            return {
                root: {class: ['table__container', {'table__container_layer': this.isUseReorder}]},
                wrapper: {class: 'table__wrapper'},
                bodyRow: {class: ['table__row', { table__row_clickable: this.actionByRowClick }, { todo__item: this.buttonBlockDisplayStatus }]},
                headerRow: {class: {'table__header--row': this.isHiddenTableHeader}},
            }
        },
        getCheckboxHeaderPTOptions() {
            return {
                headerCell: {class: 'table__journal_header--cell'},
                headerCheckbox: {class: 'table__journal_header--checkbox'},
                headerCheckboxIcon: {class: 'table__journal_header--checkbox-icon'},
                checkbox: {class: 'table__journal_header--checkbox'},
                checkboxIcon: {class: 'table__journal_header--checkbox-icon'},
                bodyCell: {class: 'table__journal_cell--checkbox'}
            }
        },
        getColumnPTOptions() {
            return {
                headerCell: {class: ['table__header--cell', {'table__header--cell-cursor': this.isSortableTable}]},
                rowToggler: {style: 'width: 32px'},
                rowTogglerIcon: {class: 'table__row_toggler'},
            }
        },
        getSlotColumnPTOptions() {
            return (flag) => {
                return {
                    headerCell: {class: ['table__header--cell', {'table__header--cell-cursor': this.isSortableTable}]},
                    headerContent: {class: [{'header__align_center': flag}]},
                    bodyCell: {class: 'table__journal_cell'},
                }
            }
        },
        getShowCondition() {
            return () => {
                switch (true) {
                    case this.tableData?.length !== 0:
                        return false;
                    default:
                        return true;
                }
            }
        },
        getArgumentForAction() {
            return (data, index) => {
                if (this.isUseFlagIndexArgument) {
                    return index;
                }
                if (data.id) {
                    return data.id;
                }
                return index;
            }
        },
        getActionByRowClick() {
            return (data) => {
                if (this.actionByRowClick) {
                    if (this.tableRowIdKey) {
                        return this.actionByRowClick(data[this.tableRowIdKey]);
                    }
                    return this.actionByRowClick(data.id);
                }
                return () => {};
            }
        },
    },
    methods: {
        isHeaderClickedAction(columnName) {
            if (columnName === "choice") {
                return;
            }
            if (this.columnNameActive !== columnName && this.columnNameActive.length !== 0) {
                this.isHeaderClicked = false;
            }
            switch (true) {
                case this.isHeaderClicked && this.columnNameActive === columnName:
                this.getCasesOrderByColumn(this.getOrderParam(columnName, false));
                    this.isHeaderClicked = false;
                    return;
                case !this.isHeaderClicked && this.columnNameActive === columnName:
                    this.getCasesOrderByColumn();
                    this.columnNameActive = "";
                    return;
                case !this.isHeaderClicked && this.columnNameActive !== columnName:
                    this.getCasesOrderByColumn(this.getOrderParam(columnName, true));
                    this.isHeaderClicked = true;
                    this.columnNameActive = columnName;
                    return;
                default:
                    return;
            }
        },
        getOrderParam(columnName, sortOrder) {
            switch(columnName) {
                case "active":
                    return sortOrder ? "active" : "-active";
                case "is_active":
                    return sortOrder ? "active" : "-active";
                case "name":
                    return sortOrder ? "name" : "-name";
                case "title":
                    return sortOrder ? "title" : "-title";
                case "full_name":
                    return sortOrder ? "full_name" : "-full_name";
                case "short_name":
                    return sortOrder ? "short_name" : "-short_name";
                case "organization_name":
                    return sortOrder ? "organization__short_name" : "-organization__short_name";
                case "department":
                    return sortOrder ? "department__name" : "-department__name";
                case "organization":
                    return sortOrder ? "department__organization__short_name" : "-department__organization__short_name";
                case "service_code":
                    return sortOrder ? "service_code" : "-service_code";
                case "code_nsi":
                    return sortOrder ? "code_nsi" : "-code_nsi";
                default:
                    return "";
            }
        },
        getCasesOrderByColumn(orderParam = "") {
            this.$emit("sortBy", orderParam)
        },
        onRowReorder(event) {
            console.log('%cevent: ', 'color: green;', event);
            this.tableDataReorder = event.value;
        },
        handleScroll(event) {
            const target = event.target;
            if (target.scrollHeight - Math.ceil(target.scrollTop) <= target.clientHeight) {
                this.$emit("loadMoreData");
            }
        },
        scrollToTop() {
            const tableWrapper = document.querySelector('.table__wrapper');
            if (tableWrapper) {
                tableWrapper.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
        collectDataByCheckboxClick(data) {
            this.$emit("collectDataByCheckboxClick", data);
        },
    },
    watch: {
        isScrollToTableTop(newVal) {
            if (newVal) {
                this.scrollToTop();
            }
        }
    },
    mounted() {
        const wrapper = document.querySelector('.table__wrapper');
        if (wrapper) {
            wrapper.addEventListener('scroll', this.handleScroll);
        }
    },
    beforeUnmount() {
        const wrapper = document.querySelector('.table__wrapper');
        if (wrapper) {
            wrapper.removeEventListener('scroll', this.handleScroll);
        }
    },
}
</script>

<style lang="sass">
@import "@/assets/css/_vars.sass"
.table__container
    margin: 16px 0
    &_layer
        z-index: 3
.table__container--bottom-margin
    margin-bottom: -11px
.table__header--row
    display: none
.table__header--cell
    border-top: none
    border-bottom: 1px solid $tableJournalRowBorderColor
    background-color: $tableJournalHeaderBackgroundColor
    font-size: 12px
    font-style: normal
    font-weight: 700
    line-height: 18px
    height: 40px
    white-space: nowrap
    padding: 12px
    border-radius: 4px 4px 0px 0px
    &-cursor
        cursor: pointer
.table__row
    position: relative
    height: 40px
    font-size: 12px
    font-style: normal
    font-weight: 500
    line-height: 18px
    background-color: white
    &.p-highlight
        background-color: transparent
        color: black
.table__row_clickable
    cursor: pointer
    &:hover,
    &.p-highlight:hover
        background-color: $tableJournalRowHoverBackgroundColor
// .table__row:last-child > td
//     border-bottom: 1px solid $tableJournalRowBorderColor
.table__cell
    align-items: center
    padding-left: 12px
    padding-right: 12px
.table__cell_text
    line-height: 40px
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap
    color: $textPrimaryBlack
.table__cell_checkbox
    text-align: center
.header__align_center
    display: flex
    justify-content: center
</style>