import store from "@/store";

export const debounceLoader = async (fetchAction, entityFlag) => {
    const timeoutID = setTimeout(()=> {
        store.dispatch(getIsComponentLoaderAction(entityFlag), true);
    }, 200);
    const data = await fetchAction;
    if (data.results) {
        clearTimeout(timeoutID);
    }
    store.dispatch(getIsComponentLoaderAction(entityFlag), false);
    return data;
}

const getIsComponentLoaderAction = (entityFlag) => {
    switch (entityFlag) {
        case "referenceList":
            return "updateIsComponentLoaderReferenceListActive";
        case "settingsReference":
            return "updateIsComponentLoaderSettingsReferenceActive";
        case "lifetimeCase":
            return "updateIsComponentLoaderLifetimecaseActive";
        default:
            return;
    }
}