import { api } from "@/api";

export default {
    state: {
        biopsyForms: [],
        biopsyFormsData: [
            {
                "id": 1,
                "title": "Эрозия"
            },
            {
                "id": 2,
                "title": "Язва"
            },
            {
                "id": 3,
                "title": "Полип"
            },
            {
                "id": 4,
                "title": "Пятно"
            },
            {
                "id": 5,
                "title": "Узел"
            },
            {
                "id": 6,
                "title": "Внешняя неизмененная ткань"
            },
            {
                "id": 7,
                "title": "Другое"
            }
        ],
    },
    actions: {
        async fetchBiopsyForms({ commit, state }) {
            try {
                const token = JSON.parse(localStorage.accessTokenData).access;
                const biopsyForms = await api.getReferenceBiopsyForm(token);

                if (typeof biopsyForms === "number") {
                    // dispatch("setExceptionAccordingResponseCode", biopsyForms);
                    commit("changeBiopsyForms", state.biopsyFormsData);
                    return;
                }
                // dispatch("setExceptionAccordingResponseCode", biopsyForms);
                commit("changeBiopsyForms", biopsyForms);
            } catch (e) {
                console.error(e);
                return e;
            }
        },
        clearBiopsyFormData({ commit }) {
            commit("changeBiopsyForms", []);
        }
    },
    mutations: {
        changeBiopsyForms(state, biopsyForms) {
            state.biopsyForms = biopsyForms;
        },
    },
    getters: {
        getBiopsyForms(state) {
            return state.biopsyForms;
        }
    }
}
