import { api } from "@/api";
import { debounceLoader } from "@/helpers/debounceLoader";

export default {
    state: {
        organizationDepartmentReferencePage: 1,
        organizationDepartmentReferencePageSize: 50,
        organizationDepartmentReferenceListShowStatus: false,
        organizationDepartments: [],
        isOrganizationDepartmentNotFound: false,
        organizationDepartmentSelectedName: "",
        organizationDepartmentSelectedItemId: null
    },

    actions: {
        async fetchNextOrganizationDepartments({ state, commit, dispatch }) {
            if (state.organizationDepartments?.next === null) {
                return;
            }
            commit("changeOrganizationDepartmentReferencePage", state.organizationDepartmentReferencePage + 1);
            dispatch("receiveOrganizationDepartments", "next");
        },
        async receiveOrganizationDepartments({ commit, state, dispatch, rootGetters }, status) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                if (!status) {
                    commit("changeOrganizationDepartmentReferencePage", 1);
                }
                switch (rootGetters.getOrganizationInformationData !== null) {
                    case rootGetters.getOrganizationInformationData?.request_organization_department_short !== undefined && !rootGetters.getInputLifetimeFocusEventStatus:
                        if (rootGetters.getOrganizationInformationData?.request_organization_department_short.length !== 0 && rootGetters.getOrganizationInformationData?.request_organization_department_short === state.organizationDepartmentSelectedName) {
                            return;
                        }
                        dispatch("fetchOrganizationDepartments", [token, state.organizationDepartmentReferencePage, state.organizationDepartmentReferencePageSize, rootGetters.getOrganizationInformationData?.request_organization_department_short, rootGetters.getOrganizationSelectedItemId, status]);
                        return;
                    case rootGetters.getOrganizationInformationData?.request_organization_department_short !== undefined && rootGetters.getInputLifetimeFocusEventStatus && rootGetters.getOrganizationInformationData?.request_organization_department_short.length === 0:
                        dispatch("fetchOrganizationDepartments", [token, state.organizationDepartmentReferencePage, state.organizationDepartmentReferencePageSize, "", rootGetters.getOrganizationSelectedItemId, status]);
                        return;
                    default:
                        return;
                }
            });
        },
        async fetchOrganizationDepartments({ dispatch, state, rootGetters }, [token, page, pageSize, department, organizationId, status]) {
            try {
                if (organizationId === null || (!rootGetters.getInputLifetimeFocusEventStatus && department.length === 0)) {
                    return;
                }
                const departments = await debounceLoader(
                    api.getReferenceOrganizationDepartment(page, pageSize, token, department, organizationId),
                    "referenceList"
                );
                if (typeof departments === "number") {
                    return;
                }
                if (departments.results.length === 0) {
                    dispatch("updateOrganizationDepartmentReferenceListShowStatus", false);
                    if (state.isOrganizationDepartmentNotFound) {
                        return;
                    }
                    dispatch("updateIsOrganizationDepartmentNotFound", true);
                    return;
                }

                // dispatch("setExceptionAccordingResponseCode", departments);

                switch (status) {
                    case "next":
                        dispatch("updateOrganizationDepartmentsByNextStatus", departments);
                        break;
                    default:
                        dispatch("updateOrganizationDepartments", departments);
                        break;
                }
                dispatch("updateIsOrganizationDepartmentNotFound", false);
                dispatch("updateOrganizationDepartmentReferenceListShowStatus", true);
            } catch (e) {
                console.error(e);
                // dispatch("setExceptionAccordingResponseCode", e.status);
                dispatch("updateOrganizationDepartmentReferenceListShowStatus", false);
            }
        },
        updateOrganizationDepartments({ commit }, departments) {
            commit("changeOrganizationDepartments", departments);
        },
        updateOrganizationDepartmentsByNextStatus({ commit }, departments) {
            commit("changeOrganizationDepartmentsByNextStatus", departments);
        },
        updateOrganizationDepartmentReferenceListShowStatus({ commit }, status) {
            commit("changeOrganizationDepartmentReferenceListShowStatus", status);
        },
        updateIsOrganizationDepartmentNotFound({ commit }, status) {
            commit("changeIsOrganizationDepartmentNotFound", status);
        },
        updateOrganizationDepartmentSelectedName({ commit }, organizationDepartmentSelectedName) {
            commit("changeOrganizationDepartmentSelectedName", organizationDepartmentSelectedName);
        },
        updateOrganizationDepartmentSelectedItemId({ commit, dispatch }, organizationDepartmentSelectedItemId) {
            commit("changeOrganizationDepartmentSelectedItemId", organizationDepartmentSelectedItemId);

            if (organizationDepartmentSelectedItemId === null || organizationDepartmentSelectedItemId === undefined) {
                dispatch("updateLifetimeOrganizationDepartmentDoctorFieldDisabledStatus", true);
                return;
            }
            dispatch("updateLifetimeOrganizationDepartmentDoctorFieldDisabledStatus", false);
        },
        clearReferenceOrganizationDepartmentData({ commit }) {
            commit("changeOrganizationDepartmentReferencePage", 1);
            commit("changeOrganizationDepartments", []);
            commit("changeOrganizationDepartmentSelectedName", "");
            commit("changeOrganizationDepartmentSelectedItemId", null);
        }
    },

    mutations: {
        changeOrganizationDepartmentReferencePage(state, organizationDepartmentReferencePage) {
            state.organizationDepartmentReferencePage = organizationDepartmentReferencePage;
        },
        changeOrganizationDepartments(state, departments) {
            state.organizationDepartments = departments;
        },
        changeOrganizationDepartmentsByNextStatus(state, departments) {
            state.organizationDepartments.results = [...state.organizationDepartments?.results, ...departments?.results];
            state.organizationDepartments.next = departments?.next;
            state.organizationDepartments.previous = departments?.previous;
        },
        changeOrganizationDepartmentReferenceListShowStatus(state, status) {
            state.organizationDepartmentReferenceListShowStatus = status;
        },
        changeIsOrganizationDepartmentNotFound(state, status) {
            state.isOrganizationDepartmentNotFound = status;
        },
        changeOrganizationDepartmentSelectedName(state, organizationDepartmentSelectedName) {
            state.organizationDepartmentSelectedName = organizationDepartmentSelectedName;
        },
        changeOrganizationDepartmentSelectedItemId(state, organizationDepartmentSelectedItemId) {
            state.organizationDepartmentSelectedItemId = organizationDepartmentSelectedItemId;
        }
    },

    getters: {
        getOrganizationDepartments(state) {
            return state.organizationDepartments;
        },
        getOrganizationDepartmentReferenceListShowStatus(state) {
            return state.organizationDepartmentReferenceListShowStatus;
        },
        getIsOrganizationDepartmentNotFound(state) {
            return state.isOrganizationDepartmentNotFound;
        },
        getOrganizationDepartmentSelectedName(state) {
            return state.organizationDepartmentSelectedName;
        },
        getOrganizationDepartmentSelectedItemId(state) {
            return state.organizationDepartmentSelectedItemId;
        }
    }
}
