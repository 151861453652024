import { api } from "@/api";
import { debounceLoader } from "@/helpers/debounceLoader";

export default {
    state: {
        organizationDepartmentDoctorReferencePage: 1,
        organizationDepartmentDoctorReferencePageSize: 50,
        organizationDepartmentDoctorReferenceListShowStatus: false,
        organizationDepartmentDoctors: [],
        isOrganizationDepartmentDoctorNotFound: false,
        organizationDepartmentDoctorSelectedName: "",
        organizationDepartmentDoctorSelectedItemId: null,
        organizationDepartmentDoctorPhone: ""
    },

    actions: {
        async fetchNextOrganizationDepartmentDoctors({ state, commit, dispatch }) {
            if (state.organizationDepartmentDoctors?.next === null) {
                return;
            }
            commit("changeOrganizationDepartmentDoctorReferencePage", state.organizationDepartmentDoctorReferencePage + 1);
            dispatch("receiveOrganizationDepartmentDoctors", "next");
        },
        async receiveOrganizationDepartmentDoctors({ commit, state, dispatch, rootGetters }, status) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                if (!status) {
                    commit("changeOrganizationDepartmentDoctorReferencePage", 1);
                }
                switch (rootGetters.getOrganizationInformationData !== null) {
                    case rootGetters.getOrganizationInformationData?.request_organization_doctor_name !== undefined && !rootGetters.getInputLifetimeFocusEventStatus:
                        if (rootGetters.getOrganizationInformationData?.request_organization_doctor_name.length !== 0 && rootGetters.getOrganizationInformationData?.request_organization_doctor_name === state.organizationDepartmentDoctorSelectedName) {
                            return;
                        }
                        dispatch("fetchOrganizationDepartmentDoctors", [
                            token, state.organizationDepartmentDoctorReferencePage,
                            state.organizationDepartmentDoctorReferencePageSize,
                            rootGetters.getOrganizationInformationData?.request_organization_doctor_name,
                            rootGetters.getOrganizationSelectedItemId,
                            rootGetters.getOrganizationDepartmentSelectedItemId,
                            status
                        ]);
                        return;
                    case rootGetters.getOrganizationInformationData?.request_organization_doctor_name !== undefined && rootGetters.getInputLifetimeFocusEventStatus && rootGetters.getOrganizationInformationData?.request_organization_doctor_name.length === 0:
                        dispatch("fetchOrganizationDepartmentDoctors", [
                            token, state.organizationDepartmentDoctorReferencePage,
                            state.organizationDepartmentDoctorReferencePageSize,
                            "",
                            rootGetters.getOrganizationSelectedItemId,
                            rootGetters.getOrganizationDepartmentSelectedItemId,
                            status
                        ]);
                        return;
                    default:
                        return;
                }
            });
        },
        async fetchOrganizationDepartmentDoctors({ dispatch, state, rootGetters }, [token, page, pageSize, doctor, organizationId, departmentId, status]) {
            try {
                if (organizationId === null || departmentId === null || (!rootGetters.getInputLifetimeFocusEventStatus && doctor.length === 0)) {
                    return;
                }
                const doctors = await debounceLoader(
                    api.getReferenceOrganizationDepartmentDoctor(page, pageSize, token, doctor, organizationId, departmentId),
                    "referenceList"
                );
                if (typeof doctors === "number") {
                    return;
                }
                if (doctors.results.length === 0) {
                    dispatch("updateOrganizationDepartmentDoctorReferenceListShowStatus", false);
                    if (state.isOrganizationDepartmentDoctorNotFound) {
                        return;
                    }
                    dispatch("updateIsOrganizationDepartmentDoctorNotFound", true);
                    return;
                }

                // dispatch("setExceptionAccordingResponseCode", doctors);

                switch (status) {
                    case "next":
                        dispatch("updateOrganizationDepartmentDoctorsByNextStatus", doctors);
                        break;
                    default:
                        dispatch("updateOrganizationDepartmentDoctors", doctors);
                        break;
                }

                dispatch("updateIsOrganizationDepartmentDoctorNotFound", false);
                dispatch("updateOrganizationDepartmentDoctorReferenceListShowStatus", true);
            } catch (e) {
                console.error(e);
                // dispatch("setExceptionAccordingResponseCode", e.status);
                dispatch("updateOrganizationDepartmentDoctorReferenceListShowStatus", false);
            }
        },
        updateOrganizationDepartmentDoctors({ commit }, doctors) {
            commit("changeOrganizationDepartmentDoctors", doctors);
        },
        updateOrganizationDepartmentDoctorsByNextStatus({ commit }, doctors) {
            commit("changeOrganizationDepartmentDoctorsByNextStatus", doctors);
        },
        updateOrganizationDepartmentDoctorReferenceListShowStatus({ commit }, status) {
            commit("changeOrganizationDepartmentDoctorReferenceListShowStatus", status);
        },
        updateIsOrganizationDepartmentDoctorNotFound({ commit }, status) {
            commit("changeIsOrganizationDepartmentDoctorNotFound", status);
        },
        updateOrganizationDepartmentDoctorSelectedName({ commit, dispatch }, organizationDepartmentDoctorSelectedName) {
            commit("changeOrganizationDepartmentDoctorSelectedName", organizationDepartmentDoctorSelectedName);
            if (organizationDepartmentDoctorSelectedName === "") {
                dispatch("updateOrganizationDepartmentDoctorPhone", "");
            }
        },
        updateOrganizationDepartmentDoctorSelectedItemId({ commit }, organizationDepartmentDoctorSelectedItemId) {
            commit("changeOrganizationDepartmentDoctorSelectedItemId", organizationDepartmentDoctorSelectedItemId);
        },
        updateOrganizationDepartmentDoctorPhone({ commit }, phone) {
            if(phone === undefined) {
                return;
            }
            commit("changeOrganizationDepartmentDoctorPhone", phone);
        },
        clearReferenceOrganizationDepartmentDoctorData({ commit }) {
            commit("changeOrganizationDepartmentDoctorReferencePage", 1);
            commit("changeOrganizationDepartmentDoctors", []);
            commit("changeOrganizationDepartmentDoctorSelectedName", "");
            commit("changeOrganizationDepartmentDoctorSelectedItemId", null);
            commit("changeOrganizationDepartmentDoctorPhone", "");
        }
    },

    mutations: {
        changeOrganizationDepartmentDoctorReferencePage(state, organizationDepartmentDoctorReferencePage) {
            state.organizationDepartmentDoctorReferencePage = organizationDepartmentDoctorReferencePage;
        },
        changeOrganizationDepartmentDoctors(state, doctors) {
            state.organizationDepartmentDoctors = doctors;
        },
        changeOrganizationDepartmentDoctorsByNextStatus(state, doctors) {
            state.organizationDepartmentDoctors.results = [...state.organizationDepartmentDoctors?.results, ...doctors?.results];
            state.organizationDepartmentDoctors.next = doctors?.next;
            state.organizationDepartmentDoctors.previous = doctors?.previous;
        },
        changeOrganizationDepartmentDoctorReferenceListShowStatus(state, status) {
            state.organizationDepartmentDoctorReferenceListShowStatus = status;
        },
        changeIsOrganizationDepartmentDoctorNotFound(state, status) {
            state.isOrganizationDepartmentDoctorNotFound = status;
        },
        changeOrganizationDepartmentDoctorSelectedName(state, organizationDepartmentDoctorSelectedName) {
            state.organizationDepartmentDoctorSelectedName = organizationDepartmentDoctorSelectedName;
        },
        changeOrganizationDepartmentDoctorSelectedItemId(state, organizationDepartmentDoctorSelectedItemId) {
            state.organizationDepartmentDoctorSelectedItemId = organizationDepartmentDoctorSelectedItemId;
        },
        changeOrganizationDepartmentDoctorPhone(state, organizationDepartmentDoctorPhone) {
            state.organizationDepartmentDoctorPhone = organizationDepartmentDoctorPhone;
        }
    },

    getters: {
        getOrganizationDepartmentDoctors(state) {
            return state.organizationDepartmentDoctors;
        },
        getOrganizationDepartmentDoctorReferenceListShowStatus(state) {
            return state.organizationDepartmentDoctorReferenceListShowStatus;
        },
        getIsOrganizationDepartmentDoctorNotFound(state) {
            return state.isOrganizationDepartmentDoctorNotFound;
        },
        getOrganizationDepartmentDoctorSelectedName(state) {
            return state.organizationDepartmentDoctorSelectedName;
        },
        getOrganizationDepartmentDoctorSelectedItemId(state) {
            return state.organizationDepartmentDoctorSelectedItemId;
        },
        getOrganizationDepartmentDoctorPhone(state) {
            return state.organizationDepartmentDoctorPhone;
        }
    }
}
