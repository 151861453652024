export const settingsUsersFields = {
    data() {
        return {
            settingsUsersTableDescriptionData: [
                { field: "is_active", header: "Активна", isSort: true, columnWidth: "90px"},
                { field: "employee_name", header: "Сотрудник", columnWidth: "350px"},
                { field: "positions", header: "Должность", columnWidth: "350px"},
                { field: "username", header: "Учётная запись", columnWidth: "auto"},
            ],
        }
    },
}