import { api } from "@/api";
import { debounceLoader } from "@/helpers/debounceLoader";

export default {
    state: {
        localizationReferencePage: 1,
        localizationReferencePageSize: 50,
        localizationReferenceListShowStatus: false,
        localizations: [],
        isLocalizationNotFound: false,
        localizationSelectedName: "",
        localizationSelectedItemId: null
    },

    actions: {
        async fetchNextLocalizations({ state, commit, dispatch }) {
            if (state.localizations?.next === null) {
                return;
            }
            commit("changeLocalizationReferencePage", state.localizationReferencePage + 1);
            dispatch("receiveLocalizations", "next");
        },
        async receiveLocalizations({ commit, state, dispatch, rootGetters }, status) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                if (!status) {
                    commit("changeLocalizationReferencePage", 1);
                }
                switch (rootGetters.getLifetimeBiopsySamplesForm !== null) {
                    case rootGetters.getLifetimeBiopsySamplesForm?.flasks?.localization.localization_title !== undefined && !rootGetters.getInputLifetimeFocusEventStatus:
                        if (rootGetters.getLifetimeBiopsySamplesForm?.flasks?.localization.localization_title.length !== 0 && rootGetters.getLifetimeBiopsySamplesForm?.flasks?.localization.localization_title === state.localizationSelectedName) {
                            return;
                        }
                        dispatch("fetchLocalizations", [token, state.localizationReferencePage, state.localizationReferencePageSize, rootGetters.getLifetimeBiopsySamplesForm?.flasks?.localization.localization_title, status]);
                        return;
                    case rootGetters.getLifetimeBiopsySamplesForm?.flasks?.localization.localization_title !== undefined && rootGetters.getInputLifetimeFocusEventStatus && rootGetters.getLifetimeBiopsySamplesForm?.flasks?.localization.localization_title.length === 0:
                        dispatch("fetchLocalizations", [token, state.localizationReferencePage, state.localizationReferencePageSize, "", status]);
                        return;
                    default:
                        return;
                }
            });
        },
        async fetchLocalizations({ dispatch, state }, [token, page, pageSize, localization, status]) {
            try {
                const localizations = await debounceLoader(
                    api.getReferenceLocalization(page, pageSize, token, localization),
                    "referenceList"
                );

                // dispatch("setExceptionAccordingResponseCode", localizations);

                switch (status) {
                    case "next":
                        dispatch("updateLocalizationsByNextStatus", localizations);
                        break;
                    default:
                        dispatch("updateLocalizations", localizations);
                        break;
                }

                if (localizations.results.length === 0) {
                    dispatch("updateLocalizationReferenceListShowStatus", false);
                    if (state.isLocalizationNotFound) {
                        return;
                    }
                    dispatch("updateIsLocalizationNotFound", true);
                    return;
                }

                dispatch("updateIsLocalizationNotFound", false);
                dispatch("updateLocalizationReferenceListShowStatus", true);
            } catch (e) {
                console.error(e);
                // dispatch("setExceptionAccordingResponseCode", e.status);
                dispatch("updateLocalizationReferenceListShowStatus", false);
            }
        },
        updateLocalizations({ commit }, localizations) {
            commit("changeLocalizations", localizations);
        },
        updateLocalizationsByNextStatus({ commit }, localizations) {
            commit("changeLocalizationsByNextStatus", localizations);
        },
        updateLocalizationReferenceListShowStatus({ commit }, status) {
            commit("changeLocalizationReferenceListShowStatus", status);
        },
        updateIsLocalizationNotFound({ commit }, status) {
            commit("changeIsLocalizationNotFound", status);
        },
        updateLocalizationSelectedName({ commit }, localizationSelectedName) {
            commit("changeLocalizationSelectedName", localizationSelectedName);
        },
        updateLocalizationSelectedItemId({ commit }, localizationSelectedItemId) {
            commit("changeLocalizationSelectedItemId", localizationSelectedItemId);
        },
        clearReferenceLocalizationData({ commit }) {
            commit("changeLocalizationSelectedItemId", null);
            commit("changeLocalizationSelectedName", "");
            commit("changeLocalizations", []);
            commit("changeLocalizationReferencePage", 1);
        },
    },

    mutations: {
        changeLocalizationReferencePage(state, localizationReferencePage) {
            state.localizationReferencePage = localizationReferencePage;
        },
        changeLocalizations(state, localizations) {
            state.localizations = localizations;
        },
        changeLocalizationsByNextStatus(state, localizations) {
            state.localizations.results = [...state.localizations?.results, ...localizations?.results];
            state.localizations.next = localizations?.next;
            state.localizations.previous = localizations?.previous;
        },
        changeLocalizationReferenceListShowStatus(state, status) {
            state.localizationReferenceListShowStatus = status;
        },
        changeIsLocalizationNotFound(state, status) {
            state.isLocalizationNotFound = status;
        },
        changeLocalizationSelectedName(state, localizationSelectedName) {
            state.localizationSelectedName = localizationSelectedName;
        },
        changeLocalizationSelectedItemId(state, localizationSelectedItemId) {
            state.localizationSelectedItemId = localizationSelectedItemId;
        }
    },

    getters: {
        getLocalizations(state) {
            return state.localizations;
        },
        getLocalizationReferenceListShowStatus(state) {
            return state.localizationReferenceListShowStatus;
        },
        getIsLocalizationNotFound(state) {
            return state.isLocalizationNotFound;
        },
        getLocalizationSelectedName(state) {
            return state.localizationSelectedName;
        },
        getLocalizationSelectedItemId(state) {
            return state.localizationSelectedItemId;
        }
    }
}
