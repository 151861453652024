export default {
    state: {
        mortemAutopsyGenitourinarySystemBtnStatus: false,
        mortemCaseGenitourinarySystemLiverSize: null,
        mortemCaseGenitourinarySystemLiverMass: null,
        mortemCaseGenitourinarySystemLiverConsistency: null,
        mortemCaseGenitourinarySystemLiverSurface: null,
        mortemCaseGenitourinarySystemLiverSectionView: null,
        mortemCaseGenitourinarySystemLiverCorticalThickness: null,
        mortemCaseGenitourinarySystemMucousMembrane: null,
        mortemCaseGenitourinarySystemUreters: null,
        mortemCaseGenitourinarySystemBladder: null,
        mortemCaseGenitourinarySystemProstate: null,
        mortemCaseGenitourinarySystemUterus: null,
        mortemCaseGenitourinarySystemFallopianTubes: null,
        mortemCaseGenitourinarySystemVagina: null,
        mortemCaseGenitourinarySystemOvaries: null,
    },
    actions: {
        updateMortemAutopsyGenitourinarySystemBtnStatus({ commit }, condition) {
            commit("changeMortemAutopsyGenitourinarySystemBtnStatus", condition);
        },
        updateAutopsyGenitourinarySystemField({ commit }, payload) {
            commit('changeAutopsyGenitourinarySystemField', payload);
        }
    },
    mutations: {
        changeMortemAutopsyGenitourinarySystemBtnStatus(state, condition) {
            state.mortemAutopsyGenitourinarySystemBtnStatus = condition;
        },
        changeAutopsyGenitourinarySystemField(state, { key, value }) {
            state[key] = value;
        }
    },
    getters: {
        getMortemAutopsyGenitourinarySystemBtnStatus(state) {
            return state.mortemAutopsyGenitourinarySystemBtnStatus;
        },
        getAutopsyGenitourinarySystemField: (state) => (key) => {
            return state[key];
        }
    }
}