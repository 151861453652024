import { lifetimeApi } from "@/api";

export default {
    state: {
        lifetimeRegisterNumberData: {},
        lifetimeRegisterNumberActiveStatus: false,
        lifetimeRegisterNumberTimeId: ""
    },
    actions: {
        async getLifetimeRegisterNumber({ dispatch, state, rootGetters }) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }

                if (rootGetters.getCaseAddModalShowBtn) {
                    dispatch("clearRegisterNumberDateTimeout", state.lifetimeRegisterNumberTimeId);
                    dispatch("getRegisterNumberDateLifeTimeStatus");
                }

                if (state.lifetimeRegisterNumberActiveStatus) {
                    dispatch("fetchLifetimeRegisterNumberExtend", [token]);
                    return;
                }

                dispatch("fetchLifetimeRegisterNumberData", [token]);
            });
        },
        async fetchLifetimeRegisterNumberData({ dispatch }, [token]) {
            try {
                localStorage.removeItem("vuex");

                const registerNumberData = await lifetimeApi.putLifetimeCaseRegisterNumberNew(token);
                dispatch("updateLifetimeRegisterNumberData", registerNumberData);
            } catch (e) {
                console.error(e);
            }
        },
        async fetchLifetimeRegisterNumberExtend({ dispatch, state }, [token]) {
            try {
                localStorage.removeItem("vuex");

                const registerNumberExtend = await lifetimeApi.postLifetimeCaseRegisterNumberExtend(state.lifetimeRegisterNumberData?.id, token);

                if (registerNumberExtend.id === undefined) {
                    console.log("Срок жизни номера истек. получаю новый");///
                    dispatch("runRegistrationNumberLogic", false);
                    return;
                } else {
                    console.log("Срок жизни номера продлен");///
                }
            } catch (e) {
                console.error(e);
            }
        },
        async fetchLifetimeRegisterNumberInvalidate({ commit, dispatch }, [registerNumberId, token]) {
            try {
                commit("changeLifetimeRegisterNumberActiveStatus", false);

                const registerNumberInvalidate = await lifetimeApi.postLifetimeCaseRegisterNumberInvalidate(registerNumberId, token);
                if (registerNumberInvalidate.status === 404) {
                    console.log("Номер не арендован");///
                    return;
                }
                if (registerNumberInvalidate.status === 200) {
                    console.log("Срок жизни номера аннулирован");///
                    localStorage.removeItem("vuex");
                    dispatch("updateLifetimeRegisterNumberData", {});
                    return;
                }
                // dispatch("updateCaseAddModalShowBtnStatus", false);
            } catch (e) {
                console.error(e);
                // dispatch("setExceptionAccordingResponseCode", e.status);
            }
        },
        getRegisterNumberDateLifeTimeStatus({ commit, dispatch, rootGetters }) {
            const dieRegisterNumberDate = 8500 + (new Date().getTime() * 1000);

            const timeOutId = setTimeout(() => {
                if (!rootGetters.getCaseAddModalShowBtn) {
                    commit("changeLifetimeRegisterNumberActiveStatus", false);
                    dispatch("clearRegisterNumberDateTimeout", timeOutId);
                    return;
                }

                if (new Date().getTime() >= Math.ceil((dieRegisterNumberDate - 1000) / 1000)) {
                    console.log("Пора продлить срок жизни номера");///
                    dispatch("runRegistrationNumberLogic", true);
                } else {
                    commit("changeLifetimeRegisterNumberActiveStatus", false);
                }
            }, 8500);

            commit("changeLifetimeRegisterNumberTimerId", timeOutId);
        },
        clearRegisterNumberDateTimeout({ dispatch }, lifetimeRegisterNumberTimeId) {
            clearTimeout(lifetimeRegisterNumberTimeId);
            dispatch("updateLifetimeRegisterNumberTimerId", "")
        },
        updateLifetimeRegisterNumberData({ commit }, registerNumber) {
            commit("changeLifetimeRegisterNumberData", registerNumber);
        },
        updateLifetimeRegisterNumberTimerId({ commit }, lifetimeRegisterNumberTimeId) {
            commit("changeLifetimeRegisterNumberTimerId", lifetimeRegisterNumberTimeId);
        },
        runRegistrationNumberLogic({ commit, dispatch }, lifetimeRegisterNumberActiveStatus) {
            commit("changeLifetimeRegisterNumberActiveStatus", lifetimeRegisterNumberActiveStatus);
            dispatch("getLifetimeRegisterNumber");
        }
    },
    mutations: {
        changeLifetimeRegisterNumberData(state, registerNumberData) {
            state.lifetimeRegisterNumberData = registerNumberData;
        },
        changeLifetimeRegisterNumberActiveStatus(state, lifetimeRegisterNumberActiveStatus) {
            state.lifetimeRegisterNumberActiveStatus = lifetimeRegisterNumberActiveStatus;
        },
        changeLifetimeRegisterNumberTimerId(state, lifetimeRegisterNumberTimeId) {
            state.lifetimeRegisterNumberTimeId = lifetimeRegisterNumberTimeId;
        }
    },
    getters: {
        getLifetimeRegisterNumberData(state) {
            return state.lifetimeRegisterNumberData;
        }
    }
}