import { api } from "@/api";


export default {
    state: {
        settingsReferenceEquipments: {},
        isSettingsReferenceEquipmentsLoading: false,
    },
    actions: {
        async fetchSettingsReferenceEquipment({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceEquipmentsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const equpments = await api.getSettingsReferenceEquipment(queryParams, token);
                    commit("changeSettingsReferenceEquipments", equpments.results);
                    commit("updateSettingsReferenceEquipmentsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferenceEquipmentsLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
                commit("updateSettingsReferenceEquipmentsLoader", false);
            }
        },
        async addSettingsReferenceEquipments({ dispatch, commit }, equipment ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const equipmentData = await api.postSettingsReferenceEquipment(equipment, token);
                    if (typeof equipmentData === "object") {
                        commit("addNewSettingsReferenceEquipments", equipmentData);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },

        async editSettingsReferenceEquipments({ dispatch, commit }, equipment ) {
            const token = await dispatch("getAccessToken");
            if (token) {
                const equipmentData = await api.patchSettingsReferenceEquipment(equipment, token);
                if (typeof equipmentData === "object") {
                    commit("updateSettingsReferenceEquipments", equipmentData);
                }
            } else {
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                return;
            }
        },
    },
    mutations: {
        changeSettingsReferenceEquipments(state, settingsReferenceEquipments) {
            state.settingsReferenceEquipments = settingsReferenceEquipments;
        },
        updateSettingsReferenceEquipmentsLoader(state, condition) {
            state.isSettingsReferenceEquipmentsLoading = condition;
        },
        updateSettingsReferenceEquipments(state, equipment) {
            const index = state.settingsReferenceEquipments.findIndex(item => item.id === equipment.id);
            if (index !== -1) {
                const updatedEquipment = { ...equipment }; // Создаем копию объекта equipment
                const uniqueId = Object.values(updatedEquipment).join('_'); // Создаем уникальный идентификатор на основе значений объекта
                updatedEquipment.uniqueId = uniqueId; // Добавляем новое поле uniqueId к объекту
                state.settingsReferenceEquipments.splice(index, 1, updatedEquipment); // Заменяем объект в массиве
            }
        },
        addNewSettingsReferenceEquipments(state, equipment) {
            state.settingsReferenceEquipments.unshift(equipment);
        },
    },
    getters: {
        getEquipmentsList(state) {
            return state.settingsReferenceEquipments;
        },
        getEquipmentsLoaderStatus(state) {
            return state.isSettingsReferenceEquipmentsLoading;
        },
    }
}