import { api } from "@/api";


export default {
    state: {
        settingsReferenceDecaltinationAgent: [],
    },
    actions: {
        async fetchSettingsReferenceDecaltinationAgent({ dispatch, commit }, queryParams) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const agents = await api.getSettingsReferenceDecaltinationAgent(queryParams, token);
                    commit("changeSettingsReferenceDecaltinationAgent", agents);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    mutations: {
        changeSettingsReferenceDecaltinationAgent(state, settingsReferenceDecaltinationAgent) {
            state.settingsReferenceDecaltinationAgent = settingsReferenceDecaltinationAgent;
        },
    },
    getters: {
        getDecaltinationAgentsList(state) {
            return state.settingsReferenceDecaltinationAgent;
        },
    }
}