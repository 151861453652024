export const caseStatus = {
    data() {
        return {
            statuses: {
                REGISTER: ["Регистрация", "badge__registered"],
                REGISTER_FINISHED: ["Регистрация", "badge__registered"],
                RECEPTION: ["Прием", "badge__reception"],
                MACROSCOPY: ["Макроскопия", "badge__reception"],
                SAMPLE_PREPARATION: ["Пробоподготовка", "badge__samplepreparation"],
                SAMPLE_PREPARATION_CITO: ["Пробоподготовка CITO", "badge__samplereception_cito"],
                MICROSCOPY: ["Микроскопия", "badge__reception"],
                WIP: ["В работе", "badge__monochrome"],
                DONE: ["Выполнен", "badge__done"],
                // NOSTATUS: ["", ""],
                ARCHIVE: ["Архив", "badge__archive"],
                DEFECT: ["Брак", "badge__defect"],
            },
            cassetteStatuses: {
                FIXATION: ["Дофиксация", "badge__additional_fixation"],
                DECALCIFICATION: ["Декальцинация", "badge__decaltination"],
                POSTING: ["Проводка", "badge__wiring"]
            },
            micropreparationStatuses: {
                POSTING: ["ПРОВОДКА", "badge__wiring"],
                FIXATION: ["ДОФИКСАЦИЯ", "badge__additional_fixation"],
                DECALCIFICATION: ["ДЕКАЛЬЦИНАЦИЯ", "badge__decaltination"],
                MICROTOMY: ["МИКРОТОМИЯ", "badge__microtomy"],
                FILLING: ["ЗАЛИВКА", "badge__filling"],
                ARCHIVE: ["АРХИВ", "badge__archive"],
                DEFECT: ["БРАК", "badge__defect"],
                Пробоподготовка: ["ПРОБОПОДГОТОВКА", "badge__samplepreparation"],
                Окраска: ["ОКРАСКА", "badge__coloring"],
                Микроскопия: ["МИКРОСКОПИЯ", "badge__reception"],
                Микротомия: ["МИКРОТОМИЯ", "badge__reception"],
            },
        }
    }
}