import { api } from "@/api";


export default {
    state: {
        settingsReferenceDefectTypeCases: {},
        isSettingsReferenceDefectTypeCasesLoading: false,
    },
    actions: {
        async fetchSettingsReferenceDefectTypeCase({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceDefectTypeCasesLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const defects = await api.getReferenceDefects(queryParams, token);
                    commit("changeSettingsReferenceDefectTypeCases", defects.results);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            } finally {
                commit("updateSettingsReferenceDefectTypeCasesLoader", false);
            }
        },
        async addSettingsReferenceDefectTypeCases({ dispatch, commit }, defect ) {
            const token = await dispatch("getAccessToken");
            if (token) {
                const defectData = await api.postReferenceDefects(defect, token);
                if (typeof defectData === "object") {
                    commit("addNewSettingsReferenceDefectTypeCases", defectData);
                }
            } else {
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                return;
            }
        },
        async editSettingsReferenceDefectTypeCases({ dispatch, commit }, defect ) {
            const token = await dispatch("getAccessToken");
            if (token) {
                const defectData = await api.patchReferenceDefects(defect, token);
                if (typeof defectData === "object") {
                    commit("updateSettingsReferenceDefectTypeCases", defectData);
                }
            } else {
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                return;
            }
        },
    },
    mutations: {
        changeSettingsReferenceDefectTypeCases(state, settingsReferenceDefectTypeCases) {
            state.settingsReferenceDefectTypeCases = settingsReferenceDefectTypeCases;
        },
        updateSettingsReferenceDefectTypeCasesLoader(state, condition) {
            state.isSettingsReferenceDefectTypeCasesLoading = condition;
        },
        updateSettingsReferenceDefectTypeCases(state, defectType) {
            const index = state.settingsReferenceDefectTypeCases.findIndex(item => item.id === defectType.id);
            if (index !== -1) {
                const updatedDefectType = { ...defectType }; // Создаем копию объекта defectType
                const uniqueId = Object.values(updatedDefectType).join('_'); // Создаем уникальный идентификатор на основе значений объекта
                updatedDefectType.uniqueId = uniqueId; // Добавляем новое поле uniqueId к объекту
                state.settingsReferenceDefectTypeCases.splice(index, 1, updatedDefectType); // Заменяем объект в массиве
            }
        },
        addNewSettingsReferenceDefectTypeCases(state, defectType) {
            state.settingsReferenceDefectTypeCases.unshift(defectType);
        },
    },
    getters: {
        getSettingsReferenceDefectTypeCases(state) {
            return state.settingsReferenceDefectTypeCases;
        },
        getSettingsReferenceDefectTypeCasesLoaderStatus(state) {
            return state.isSettingsReferenceDefectTypeCasesLoading;
        },
    }
}