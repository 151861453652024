export const tooltip = {
    data() {
        return {
            isShowTooltip: true
        }
    },
    methods: {
        setShowTooltipFlag(event) {
            if (event.target.offsetWidth < event.target.scrollWidth) {
                this.isShowTooltip = false;
            }
        },
        unsetShowTooltipFlag(event) {
            if (event.target.offsetWidth < event.target.scrollWidth) {
                this.isShowTooltip = true;
            }
        }
    }
}