export const referenceFormsFields = {
    data() {
        return {
            referenceFormsTableDescriptionData: [
                { field: "active", header: "Активна", columnWidth: "100px"},
                { field: "title", header: "Название", columnWidth: "465px"},
                { field: "comment", header: "Комментарий", columnWidth: "auto"},
            ],
        }
    },
}