import { api } from "@/api";


export default {
    state: {
        settingsReferenceDepartmentPage: 1,
        settingsReferenceDepartmentPageSize: 50,
        settingsReferenceDepartments: [],
        settingsReferenceDepartmentsTotalPages: null,
        isSettingsReferenceDepartmentsLoading: false,
    },
    actions: {
        async fetchSettingsReferenceDepartment({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceDepartmentsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const departments = await api.getReferenceOrganizationDepartment(queryParams, token);
                    commit("changeSettingsReferenceDepartments", departments.results);
                    commit("changeSettingsReferenceDepartmentsTotalPages", departments.total_pages);
                    commit("updateSettingsReferenceDepartmentsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferenceDepartmentsLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async fetchMoreSettingsReferenceDepartments({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceDepartmentsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const departments = await api.getReferenceOrganizationDepartment(queryParams, token);
                    commit("updateMoreSettingsReferenceDepartments", departments.results);
                    commit("updateSettingsReferenceDepartmentsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferenceDepartmentsLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("updateSettingsReferenceDepartmentsLoader", false);
            }

        },
        async addSettingsReferenceDepartments({ dispatch, commit }, department ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const departmentsData = await api.postReferenceOrganizationDepartment(department, token);
                    if (typeof departmentsData === "object") {
                        commit("addNewSettingsReferenceDepartments", departmentsData);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async editSettingsReferenceDepartments({ dispatch, commit }, department ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const departmentsData = await api.patchReferenceOrganizationDepartment(department, department.id, token);
                    if (typeof departmentsData === "object") {
                        commit("updateSettingsReferenceDepartments", departmentsData);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    mutations: {
        changeSettingsReferenceDepartments(state, settingsReferenceDepartments) {
            state.settingsReferenceDepartments = settingsReferenceDepartments;
        },
        updateMoreSettingsReferenceDepartments(state, settingsReferenceDepartments) {
            state.settingsReferenceDepartments = [...state.settingsReferenceDepartments, ...settingsReferenceDepartments];
        },
        changeSettingsReferenceDepartmentsTotalPages(state, settingsReferenceDepartmentsTotalPages) {
            state.settingsReferenceDepartmentsTotalPages = settingsReferenceDepartmentsTotalPages;
        },
        updateSettingsReferenceDepartmentsLoader(state, condition) {
            state.isSettingsReferenceDepartmentsLoading = condition;
        },
        updateSettingsReferenceDepartments(state, department) {
            const index = state.settingsReferenceDepartments.findIndex(item => item.id === department.id);
            if (index !== -1) {
                const updatedDepartment = { ...department }; // Создаем копию объекта department
                const uniqueId = Object.values(updatedDepartment).join('_'); // Создаем уникальный идентификатор на основе значений объекта
                updatedDepartment.uniqueId = uniqueId; // Добавляем новое поле uniqueId к объекту
                state.settingsReferenceDepartments.splice(index, 1, updatedDepartment); // Заменяем объект в массиве
            }
        },
        addNewSettingsReferenceDepartments(state, department) {
            state.settingsReferenceDepartments.unshift(department);
        },
    },
    getters: {
        getSettingsReferenceDepartments(state) {
            return state.settingsReferenceDepartments;
        },
        getSettingsReferenceDepartmentsTotalPages(state) {
            return state.settingsReferenceDepartmentsTotalPages;
        },
        getSettingsReferenceDepartmentsLoaderStatus(state) {
            return state.isSettingsReferenceDepartmentsLoading;
        },
    }
}