export default {
    state: {
        mortemCaseArrivedDate: null,
        mortemCaseType: {
            id: 1,
            title: "Взрослый",
        },
        mortemCasePurpose: null,
        mortemCaseResponsibleStaffMember: null,
        mortemCaseArrivedDateInputFocus: false,
        mortemRegistrationGeneralBtnStatus: false
    },
    actions: {
        updateMortemCaseArrivedDate({ commit }, date) {
            commit("changeMortemCaseArrivedDate", date);
        },
        updateMortemCaseType({ commit }, type) {
            commit("changeMortemCaseType", type);
        },
        updateMortemCasePurpose({ commit }, purpose) {
            commit("changeMortemCasePurpose", purpose);
        },
        updateMortemCaseResponsibleStaffMember({ commit }, staff) {
            commit("changeMortemCaseResponsibleStaffMember", staff);
        },
        updateMortemCaseArrivedDateInputFocus({ commit }, mortemCaseArrivedDateInputFocus) {
            commit("changeMortemCaseArrivedDateInputFocus", mortemCaseArrivedDateInputFocus);
        },
        updateMortemRegistrationGeneralBtnStatus({ commit }, mortemRegistrationGeneralBtnStatus) {
            commit("changeMortemRegistrationGeneralBtnStatus", mortemRegistrationGeneralBtnStatus);
        }
    },
    mutations: {
        changeMortemCaseArrivedDate(state, date) {
            state.mortemCaseArrivedDate = date;
        },
        changeMortemCaseType(state, type) {
            state.mortemCaseType = type;
        },
        changeMortemCasePurpose(state, purpose) {
            state.mortemCasePurpose = purpose;
        },
        changeMortemCaseResponsibleStaffMember(state, staff) {
            state.mortemCaseResponsibleStaffMember = staff;
        },
        changeMortemCaseArrivedDateInputFocus(state, mortemCaseArrivedDateInputFocus) {
            state.mortemCaseArrivedDateInputFocus = mortemCaseArrivedDateInputFocus;
        },
        changeMortemRegistrationGeneralBtnStatus(state, mortemRegistrationGeneralBtnStatus) {
            state.mortemRegistrationGeneralBtnStatus = mortemRegistrationGeneralBtnStatus;
        }
    },
    getters: {
        getMortemCaseArrivedDate(state) {
            return state.mortemCaseArrivedDate;
        },
        getMortemCaseType(state) {
            return state.mortemCaseType;
        },
        getMortemCasePurpose(state) {
            return state.mortemCasePurpose;
        },
        getMortemCaseResponsibleStaffMember(state) {
            return state.mortemCaseResponsibleStaffMember;
        },
        getMortemCaseArrivedDateInputFocus(state) {
            return state.mortemCaseArrivedDateInputFocus;
        },
        getMortemRegistrationGeneralBtnStatus(state) {
            return state.mortemRegistrationGeneralBtnStatus;
        },
    }
}