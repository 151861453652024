import { api } from "@/api";
import { debounceLoader } from "@/helpers/debounceLoader";


export default {
    state: {
        settingsReferencePositionPage: 1,
        settingsReferencePositionPageSize: 50,
        settingsReferencePositions: {},
        settingsReferencePositionsTotalPages: null,
        isSettingsReferencePositionsLoading: false,
    },
    actions: {
        async fetchSettingsReferencePosition({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferencePositionsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const position = await debounceLoader(
                        api.getReferenceBiopsyPosition(queryParams, token),
                        "settingsReference"
                    );
                    commit("changeSettingsReferencePositions", position.results);
                    commit("changeSettingsReferencePositionsTotalPages", position.total_pages);
                    commit("updateSettingsReferencePositionsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferencePositionsLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async fetchMoreSettingsReferencePositions({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferencePositionsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const position = await api.getReferenceBiopsyPosition(queryParams, token);
                    commit("updateMoreSettingsReferencePositions", position.results);
                    commit("updateSettingsReferencePositionsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferencePositionsLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("updateSettingsReferencePositionsLoader", false);
            }

        },
        async addSettingsReferencePositions({ dispatch, commit }, position ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const positionData = await api.postReferenceBiopsyPosition(position, token);
                    if (typeof positionData === "object") {
                        commit("addNewSettingsReferencePosition", positionData);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async editSettingsReferencePositions({ dispatch, commit }, position ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const positionData = await api.patchReferenceBiopsyPosition(position, token);
                    if (typeof positionData === "object") {
                        commit("updateSettingsPositions", positionData);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    mutations: {
        changeSettingsReferencePositions(state, settingsReferencePositions) {
            state.settingsReferencePositions = settingsReferencePositions;
        },
        updateMoreSettingsReferencePositions(state, settingsReferencePositions) {
            state.settingsReferencePositions = [...state.settingsReferencePositions, ...settingsReferencePositions];
        },
        changeSettingsReferencePositionsTotalPages(state, settingsReferencePositionsTotalPages) {
            state.settingsReferencePositionsTotalPages = settingsReferencePositionsTotalPages;
        },
        updateSettingsReferencePositionsLoader(state, condition) {
            state.isSettingsReferencePositionsLoading = condition;
        },
        addNewSettingsReferencePosition(state, position) {
            state.settingsReferencePositions.unshift(position);
        },
        updateSettingsPositions(state, position) {
            const index = state.settingsReferencePositions.findIndex(item => item.id === position.id);
            if (index !== -1) {
                const updatedPosition = { ...position }; // Создаем копию объекта position
                const uniqueId = Object.values(updatedPosition).join('_'); // Создаем уникальный идентификатор на основе значений объекта
                updatedPosition.uniqueId = uniqueId; // Добавляем новое поле uniqueId к объекту
                state.settingsReferencePositions.splice(index, 1, updatedPosition); // Заменяем объект в массиве
            }
        },
    },
    getters: {
        getSettingsReferencePositions(state) {
            return state.settingsReferencePositions;
        },
        getSettingsReferencePositionsTotalPages(state) {
            return state.settingsReferencePositionsTotalPages;
        },
        getSettingsReferencePositionsLoaderStatus(state) {
            return state.isSettingsReferencePositionsLoading;
        },
    }
}