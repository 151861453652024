import { api } from "@/api";
import { debounceLoader } from "@/helpers/debounceLoader";


export default {
    state: {
        settingsReferenceFormPage: 1,
        settingsReferenceFormPageSize: 50,
        settingsReferenceForms: {},
        settingsReferenceFormsTotalPages: null,
        isSettingsReferenceFormsLoading: false,
    },
    actions: {
        async fetchSettingsReferenceForm({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceFormsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const form = await debounceLoader(
                        api.getReferenceBiopsyForm(queryParams, token),
                        "settingsReference"
                    );
                    commit("changeSettingsReferenceForms", form.results);
                    commit("changeSettingsReferenceFormsTotalPages", form.total_pages);
                    commit("updateSettingsReferenceFormsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferenceFormsLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async fetchMoreSettingsReferenceForms({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceFormsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const form = await api.getReferenceBiopsyForm(queryParams, token);
                    commit("updateMoreSettingsReferenceForms", form.results);
                    commit("updateSettingsReferenceFormsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferenceFormsLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("updateSettingsReferenceFormsLoader", false);
            }

        },
        async addSettingsReferenceForms({ dispatch, commit }, form ) {
            const token = await dispatch("getAccessToken");
            if (token) {
                const formData = await api.postReferenceBiopsyForm(form, token);
                if (typeof formData === "object") {
                    commit("addNewSettingsReferenceForms", formData);
                }
            } else {
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                return;
            }
        },
        async editSettingsReferenceForms({ dispatch, commit }, form ) {
            const token = await dispatch("getAccessToken");
            if (token) {
                const formData = await api.patchReferenceBiopsyForm(form, token);
                if (typeof formData === "object") {
                    commit("updateSettingsReferenceForms", formData);
                }
            } else {
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                return;
            }
        },
    },
    mutations: {
        changeSettingsReferenceForms(state, settingsReferenceForms) {
            state.settingsReferenceForms = settingsReferenceForms;
        },
        updateMoreSettingsReferenceForms(state, settingsReferenceForms) {
            state.settingsReferenceForms = [...state.settingsReferenceForms, ...settingsReferenceForms];
        },
        changeSettingsReferenceFormsTotalPages(state, settingsReferenceFormsTotalPages) {
            state.settingsReferenceFormsTotalPages = settingsReferenceFormsTotalPages;
        },
        updateSettingsReferenceFormsLoader(state, condition) {
            state.isSettingsReferenceFormsLoading = condition;
        },
        updateSettingsReferenceForms(state, form) {
            const index = state.settingsReferenceForms.findIndex(item => item.id === form.id);
            if (index !== -1) {
                const updatedForm = { ...form }; // Создаем копию объекта form
                const uniqueId = Object.values(updatedForm).join('_'); // Создаем уникальный идентификатор на основе значений объекта
                updatedForm.uniqueId = uniqueId; // Добавляем новое поле uniqueId к объекту
                state.settingsReferenceForms.splice(index, 1, updatedForm); // Заменяем объект в массиве
            }
        },
        addNewSettingsReferenceForms(state, form) {
            state.settingsReferenceForms.unshift(form);
        },
    },
    getters: {
        getSettingsReferenceForms(state) {
            return state.settingsReferenceForms;
        },
        getSettingsReferenceFormsTotalPages(state) {
            return state.settingsReferenceFormsTotalPages;
        },
        getSettingsReferenceFormsLoaderStatus(state) {
            return state.isSettingsReferenceFormsLoading;
        },
    }
}