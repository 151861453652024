export default {
    state: {
        sidebarMenuActive: "lifetimeCases",
        isShowArchiveSubmenu: false,
        isShowLifetimeCasesListSubmenu: false,
        isShowReferenceSubmenu: false,
        isShowSettingsSubmenu: false,
        isShowMortemCasesListSubmenu: false,
    },

    actions: {
        updateSidebarMenuActive({ commit }, sidebarMenuActive) {
            commit("changeSidebarMenuActive", sidebarMenuActive);
        },
        updateIsShowArchiveSubmenu({ commit }, sidebarSubmenuActiveStatus) {
            commit("changeIsShowArchiveSubmenu", sidebarSubmenuActiveStatus);
        },
        updateIsShowLifetimeCasesListSubmenu({ commit }, sidebarSubmenuActiveStatus) {
            commit("changeIsShowLifetimeCasesListSubmenu", sidebarSubmenuActiveStatus);
        },
        updateIsShowMortemCasesListSubmenu({ commit }, sidebarSubmenuActiveStatus) {
            commit("changeIsShowMortemCasesListSubmenu", sidebarSubmenuActiveStatus);
        },
        updateIsShowReferenceSubmenu({ commit }, referenceSubmenuStatus) {
            commit("changeIsShowReferenceSubmenu", referenceSubmenuStatus);
        },
        updateIsShowSettingsSubmenu({ commit }, settingsSubmenuStatus) {
            commit("changeIsShowSettingsSubmenu", settingsSubmenuStatus);
        },
        resetMenuStoreState({ dispatch }) {
            dispatch("updateSidebarMenuActive", "lifetimeCases");
            dispatch("updateIsShowArchiveSubmenu", false);
            dispatch("updateIsShowLifetimeCasesListSubmenu", false);
            dispatch("updateIsShowReferenceSubmenu", false);
            dispatch("updateIsShowSettingsSubmenu", false);
        }
    },

    mutations: {
        changeSidebarMenuActive(state, sidebarMenuActive) {
            state.sidebarMenuActive = sidebarMenuActive;
        },
        changeIsShowArchiveSubmenu(state, sidebarSubmenuActiveStatus) {
            state.isShowArchiveSubmenu = sidebarSubmenuActiveStatus;
        },
        changeIsShowLifetimeCasesListSubmenu(state, sidebarSubmenuActiveStatus) {
            state.isShowLifetimeCasesListSubmenu = sidebarSubmenuActiveStatus;
        },
        changeIsShowMortemCasesListSubmenu(state, sidebarSubmenuActiveStatus) {
            state.isShowMortemCasesListSubmenu = sidebarSubmenuActiveStatus;
        },
        changeIsShowReferenceSubmenu(state, referenceSubmenuStatus) {
            state.isShowReferenceSubmenu = referenceSubmenuStatus;
        },
        changeIsShowSettingsSubmenu(state, settingsSubmenuStatus) {
            state.isShowSettingsSubmenu = settingsSubmenuStatus;
        },
    },

    getters: {
        getSidebarMenuActive(state) {
            return state.sidebarMenuActive;
        },
        getIsShowArchiveSubmenu(state) {
            return state.isShowArchiveSubmenu;
        },
        getIsShowLifetimeCasesListSubmenu(state) {
            return state.isShowLifetimeCasesListSubmenu;
        },
        getIsShowMortemCasesListSubmenu(state) {
            return state.isShowMortemCasesListSubmenu;
        },
        getIsShowReferenceSubmenu(state) {
            return state.isShowReferenceSubmenu;
        },
        getIsShowSettingsSubmenu(state) {
            return state.isShowSettingsSubmenu;
        },
    }
}
