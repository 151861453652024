import { lifetimeCasesTabsApi } from "@/api";

export default {
    state: {
        organizationInformationData: null,
        organizationInformationDataFromAdditionalLogic: null,
        lifetimeOrganizationDepartmentFieldDisabledStatus: true,
        lifetimeOrganizationDepartmentDoctorFieldDisabledStatus: true,
        mortemOrganizationInformationData: {},
        // requestOrganizationDate: null,
    },

    actions: {
        async fetchLifetimeCaseRequestOrganizationDataById({ dispatch, rootGetters }, [token, updatedRequestOrganizationData]) {
            try {
                const patientData = await lifetimeCasesTabsApi.putLifetimeCaseRequestOrganizationById(rootGetters.getLifetimeCaseId, updatedRequestOrganizationData, token);
                if (typeof patientData === "number") {
                    return;
                }
                if (patientData) {
                    dispatch("addToastMessage", {code: 200, message: null});
                    dispatch("fetchLifetimeCaseById", [token, rootGetters.getLifetimeCaseId]);
                }
            } catch (e) {
                console.error(e);
            }
        },
        async updateLifetimeCaseRequestOrganizationDataById({ dispatch, state }) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                const updatedRequestOrganizationData = {
                    ...state.organizationInformationData,
                    ...state.organizationInformationDataFromAdditionalLogic
                };
                dispatch("fetchLifetimeCaseRequestOrganizationDataById", [token, updatedRequestOrganizationData]);
            });
        },
        updateLifetimeOrganizationInformationData({ commit, dispatch }, organizationInformationData) {
            commit("addLifetimeOrganizationInformationData", organizationInformationData);
            switch (true) {
                case organizationInformationData?.request_organization_title_short !== undefined && organizationInformationData?.request_organization_title_short?.length === 0:
                    dispatch("updateIsOrganizationNotFound", false);
                    break;
                case organizationInformationData?.request_organization_department_short !== undefined && organizationInformationData?.request_organization_department_short?.length === 0:
                    dispatch("updateIsOrganizationDepartmentNotFound", false);
                    break;
                default:
                    break;
            }
        },
        // updateRequestOrganizationDate({ commit }, requestOrganizationDate) {
        //     commit("changeRequestOrganizationDate", requestOrganizationDate);
        // },
        updateLifetimeOrganizationInformationDataFromAdditionalLogic({ commit }, organizationInformationData) {
            commit("changeLifetimeOrganizationInformationDataFromAdditionalLogic", organizationInformationData);
        },
        updateMortemOrganizationInformationData({ commit }, mortemOrganizationInformationData) {
            commit("addMortemOrganizationInformationData", mortemOrganizationInformationData);
        },
        updateLifetimeOrganizationDepartmentFieldDisabledStatus({ commit }, lifetimeOrganizationDepartmentFieldDisabledStatus) {
            commit("changeLifetimeOrganizationDepartmentFieldDisabledStatus", lifetimeOrganizationDepartmentFieldDisabledStatus);
        },
        updateLifetimeOrganizationDepartmentDoctorFieldDisabledStatus({ commit }, lifetimeOrganizationDepartmentDoctorFieldDisabledStatus) {
            commit("changeLifetimeOrganizationDepartmentDoctorFieldDisabledStatus", lifetimeOrganizationDepartmentDoctorFieldDisabledStatus);
        },
        clearLifetimeOrganizationData({ commit }) {
            commit("addLifetimeOrganizationInformationData", null);
            commit("changeLifetimeOrganizationInformationDataFromAdditionalLogic", null);
            commit("changeLifetimeOrganizationDepartmentFieldDisabledStatus", true);
            commit("changeLifetimeOrganizationDepartmentDoctorFieldDisabledStatus", true);
        },
    },

    mutations: {
        // changeRequestOrganizationDate(state, requestOrganizationDate) {
        //     state.requestOrganizationDate = requestOrganizationDate;
        // },
        addLifetimeOrganizationInformationData(state, organizationInformationData) {
            state.organizationInformationData = organizationInformationData;
        },
        changeLifetimeOrganizationInformationDataFromAdditionalLogic(state, organizationInformationDataFromAdditionalLogic) {
            if (organizationInformationDataFromAdditionalLogic !== null) {
                state.organizationInformationDataFromAdditionalLogic = Object.assign({}, state.organizationInformationDataFromAdditionalLogic, organizationInformationDataFromAdditionalLogic);
                return;
            }
            state.organizationInformationDataFromAdditionalLogic = organizationInformationDataFromAdditionalLogic;
        },
        addMortemOrganizationInformationData(state, mortemOrganizationInformationData) {
            state.mortemOrganizationInformationData = mortemOrganizationInformationData;
        },
        changeLifetimeOrganizationDepartmentFieldDisabledStatus(state, lifetimeOrganizationDepartmentFieldDisabledStatus) {
            state.lifetimeOrganizationDepartmentFieldDisabledStatus = lifetimeOrganizationDepartmentFieldDisabledStatus;
        },
        changeLifetimeOrganizationDepartmentDoctorFieldDisabledStatus(state, lifetimeOrganizationDepartmentDoctorFieldDisabledStatus) {
            state.lifetimeOrganizationDepartmentDoctorFieldDisabledStatus = lifetimeOrganizationDepartmentDoctorFieldDisabledStatus;
        },
    },

    getters: {
        getOrganizationInformationData(state) {
            return state.organizationInformationData;
        },
        // getRequestOrganizationDate(state) {
        //     return state.requestOrganizationDate;
        // },
        getMortemOrganizationInformationData(state) {
            return state.mortemOrganizationInformationData;
        },
        getLifetimeOrganizationDepartmentFieldDisabledStatus(state) {
            return state.lifetimeOrganizationDepartmentFieldDisabledStatus;
        },
        getLifetimeOrganizationDepartmentDoctorFieldDisabledStatus(state) {
            return state.lifetimeOrganizationDepartmentDoctorFieldDisabledStatus;
        },
        getOrganizationInformationDataFromAdditionalLogic(state) {
            return state.organizationInformationDataFromAdditionalLogic;
        },
    }
}
