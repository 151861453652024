export default {
    state: {
        mortemRegistrationInfoBtnStatus: false,
        mortemCaseRegistrationInfoDate: null
    },
    actions: {
        updateMortemRegistrationInfoBtnStatus({ commit }, mortemRegistrationInfoBtnStatus) {
            commit("changeMortemRegistrationInfoBtnStatus", mortemRegistrationInfoBtnStatus);
        },
        updateMortemCaseRegistrationInfoDate({ commit }, mortemCaseRegistrationInfoDate) {
            commit("changeMortemCaseRegistrationInfoDate", mortemCaseRegistrationInfoDate);
        },
    },
    mutations: {
        changeMortemRegistrationInfoBtnStatus(state, mortemRegistrationInfoBtnStatus) {
            state.mortemRegistrationInfoBtnStatus = mortemRegistrationInfoBtnStatus;
        },
        changeMortemCaseRegistrationInfoDate(state, mortemCaseRegistrationInfoDate) {
            state.mortemCaseRegistrationInfoDate = mortemCaseRegistrationInfoDate;
        },
    },
    getters: {
        getMortemRegistrationInfoBtnStatus(state) {
            return state.mortemRegistrationInfoBtnStatus;
        },
        getMortemCaseRegistrationInfoDate(state) {
            return state.mortemCaseRegistrationInfoDate;
        },
    }
}