import { lifetimeApiWS } from "@/api";
export default {
    state: {
        lifetimeCasesJournalPrintBtnShowPopupStatus: false,
        lifetimeJournalDialogVisibility: false,
        lifetimeProtocolDialogVisibility: false,
        lifetimeDirectionDialogVisibility: false,
        lifetimePrintQueue: [],
        printWsConnection: null
    },
    actions: {
        async sendPdfGenerationRequest({ state, dispatch }, printData) {
            const socket = state.printWsConnection;
            console.warn('sendPdfGenerationRequest - state.printWsConnection', state.printWsConnection)
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.send(JSON.stringify(printData));
                console.warn("Sent PDF generation request:", printData);
            } else {
                console.error("WebSocket is not open. Cannot send request. WebSocket state:", socket ? socket.readyState : "No WebSocket connection");
                await dispatch("connectToDocumentWS");
                const newSocket = state.printWsConnection;
                if (newSocket && newSocket.readyState === WebSocket.OPEN) {
                    newSocket.send(JSON.stringify(printData));
                    console.warn("Sent PDF generation request after reconnect:", printData);
                } else {
                    console.error("WebSocket is still not open after reconnect. Cannot send request.");
                }
            }
        },
        async clearPrintQueueRequest({ state, dispatch }) {
            const socket = state.printWsConnection;
            const requestObject = {
                request_id: Date.now(),
                action: "delete_documents",
            }
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.send(JSON.stringify(requestObject));
                console.warn("Sent clear print queue request:", requestObject);
            } else {
                console.error("WebSocket is not open. Cannot send request. WebSocket state:", socket ? socket.readyState : "No WebSocket connection");
                await dispatch("connectToDocumentWS");
                const newSocket = state.printWsConnection;
                if (newSocket && newSocket.readyState === WebSocket.OPEN) {
                    newSocket.send(JSON.stringify(requestObject));
                    console.warn("Sent clear print queue request after reconnect:", requestObject);
                } else {
                    console.error("WebSocket is still not open after reconnect. Cannot send request.");
                }
            }
        },
        async connectToDocumentWS({ commit, dispatch, state }) {
            
            console.warn("Attempting to connect to Document WebSocket...", state.printWsConnection);

            const token = await dispatch("getAccessToken");

            if (!token) {
                console.warn("Token not found, redirecting to auth...");
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                return;
            }

            const socket = state.printWsConnection;

            if (socket && (socket.readyState === WebSocket.OPEN)) {
                console.warn("WebSocket is already connected");
                return;
            }

            if (socket && (socket.readyState === WebSocket.CONNECTING)) {
                console.warn("WebSocket is connecting...");
                return;
            }

            // Todo: стоит улучшить
            if (socket && (socket.readyState === WebSocket.CLOSING || socket.readyState === WebSocket.CLOSED)) {
                console.warn("Waiting for WebSocket to close...");
                await new Promise((resolve) => {
                    const handleClose = () => {
                        console.warn('WebSocket closed, attempting to reconnect...');
                        resolve();
                    };
                    socket.addEventListener('close', handleClose);
                    // Принудительно закрываем, если в состоянии CLOSING
                    if (socket.readyState === WebSocket.CLOSING) {
                        socket.close();
                    }
                    // Дополнительная уверенность
                    if (socket.readyState === WebSocket.CLOSED) {
                        resolve();
                    }
                });
            }
            
            try {
                const newSocket = await lifetimeApiWS.requestPdfGeneration(token);
                commit("changePrintWsConnection", newSocket);

                const requestObject = {
                    request_id: Date.now(),
                    action: "get_documents",
                };

                newSocket.onopen = () => {
                    newSocket.send(JSON.stringify(requestObject));
                    console.warn("WebSocket connection established and sent initial request:", requestObject);
                };

                newSocket.onmessage = (event) => {
                    console.warn("WebSocket message received:", event.data);
                    const data = JSON.parse(event.data);
                    if (data.response_status === 403) {
                        console.warn("Received 403 response, attempting to reconnect...");
                        dispatch("disconnectFromDocumentWS");
                        dispatch("reconnectDocumentWS");
                    }
                    if (data.data === null || data.data?.length === 0) {
                        if (data.action === "delete_documents") {
                            commit("changeLifetimePrintQueue", []);
                        }
                        return;
                    }
                    if (data.action === "get_documents") {
                        commit("changeLifetimePrintQueue", data.data);
                    }
                    if (data.action === "create_document" || data.action === undefined) {
                        commit("changeLifetimePrintGenerateQueue", data.data);
                    }
                };

                newSocket.onclose = (event) => {
                    console.warn("WebSocket connection closed:", event);
                    dispatch("reconnectDocumentWS");
                };

                newSocket.onerror = (error) => {
                    console.error("WebSocket error:", error);
                    dispatch("reconnectDocumentWS");
                };
                console.warn("WebSocket connection established");
            } catch (error) {
                console.error("Error connecting to WebSocket:", error);
                dispatch("reconnectDocumentWS");
            }
        },
        async reconnectDocumentWS({ dispatch }) {
            console.warn("Reconnecting WebSocket...");
            setTimeout(() => {
                dispatch("connectToDocumentWS");
            }, 1000);
        },
        disconnectFromDocumentWS({ commit, state }) {
            const socket = state.printWsConnection;
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.close();
                console.warn("WebSocket connection closed");
            } else {
                console.warn("WebSocket was already closed or not established");
            }
            commit("changePrintWsConnection", null);
            commit("changeLifetimePrintQueue", []);
        },
        updateLifetimeCasesJournalPrintBtnShowPopupStatus({ commit }) {
            commit("changeLifetimeCasesJournalPrintBtnShowPopupStatus");
        },
        updateLifetimeJournalDialogVisibility({ commit }, condition) {
            commit("changeLifetimeJournalDialogVisibility", condition);
        },
        updateLifetimeProtocolDialogVisibility({ commit }, condition) {
            commit("changeLifetimeProtocolDialogVisibility", condition);
        },
        updateLifetimeDirectionDialogVisibility({ commit }, condition) {
            commit("changeLifetimeDirectionDialogVisibility", condition);
        },
    },
    mutations: {
        changeLifetimeCasesJournalPrintBtnShowPopupStatus(state) {
            state.lifetimeCasesJournalPrintBtnShowPopupStatus = !state.lifetimeCasesJournalPrintBtnShowPopupStatus;
        },
        changeLifetimeJournalDialogVisibility(state, condition) {
            state.lifetimeJournalDialogVisibility = condition;
        },
        changeLifetimeProtocolDialogVisibility(state, condition) {
            state.lifetimeProtocolDialogVisibility = condition;
        },
        changeLifetimeDirectionDialogVisibility(state, condition) {
            state.lifetimeDirectionDialogVisibility = condition;
        },
        changeLifetimePrintGenerateQueue(state, socketReceive) {
            let idIsContainInPrintQueue = false;
            state.lifetimePrintQueue.filter((file) => {
                if (file.id === socketReceive.id) {
                    idIsContainInPrintQueue = true;
                    file.url = socketReceive.file?.url;
                    file.document_type_label = socketReceive.document_type_label;
                    file.created_at = socketReceive.created_at;
                    file.status = socketReceive.task?.status;
                    file.lifecase_count = socketReceive.lifecase_count;
                    file.progress = socketReceive.task?.result?.cur_value / (socketReceive.task?.result?.total_value / 100);
                }
            })
            if (!idIsContainInPrintQueue) {
                state.lifetimePrintQueue.push({
                    id: socketReceive.id,
                    url: socketReceive.file?.url,
                    document_type_label: socketReceive.document_type_label,
                    created_at: socketReceive.created_at,
                    status: socketReceive.task?.status,
                    lifecase_count: socketReceive.lifecase_count,
                });
            }
        },
        changeLifetimePrintQueue(state, socketReceive) {
            if (socketReceive.length === 0) {
                state.lifetimePrintQueue = [];
                return;
            }
            socketReceive.forEach(documentObject => {
                state.lifetimePrintQueue.push({
                    id: documentObject.id,
                    url: documentObject.file?.url,
                    document_type_label: documentObject.document_type_label,
                    created_at: documentObject.created_at,
                    status: documentObject.task.status,
                    lifecase_count: documentObject.lifecase_count,
                });
            });
        },
        changePrintWsConnection(state, printWsConnection) {
            state.printWsConnection = printWsConnection;
        },
    },
    getters: {
        getLifetimeCasesJournalPrintBtnShowPopupStatus(state) {
            return state.lifetimeCasesJournalPrintBtnShowPopupStatus;
        },
        getLifetimeJournalDialogVisibility(state) {
            return state.lifetimeJournalDialogVisibility;
        },
        getLifetimeProtocolDialogVisibility(state) {
            return state.lifetimeProtocolDialogVisibility;
        },
        getLifetimeDirectionDialogVisibility(state) {
            return state.lifetimeDirectionDialogVisibility;
        },
        getLifetimePrintQueue(state) {
            return state.lifetimePrintQueue;
        },
        getPrintWsConnection(state) {
            return state.printWsConnection;
        },
    }
}
