export default {
    state: {
        isMortemCaseAddDialogVisible: false,
        isClearAllDataToggle: false
    },
    actions: {
        updateMortemCaseAddDialogVisibility({ commit }, status) {
            commit("changeMortemCaseAddDialogVisibility", status);
        },
        updateClearAllDataToggleValue({ commit }) {
            commit("changeClearAllDataToggleValue");
        }
    },
    mutations: {
        changeMortemCaseAddDialogVisibility(state, status) {
            state.isMortemCaseAddDialogVisible = status;
        },
        changeClearAllDataToggleValue(state) {
            state.isClearAllDataToggle = !state.isClearAllDataToggle;
        },
    },
    getters: {
        getMortemCaseAddDialogVisibility(state) {
            return state.isMortemCaseAddDialogVisible;
        },
        getClearAllDataToggleValue(state) {
            return state.isClearAllDataToggle;
        },
    }
}