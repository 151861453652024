export const gender = {
    data() {
        return {
            patientGender: {
                MALE: 'Муж',
                FEMALE: 'Жен',
                NOGENDER: '-',
            },
            patientGenderSelectOptionList: [
                {id: 'MALE', title: 'Мужской'},
                {id: 'FEMALE', title: 'Женский'},
            ]
        }
    }
}