export default {
    state: {
        mortemAutopsyRespiratorySystemBtnStatus: false,
        mortemCaseRespiratorySystemParanasalSinuses: null,
        mortemCaseRespiratorySystemLarynx: null,
        mortemCaseRespiratorySystemTracheaBronchi: null,
        mortemCaseRespiratorySystemLungSize: null,
        mortemCaseRespiratorySystemLungMass: null,
        mortemCaseRespiratorySystemLungForm: null,
        mortemCaseRespiratorySystemLungAiriness: null,
        mortemCaseRespiratorySystemLungDensity: null,
        mortemCaseRespiratorySystemPleuralLayers: null,
        mortemCaseRespiratorySystemLungTissue: null,
        mortemCaseRespiratorySystemPathologicalFormations: null,
        mortemCaseRespiratorySystemWaterSample: null,
        mortemCaseRespiratorySystemVessels: null,
        mortemCaseRespiratorySystemBronchi: null,
        mortemCaseRespiratorySystemHilarLymph: null,
        mortemCaseRespiratorySystemParatrachealLymph: null,
    },
    actions: {
        updateMortemAutopsyRespiratorySystemBtnStatus({ commit }, condition) {
            commit("changeMortemAutopsyRespiratorySystemBtnStatus", condition);
        },
        updateAutopsyRespiratorySystemField({ commit }, payload) {
            commit('changeAutopsyRespiratorySystemField', payload);
        }
    },
    mutations: {
        changeMortemAutopsyRespiratorySystemBtnStatus(state, condition) {
            state.mortemAutopsyRespiratorySystemBtnStatus = condition;
        },
        changeAutopsyRespiratorySystemField(state, { key, value }) {
            state[key] = value;
        }
    },
    getters: {
        getMortemAutopsyRespiratorySystemBtnStatus(state) {
            return state.mortemAutopsyRespiratorySystemBtnStatus;
        },
        getAutopsyRespiratorySystemField: (state) => (key) => {
            return state[key];
        }
    }
}