export default {
    state: {
        toast: null,
    },
    actions: {
        addToastMessage({ commit }, response) {
            commit('changeToastMessage', response)
            setTimeout(() => {
                commit('clearToastMessage')
            }, 0)
        },
    },
    mutations: {
        changeToastMessage(state, response) {
            state.toast = response
        },
        clearToastMessage(state) {
            state.toast = null
        },
    },
    getters: {
        getToastMessage(state) {
            return state.toast
        },
    },
}
