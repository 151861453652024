import { api } from "@/api";
import { debounceLoader } from "@/helpers/debounceLoader";

export default {
    state: {
        biopsyPositionReferencePage: 1,
        biopsyPositionReferencePageSize: 50,
        isBiopsyPositionNotFound: false,
        biopsyPositions: [],
        biopsyPositionReferenceListShowStatus: false,
        biopsyPositionSelectedTitle: "",
        biopsyPositionSelectedItemId: null
    },
    actions: {
        async fetchNextBiopsyPositions({ state, commit, dispatch }) {
            if (state.biopsyPositions?.next === null) {
                return;
            }
            commit("changeBiopsyPositionReferencePage", state.biopsyPositionReferencePage + 1);
            dispatch("receiveBiopsyPositions", "next");
        },
        async receiveBiopsyPositions({ commit, state, dispatch, rootGetters }, status) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                if (!status) {
                    commit("changeBiopsyPositionReferencePage", 1);
                }
                switch (rootGetters.getLifetimeBiopsySamplesForm !== null) {
                    case rootGetters.getLifetimeBiopsySamplesForm?.flasks?.position.position_title !== undefined && !rootGetters.getInputLifetimeFocusEventStatus:
                        if (rootGetters.getLifetimeBiopsySamplesForm?.flasks?.position.position_title.length !== 0 && rootGetters.getLifetimeBiopsySamplesForm?.flasks?.position.position_title === state.localizationSelectedName) {
                            return;
                        }
                        dispatch("fetchBiopsyPositions", [token, state.biopsyPositionReferencePage, state.biopsyPositionReferencePageSize, rootGetters.getLifetimeBiopsySamplesForm?.flasks?.position.position_title, status]);
                        return;
                    case rootGetters.getLifetimeBiopsySamplesForm?.flasks?.position.position_title !== undefined && rootGetters.getInputLifetimeFocusEventStatus && rootGetters.getLifetimeBiopsySamplesForm?.flasks?.position.position_title.length === 0:
                        dispatch("fetchBiopsyPositions", [token, state.biopsyPositionReferencePage, state.biopsyPositionReferencePageSize, "", status]);
                        return;
                    default:
                        return;
                }
            });
        },
        async fetchBiopsyPositions({ dispatch, state }, [token, page, pageSize, biopsyPosition, status]) {
            try {
                const biopsyPositions = await debounceLoader(
                    api.getReferenceBiopsyPosition(page, pageSize, token, biopsyPosition),
                    "referenceList"
                );

                // dispatch("setExceptionAccordingResponseCode", biopsyPositions);

                switch (status) {
                    case "next":
                        dispatch("updateBiopsyPositionsByNextStatus", biopsyPositions);
                        break;
                    default:
                        dispatch("updateBiopsyPositions", biopsyPositions);
                        break;
                }

                if (biopsyPositions.results.length === 0) {
                    dispatch("updateBiopsyPositionReferenceListShowStatus", false);
                    if (state.isBiopsyPositionNotFound) {
                        return;
                    }
                    dispatch("updateIsBiopsyPositionNotFound", true);
                    return;
                }

                dispatch("updateIsBiopsyPositionNotFound", false);
                dispatch("updateBiopsyPositionReferenceListShowStatus", true);
            } catch (e) {
                console.error(e);
                // dispatch("setExceptionAccordingResponseCode", e.status);
                dispatch("updateBiopsyPositionReferenceListShowStatus", false);
            }
        },
        updateIsBiopsyPositionNotFound({ commit }, status) {
            commit("changeIsBiopsyPositionNotFound", status);
        },
        updateBiopsyPositions({ commit }, biopsyPositions) {
            commit("changeBiopsyPositions", biopsyPositions);
        },
        updateBiopsyPositionsByNextStatus({ commit }, biopsyPositions) {
            commit("changeBiopsyPositionsByNextStatus", biopsyPositions);
        },
        updateBiopsyPositionReferenceListShowStatus({ commit }, status) {
            commit("changeBiopsyPositionReferenceListShowStatus", status);
        },
        updateBiopsyPositionSelectedTitle({ commit }, biopsyPositionSelectedTitle) {
            commit("changeBiopsyPositionSelectedTitle", biopsyPositionSelectedTitle);
        },
        updateBiopsyPositionSelectedItemId({ commit }, biopsyPositionSelectedItemId) {
            commit("changeBiopsyPositionSelectedItemId", biopsyPositionSelectedItemId);
        },
        clearReferenceBiopsyPositionData({ commit }) {
            commit("changeBiopsyPositions", []);
            commit("changeBiopsyPositionReferencePage", 1);
            commit("changeBiopsyPositionSelectedTitle", "");
            commit("changeBiopsyPositionSelectedItemId", null);
        },
    },
    mutations: {
        changeBiopsyPositionReferencePage(state, biopsyPositionReferencePage) {
            state.biopsyPositionReferencePage = biopsyPositionReferencePage;
        },
        changeIsBiopsyPositionNotFound(state, status) {
            state.isBiopsyPositionNotFound = status;
        },
        changeBiopsyPositions(state, biopsyPositions) {
            state.biopsyPositions = biopsyPositions;
        },
        changeBiopsyPositionsByNextStatus(state, biopsyPositions) {
            state.biopsyPositions.results = [...state.biopsyPositions?.results, ...biopsyPositions?.results];
            state.biopsyPositions.next = biopsyPositions?.next;
            state.biopsyPositions.previous = biopsyPositions?.previous;
        },
        changeBiopsyPositionReferenceListShowStatus(state, status) {
            state.biopsyPositionReferenceListShowStatus = status;
        },
        changeBiopsyPositionSelectedTitle(state, biopsyPositionSelectedTitle) {
            state.biopsyPositionSelectedTitle = biopsyPositionSelectedTitle;
        },
        changeBiopsyPositionSelectedItemId(state, biopsyPositionSelectedItemId) {
            state.biopsyPositionSelectedItemId = biopsyPositionSelectedItemId;
        }
    },
    getters: {
        getIsBiopsyPositionNotFound(state) {
            return state.isBiopsyPositionNotFound;
        },
        getBiopsyPositions(state) {
            return state.biopsyPositions;
        },
        getBiopsyPositionReferenceListShowStatus(state) {
            return state.biopsyPositionReferenceListShowStatus;
        },
        getBiopsyPositionSelectedTitle(state) {
            return state.biopsyPositionSelectedTitle;
        },
        getBiopsyPositionSelectedItemId(state) {
            return state.biopsyPositionSelectedItemId;
        }
    },
}
