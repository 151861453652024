import { lifetimeApi, lifetimeApiWS } from "@/api";
// import { getDateFormatSortWithTimeAndTz } from "@/helpers/dateFormatSort";
import { isRangeDateValue, isFillInDate } from "@/helpers/checkRangeValue";
import { debounceLoader } from "@/helpers/debounceLoader";

export default {
    state: {
        lifetimeCases: [],
        lifetimeCaseByIdViewing: [],
        lifetimeCaseId: null,
        lifetimePage: 1,
        lifetimeCasesBtnPrintStatus: false,
        lifetimeCasesDataWithSort: [],
        lifetimeCasesSearchInputData: "",
        lifetimeCasesActiveTab: "",
        lifetimePageSort: 1,
        lifetimePageSize: 50,
        lifetimeCasesFilterTypeData: null,
        lifetimeCasesFilterShowArchiveStatus: false,
        lifetimeCasesInputSortDateFromData: "",
        lifetimeCasesInputSortDateToData: "",
        lifetimeCasesOrderByColumnData: "",
        loader: false,
        lifetimeCasesId: [],
        lifetimeSelectOptionStageData: "",
        lifetimeSelectOptionResponsibleData: "",
        lifetimeSelectOptionStageShowStatus: false,
        lifetimeSelectOptionResponsibleShowStatus: false,
        lifetimeCasesAssignMeButtonClickedStatus: false,
        lifetimeCasesOpenFiltersButtonClickedStatus: false,
        lifetimeCasesInputSortDateValidStatus: false,
        lifetimeCasesInputSortDateFromFillValidStatus: false,
        lifetimeCasesInputSortDateToFillValidStatus: false,
        lifetimeBiopsySamplesFlasksWithCharacteristicsTemplate: [],
        lifetimeCasesChecked: [],
        isLifetimeCaseByIdLoading: false,
        lifeCasesJournalWsConnection: null,
        isFetchByWebSocket: false
    },
    actions: {
        async connectToLifeCasesJournalWS({ commit, dispatch, state }) {
            
            console.warn("Attempting to connect to LifeCasesJournal WebSocket...");
        
            const token = await dispatch("getAccessToken");

            if (!token) {
                console.warn("Token not found, redirecting to auth...");
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                return;
            }
        
            const socket = state.lifeCasesJournalWsConnection;

            if (socket && (socket.readyState === WebSocket.OPEN)) {
                console.warn("WebSocket is already connected");
                return;
            }

            if (socket && (socket.readyState === WebSocket.CONNECTING)) {
                console.warn("WebSocket is connecting...");
                return;
            }
            
            // Todo: стоит улучшить
            if (socket && (socket.readyState === WebSocket.CLOSING || socket.readyState === WebSocket.CLOSED)) {
                console.warn("Waiting for WebSocket to close...");
                await new Promise((resolve) => {
                    const handleClose = () => {
                        console.warn('WebSocket closed, attempting to reconnect...');
                        resolve();
                    };
                    socket.addEventListener('close', handleClose);
                    // Принудительно закрываем, если в состоянии CLOSING
                    if (socket.readyState === WebSocket.CLOSING) {
                        socket.close();
                    }
                    // Дополнительная уверенность
                    if (socket.readyState === WebSocket.CLOSED) {
                        resolve();
                    }
                });
            }
        
            try {
                const newSocket = await lifetimeApiWS.requestLifeCasesJournal(token);
                commit("changeLifeCasesJournalWsConnection", newSocket);
        
                newSocket.onmessage = (event) => {
                    console.warn("WebSocket message received:", event.data);
                    const data = JSON.parse(event.data);
                    if (data.response_status === 403) {
                        console.warn("Received 403 response, attempting to reconnect...");
                        dispatch("disconnectFromLifeCasesJournalWS");
                        dispatch("reconnectLifeCasesJournalWS");
                    }
                    if (data?.data?.lifecase_id && data?.data?.user_id) {
                        if (state.lifetimeCaseByIdViewing.id === undefined) {
                            dispatch("getLifetimeCases", [false, true]); // [nextStatus, isFetchByWebSocket]
                        }
                        if (data.data.lifecase_id === state.lifetimeCaseByIdViewing.id) {
                            commit("changeIsFetchByWebSocket", true);
                            dispatch("getLifetimeCaseById", state.lifetimeCaseByIdViewing.id);
                        }
                    }
                };
        
                newSocket.onclose = (event) => {
                    console.warn("WebSocket connection closed:", event);
                    dispatch("reconnectLifeCasesJournalWS");
                };
        
                newSocket.onerror = (error) => {
                    console.error("WebSocket error:", error);
                    dispatch("reconnectLifeCasesJournalWS");
                };
        
                console.warn("WebSocket connection established");
            } catch (error) {
                console.error("Error connecting to WebSocket:", error);
                dispatch("reconnectLifeCasesJournalWS");
            }
        },
        async reconnectLifeCasesJournalWS({ dispatch }) {
            console.warn("Reconnecting WebSocket...");
            setTimeout(() => {
                dispatch("connectToLifeCasesJournalWS");
            }, 1000);
        },
        disconnectFromLifeCasesJournalWS({ commit, state }) {
            const socket = state.lifeCasesJournalWsConnection;
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.close();
                console.warn("WebSocket connection closed");
            } else {
                console.warn("WebSocket was already closed or not established");
            }
            commit("changeLifeCasesJournalWsConnection", null);
        },
        async getNextLifetimeCases({ state, dispatch }) {
            console.warn("Fetching next lifetime cases...");
            if (state.lifetimeCases?.next === null || state.lifetimeCases?.next === undefined) {
                console.warn("No more cases to fetch");
                return;
            }
            await dispatch("getLifetimeCases", [true]);
        },
        async getLifetimeCases({ dispatch, state }, [
                nextStatus = false,
                isFetchByWebSocket = false,
                lifetimeSearchParams = state.lifetimeCasesSearchInputData,
                lifetimeSortDateFromParam = state.lifetimeCasesInputSortDateFromData,
                lifetimeSortDateToParam = state.lifetimeCasesInputSortDateToData,
                lifetimeOrderByColumn = state.lifetimeCasesOrderByColumnData,
                lifetimeFilterByStatus = state.lifetimeSelectOptionStageData?.id,
                lifetimeResponsibleData = state.lifetimeSelectOptionResponsibleData?.last_name,
                lifetimeFilterShowArchiveStatus = state.lifetimeCasesFilterShowArchiveStatus
            ] = []) {

            console.warn("Fetching lifetime cases with parameters:", {
                nextStatus, isFetchByWebSocket, lifetimeSearchParams, lifetimeSortDateFromParam,
                lifetimeSortDateToParam, lifetimeOrderByColumn, lifetimeFilterByStatus,
                lifetimeResponsibleData, lifetimeFilterShowArchiveStatus
            });

            const token = await dispatch("getAccessToken");
            if (!token) {
                console.warn("Token not found, redirecting to auth...");
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                return;
            }

            try {
                const queryParams = {
                    page: state.lifetimePageSort,
                    page_size: state.lifetimePageSize,
                    search: lifetimeSearchParams,
                    [`${state.lifetimeCasesFilterTypeData?.id}__gte`]: lifetimeSortDateFromParam,
                    [`${state.lifetimeCasesFilterTypeData?.id}__lte`]: lifetimeSortDateToParam,
                    ordering: lifetimeOrderByColumn,
                    status__status: lifetimeFilterByStatus,
                    employee: lifetimeResponsibleData
                };
                if (state.lifetimeCasesFilterShowArchiveStatus) {
                    queryParams.archive = state.lifetimeCasesFilterShowArchiveStatus;
                }

                if (nextStatus) {
                    dispatch("updateLifetimePageSort", state.lifetimePageSort + 1);
                } else {
                    dispatch("updateLifetimePageSort", 1);
                }

                if ((lifetimeSearchParams?.length === 0 && lifetimeOrderByColumn?.length === 0 && lifetimeFilterByStatus?.length === 0 && lifetimeResponsibleData?.length === 0 && !lifetimeFilterShowArchiveStatus) &&
                    (!state.lifetimeCasesInputSortDateValidStatus || !state.lifetimeCasesInputSortDateFromFillValidStatus || !state.lifetimeCasesInputSortDateToFillValidStatus)) {
                    console.error("Invalid date range provided");
                    return;
                }

                if (lifetimeSearchParams?.length !== 0 && (lifetimeSortDateFromParam?.length !== 0 || lifetimeSortDateToParam?.length !== 0) &&
                    (!state.lifetimeCasesInputSortDateValidStatus || !state.lifetimeCasesInputSortDateFromFillValidStatus || !state.lifetimeCasesInputSortDateToFillValidStatus)) {
                    console.error("Invalid date range with search parameters");
                    return;
                }

                if (nextStatus) {
                    dispatch("updateLifetimePage", state.lifetimePage + 1);
                } else {
                    dispatch("updateLifetimePage", 1);
                }

                dispatch("fetchLifetimeCasesDataWithSort", [nextStatus, isFetchByWebSocket, token, queryParams]);
            } catch (error) {
                console.error("Error fetching lifetime cases:", error);
            }
        },
        async getLifetimeCaseById({ dispatch }, lifetimeCaseId) { //lifetimeCaseId проверить когда передается и что
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                dispatch("fetchLifetimeCaseById", [token, lifetimeCaseId]);
            });
        },
        async editLifetimeCaseById({ dispatch }, [lifetimeCaseId, newLifetimeCaseData]) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                dispatch("patchLifetimeCaseById", [token, lifetimeCaseId, newLifetimeCaseData]);
            });
        },
        async updateLifetimePartialCaseDataById({ dispatch }, updatedCaseData) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                dispatch("fetchLifetimePartialCaseDataById", [token, updatedCaseData]);
            });
        },
        async addNewLifetimeCaseData({ dispatch }, newCaseData) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                dispatch("fetchNewLifetimeCaseData", [token, newCaseData]);
            });
        },
        async patchLifetimeCaseById({ dispatch }, [token, lifetimeCaseId, newLifetimeCaseData]) {
            const lifetimeCaseData = await lifetimeApi.patchLifetimeCaseById(lifetimeCaseId, newLifetimeCaseData, token);
            dispatch("updateLifetimeCaseById", lifetimeCaseData);
            if(newLifetimeCaseData.microscopy !== undefined || newLifetimeCaseData.cito !== undefined){ //Изменить условие, когда будут приходить новые данные при изменении Микроскопии
                await dispatch("reloadLifetimeCaseById");
            }
        },
        async fetchLifetimeCases({ dispatch, state }, [nextStatus, isFetchByWebSocket, token, lifetimePage]) {
            try {
                localStorage.removeItem("vuex");
                let cases = [];
                if (isFetchByWebSocket) {
                    cases = await lifetimeApi.getLifetimeCases(lifetimePage, state.lifetimePageSize, token);
                } else {
                    cases = await debounceLoader(
                    lifetimeApi.getLifetimeCases(lifetimePage, state.lifetimePageSize, token),
                    "lifetimeCase"
                );
                }

                dispatch("updateLifetimeCases", [cases, nextStatus]);
                dispatch("updateLifetimeCasesId", cases.results);
            } catch (e) {
                console.error(e);
            }
        },
        async fetchLifetimeCasesDataWithSort({ dispatch }, [nextStatus, isFetchByWebSocket, token, params]) {
            try {
                localStorage.removeItem("vuex");

                let cases = [];
                if (isFetchByWebSocket) {
                    cases = await lifetimeApi.getLifetimeCasesWithSort(token, params);
                } else {
                    cases = await debounceLoader(
                        lifetimeApi.getLifetimeCasesWithSort(token, params),
                        "lifetimeCase"
                    );
                }

                if (cases?.results !== undefined && cases?.count === 0) {
                    dispatch("updateLifetimeCases", [[], nextStatus]);
                    dispatch("updateLifetimeCasesId", cases.results);
                    return;
                }
                dispatch("updateLifetimeCases", [cases, nextStatus]);
            } catch (e) {
                console.error(e);
            }
        },
        async fetchLifetimeCaseById({ commit, state, dispatch }, [token, lifetimeCaseId]) {
            if (!state.isFetchByWebSocket) {
                commit("changeIsLifetimeCaseByIdLoading", true);
            }
            try {
                let caseByIdViewing = [];
                if (localStorage.accessTokenData !== undefined && state.lifetimeCaseId !== null && state.lifetimeCaseId !== undefined) {
                    caseByIdViewing = await lifetimeApi.getLifetimeCaseById(state.lifetimeCaseId, token);
                }
                if (state.lifetimeCaseId === null) { //TODO условие не давало перезапросить случай state.lifetimeCaseByIdViewing.length === 0 && state.lifetimeCaseId === null
                    caseByIdViewing = await lifetimeApi.getLifetimeCaseById(lifetimeCaseId, token);
                }
                // dispatch("setExceptionAccordingResponseCode", caseByIdViewing);
                dispatch("updateLifetimeCaseById", caseByIdViewing);
                dispatch("updateLifetimeCaseCurrentStatusDataById");
                dispatch("initLifetimeCaseDefect", caseByIdViewing.defect);

                // if (caseByIdViewing?.biopsy?.flasks && caseByIdViewing?.biopsy?.flasks !== null) {
                //     dispatch("updateLifetimeBiopsySamplesFlasksWithCharacteristicsTemplate", [caseByIdViewing?.biopsy?.flasks, "fillIn"]);
                //     dispatch("updateLifetimeBiopsySamplesFlasks", [state.lifetimeBiopsySamplesFlasksWithCharacteristicsTemplate]);
                //     dispatch("updateLifetimeBiopsySamplesFlasksWithCharacteristicsTemplate", [[]]);
                // }

                if (caseByIdViewing?.disease?.previous_cases && caseByIdViewing?.disease?.previous_cases !== null) {
                    dispatch("updateLifetimeBiopsyStudies", [caseByIdViewing?.disease?.previous_cases]);
                }

                if (lifetimeCaseId === "fetchLifetimePartialCaseDataById") {
                    // dispatch("setExceptionAccordingResponseCode", 200);
                }
                commit("changeIsFetchByWebSocket", false);
            } catch (e) {
                console.error(e);
                // dispatch("setExceptionAccordingResponseCode", e.status);
            } finally {
                commit("changeIsLifetimeCaseByIdLoading", false);
            }
            dispatch("updateLifetimeCasesBtnPrintStatus");
        },
        async fetchLifetimePartialCaseDataById({ dispatch, state }, [token, updatedCaseData]) {
            try {
                lifetimeApi.patchLifetimeCaseById(state.lifetimeCaseId, updatedCaseData, token).
                then(() => {
                    dispatch("getLifetimeCaseById", "fetchLifetimePartialCaseDataById")
                });
            } catch (e) {
                console.error(e);
                // dispatch("setExceptionAccordingResponseCode", e.status);
            }
        },
        async fetchNewLifetimeCaseData({ dispatch, rootGetters }, [token, newCaseData]) {
            try {
                // if (rootGetters.getLifetimeRegisterNumberData?.number === undefined) {
                //     if (rootGetters.getCaseAddModalShowBtn) {
                //         dispatch("runRegistrationNumberLogic", true);
                //     }
                // }

                const newCase = await lifetimeApi.postLifetimeCaseRegisterNumberNewCase(rootGetters.getLifetimeRegisterNumberData?.id, newCaseData, token); //addLifetimeCase
                console.warn(newCase);
                // dispatch("setExceptionAccordingResponseCode", newCase);

                if (typeof newCase === "number") {
                    return;
                }


                dispatch("updateCaseAddModalShowBtnStatus", false);
                dispatch("clearStorageRelatedWithAddNewLifetimeCase");
                dispatch("updateIsCaseAddModalActionConfirmationExitShowStatus", false);
                dispatch("updateLifetimePage", 1);
                dispatch("getLifetimeCases");
                dispatch("addToastMessage", {code: 200, message: "Случай успешно добавлен"});
            } catch (e) {
                console.error(e);
                // dispatch("setExceptionAccordingResponseCode", e.status);
            }
        },
        updateLifetimeCasesBtnPrintStatus({ state, commit }) {
            if (!state.lifetimeCaseByIdViewing || state.lifetimeCaseByIdViewing.length === 0 || state.lifetimeCaseByIdViewing === null) {
                commit("changeLifetimeCasesBtnPrintStatus", false);
                return;
            }
            if (state.lifetimeCaseByIdViewing?.report === null) {
                commit("changeLifetimeCasesBtnPrintStatus", false);
                return;
            }
            if (
                state.lifetimeCaseByIdViewing?.report?.report_microscopic &&
                state.lifetimeCaseByIdViewing?.report?.report_icd_code?.code &&
                state.lifetimeCaseByIdViewing?.report?.report_comment &&
                state.lifetimeCaseByIdViewing?.report?.report_laboratory_assistant &&
                state.lifetimeCaseByIdViewing?.report?.report_pathologist &&
                state.lifetimeCaseByIdViewing?.report?.report_consultant
            ) {
                commit("changeMortemBtnPrintStatus", false);
                commit("changeLifetimeCasesJournalBtnPrintStatus", false);
                commit("changeLifetimeCasesBtnPrintStatus", true);
            } else {
                commit("changeLifetimeCasesBtnPrintStatus", false);
            }
        },
        updateLifetimePage({ commit }, page) {
            commit("changeLifetimePage", page);
        },
        updateLifetimeCases({ commit }, cases) {
            commit("changeLifetimeCases", cases);
        },
        updateLifetimeCasesActiveTab({commit}, lifetimeCasesActiveTab) {
            commit("changeLifetimeCasesActiveTab", lifetimeCasesActiveTab);
        },
        updateLifetimeCasesDataWithSort({ commit }, lifetimeCasesDataWithSort) {
            commit("changeLifetimeCases", lifetimeCasesDataWithSort);
        },
        updateLifetimeCaseId({ commit }, lifetimeCaseId) {
            commit("changeLifetimeCaseId", lifetimeCaseId);
        },
        updateLifetimeCaseById({ commit }, caseByIdViewing) {
            commit("changeLifetimeCaseById", caseByIdViewing);
        },
        updateLifetimePageSort({ commit }, page) {
            commit("changeLifetimePageSort", page);
        },
        updateLifetimeCasesSearchInputData({ commit }, lifetimeCasesSearchInputData) {
            commit("changeLifetimeCasesSearchInputData", lifetimeCasesSearchInputData);
        },
        updateLifetimeCasesFilterTypeData({ commit }, lifetimeCasesFilterTypeData) {
            commit("changeLifetimeCasesFilterTypeData", lifetimeCasesFilterTypeData);
        },
        updateLifetimeCasesInputSortDateFromData({ commit, state }, lifetimeCasesInputSortDateFromData) {
            commit("changeLifetimeCasesInputSortDateFromData", lifetimeCasesInputSortDateFromData);
            commit("changeLifetimeCasesInputSortDateFromFillValidStatus", isFillInDate(lifetimeCasesInputSortDateFromData));
            commit("changeLifetimeCasesInputSortDateValidStatus", isRangeDateValue(lifetimeCasesInputSortDateFromData, state.lifetimeCasesInputSortDateToData));
        },
        updateLifetimeCasesInputSortDateToData({ commit, state }, lifetimeCasesInputSortDateToData) {
            commit("changeLifetimeCasesInputSortDateToData", lifetimeCasesInputSortDateToData);
            commit("changeLifetimeCasesInputSortDateToFillValidStatus", isFillInDate(lifetimeCasesInputSortDateToData));
            commit("changeLifetimeCasesInputSortDateValidStatus", isRangeDateValue(state.lifetimeCasesInputSortDateFromData, lifetimeCasesInputSortDateToData));
        },
        updateLifetimeCasesOrderByColumnData({ commit }, lifetimeCasesOrderByColumnData) {
            commit("changeLifetimeCasesOrderByColumnData", lifetimeCasesOrderByColumnData);
        },
        updateLifetimeCasesId({ commit }, cases) {
            commit("changeLifetimeCasesId", cases);
        },
        updateLifetimeSelectOptionStageData({ commit }, lifetimeSelectOptionStageData) {
            commit("changeLifetimeSelectOptionStageData", lifetimeSelectOptionStageData);
        },
        updateLifetimeSelectOptionResponsibleData({ commit }, lifetimeSelectOptionResponsibleData) {
            commit("changeLifetimeSelectOptionResponsibleData", lifetimeSelectOptionResponsibleData);
        },
        updateLifetimeCasesAssignMeButtonClickedStatus({ commit }, lifetimeCasesAssignMeButtonClickedStatus) {
            commit("changeLifetimeCasesAssignMeButtonClickedStatus", lifetimeCasesAssignMeButtonClickedStatus);
        },
        updateLifetimeCasesOpenFiltersButtonClickedStatus({ commit }, lifetimeCasesOpenFiltersButtonClickedStatus) {
            commit("changeLifetimeCasesOpenFiltersButtonClickedStatus", lifetimeCasesOpenFiltersButtonClickedStatus);
        },
        updateLifetimeSelectOptionStageShowStatus({ commit }, lifetimeSelectOptionStageShowStatus) {
            commit("changeLifetimeSelectOptionStageShowStatus", lifetimeSelectOptionStageShowStatus);
            commit("changeLifetimeSelectOptionResponsibleShowStatus", false);
        },
        updateLifetimeSelectOptionResponsibleShowStatus({ commit }, lifetimeSelectOptionResponsibleShowStatus) {
            commit("changeLifetimeSelectOptionResponsibleShowStatus", lifetimeSelectOptionResponsibleShowStatus);
            commit("changeLifetimeSelectOptionStageShowStatus", false);
        },
        clearStorageRelatedWithAddNewLifetimeCase({ dispatch }) {
            dispatch("clearLifetimeGeneralData");
            dispatch("clearLifetimeOrganizationData");
            dispatch("clearReferenceOrganizationData");
            dispatch("clearReferenceOrganizationDepartmentData");
            dispatch("clearReferenceOrganizationDepartmentDoctorData");
            dispatch("clearReferenceIcdCodeData");
            dispatch("clearPatientStore");
            dispatch("clearLifetimeDiseaseData");
            dispatch("clearReferencePreviousOrganizationData");
            dispatch("clearLifetimeMaterialData");
            dispatch("clearReferenceLocalizationData");
            dispatch("clearBiopsyFormData");
            dispatch("clearReferenceBiopsyPositionData");
        },
        updateLifetimeBiopsySamplesFlasksWithCharacteristicsTemplate({ commit }, [data, flag]) {
            switch (flag) {
                case "fillIn":
                    commit("fillInTemplateOfCharacteristicField", data);
                    return;
                default:
                    commit("changeLifetimeBiopsySamplesFlasksWithCharacteristicsTemplate", data);
                    return;
            }
        },
        updateLifetimeCasesChecked({ commit }, checkedData) {
            commit("changeLifetimeCasesChecked", checkedData);
        },
        updateLifetimeCaseByIdFlasks({ commit }, flasks) {
            commit("changeLifetimeCaseByIdFlasks", flasks);
        },
        updateLifetimeCasesFilterShowArchiveStatus({ commit }, status) {
            commit("changeLifetimeCasesFilterShowArchiveStatus", status);
        },
    },
    mutations: {
        changeIsFetchByWebSocket(state, isFetchByWebSocket) {
            console.warn("Setting isFetchByWebSocket to:", isFetchByWebSocket);
            state.isFetchByWebSocket = isFetchByWebSocket;
        },
        changeLifeCasesJournalWsConnection(state, lifeCasesJournalWsConnection) {
            console.warn("Updating WebSocket connection state");
            state.lifeCasesJournalWsConnection = lifeCasesJournalWsConnection;
        },
        changeLifetimeCases(state, [lifetimeCases, nextStatus]) {
            if (state.lifetimeCases.length === 0 || state.lifetimeCases?.next === null) {
                state.lifetimeCases = lifetimeCases;
                return;
            }
            if (!nextStatus) {
                state.lifetimeCases = lifetimeCases;
                return;
            }
            state.lifetimeCases.next = lifetimeCases?.next;
            state.lifetimeCases.previous = lifetimeCases?.previous;
            state.lifetimeCases.results = [...state.lifetimeCases?.results, ...lifetimeCases?.results];
        },
        changeLifetimeCasesDataWithSort(state, [lifetimeCasesDataWithSort, nextStatus]) {
            if (state.lifetimeCasesDataWithSort.length === 0 || state.lifetimeCasesDataWithSort?.next === null) {
                state.lifetimeCasesDataWithSort = lifetimeCasesDataWithSort;
                return;
            }
            if (!nextStatus) {
                state.lifetimeCasesDataWithSort = lifetimeCasesDataWithSort;
                return;
            }
            state.lifetimeCasesDataWithSort.next = lifetimeCasesDataWithSort?.next;
            state.lifetimeCasesDataWithSort.previous = lifetimeCasesDataWithSort?.previous;
            state.lifetimeCasesDataWithSort.results = [...state.lifetimeCasesDataWithSort?.results, ...lifetimeCasesDataWithSort?.results];
        },
        changeLifetimeCaseById(state, caseByIdViewing) {
            state.lifetimeCaseByIdViewing = caseByIdViewing;
        },
        changeLifetimeCaseByIdFlasks(state, flasks) {
            state.lifetimeCaseByIdViewing.biopsy.flasks = flasks;
        },
        changeLifetimeCaseId(state, lifetimeCaseId) {
            state.lifetimeCaseId = lifetimeCaseId;
        },
        changeLifetimePage(state, page) {
            state.lifetimePage = page;
        },
        changeLifetimeCasesActiveTab(state, lifetimeCasesActiveTab) {
            state.lifetimeCasesActiveTab = lifetimeCasesActiveTab;
        },
        changeLifetimeCasesBtnPrintStatus(state, btnPrintStatus) {
            state.lifetimeCasesBtnPrintStatus = btnPrintStatus;
        },
        changeLifetimePageSort(state, page) {
            state.lifetimePageSort = page;
        },
        changeLifetimeCasesSearchInputData(state, lifetimeCasesSearchInputData) {
            state.lifetimeCasesSearchInputData = lifetimeCasesSearchInputData;
        },
        changeLifetimeCasesFilterTypeData(state, lifetimeCasesFilterTypeData) {
            state.lifetimeCasesFilterTypeData = lifetimeCasesFilterTypeData
        },
        changeLifetimeCasesInputSortDateFromData(state, lifetimeCasesInputSortDateFromData) {
            state.lifetimeCasesInputSortDateFromData = lifetimeCasesInputSortDateFromData;
        },
        changeLifetimeCasesInputSortDateToData(state, lifetimeCasesInputSortDateToData) {
            state.lifetimeCasesInputSortDateToData = lifetimeCasesInputSortDateToData;
        },
        changeLifetimeCasesOrderByColumnData(state, lifetimeCasesOrderByColumnData) {
            state.lifetimeCasesOrderByColumnData = lifetimeCasesOrderByColumnData;
        },
        changeLifetimeCasesInputSortDateValidStatus(state, lifetimeCasesInputSortDateValidStatus) {
            state.lifetimeCasesInputSortDateValidStatus = lifetimeCasesInputSortDateValidStatus;
        },
        changeLifetimeCasesInputSortDateFromFillValidStatus(state, lifetimeCasesInputSortDateFromFillValidStatus) {
            state.lifetimeCasesInputSortDateFromFillValidStatus = lifetimeCasesInputSortDateFromFillValidStatus;
        },
        changeLifetimeCasesInputSortDateToFillValidStatus(state, lifetimeCasesInputSortDateToFillValidStatus) {
            state.lifetimeCasesInputSortDateToFillValidStatus = lifetimeCasesInputSortDateToFillValidStatus;
        },
        changeNextStatus(state, status) {
            state.nextStatus = status;
        },
        changeScrollLoaderStatus(state, status) {
            state.loader = status;
        },
        changeLifetimeCasesId(state, lifetimeCases) {
            state.lifetimeCasesId = lifetimeCases.map(cases => cases?.id);
        },
        changeLifetimeSelectOptionStageData(state, lifetimeSelectOptionStageData) {
            state.lifetimeSelectOptionStageData = lifetimeSelectOptionStageData;
        },
        changeLifetimeSelectOptionResponsibleData(state, lifetimeSelectOptionResponsibleData) {
            state.lifetimeSelectOptionResponsibleData = lifetimeSelectOptionResponsibleData;
        },
        changeLifetimeCasesAssignMeButtonClickedStatus(state, lifetimeCasesAssignMeButtonClickedStatus) {
            state.lifetimeCasesAssignMeButtonClickedStatus = lifetimeCasesAssignMeButtonClickedStatus;
        },
        changeLifetimeCasesOpenFiltersButtonClickedStatus(state, lifetimeCasesOpenFiltersButtonClickedStatus) {
            state.lifetimeCasesOpenFiltersButtonClickedStatus = lifetimeCasesOpenFiltersButtonClickedStatus;
        },
        changeLifetimeSelectOptionStageShowStatus(state, lifetimeSelectOptionStageShowStatus) {
            state.lifetimeSelectOptionStageShowStatus = lifetimeSelectOptionStageShowStatus;
        },
        changeLifetimeSelectOptionResponsibleShowStatus(state, lifetimeSelectOptionResponsibleShowStatus) {
            state.lifetimeSelectOptionResponsibleShowStatus = lifetimeSelectOptionResponsibleShowStatus;
        },
        fillInTemplateOfCharacteristicField(state, flaskData) {
            const flasks = JSON.parse(JSON.stringify(flaskData));
            for (let flask of flasks) {
                const newObj = {
                    characteristics: `Форма: ${flask.form}; Характеристика: ${flask.characteristic}; Отношение к окружающим тканям: ${flask.position}`,
                }
                Object.assign(flask, newObj);
            }
            state.lifetimeBiopsySamplesFlasksWithCharacteristicsTemplate = flasks;
        },
        changeLifetimeBiopsySamplesFlasksWithCharacteristicsTemplate(state, arr) {
            state.lifetimeBiopsySamplesFlasksWithCharacteristicsTemplate = arr;
        },

        changeLifetimeCasesChecked(state, checkedData) {
            state.lifetimeCasesChecked = checkedData;
        },
        changeIsLifetimeCaseByIdLoading(state, condition) {
            state.isLifetimeCaseByIdLoading = condition;
        },
        addCassetteToLifetimeCaseByIdViewing(state, [newLifetimeCaseCassete, flaskId]) {
            const { lifetimeCaseByIdViewing } = state;
            if (lifetimeCaseByIdViewing?.biopsy?.flasks) {
                lifetimeCaseByIdViewing.biopsy.flasks.forEach(flask => {
                    if (flask.id === flaskId.flask_id) {
                        if (!flask.cassettes) {
                            flask.cassettes = [];
                        }
                        flask.cassettes.push(newLifetimeCaseCassete);
                    }
                });
            }
        },
        deleteCassetteFromLifetimeCaseByIdViewing(state, [flaskId, cassetteId]) {
            const { lifetimeCaseByIdViewing } = state;

            if (lifetimeCaseByIdViewing?.biopsy?.flasks) {
              lifetimeCaseByIdViewing.biopsy.flasks.forEach(flask => {
                if (flask.id === flaskId && flask.cassettes) {
                  flask.cassettes = flask.cassettes.filter(cassette => cassette.id !== cassetteId);
                }
              });
            }
        },
        deleteCassetteMicropreparationFromLifetimeCaseByIdViewing(state, [flaskId, cassetteId, micropreparationId]) {
            const { lifetimeCaseByIdViewing } = state;

            if (lifetimeCaseByIdViewing?.biopsy?.flasks) {
              lifetimeCaseByIdViewing.biopsy.flasks.forEach(flask => {
                if (flask.id === flaskId && flask.cassettes) {
                  const targetCassette = flask.cassettes.find(cassette => cassette.id === cassetteId);

                  if (targetCassette && targetCassette.micropreparations) {
                    targetCassette.micropreparations = targetCassette.micropreparations.filter(
                        micropreparation => micropreparation.id !== micropreparationId
                    );
                  }
                }
              });
            }
        },
        addMicropraparationToLifetimeCaseCassette(state, [flaskId, cassetteId, newLifetimeCaseMicropraparation]) {
            const { lifetimeCaseByIdViewing } = state;
            if (lifetimeCaseByIdViewing?.biopsy?.flasks) {
              lifetimeCaseByIdViewing.biopsy.flasks.forEach(flask => {
                if (flask.id === flaskId && flask.cassettes) {
                  const targetCassette = flask.cassettes.find(cassette => cassette.id === cassetteId);

                  if (targetCassette && !targetCassette.micropreparations) {
                    targetCassette.micropreparations = [];
                  }

                  if (targetCassette) {
                    targetCassette.micropreparations.push(newLifetimeCaseMicropraparation);
                  }
                }
              });
            }
        },
        changeLifetimeCaseFlaskDescription(state, [flaskId, newFlaskDescription]) {
            const { lifetimeCaseByIdViewing } = state;
            if (lifetimeCaseByIdViewing?.biopsy?.flasks) {
                lifetimeCaseByIdViewing.biopsy.flasks.forEach(flask => {
                    if (flask.id === flaskId) {
                        flask.description = newFlaskDescription;
                    }
                });
            }
        },
        changeLifetimeCaseCassetteAdditionalFicsation(state, [flaskId, cassetteId]) {
            const { lifetimeCaseByIdViewing } = state;
            if (lifetimeCaseByIdViewing?.biopsy?.flasks) {
                lifetimeCaseByIdViewing.biopsy.flasks.forEach(flask => {
                  if (flask.id === flaskId && flask.cassettes) {
                    const targetCassette = flask.cassettes.find(cassette => cassette.id === cassetteId);
                    if (targetCassette.stage?.stage) {
                        targetCassette.stage.stage = "ADDITIONAL_FIXATION";
                        targetCassette.additional_fixation = true;
                        targetCassette.decalcification = false;
                    }
                  }
                });
              }
        },
        changeLifetimeCaseCassetteDecalcification(state, [flaskId, cassetteId]) {
            const { lifetimeCaseByIdViewing } = state;
            if (lifetimeCaseByIdViewing?.biopsy?.flasks) {
                lifetimeCaseByIdViewing.biopsy.flasks.forEach(flask => {
                  if (flask.id === flaskId && flask.cassettes) {
                    const targetCassette = flask.cassettes.find(cassette => cassette.id === cassetteId);
                    if (targetCassette.stage?.stage) {
                        targetCassette.stage.stage = "DECALCIFICATION";
                        targetCassette.decalcification = true;
                        targetCassette.additional_fixation = false;
                    }
                  }
                });
              }
        },
        changeLifetimeCaseCassetteWiring(state, [flaskId, cassetteId]) {
            const { lifetimeCaseByIdViewing } = state;
            if (lifetimeCaseByIdViewing?.biopsy?.flasks) {
                lifetimeCaseByIdViewing.biopsy.flasks.forEach(flask => {
                  if (flask.id === flaskId && flask.cassettes) {
                    const targetCassette = flask.cassettes.find(cassette => cassette.id === cassetteId);
                    if (targetCassette.stage?.stage) {
                        targetCassette.stage.stage = "WIRING";
                        targetCassette.decalcification = false;
                        targetCassette.additional_fixation = false;
                    }
                  }
                });
              }
        },
        changeLifetimeCaseCassetteDescription(state, [flaskId, cassetteId, cassetteDescription]) {
            const { lifetimeCaseByIdViewing } = state;
            if (lifetimeCaseByIdViewing?.biopsy?.flasks) {
                lifetimeCaseByIdViewing.biopsy.flasks.forEach(flask => {
                  if (flask.id === flaskId && flask.cassettes) {
                    const targetCassette = flask.cassettes.find(cassette => cassette.id === cassetteId);
                    targetCassette.description = cassetteDescription;
                  }
                });
              }
        },
        changeLifetimeCaseMicropreparationColoringMethod(state, [flaskId, cassetteId, micropreparationId, coloringMethod]) {
            const { lifetimeCaseByIdViewing } = state;

            if (lifetimeCaseByIdViewing?.biopsy?.flasks) {
              lifetimeCaseByIdViewing.biopsy.flasks.forEach(flask => {
                if (flask.id === flaskId && flask.cassettes) {
                  const targetCassette = flask.cassettes.find(cassette => cassette.id === cassetteId);

                  if (targetCassette && targetCassette.micropreparations) {
                    targetCassette.micropreparations.forEach((micropreparation) => {
                        if (micropreparation.id === micropreparationId) {
                            micropreparation.coloring_method = coloringMethod;
                        }
                    })
                  }
                }
              });
            }
        },
        changeLifetimeCaseMicropreparationColoringList(state, [flaskId, cassetteId, micropreparationId, coloring]) {
            const { lifetimeCaseByIdViewing } = state;

            if (lifetimeCaseByIdViewing?.biopsy?.flasks) {
              lifetimeCaseByIdViewing.biopsy.flasks.forEach(flask => {
                if (flask.id === flaskId && flask.cassettes) {
                  const targetCassette = flask.cassettes.find(cassette => cassette.id === cassetteId);

                  if (targetCassette && targetCassette.micropreparations) {
                    targetCassette.micropreparations.forEach((micropreparation) => {
                        if (micropreparation.id === micropreparationId) {
                            micropreparation.coloring = coloring;
                        }
                    })
                  }
                }
              });
            }
        },
        changeLifetimeCasesFilterShowArchiveStatus(state, status) {
            state.lifetimeCasesFilterShowArchiveStatus = status;
        },
    },
    getters: {
        allLifetimeCases(state) {
            return state.lifetimeCases;
        },
        getLifetimeCasesDataWithSort(state) {
            return state.lifetimeCasesDataWithSort;
        },
        returnLifetimeCaseById(state) {
            return state.lifetimeCaseByIdViewing;
        },
        getLifetimeCaseId(state) {
            return state.lifetimeCaseId;
        },
        getLifetimeCasesActiveTab(state) {
            return state.lifetimeCasesActiveTab
        },
        getLifetimeCasesBtnPrintStatus(state) {
            return state.lifetimeCasesBtnPrintStatus;
        },
        getLifetimeCasesSearchInputData(state) {
            return state.lifetimeCasesSearchInputData;
        },
        getLifetimeCasesFilterTypeData(state) {
            return state.lifetimeCasesFilterTypeData;
        },
        getLifetimeCasesInputSortDateFromData(state) {
            return state.lifetimeCasesInputSortDateFromData;
        },
        getLifetimeCasesInputSortDateToData(state) {
            return state.lifetimeCasesInputSortDateToData;
        },
        getLifetimeCasesInputSortDateValidStatus(state) {
            return state.lifetimeCasesInputSortDateValidStatus;
        },
        getLifetimeCasesInputSortDateFromFillValidStatus(state) {
            return state.lifetimeCasesInputSortDateFromFillValidStatus;
        },
        getLifetimeCasesInputSortDateToFillValidStatus(state) {
            return state.lifetimeCasesInputSortDateToFillValidStatus;
        },
        getScrollLoaderStatus(state) {
            return state.loader;
        },
        getLifetimeCasesId(state) {
            return state.lifetimeCasesId;
        },
        getLifetimeSelectOptionStageData(state) {
            return state.lifetimeSelectOptionStageData;
        },
        getLifetimeSelectOptionResponsibleData(state) {
            return state.lifetimeSelectOptionResponsibleData;
        },
        getLifetimeCasesAssignMeButtonClickedStatus(state) {
            return state.lifetimeCasesAssignMeButtonClickedStatus;
        },
        getLifetimeCasesOpenFiltersButtonClickedStatus(state) {
            return state.lifetimeCasesOpenFiltersButtonClickedStatus;
        },
        getLifetimeSelectOptionStageShowStatus(state) {
            return state.lifetimeSelectOptionStageShowStatus;
        },
        getLifetimeSelectOptionResponsibleShowStatus(state) {
            return state.lifetimeSelectOptionResponsibleShowStatus;
        },
        getLifetimeCasesChecked(state) {
            return state.lifetimeCasesChecked;
        },
        getIsLifetimeCaseByIdLoading(state) {
            return state.isLifetimeCaseByIdLoading;
        },
        getLifetimeCasesFilterShowArchiveStatus(state) {
            return state.lifetimeCasesFilterShowArchiveStatus;
        }
    }
}