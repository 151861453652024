<template>
  <slot />
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "PageLayout",
    computed: {
        ...mapGetters([
            "getHintContainerShowStatus",
            "getSomethingWrongStatus",
            "getServerProblemStatus",
            "getInvalidRefreshTokenStatus"
        ])
    },
}
</script>