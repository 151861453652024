export default {
    state: {
        mortemDiseaseInfoBtnStatus: null,
        mortemCaseDiseaseinfoArrivedDate: null,
        mortemDiseaseTimeAfterHours: null,
        mortemDiseaseTimeAfterDays: null,
        mortemDiseaseDoctorName: null,
        mortemDiseaseClinicalData: null,
        mortemDiseaseInfoIcdCode: null,
        mortemDiseaseDescription: null,
        mortemDiseaseGeneral: null,
        mortemDiseaseGeneralComplication: null,
        mortemDiseaseRelated: null,
    },
    actions: {
        updateMortemDiseaseInfoBtnStatus({ commit }, mortemDiseaseInfoBtnStatus) {
            commit("changeMortemDiseaseInfoBtnStatus", mortemDiseaseInfoBtnStatus);
        },
        updatemMortemCaseDiseaseinfoArrivedDate({ commit }, mortemCaseDiseaseinfoArrivedDate) {
            commit("changeMortemCaseDiseaseinfoArrivedDate", mortemCaseDiseaseinfoArrivedDate);
        },
        updateMortemDiseaseTimeAfterHours({ commit }, mortemDiseaseTimeAfterHours) {
            commit("changeMortemDiseaseTimeAfterHours", mortemDiseaseTimeAfterHours);
        },
        updateMortemDiseaseTimeAfterDays({ commit }, mortemDiseaseTimeAfterDays) {
            commit("changeMortemDiseaseTimeAfterDays", mortemDiseaseTimeAfterDays);
        },
        updateMortemDiseaseDoctorName({ commit }, mortemDiseaseDoctorName) {
            commit("changeMortemDiseaseDoctorName", mortemDiseaseDoctorName);
        },
        updateMortemDiseaseClinicalData({ commit }, mortemDiseaseClinicalData) {
            commit("changeMortemDiseaseClinicalData", mortemDiseaseClinicalData);
        },
        updateMortemDiseaseInfoIcdCode({ commit }, mortemDiseaseInfoIcdCode) {
            commit("changeMortemDiseaseInfoIcdCode", mortemDiseaseInfoIcdCode);
        },
        updateMortemDiseaseDescription({ commit }, mortemDiseaseDescription) {
            commit("changeMortemDiseaseDescription", mortemDiseaseDescription);
        },
        updateMortemDiseaseGeneral({ commit }, mortemDiseaseGeneral) {
            commit("changeMortemDiseaseGeneral", mortemDiseaseGeneral);
        },
        updateMortemDiseaseGeneralComplication({ commit }, mortemDiseaseGeneralComplication) {
            commit("changeMortemDiseaseGeneralComplication", mortemDiseaseGeneralComplication);
        },
        updateMortemDiseaseRelated({ commit }, mortemDiseaseRelated) {
            commit("changeMortemDiseaseRelated", mortemDiseaseRelated);
        },
    },
    mutations: {
        changeMortemDiseaseInfoBtnStatus(state, mortemDiseaseInfoBtnStatus) {
            state.mortemDiseaseInfoBtnStatus = mortemDiseaseInfoBtnStatus;
        },
        changeMortemCaseDiseaseinfoArrivedDate(state, mortemCaseDiseaseinfoArrivedDate) {
            state.mortemCaseDiseaseinfoArrivedDate = mortemCaseDiseaseinfoArrivedDate;
        },
        changeMortemDiseaseTimeAfterHours(state, mortemDiseaseTimeAfterHours) {
            state.mortemDiseaseTimeAfterHours = mortemDiseaseTimeAfterHours;
        },
        changeMortemDiseaseTimeAfterDays(state, mortemDiseaseTimeAfterDays) {
            state.mortemDiseaseTimeAfterDays = mortemDiseaseTimeAfterDays;
        },
        changeMortemDiseaseDoctorName(state, mortemDiseaseDoctorName) {
            state.mortemDiseaseDoctorName = mortemDiseaseDoctorName;
        },
        changeMortemDiseaseClinicalData(state, mortemDiseaseClinicalData) {
            state.mortemDiseaseClinicalData = mortemDiseaseClinicalData;
        },
        changeMortemDiseaseInfoIcdCode(state, mortemDiseaseInfoIcdCode) {
            state.mortemDiseaseInfoIcdCode = mortemDiseaseInfoIcdCode;
        },
        changeMortemDiseaseDescription(state, mortemDiseaseDescription) {
            state.mortemDiseaseDescription = mortemDiseaseDescription;
        },
        changeMortemDiseaseGeneral(state, mortemDiseaseGeneral) {
            state.mortemDiseaseGeneral = mortemDiseaseGeneral;
        },
        changeMortemDiseaseGeneralComplication(state, mortemDiseaseGeneralComplication) {
            state.mortemDiseaseGeneralComplication = mortemDiseaseGeneralComplication;
        },
        changeMortemDiseaseRelated(state, mortemDiseaseRelated) {
            state.mortemDiseaseRelated = mortemDiseaseRelated;
        },
    },
    getters: {
        getMortemDiseaseInfoBtnStatus(state) {
            return state.mortemDiseaseInfoBtnStatus;
        },
        getMortemCaseDiseaseinfoArrivedDate(state) {
            return state.mortemCaseDiseaseinfoArrivedDate;
        },
        getMortemDiseaseTimeAfterHours(state) {
            return state.mortemDiseaseTimeAfterHours;
        },
        getMortemDiseaseTimeAfterDays(state) {
            return state.mortemDiseaseTimeAfterDays
        },
        getMortemDiseaseDoctorName(state) {
            return state.mortemDiseaseDoctorName
        },
        getMortemDiseaseClinicalData(state) {
            return state.mortemDiseaseClinicalData
        },
        getMortemDiseaseInfoIcdCode(state) {
            return state.mortemDiseaseInfoIcdCode
        },
        getMortemDiseaseDescription(state) {
            return state.mortemDiseaseDescription
        },
        getMortemDiseaseGeneral(state) {
            return state.mortemDiseaseGeneral
        },
        getMortemDiseaseGeneralComplication(state) {
            return state.mortemDiseaseGeneralComplication
        },
        getMortemDiseaseRelated(state) {
            return state.mortemDiseaseRelated
        }
    }
}