export default {
    state: {
        // macroDescriptionInformationData: null,
        totalCassetteCount: null,
        // lifetimeMacroscopyData: null,
    },
    actions: {
        // updateLifetimeCaseMacroDescriptionStatus({ dispatch, state }) {
        //     dispatch("updateLifetimeCaseStatus", state.macroDescriptionInformationData);
        // },
        // updateLifetimeMacroDescriptionInformationData({ commit }, lifetimeMacroDescriptionInformationData) {
        //     commit("changeLifetimeMacroDescriptionInformationData", lifetimeMacroDescriptionInformationData);
        // },
        updateTotalCassetteCount({ commit }, totalCassetteCount) {
            commit("changeTotalCassetteCount", totalCassetteCount);
        },
        // updateLifetimeCaseMacroscopyData({ commit }, macroscopyData) {
        //     commit("changeLifetimeCaseMacroscopyData", macroscopyData);
        // },
        // async updateLifetimeCaseMacroscopyStatus({ dispatch, state }) {
        //     await dispatch("updateLifetimeCaseStatus", state.lifetimeMacroscopyData);
        // },
    },
    mutations: {
        // changeLifetimeMacroDescriptionInformationData(state, lifetimeMacroDescriptionInformationData) {
        //     state.macroDescriptionInformationData = lifetimeMacroDescriptionInformationData;
        // },
        changeTotalCassetteCount(state, totalCassetteCount) {
            state.totalCassetteCount = totalCassetteCount;
        },
        // changeLifetimeCaseMacroscopyData(state, macroscopyData) {
        //     state.lifetimeMacroscopyData = macroscopyData;
        // },
    },
    getters: {
        // getLifetimeMacroDescriptionInformationData(state) {
        //     return state.macroDescriptionInformationData;
        // },
        getTotalCassetteCount(state) {
            return state.totalCassetteCount;
        },
        // getLifetimeMacroscopyData(state) {
        //     return state.lifetimeMacroscopyData;
        // },
    }
}