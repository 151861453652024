export default {
    state: {
        mortemAutopsyMembranesBtnStatus: false,
        mortemAutopsyMembranesDescription: null,
    },
    actions: {
        updateMortemAutopsyMembranesBtnStatus({ commit }, condition) {
            commit("changeMortemAutopsyMembranesBtnStatus", condition);
        },
        updateAutopsyMembranesField({ commit }, payload) {
            commit('changeAutopsyMembranesField', payload);
        }
    },
    mutations: {
        changeMortemAutopsyMembranesBtnStatus(state, condition) {
            state.mortemAutopsyMembranesBtnStatus = condition;
        },
        changeAutopsyMembranesField(state, { key, value }) {
            state[key] = value;
        }
    },
    getters: {
        getMortemAutopsyMembranesBtnStatus(state) {
            return state.mortemAutopsyMembranesBtnStatus;
        },
        getAutopsyMembranesField: (state) => (key) => {
            return state[key];
        }
    }
}