<template>
    <Dialog 
        v-model:visible="isRestorePasswordDialogVisible"
        modal
        header="Header"
        class="dialog"
    >
        <template #header>
            <div class="dialog__header">
                <span>Восстановление пароля</span>
            </div>
        </template>
        <template #closeicon>
            <div style="display: none;"></div>
        </template>
        <div class="dialog__body" style="display: flex; flex-direction: column; gap: 16px;">
            <div class="additional__tab_items">
                <div class="additional__tab_item" style="width: 100%;">
                    <div>
                        <p style="padding-bottom: 10px;">
                            Если вы забыли свой пароль, не волнуйтесь. Вы можете восстановить доступ к своему аккаунту одним из следующих способов:
                        </p>
                        <p style="padding-bottom: 5px; padding-left: 10px;">
                            1. Перейдите по ссылке <a href="https://help.ecomedis.ru" style="text-decoration: underline; color: #6C69EB;">https://help.ecomedis.ru</a> для восстановления пароля.
                        </p>
                        <p style="padding-bottom: 5px; padding-left: 10px;">
                            2. Отправьте запрос на наш адрес электронной почты: <a href="mailto:help@ecomedis.ru" style="text-decoration: underline; color: #6C69EB;">help@ecomedis.ru</a>.
                        </p>
                        <p style="padding-bottom: 5px; padding-left: 10px;">
                            3. Свяжитесь с нашей службой технической поддержки по телефону: <a href="tel: +7 800 301-25-27" style="text-decoration: underline; color: #6C69EB;">+7 800 301-25-27</a>. Наши специалисты помогут вам восстановить доступ к вашему аккаунту.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button
                class="button__purple"
                label="Закрыть"
                @click="isRestorePasswordDialogVisible = false"
            />
        </template>
    </Dialog>
    <div class="page" data-testid="authorization">
        <div class="top">
            <div class="authorization">
                <div class="authorization__logo">
                    <img
                        src="../assets/img/MORFIS_LOGO.svg"
                        alt="logo"
                        class="authorization__logo_img"
                    />
                </div>
                <div class="authorization__form">
                    <form
                        ref="authorization-form-ref"
                        action="POST"
                        class="form"
                        @keydown.prevent.enter="submit"
                    >
                        <h3 class="form__header">
                            Авторизация
                        </h3>
                        <div class="authorization__form_row">
                            <AppInput
                                key="authorization-app-input-username"
                                inputType="text"
                                :labelClass="['authorization__form_input--label', {label__invalid: getLoginFieldFilledInStatus}]"
                                inputId="username"
                                inputName="username"
                                inputRequired
                                autocomplete="off"
                                fieldDataName="username"
                                floatLabelTitle="Логин"
                                inputPlaceholder=" "
                                :inputClasses="['authorization__form_input', {field__invalid: getLoginFieldFilledInStatus}]"
                                :inputFieldNotificationCondition="getLoginFieldFilledInStatus"
                                inputFieldNotificationKey="authorization-app-input-form-input-field-notification-username"
                                :inputFieldNotification="getFieldHintAuthorizationMessage"
                                :inputFieldNotificationClasses="['notification__invalid']"
                                :collectDataToStore="collectAuthorizationDataToStore"
                                :fieldCollectionName="fieldsFlags.authorizationCollection"
                                @collectComponentData="collectChildComponentData"
                            />
                        </div>
                        <div class="authorization__form_row">
                            <AppInput
                                key="authorization-app-input-password"
                                :inputType="isHiddenPassword ? 'password' : 'text'"
                                :labelClass="['authorization__form_input--label', {label__invalid: getPasswordFieldFilledInStatus}]"
                                inputId="password"
                                inputName="password"
                                inputRequired
                                autocomplete="off"
                                fieldDataName="password"
                                floatLabelTitle="Пароль"
                                inputPlaceholder=" "
                                :inputClasses="['authorization__form_input', {field__invalid: getPasswordFieldFilledInStatus}]"
                                :inputFieldNotificationCondition="getPasswordFieldFilledInStatus"
                                inputFieldNotificationKey="authorization-app-input-form-input-field-notification-password"
                                :inputFieldNotification="getFieldHintAuthorizationMessage"
                                :inputFieldNotificationClasses="['notification__invalid']"
                                :collectDataToStore="collectAuthorizationDataToStore"
                                :fieldCollectionName="fieldsFlags.authorizationCollection"
                                @collectComponentData="collectChildComponentData"
                            />
                            <Button
                                class="button__show_password"
                                :disabled="getDisabledShowPasswordStatus"
                                :pt="{
                                    label: {style: 'display: none;'},
                                }"
                                @click="showPassword()"
                            >
                                <template #icon>
                                    <i
                                        class="icon__eye"
                                        v-html="isHiddenPassword ? getIconTemplate('icon-eye-crossed') : getIconTemplate('icon-eye')"
                                    ></i>
                                </template>
                            </Button>
                        </div>
                        <Button
                            class="button__purple"
                            style="width: 100%;"
                            label="Войти"
                            :disabled="getAuthorizationLoginButtonDisabledStatus"
                            @click="submit()" 
                        />
                        <div 
                            class="authorization__form_forgot authorization__form_forgot--link"
                            style="cursor: pointer;"
                            @click="isRestorePasswordDialogVisible = true"
                        >
                            Забыли пароль?
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getAboutLink, getSupportLink, getLifetimeCasesLink } from "@/router";
import { mapActions, mapGetters } from "vuex";
import { emitting, fieldsFlags, fieldHints, icons } from "@/mixins";
import AppInput from "@/components/AppInput.vue";

export default {
    name: "Authorization",
    mixins: [emitting, fieldsFlags, fieldHints, icons],
    data() {
        return {
            isHiddenPassword: true,
            aboutLink: getAboutLink(),
            supportLink: getSupportLink(),
            lifetimeCasesLink: getLifetimeCasesLink(),
            isRestorePasswordDialogVisible: false,
        };
    },
    components: {
        AppInput,
    },
    computed: {
        ...mapGetters([
            "getErrorStatus",
            "getAuthorizationData",
            "getLoginFieldFilledInStatus",
            "getPasswordFieldFilledInStatus",
            "getAuthorizationLoginButtonDisabledStatus",
        ]),
        getDisabledShowPasswordStatus() {
            return this.getAuthorizationData.password === undefined || this.getAuthorizationData.password?.length === 0;
        }
    },
    methods: {
        ...mapActions([
            "getTokenData",
            "updateAuthorizationData",
            "updateLoginFieldFilledInStatus",
            "updatePasswordFieldFilledInStatus",
            "updateAuthorizationLoginButtonDisabledStatus",
        ]),
        showPassword() {
            this.isHiddenPassword = !this.isHiddenPassword;
        },
        collectAuthorizationDataToStore() {
            const authorizationData = Object.fromEntries(this.changedData);
            if (authorizationData.username?.length !== 0) {
                this.updateLoginFieldFilledInStatus(false);
            }
            if (authorizationData.password?.length !== 0) {
                this.updatePasswordFieldFilledInStatus(false);
            }
            this.updateAuthorizationData(authorizationData);
        },
        async submit() {
            if (!this.getAuthorizationData.username || !this.getAuthorizationData.password) {
                if(!this.getAuthorizationData.username) {
                    this.updateLoginFieldFilledInStatus(true);
                }
                if(!this.getAuthorizationData.password) {
                    this.updatePasswordFieldFilledInStatus(true);
                }
                return;
            }
            await this.getTokenData()
                .then(() => {
                    if (this.getErrorStatus !== "") {
                        return;
                    }
                    this.$router.push(getLifetimeCasesLink());
                })
                .catch((error) => {
                    console.log('%cError: ', 'color: green;', error);
                });
        },
    },
    watch: {
        "getAuthorizationData.username": function() {
            this.updateAuthorizationLoginButtonDisabledStatus(false);
        },
        "getAuthorizationData.password": function() {
            this.updateAuthorizationLoginButtonDisabledStatus(false);
        }
    },
    mounted() {
        setTimeout(() => {
            this.$refs["authorization-form-ref"].password.focus();
            this.$refs["authorization-form-ref"].username.focus();
        }, 10)
    }
};
</script>

<style lang="sass">
@import "@/assets/css/_vars.sass"
.authorization
    display: flex
    justify-content: space-between
    align-items: center
    width: 680px
    height: 328px
    padding: 8px
    gap: 8px
    background: $authorizationBackground
    border-radius: 16px
    &__logo
        display: flex
        align-items: center
        justify-content: center
        width: 50%
        &_img
            width: 260px
    &__form
        background-color: $authorizationFormBackground
        border-radius: 8px
        width: 50%
        height: 100%
        padding: 48px 40px 0 40px
        &_row
            position: relative
            width: 100%
        &_forgot
            display: flex
            justify-content: flex-end
            width: 100%
        &_forgot--link
            color: #7952a1
            font-size: 12px
            margin-top: 12px
            transition: all 0.2s ease
            text-decoration: underline
            &:hover
                color: $authorizationForgotLinkColor
        .form
            display: flex
            flex-direction: column
            align-items: center
            height: 100%
            &__header
                margin-bottom: 24px
                font-size: 20px
                font-style: normal
                font-weight: 700
                line-height: 28px
                letter-spacing: -0.4px
            &__row
                position: relative
                margin-bottom: 30px
.icon__eye
    display: flex
    font-size: 16px
.button__show_password
    position: absolute
    top: 11px
    right: 16px
    cursor: pointer
    background-color: white
.button__show_password:disabled .icon__eye
    cursor: text
.authorization__form_input
    display: flex
    width: 100%
    height: 38px
    padding: 12px 16px 12px 16px
    font-size: 13px
    font-weight: 400
    line-height: 1.5
    background-color: $authorizationInputBackgroundColor
    background-clip: padding-box
    border: 1px solid $authorizationInputBorderColor
    border-radius: 4px
    box-shadow: inset 0 0 0 50px #fff
    margin-bottom: 24px
    &:focus
        border-color: $authorizationInputFocusBorderColor
    &--label
        position: absolute
        top: 12px
        left: 8px
        font-size: 12px
        padding: 0 8px
        color: $authorizationLabelPlaceholderTextColor
        background-color: $authorizationLabelPlaceholderBorderColor
        pointer-events: none
        transform-origin: 0 50%
        transition: transform 200ms, color 200ms
.authorization__form_input:focus ~ .authorization__form_input--label,
.authorization__form_input:not(:placeholder-shown) ~ .authorization__form_input--label
    transform: translateY(-19px) translateX(0px)
.authorization__form_input:placeholder-shown ~ .label__invalid
    transform: translateY(-19px) translateX(0px)
.authorization__form_input:not(:placeholder-shown) ~ .authorization__form_input--label
    color: $authorizationLabelTextColor
.authorization__form_input:not(:placeholder-shown) ~ .label__invalid
    color: $authorizationLabelInvalidTextColor
.authorization__form_input:focus ~ .authorization__form_input--label
    color: $authorizationLabelTextColor
.authorization__form_input:focus ~ .label__invalid
    color: $authorizationLabelInvalidTextColor
.login__button
    width: 100%
    height: 38px
    text-transform: none
    padding: 12px 15px
    font-size: 14px
    font-weight: 400
    background-color: $authorizationLoginButtonBackgroundColor
    &:hover
        background-color: $authorizationLoginButtonHoverBackgroundColor
.field__invalid
    border-color: $authorizationInputInvalidBorderColor !important
.label__invalid
    color: $authorizationLabelInvalidTextColor
.notification__invalid
    top: 36px
    font-size: 10px
.loader__button_position
    position: absolute
    left: -17px
    top: 2px
</style>