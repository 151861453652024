import moment from "moment";
export const calcAge = {
    computed: {
        pluralize() {
            return (n, var1, var2, var3) => {
                let remainderDivision = n % 10;

                if (n === 0 && var1 === "день") return "1 день";
                if (n === 0 && var1 === "месяц") return;

                if ((remainderDivision === 1) && (n !== 11) && (n !== 10)) {
                    return `${n} ${var1}`;
                } else if ((remainderDivision > 1 && remainderDivision <= 4) && (n !== 10 && n !== 11 && n !== 12 && n !== 13 && n !== 14)) {
                    return `${n} ${var2}`;
                } else {
                    return `${n} ${var3}`;
                }
            }
        },
        fullYears() {
            return (dob, pluralYears, ageInYears, dateFromWhichCount = Date.now()) => {
                const monthFromWhichCount = moment(dateFromWhichCount).format("MM");
                const dayFromWhichCount = moment(dateFromWhichCount).format("DD");
                const monthBirthd = moment(dob).format("MM");
                const dayBirthd = moment(dob).format("DD");

                if (monthFromWhichCount === monthBirthd) {
                    if (dayFromWhichCount > dayBirthd || dayFromWhichCount === dayBirthd) {
                        return pluralYears;
                    } else {
                        return this.pluralize(ageInYears - 1, "год", "года", "лет");
                    }
                } else if (monthBirthd > monthFromWhichCount) {
                    return this.pluralize(ageInYears - 1, "год", "года", "лет");
                } else {
                    return pluralYears;
                }
            }
        },
        calcAge() {
            return (dob, dateFromWhichCount = Date.now()) => {
                if ((dob === undefined) || (dob === null) || (dob === "")) {
                    return "";
                }
                const age = moment.duration(moment(dateFromWhichCount).diff(moment(dob)));
                const ageInYears = Math.round(age.asYears());
                const ageInMonths = Math.floor(age.asMonths());
                if (age < 0)
                    return 'ERROR: Еще не родился!'

                let pluralYears = this.pluralize(ageInYears - 1, "год", "года", "лет");
                let pluralDays = this.pluralize(age.days(), "день", "дня", "дней");

                if (ageInYears < 18) {
                    if (ageInYears >= 1) {
                        if (age.months() === 0) {
                            return `${pluralYears} ${pluralDays}`;
                        } else {
                            return `${pluralYears} ${this.pluralize(age.months(), "месяц", "месяца", "месяцев")}`;
                        }
                    } else if (ageInYears < 1 && ageInMonths >= 1) {
                        return `${this.pluralize(ageInMonths, "месяц", "месяца", "месяцев")} ${pluralDays}`;
                    } else {
                        return pluralDays;
                    }
                } else {
                    return this.fullYears(dob, pluralYears, ageInYears, dateFromWhichCount);
                }
            }
        }
    }
}