export default {
    state: {
        mortemCasesOpenFiltersButtonClickedStatus: false,
        mortemCasesChecked: [],
        mortemCasesOrderByColumnData: "",
    },
    actions: {
        updateMortemCasesOpenFiltersButtonClickedStatus({ commit }, mortemCasesOpenFiltersButtonClickedStatus) {
            commit("changeMortemCasesOpenFiltersButtonClickedStatus", mortemCasesOpenFiltersButtonClickedStatus);
        },
        updateMortemCasesChecked({ commit }, checkedData) {
            commit("changeMortemCasesChecked", checkedData);
        },
        updateMortemCasesOrderByColumnData({ commit }, mortemCasesOrderByColumnData) {
            commit("changeMortemCasesOrderByColumnData", mortemCasesOrderByColumnData);
        },
    },
    mutations: {
        changeMortemCasesOpenFiltersButtonClickedStatus(state, mortemCasesOpenFiltersButtonClickedStatus) {
            state.mortemCasesOpenFiltersButtonClickedStatus = mortemCasesOpenFiltersButtonClickedStatus;
        },
        changeMortemCasesChecked(state, checkedData) {
            state.mortemCasesChecked = checkedData;
        },
        changeMortemCasesOrderByColumnData(state, mortemCasesOrderByColumnData) {
            state.mortemCasesOrderByColumnData = mortemCasesOrderByColumnData;
        },
    },
    getters: {
        getMortemCasesOpenFiltersButtonClickedStatus(state) {
            return state.mortemCasesOpenFiltersButtonClickedStatus;
        },
        getMortemCasesChecked(state) {
            return state.mortemCasesChecked;
        },
    }
}