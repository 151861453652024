import {
    distributorCheckFunctions,
    distributorFormFlagActions
} from "@/mixins";

export const fields = {
    mixins: [distributorCheckFunctions, distributorFormFlagActions],
    computed: {
        getLifetimeFieldsCollection() {
            // ["", ["", "", "", "", "", ""]],

            // [
            //     "[field_name]",
            //     [
            //         "[api_category_name]", string
            //         "[parent_object_name]", string
            //         "[tab_form_save_btn_block_flag]", string
            //         "[modal_check_flag]", string
            //         "[server_check_flag]", string
            //         "[view_check_flag]", string
            //         "[required_field_flag]", boolean
            //         "[reference_search_field_flag]", boolean
            //         "[field_ru_description]" string
            //     ]
            // ],
            return (collectionName) => {
                switch (collectionName) {
                    case "authorizationCollection":
                        return new Map([
                            ["username", ["universal", "none", "", "string", "string", "string", false, false, "Логин"]],
                            ["password", ["universal", "none", "", "string", "string", "string", false, false, "Пароль"]],
                        ]);
                    case "lifetimeCaseGeneralCollection":
                        return new Map([
                            ["register_number", ["lifetime", "results", "", "string", "string", "string", false, false, "Регистрационный номер"]],
                            ["cito", ["lifetime", "results", "", "boolean", "boolean", "boolean", false, false, "Срочность"]],
                            ["arrived_date", ["lifetime", "results", "", "dateTimeInput", "dateTimeTimeZone", "dateTime", true, false, "Дата и время поступления"]],
                            ["responsible_staff", ["lifetime", "none", "", "string", "string", "string", false, false, "Сотрудник на прием материала"]],
                        ]);
                    case "lifetimeCaseRegistrationInformationCollection":
                        return new Map([
                            ["register_timestamp", ["lifetime", "results", "", "dateTimeInput", "dateTimeTimeZone", "dateTime", false, false, "Дата и время регистрации"]],
                            ["responsible_staff_name", ["lifetime", "results", "lifetimeGeneral", "string", "string", "string", false, false, "Ответственный сотрудник"]],
                        ]);
                    case "lifetimeRegisterNumberCollection":
                        return new Map([
                            ["register_number_format", ["lifetime", "none", "", "string", "string", "string", false, false, "Формат регистрационного номера Случая"]],
                        ]);
                    case "lifetimePreviousBiopsyStudiesTableCollection":
                        return new Map([
                            ["register_number", ["lifetime_previous_biopsy_studies_table", "previous_cases", "lifetimeDisease", "string", "string", "string", false, false, "Рег. номер"]],
                            ["completion_date", ["lifetime_previous_biopsy_studies_table", "previous_cases", "lifetimeDisease", "dateInput", "dateInput", "date", false, false, "Дата исследования"]],
                            ["organization_title", ["lifetime_previous_biopsy_studies_table", "previous_cases", "lifetimeDisease", "string", "string", "string", false, true, "Медицинская организация"]],
                            ["disease_report", ["lifetime_previous_biopsy_studies_table", "previous_cases", "lifetimeDisease", "string", "string", "string", false, false, "Заключение"]],
                        ]);
                    case "lifetimeBiopsyCollection":
                        return new Map([
                            ["biopsy_material_id", ["lifetime", "biopsy", "", "integer", "integer", "integer", false, false, "Биопсийный материал"]],
                            ["biopsy_material_title", ["lifetime", "biopsy", "", "string", "string", "string", false, false, "Биопсийный материал"]],
                            ["biopsy_type_id", ["lifetime", "biopsy", "", "integer", "integer", "integer", false, false, "Вид биопсии"]],
                            ["biopsy_type_title", ["lifetime", "biopsy", "", "string", "string", "string", false, false, "Вид биопсии"]],
                            ["biopsy_date", ["lifetime", "biopsy", "", "integer", "integer", "integer", false, false, "Дата и время взятия"]],
                            ["biopsy_container_status", ["lifetime", "biopsy", "", "boolean", "boolean", "boolean", false, false, "Сохранность упаковки"]],
                            ["biopsy_10_formalin", ["lifetime", "biopsy", "", "boolean", "boolean", "boolean", false, false, "Помещен в формалин"]],
                            ["biopsy_contaminated", ["lifetime", "biopsy", "", "boolean", "boolean", "boolean", false, false, "Загрязнен"]],
                        ]);
                    case "lifetimeMaterialTableCollection":
                        return new Map([
                            ["number", ["lifetime_material_table", "flasks", "lifetimeMaterial", "string", "string", "string", true, false, "№ фл."]],
                            ["localization", ["lifetime_material_table", "flasks", "lifetimeMaterial", "string", "string", "string", false, true, "Локализация"]],
                            ["localization_title", ["lifetime_material_table", "flasks", "lifetimeMaterial", "string", "string", "string", false, true, "Локализация"]],
                            ["form", ["lifetime_material_table", "flasks", "lifetimeMaterial", "string", "string", "string", false, false, "Форма"]],
                            ["position", ["lifetime_material_table", "flasks", "lifetimeMaterial", "string", "string", "string", false, true, "Отношение к окружающим тканям"]],
                            ["characteristic", ["lifetime_material_table", "flasks", "lifetimeMaterial", "string", "string", "string", false, false, "Характеристика"]],
                            ["characteristics", ["lifetime_material_table", "flasks", "lifetimeMaterial", "string", "string", "string", false, true, "Характер патологического процесса"]],
                            ["pieces_count", ["lifetime_material_table", "flasks", "lifetimeMaterial", "integer", "integer", "integer", false, false, "Кол-во объектов"]],
                            ["pieces_count_to_work", ["lifetime_material_table", "flasks", "lifetimeMaterial", "integer", "integer", "integer", false, false, "Количество объектов в работу"]],
                            ["defect_info", ["lifetime_material_table", "flasks", "lifetimeMaterial", "integer", "integer", "integer", false, false, "Информация о браке"]],
                        ]);
                    case "lifetimeCaseJournalCollection":
                        return new Map([
                            ["isPrinted", ["lifetime", "none", "", "boolean", "boolean", "boolean", false, false, "Распечатан"]],
                            ["choice", ["lifetime", "none", "", "string", "string", "string", false, false, "USE"]],
                            ["register_number", ["lifetime", "results", "", "string", "string", "string", false, false, "Регистрационный номер"]],
                            ["cito", ["lifetime", "results", "", "boolean", "boolean", "boolean", false, false, ""]],
                            ["arrived_date", ["lifetime", "results", "", "dateTimeInput", "dateTimeTimeZone", "dateTime", true, false, "Дата поступления"]],
                            ["estimated_time", ["lifetime", "results", "", "dateTimeInput", "dateTimeTimeZone", "dateTime", false, false, "Выполнить до"]],//date?
                            ["flask_count", ["lifetime", "results", "", "string", "string", "string", false, false, "Кол-во флаконов"]],//integer?
                            ["cassette_count", ["lifetime", "results", "", "string", "string", "string", false, false, "Кол-во кассет"]],//integer?
                            ["status", ["lifetime", "results", "", "string", "string", "string", false, false, "Этап"]],
                            ["processed_on", ["lifetime", "results", "", "string", "string", "string", false, false, "Готовность"]],
                            ["in_step", ["lifetime", "results", "", "string", "string", "string", false, false, "В этапе"]],//bool?
                            ["responsible_staff", ["lifetime", "results", "lifetimeGeneral", "string", "string", "string", false, false, "Ответственный за этап"]],
                            // ["responsible_staff_name", ["lifetime", "results", "lifetimeGeneral", "string", "string", "string", false, false, "Ответственный за этап"]],
                            ["patient_full_name", ["lifetime", "results", "", "string", "string", "string", false, false, "Пациент"]],
                            ["request_organization_title", ["lifetime", "results", "", "string", "string", "string", false, false, "Заказчик"]],//"Наименование организации"
                            ["request_organization_department", ["lifetime", "results", "", "string", "string", "string", false, false, "Отделение"]],
                            ["completion_staff", ["lifetime", "results", "", "string", "string", "string", false, false, "Выполнил"]],
                            ["release_date", ["lifetime", "results", "", "dateTimeInput", "dateTimeTimeZone", "dateTime", false, false, "Дата выдачи"]],
                            ["external_export_status", ["lifetime", "results", "", "string", "string", "string", false, false, "Статус выгрузки"]],
                            ["patient_external_number", ["lifetime", "results", "", "string", "string", "string", false, false, "Внешний номер"]],
                            ["source", ["lifetime", "results", "", "string", "string", "string", false, false, "Источник заявки"]],
                            ["has_previous_case", ["lifetime", "results", "", "string", "string", "string", false, false, "История"]],//bool?
                            ["biopsy_date", ["lifetime", "results", "", "dateTimeInput", "dateTimeTimeZone", "dateTime", false, false, "Дата взятия"]],
                            ["register_timestamp", ["lifetime", "results", "", "dateTimeInput", "dateTimeTimeZone", "dateTime", false, false, "Дата регистрации"]],
                            ["completion_date", ["lifetime", "results", "", "dateTimeInput", "dateTimeTimeZone", "dateTime", false, false, "Дата выполнения"]],
                            ["difficulty", ["lifetime", "results", "", "string", "string", "string", false, false, "Категория сложности"]],
                            // ["patient_birthdate", ["lifetime", "results", "", "dateInput", "dateInput", "date", false, false, "Дата рождения"]],//date?
                            ["patient_birthday", ["lifetime", "results", "", "dateInput", "dateInput", "date", false, false, "Дата рождения"]],//date?
                            ["patient_insurance", ["lifetime", "results", "", "string", "string", "string", true, false, "Полис ОМС"]],//integer?
                            ["patient_ipa", ["lifetime", "results", "", "string", "string", "string", false, false, "СНИЛС"]],//integer?
                        ]);
                    case "lifetimePatientCollection":
                        return new Map([
                            ["patient_ipa", ["lifetime", "patient", "", "string", "string", "string", false, false, "СНИЛС"]],
                            ["patient_medical_card", ["lifetime", "patient", "", "string", "string", "string", false, false, "Номер медицинской карты"]],
                            ["patient_insurance", ["lifetime", "patient", "", "string", "string", "string", false, false, "Номер полиса ОМС"]],
                            ["patient_surname", ["lifetime", "patient", "", "string", "string", "string", false, false, "Фамилия"]],
                            ["patient_name", ["lifetime", "patient", "", "string", "string", "string", false, false, "Имя"]],
                            ["patient_patronymic", ["lifetime", "patient", "", "string", "string", "string", false, false, "Отчество"]],
                            ["patient_has_patronymic", ["lifetime", "patient", "", "boolean", "boolean", "boolean", false, false, "Отчество отсутствует"]],
                            ["patient_gender", ["lifetime", "patient", "", "string", "string", "string", false, false, "Пол"]],
                            ["patient_birthday", ["lifetime", "patient", "", "dateInput", "dateInput", "date", false, false, "Дата рождения"]],
                            ["patient_phone", ["lifetime", "patient", "", "string", "string", "string", false, false, "Номер телефона"]],
                            ["patient_address_area", ["lifetime", "patient", "", "string", "string", "string", false, false, "Местность"]],
                            ["patient_address_postcode", ["lifetime", "patient", "", "string", "string", "string", false, false, "Почтовый индекс"]],
                            ["patient_address_region", ["lifetime", "patient", "", "string", "string", "string", false, false, "Область, край"]],
                            ["patient_address_locality", ["lifetime", "patient", "", "string", "string", "string", false, false, "Населенный пункт"]],
                            ["patient_address_street", ["lifetime", "patient", "", "string", "string", "string", false, false, "Улица"]],
                            ["patient_address_house", ["lifetime", "patient", "", "string", "string", "string", false, false, "Дом"]],
                            ["patient_address_building", ["lifetime", "patient", "", "string", "string", "string", false, false, "Корпус"]],
                            ["patient_address_apartment", ["lifetime", "patient", "", "string", "string", "string", false, false, "Квартира"]],
                        ]);
                    case "lifetimeCustomerCollection":
                        return new Map([
                            ["request_organization_title", ["universal", "none", "lifetimeCustomer", "string", "string", "string", false, true, "Заказчик"]],//"Наименование организации"
                            ["request_organization_title_short", ["universal", "none", "lifetimeCustomer", "string", "string", "string", false, true, "Заказчик"]],//"Наименование организации"
                            ["request_organization_department", ["universal", "none", "lifetimeCustomer", "string", "string", "string", false, true, "Отделение"]],
                            ["request_organization_department_short", ["universal", "none", "lifetimeCustomer", "string", "string", "string", false, true, "Отделение"]],
                            ["request_organization_doctor_name", ["universal", "none", "lifetimeCustomer", "string", "string", "string", false, true, "ФИО создавшего направление"]],
                            ["request_organization_doctor_phone", ["universal", "none", "lifetimeCustomer", "string", "string", "string", false, false, "Телефон создавшего направление"]],
                            ["request_organization_date", ["universal", "none", "lifetimeCustomer", "dateInput", "dateInput", "date", false, false, "Дата направления"]],//// переименовать когда добавят в базу новое поле
                        ]);
                    case "lifetimeDiseaseCollection":
                        return new Map([
                            ["disease_icd_code", ["lifetime", "none", "lifetimeDisease", "string", "integer", "string", false, true, "Код заболевания по МКБ"]],
                            ["disease_icd_code_code", ["lifetime", "none", "lifetimeDisease", "string", "integer", "string", false, true, "Код заболевания по МКБ"]],
                            ["disease_diagnosis", ["lifetime", "none", "lifetimeDisease", "string", "string", "string", false, false, "Диагноз заболевания"]],//на сервер не шлем
                            ["disease_icd_code_description", ["lifetime", "none", "lifetimeDisease", "string", "string", "string", false, false, "Диагноз заболевания"]],
                            ["disease_research_task", ["lifetime", "none", "lifetimeDisease", "string", "string", "string", false, false, "Задача исследования"]],
                            ["disease_additional_clinical_info", ["lifetime", "none", "lifetimeDisease", "string", "string", "string", false, false, "Дополнительные сведения"]],
                            ["disease_clinical_treatment", ["lifetime", "none", "lifetimeDisease", "string", "string", "string", false, false, "Проведенное предоперационное лечение"]],
                        ]);
                    case "lifetimeDiseaseComment":
                        return new Map([
                            ["disease_comment", ["lifetime", "none", "lifetimeComment", "string", "string", "string", false, false, "Комментарий к направлению"]],
                        ]);
                    case "lifetimeMicroDescriptionCollection":
                        return new Map([
                            ["report_microscopic", ["lifetime", "report", "lifetimeMicroDescription", "string", "string", "string", false, false, "Микроскопическое описание"]],
                            ["report_conclusion", ["lifetime", "report", "lifetimeMicroDescription", "string", "string", "string", false, false, "Заключение"]],
                            ["report_icd_code", ["lifetime", "report", "lifetimeMicroDescription", "string", "string", "string", false, true, "icd code"]],
                            ["report_comment", ["lifetime", "report", "lifetimeMicroDescription", "string", "string", "string", false, false, "Комментарии к заключению и рекомендации"]],
                            ["report_laboratory_assistant", ["lifetime", "report", "lifetimeMicroDescription", "string", "string", "string", false, false, "Фельдшер-лаборант исполнитель"]],
                            ["report_pathologist", ["lifetime", "report", "lifetimeMicroDescription", "string", "string", "string", false, false, "Врач патологоанатом исполнитель"]],
                            ["report_consultant", ["lifetime", "report", "lifetimeMicroDescription", "string", "string", "string", false, false, "Врач-специалист консультант"]],
                        ]);
                    case "lifetimeTransferToReceptionCollection":
                        return new Map([
                            ["reception_staff", ["lifetime", "results", "", "string", "string", "string", false, false, "Сотрудник на прием"]],
                        ]);
                    case "lifetimeStartReceptionMaterialCollection":
                        return new Map([
                            ["responsible_staff", ["lifetime", "results", "", "string", "string", "string", false, false, "Ответственный сотрудник"]],
                        ]);
                    case "lifetimeStartClippingCollection":
                        return new Map([
                            ["medical_registrar", ["lifetime", "results", "", "string", "string", "string", false, false, "Медицинский регистратор"]],
                            ["pathologist", ["lifetime", "results", "", "string", "string", "string", false, false, "Врач-патологоанатом"]],
                        ]);
                    case "referenceStaffCollection":
                        return new Map([
                            ["active", ["universal", "none", "", "boolean", "boolean", "boolean", true, false, "Активна"]],
                            ["surname", ["universal", "none", "", "string", "string", "string", true, false, "Фамилия"]],
                            ["name", ["universal", "none", "", "string", "string", "string", true, false, "Имя"]],
                            ["patronymic", ["universal", "none", "", "string", "string", "string", true, false, "Отчество"]],
                            ["full_name", ["universal", "none", "", "string", "string", "string", false, false, "ФИО"]],
                            ["birthday", ["universal", "none", "", "dateInput", "dateInput", "date", false, false, "Дата рождения"]],
                            ["position", ["universal", "none", "", "integer", "integer", "string", true, false, "Должность"]],
                            ["rate", ["universal", "none", "", "string", "integer", "string", false, false, "Ставка"]],//string
                            ["phone", ["universal", "none", "", "string", "string", "string", false, false, "Телефон"]],
                            ["additional_phone", ["universal", "none", "", "string", "string", "string", false, false, "Телефон (доп.)"]],
                            ["email", ["universal", "none", "", "string", "string", "string", false, false, "E-mail"]],
                            ["ipa", ["universal", "none", "", "string", "string", "string", false, false, "СНИЛС"]],
                        ]);
                    case "referenceOrganizationCollection":
                        return new Map([
                            ["active", ["universal", "none", "", "boolean", "boolean", "boolean", true, false, "Активна"]],//есть дубль
                            ["own_organization", ["universal", "none", "", "boolean", "boolean", "boolean", true, false, "Своя"]],
                            ["name", ["universal", "none", "", "string", "string", "string", true, false, "Полное наименование"]],//есть дубль
                            ["short_name", ["universal", "none", "", "string", "string", "string", true, false, "Краткое наименование"]],//есть дубль
                            ["oid", ["universal", "none", "", "string", "string", "string", true, false, "OID организации"]],
                            ["address", ["universal", "none", "", "string", "string", "string", false, false, "Фактический адрес"]],
                            ["juristic_address", ["universal", "none", "", "string", "string", "string", false, false, "Юридический адрес"]],
                            ["post_address", ["universal", "none", "", "string", "integer", "string", false, false, "Почтовый адрес"]],//string
                            ["supervisor_name", ["universal", "none", "", "string", "string", "string", false, false, "ФИО руководителя"]],
                            ["supervisor_position", ["universal", "none", "", "string", "string", "string", false, false, "Должность руководителя"]],
                            ["acts_on", ["universal", "none", "", "string", "string", "string", false, false, "Действует на основании"]],
                            ["phone", ["universal", "none", "", "string", "string", "string", false, false, "Контактный телефон"]],//есть дубль
                            ["email", ["universal", "none", "", "string", "string", "string", false, false, "E-mail"]],//есть дубль
                            ["ogrn", ["universal", "none", "", "string", "string", "string", false, false, "ОГРН"]],
                            ["inn", ["universal", "none", "", "string", "string", "string", false, false, "ИНН"]],
                            ["kpp", ["universal", "none", "", "string", "string", "string", false, false, "КПП"]],
                            ["bik", ["universal", "none", "", "string", "string", "string", false, false, "БИК"]],
                            ["bank_name", ["universal", "none", "", "string", "string", "string", false, false, "Наименование банка"]],
                            ["personal_account", ["universal", "none", "", "string", "string", "string", false, false, "Лицевой счет"]],
                            ["checking_account", ["universal", "none", "", "string", "string", "string", false, false, "Расчетный счет"]],
                            ["correspondent_account", ["universal", "none", "", "string", "string", "string", false, false, "Корреспондентский счет"]],
                            ["okpo", ["universal", "none", "", "string", "string", "string", false, false, "ОКПО"]],
                            ["okogu", ["universal", "none", "", "string", "string", "string", false, false, "ОКОГУ"]],
                            ["okato", ["universal", "none", "", "string", "string", "string", false, false, "ОКАТО"]],
                            ["oktmo", ["universal", "none", "", "string", "string", "string", false, false, "ОКТМО"]],
                            ["okfs", ["universal", "none", "", "string", "string", "string", false, false, "ОКФС"]],
                            ["okopf", ["universal", "none", "", "string", "string", "string", false, false, "ОКОПФ"]],
                            ["oksm", ["universal", "none", "", "string", "string", "string", false, false, "ОКСМ"]],
                            ["comment", ["universal", "none", "", "string", "string", "string", false, false, "Комментарий"]],
                        ]);
                    case "referenceDepartmentCollection":
                        return new Map([
                            ["active", ["universal", "none", "", "boolean", "boolean", "boolean", true, false, "Активна"]],
                            ["organization_name", ["universal", "none", "", "string", "string", "string", false, false, "Полное наименование организации"]],
                            ["organization_short_name", ["universal", "none", "", "string", "string", "string", false, false, "Краткое наименование организации"]],
                            ["name", ["universal", "none", "", "string", "string", "string", true, false, "Полное наименование отделения"]],
                            ["short_name", ["universal", "none", "", "string", "string", "string", true, false, "Краткое наименование отделения"]],
                            ["address", ["universal", "none", "", "string", "string", "string", false, false, "Фактический адрес"]],
                            ["comment", ["universal", "none", "", "string", "string", "string", false, false, "Комментарий"]],
                        ]);
                    case "referenceDoctorCollection":
                        return new Map([
                            ["active", ["universal", "none", "", "boolean", "boolean", "boolean", true, false, "Активна"]],
                            ["organization_short_name", ["universal", "none", "", "string", "string", "string", false, false, "Краткое наименование организации"]],
                            ["organization_name", ["universal", "none", "", "string", "string", "string", false, false, "Полное наименование организации"]],
                            ["department_name", ["universal", "none", "", "string", "string", "string", false, false, "Полное наименование отделения"]],
                            ["department", ["universal", "none", "", "string", "string", "string", false, false, "Полное наименование отделения"]],
                            ["name", ["universal", "none", "", "string", "string", "string", true, false, "ФИО врача"]],
                            ["phone", ["universal", "none", "", "string", "string", "string", false, false, "Телефон врача"]],
                            ["email", ["universal", "none", "", "string", "string", "string", false, false, "E-mail врача"]],
                            ["ipa", ["universal", "none", "", "string", "string", "string", false, false, "СНИЛС врача"]],
                            ["comment", ["universal", "none", "", "string", "string", "string", false, false, "Комментарий"]],
                        ]);
                    case "referenceLocalizationCollection":
                        return new Map([
                            ["active", ["universal", "none", "", "boolean", "boolean", "boolean", true, false, "Активна"]],
                            ["name", ["universal", "none", "", "string", "string", "string", true, false, "Название локализации"]],
                            ["organization_name", ["universal", "none", "", "string", "string", "string", false, false, "Полное наименование организации"]],
                            ["organization_short_name", ["universal", "none", "", "string", "string", "string", false, false, "Краткое наименование организации"]],
                            ["department_name", ["universal", "none", "", "string", "string", "string", false, false, "Полное наименование отделения"]],
                            ["department", ["universal", "none", "", "string", "string", "string", false, false, "Полное наименование отделения"]],
                            ["anatomic_field", ["universal", "none", "", "string", "string", "string", false, false, "Анатомическая область"]],
                            ["name_eng", ["universal", "none", "", "string", "string", "string", false, false, "Английское наименование"]],
                            ["synonyms", ["universal", "none", "", "string", "string", "string", false, false, "Синонимы"]],
                            ["snomed", ["universal", "none", "", "string", "string", "string", false, false, "SNOMED-CT"]],
                            ["laterality", ["universal", "none", "", "boolean", "boolean", "boolean", false, false, "Латеральность"]],
                        ]);
                    case "lifetimeDefectCollection":
                        return new Map([
                            ["defect_category", ["lifetime", "none", "", "string", "string", "string", false, false, "Вид брака"]],//TODO Переименовать когда добавят поле в БД
                            ["defect_type", ["lifetime", "none", "", "string", "string", "string", false, false, "Тип брака"]],//TODO Переименовать когда добавят поле в БД
                            ["defect_comment", ["lifetime", "none", "", "string", "string", "string", false, false, "Комментарий"]],//TODO Переименовать когда добавят поле в БД
                        ]);
                    case "lifetimeRegistrationTabCollection":
                        return new Map([
                            ["responsible_staff", ["lifetime", "results", "lifetimeGeneral", "string", "string", "string", false, false, "Ответственный за этап"]],
                            ["responsible_staff_name", ["lifetime", "results", "lifetimeGeneral", "string", "string", "string", false, false, "Ответственный за этап"]],
                            ["register_timestamp", ["lifetime", "results", "", "dateTimeInput", "dateTimeTimeZone", "dateTime", false, false, "Дата регистрации"]],
                        ]);
                    case "lifetimeReceptionInfoTabCollection":
                        return new Map([
                            ["responsible_staff", ["lifetime", "results", "lifetimeGeneral", "string", "string", "string", false, false, "Ответственный сотрудник"]],
                            ["register_timestamp", ["lifetime", "results", "", "dateTimeInput", "dateTimeTimeZone", "dateTime", false, false, "Дата и время приема"]],
                        ]);
                    case "mortemAutopsyExaminationCollection":
                        return new Map([
                            ["autopsy_examination_date", ["mortem", "autopsy_examination", "", "dateInput", "dateInput", "date", false, false, "Дата проведения"]],
                            ["autopsy_examination_doctor_presence", ["mortem", "autopsy_examination", "", "boolean", "boolean", "boolean", false, false, "Присутствие лечащего врача (заведующего отделением)"]],
                            ["autopsy_examination_refusal_status", ["mortem", "autopsy_examination", "", "boolean", "boolean", "boolean", false, false, "Отказ от вскрытия"]],
                            ["autopsy_examination_refusal_document_details", ["mortem", "autopsy_examination", "", "string", "string", "string", false, false, "Реквизиты документа"]],
                            ["autopsy_examination_refusal_resp_position", ["mortem", "autopsy_examination", "", "string", "string", "string", false, false, "Должностное лицо, разрешившее выдачу тела без вскрытия - Должность"]],
                            ["autopsy_examination_refusal_resp_lastname", ["mortem", "autopsy_examination", "", "string", "string", "string", false, false, "Должностное лицо, разрешившее выдачу тела без вскрытия - Фамилия"]],
                            ["autopsy_examination_refusal_resp_firstname", ["mortem", "autopsy_examination", "", "string", "string", "string", false, false, "Должностное лицо, разрешившее выдачу тела без вскрытия - Имя"]],
                            ["autopsy_examination_refusal_resp_patronymic", ["mortem", "autopsy_examination", "", "string", "string", "string", false, false, "Должностное лицо, разрешившее выдачу тела без вскрытия - Отчество"]],
                            ["autopsy_examination_body", ["mortem", "autopsy_examination", "", "string", "string", "string", false, false, "Наружный осмотр тела"]],
                            ["autopsy_examination_abdomen", ["mortem", "autopsy_examination", "", "string", "string", "string", false, false, "Брюшная полость"]],
                            ["autopsy_examination_chest", ["mortem", "autopsy_examination", "", "string", "string", "string", false, false, "Грудная полость"]],
                            ["autopsy_examination_cranium", ["mortem", "autopsy_examination", "", "string", "string", "string", false, false, "Полость черепа"]],
                            ["autopsy_examination_circulatory", ["mortem", "autopsy_examination", "", "string", "string", "string", false, false, "Органы кровообращения"]],
                            ["autopsy_examination_respiratory", ["mortem", "autopsy_examination", "", "string", "string", "string", false, false, "Органы дыхания"]],
                            ["autopsy_examination_digestive", ["mortem", "autopsy_examination", "", "string", "string", "string", false, false, "Органы пищеварения"]],
                            ["autopsy_examination_genitourinary", ["mortem", "autopsy_examination", "", "string", "string", "string", false, false, "Органы мочеполовой системы"]],
                            ["autopsy_examination_hematopoietic", ["mortem", "autopsy_examination", "", "string", "string", "string", false, false, "Органы кроветворения"]],
                            ["autopsy_examination_endocrine", ["mortem", "autopsy_examination", "", "string", "string", "string", false, false, "Эндокринные железы"]],
                            ["autopsy_examination_musculoskeletal", ["mortem", "autopsy_examination", "", "string", "string", "string", false, false, "Костно-мышечная система"]],
                            ["autopsy_examination_placenta", ["mortem", "autopsy_examination", "", "string", "string", "string", false, false, "Плацента (при наличии)"]],
                            ["autopsy_examination_membranes", ["mortem", "autopsy_examination", "", "string", "string", "string", false, false, "Плодные оболочки и пуповина"]],
                        ]);
                    case "mortemConclusionCollection":
                        return new Map([
                            ["conclusion_additional_research_results", ["mortem", "conclusion", "", "string", "string", "string", false, false, "Описание результатов дополнительных исследований"]],
                            ["conclusion_medical_help_errors", ["mortem", "conclusion", "", "string", "string", "string", false, false, "Дефекты оказания медицинской помощи"]],
                            ["conclusion_cause_of_death", ["mortem", "conclusion", "", "string", "string", "string", false, false, "Причина смерти"]],
                            ["conclusion_pat_epicrisis", ["mortem", "conclusion", "", "string", "string", "string", false, false, "Клинико-патологически-анатомический эпикриз"]],
                            ["conclusion_pathologist", ["mortem", "conclusion", "", "string", "string", "string", false, false, "ФИО врача-патологоанатома"]],
                            ["conclusion_pathologist_dep_head", ["mortem", "conclusion", "", "string", "string", "string", false, false, "ФИО заведующего пат. отделением"]],
                            ["conclusion_date", ["mortem", "conclusion", "", "dateTimeInput", "dateTimeTimeZone", "dateTime", false, false, "Дата составления протокола"]],
                        ]);
                    case "mortemDiseaseCollection":
                        return new Map([
                            ["disease_hospital_arrived_date", ["mortem", "disease", "", "dateTimeInput", "dateTimeTimeZone", "dateTime", false, false, "Дата и время поступления"]],
                            ["disease_hospital_arrived_after_hours", ["mortem", "disease", "", "string", "string", "string", false, false, "Доставлен после начала заболевания через - часов"]],
                            ["disease_hospital_arrived_after_days", ["mortem", "disease", "", "string", "string", "string", false, false, "Доставлен после начала заболевания через - дней"]],
                            ["disease_hospital_doctor_lastname", ["mortem", "disease", "", "string", "string", "string", false, false, "ФИО врача - Фамилия"]],
                            ["disease_hospital_doctor_name", ["mortem", "disease", "", "string", "string", "string", false, false, "ФИО врача - Имя"]],
                            ["disease_hospital_doctor_patronymic", ["mortem", "disease", "", "string", "string", "string", false, false, "ФИО врача - Отчество"]],
                            ["disease_clinical_diagnosis_main_info", ["mortem", "disease", "", "string", "string", "string", false, false, "Основные клинические данные"]],
                            ["disease_clinical_diagnosis_code", ["mortem", "disease", "", "string", "string", "string", false, false, "Disease clinical diagnosis code"]],
                            ["disease_clinical_diagnosis_main", ["mortem", "disease", "", "string", "string", "string", false, false, "Заключительный клинический диагноз - Основное заболевание"]],
                            ["disease_clinical_diagnosis_main_complications", ["mortem", "disease", "", "string", "string", "string", false, false, "Заключительный клинический диагноз - Осложнение основного заболевания"]],
                            ["disease_clinical_diagnosis_related_diseases", ["mortem", "disease", "", "string", "string", "string", false, false, "Заключительный клинический диагноз - Сопутствующие заболевания"]],
                        ]);
                    case "mortemPatDiagnosisCollection":
                        return new Map([
                            ["pat_diagnosis_preliminary_code", ["mortem", "pat_diagnosis", "", "string", "string", "string", false, false, "Pat diagnosis preliminary code"]],
                            ["pat_diagnosis_preliminary_main", ["mortem", "pat_diagnosis", "", "string", "string", "string", false, false, "Патолого-анатомический диагноз (предварительный): Основное заболевание"]],
                            ["pat_diagnosis_preliminary_main_complications", ["mortem", "pat_diagnosis", "", "string", "string", "string", false, false, "Патолого-анатомический диагноз (предварительный): Осложнение основного заболевания"]],
                            ["pat_diagnosis_preliminary_related_diseases", ["mortem", "pat_diagnosis", "", "string", "string", "string", false, false, "Патолого-анатомический диагноз (предварительный): Сопутствующие заболевания"]],
                            ["pat_diagnosis_code", ["mortem", "pat_diagnosis", "", "string", "string", "string", false, false, "Pat diagnosis code"]],
                            ["pat_diagnosis_main", ["mortem", "pat_diagnosis", "", "string", "string", "string", false, false, "Патолого-анатомический диагноз: Основное заболевание"]],
                            ["pat_diagnosis_main_complications", ["mortem", "pat_diagnosis", "", "string", "string", "string", false, false, "Патолого-анатомический диагноз: Осложнение основного заболевания"]],
                            ["pat_diagnosis_related_diseases", ["mortem", "pat_diagnosis", "", "string", "string", "string", false, false, "Патолого-анатомический диагноз: Сопутствующие заболевания"]],
                            ["pat_diagnosis_related_diseases_conclusion", ["mortem", "pat_diagnosis", "", "string", "string", "string", false, false, "Сопоставление заключительного диагноза и патолого-анатомического диагноза"]],
                        ]);
                    default:
                        break;
                }
            }
        }
    },
    methods: {
        getFlagAndCheckFunctionForDataFromAndToServer(fieldName, type, collectionName) {
            if (!this.getLifetimeFieldsCollection(collectionName).has(fieldName)) {
                return "error";
            }
            const fieldValue = this.getLifetimeFieldsCollection(collectionName).get(fieldName);
            switch (type) {
                case "form":
                    // console.log("tab",fieldValue);
                    if (fieldValue[2].length === 0) {
                        return;
                    }
                    return this.getBlockFlagToFormSaveButtonAction(fieldValue[2]);
                case "modal":
                    // console.log("modal",fieldValue[3]);
                    return this.getCheckDataFunction(fieldValue[3]);
                case "server":
                    // console.log("server", fieldValue);
                    return this.getCheckDataFunction(fieldValue[4]);
                case "view":
                    // console.log("view", fieldValue);
                    return this.getCheckDataFunction(fieldValue[5]);
                case "isRequiredField":
                    return fieldValue[6]
                case "isReferenceSearchField":
                    return fieldValue[7];
                case "description":
                    return fieldValue[8];
                default:
                    break;
            }
        },
        getCheckedDataToChangeFromParent(fieldName, fieldValueFromServer, collectionName) {
            const checkFunctionForDataToChangeFromParent = this.getFlagAndCheckFunctionForDataFromAndToServer(fieldName, "modal", collectionName);
            if (checkFunctionForDataToChangeFromParent === "error") {
                return; // на будущее должны вернуть текст ошибки для всплывающего окна-подсказки
            }
            return checkFunctionForDataToChangeFromParent(fieldValueFromServer);
        },
        getCheckFunctionForDataFromParent(fieldName, collectionName) {
            const checkFunctionForDataFromParent= this.getFlagAndCheckFunctionForDataFromAndToServer(fieldName, "modal", collectionName);
            if (checkFunctionForDataFromParent === "error") {
                return; // на будущее должны вернуть текст ошибки для всплывающего окна-подсказки
            }
            return checkFunctionForDataFromParent;
        },
        getCheckedDataForServerBeforeChangeFromParent(fieldName, fieldValueToServer, collectionName) {
            const checkFunctionForDataToServerBeforeChangeFromParent = this.getFlagAndCheckFunctionForDataFromAndToServer(fieldName, "server", collectionName);
            if (checkFunctionForDataToServerBeforeChangeFromParent === "error") {
                return; // на будущее должны вернуть текст ошибки для всплывающего окна-подсказки
            }
            return checkFunctionForDataToServerBeforeChangeFromParent(fieldValueToServer);
        },
        getCheckFunctionForDataToView(fieldName, collectionName) {
            const checkFunctionForDataToView = this.getFlagAndCheckFunctionForDataFromAndToServer(fieldName,"view", collectionName);
            if (checkFunctionForDataToView === "error") {
                return; // на будущее должны вернуть текст ошибки для всплывающего окна-подсказки
            }
            return checkFunctionForDataToView;
        },
        getFieldDescriptionToView(fieldName, collectionName) {
            const fieldDescriptionToView = this.getFlagAndCheckFunctionForDataFromAndToServer(fieldName,"description", collectionName);
            if (fieldDescriptionToView === "error") {
                return; // на будущее должны вернуть текст ошибки для всплывающего окна-подсказки
            }
            return fieldDescriptionToView;
        },
        getTabFlagByFieldToBlockSaveButton(fieldName, collectionName) {
            const tabFlagByFieldToBlockSaveButtonAction = this.getFlagAndCheckFunctionForDataFromAndToServer(fieldName, "form", collectionName);
            if (tabFlagByFieldToBlockSaveButtonAction === "error") {
                return; // на будущее должны вернуть текст ошибки для всплывающего окна-подсказки
            }
            return tabFlagByFieldToBlockSaveButtonAction;
        },
        getIsRequiredField(fieldName, collectionName) {
            const isRequiredField = this.getFlagAndCheckFunctionForDataFromAndToServer(fieldName,"isRequiredField", collectionName);
            if (isRequiredField === "error") {
                return; // на будущее должны вернуть текст ошибки для всплывающего окна-подсказки
            }
            return isRequiredField;
        },
        getIsReferenceSearchField(fieldName, collectionName) {
            const isReferenceSearchField = this.getFlagAndCheckFunctionForDataFromAndToServer(fieldName,"isReferenceSearchField", collectionName);
            if (isReferenceSearchField === "error") {
                return; // на будущее должны вернуть текст ошибки для всплывающего окна-подсказки
            }
            return isReferenceSearchField;
        }
    },
};