import { api } from "@/api";
import { debounceLoader } from "@/helpers/debounceLoader";

export default {
    state: {
        previousOrganizationReferencePage: 1,
        previousOrganizationReferencePageSize: 50,
        previousOrganizationReferenceListShowStatus: false,
        previousOrganizations: [],
        isPreviousOrganizationNotFound: false,
        previousOrganizationSelectedName: "",
        previousOrganizationSelectedItemId: null,
        previousOrganizationReferenceFieldChangeStatus: false,
    },

    actions: {
        async fetchNextPreviousOrganizations({ state, commit, dispatch }) {
            if (state.previousOrganizations?.next === null) {
                return;
            }
            commit("changePreviousOrganizationReferencePage", state.previousOrganizationReferencePage + 1);
            dispatch("receivePreviousOrganizations", "next");
        },
        async receivePreviousOrganizations({ commit, state, dispatch, rootGetters }, status) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                if (!status) {
                    commit("changePreviousOrganizationReferencePage", 1);
                }
                switch (rootGetters.getLifetimePreviousBiopsyStudiesForm !== null) {
                    case rootGetters.getLifetimePreviousBiopsyStudiesForm?.organization_title !== undefined && !rootGetters.getInputLifetimeFocusEventStatus:
                        if (rootGetters.getLifetimePreviousBiopsyStudiesForm?.organization_title.length !== 0 && rootGetters.getLifetimePreviousBiopsyStudiesForm?.organization_title === state.previousOrganizationSelectedName) {
                            return;
                        }
                        dispatch("fetchPreviousOrganizations", [token, state.previousOrganizationReferencePage, state.previousOrganizationReferencePageSize, rootGetters.getLifetimePreviousBiopsyStudiesForm?.organization_title, status]);
                        return;
                    case rootGetters.getLifetimePreviousBiopsyStudiesForm?.organization_title !== undefined && rootGetters.getInputLifetimeFocusEventStatus && rootGetters.getLifetimePreviousBiopsyStudiesForm?.organization_title.length === 0:

                        dispatch("fetchPreviousOrganizations", [token, state.previousOrganizationReferencePage, state.previousOrganizationReferencePageSize, "", status]);
                        return;
                    default:
                        return;
                }
            });
        },
        async fetchPreviousOrganizations({ dispatch, state, rootGetters }, [token, page, pageSize, previousOrganization, status]) {
            try {
                if (!rootGetters.getInputLifetimeFocusEventStatus && previousOrganization.length === 0) {
                    return;
                }
                const previousOrganizations = await debounceLoader(
                    api.getReferenceOrganization(page, pageSize, token, previousOrganization),
                    "referenceList"
                );

                if (previousOrganizations.results.length === 0) {
                    dispatch("updatePreviousOrganizationReferenceListShowStatus", false);
                    if (state.isPreviousOrganizationNotFound) {
                        return;
                    }
                    dispatch("updateIsPreviousOrganizationNotFound", true);
                    return;
                }

                // dispatch("setExceptionAccordingResponseCode", previousOrganizations);

                switch (status) {
                    case "next":
                        dispatch("updatePreviousOrganizationsByNextStatus", previousOrganizations);
                        break;
                    default:
                        dispatch("updatePreviousOrganizations", previousOrganizations);
                        break;
                }

                dispatch("updateIsPreviousOrganizationNotFound", false);
                dispatch("updatePreviousOrganizationReferenceListShowStatus", true);
            } catch (e) {
                console.error(e);
                // dispatch("setExceptionAccordingResponseCode", e.status);
                dispatch("updatePreviousOrganizationReferenceListShowStatus", false);
            }
        },
        updatePreviousOrganizations({ commit }, previousOrganizations) {
            commit("changePreviousOrganizations", previousOrganizations);
        },
        updatePreviousOrganizationsByNextStatus({ commit }, previousOrganizations) {
            commit("changePreviousOrganizationsByNextStatus", previousOrganizations);
        },
        updatePreviousOrganizationReferenceListShowStatus({ commit }, status) {
            commit("changePreviousOrganizationReferenceListShowStatus", status);
        },
        updateIsPreviousOrganizationNotFound({ commit }, status) {
            commit("changeIsPreviousOrganizationNotFound", status);
        },
        updatePreviousOrganizationSelectedName({ commit }, previousOrganizationSelectedName) {
            commit("changePreviousOrganizationSelectedName", previousOrganizationSelectedName);
        },
        updatePreviousOrganizationSelectedItemId({ commit }, previousOrganizationSelectedItemId) {
            commit("changePreviousOrganizationSelectedItemId", previousOrganizationSelectedItemId);
        },
        updatePreviousOrganizationReferenceFieldChangeStatus({ commit }, status) {
            commit("changePreviousOrganizationReferenceFieldChangeStatus", status);
        },
        clearReferencePreviousOrganizationData({ commit }) {
            commit("changePreviousOrganizationReferencePage", 1);
            commit("changePreviousOrganizations", []);
            commit("changePreviousOrganizationSelectedName", "");
            commit("changePreviousOrganizationSelectedItemId", null);
            commit("changePreviousOrganizationReferenceFieldChangeStatus", false);
        }
    },

    mutations: {
        changePreviousOrganizationReferencePage(state, previousOrganizationReferencePage) {
            state.previousOrganizationReferencePage = previousOrganizationReferencePage;
        },
        changePreviousOrganizations(state, previousOrganizations) {
            state.previousOrganizations = previousOrganizations;
        },
        changePreviousOrganizationsByNextStatus(state, previousOrganizations) {
            state.previousOrganizations.results = [...state.previousOrganizations?.results, ...previousOrganizations?.results];
            state.previousOrganizations.next = previousOrganizations?.next;
            state.previousOrganizations.previous = previousOrganizations?.previous;
        },
        changePreviousOrganizationReferenceListShowStatus(state, status) {
            state.previousOrganizationReferenceListShowStatus = status;
        },
        changeIsPreviousOrganizationNotFound(state, status) {
            state.isPreviousOrganizationNotFound = status;
        },
        changePreviousOrganizationSelectedName(state, previousOrganizationSelectedName) {
            state.previousOrganizationSelectedName = previousOrganizationSelectedName;
        },
        changePreviousOrganizationSelectedItemId(state, previousOrganizationSelectedItemId) {
            state.previousOrganizationSelectedItemId = previousOrganizationSelectedItemId;
        },
        changePreviousOrganizationReferenceFieldChangeStatus(state, status) {
            state.previousOrganizationReferenceFieldChangeStatus = status;
        }
    },

    getters: {
        getPreviousOrganizations(state) {
            return state.previousOrganizations;
        },
        getPreviousOrganizationReferenceListShowStatus(state) {
            return state.previousOrganizationReferenceListShowStatus;
        },
        getIsPreviousOrganizationNotFound(state) {
            return state.isPreviousOrganizationNotFound;
        },
        getPreviousOrganizationSelectedName(state) {
            return state.previousOrganizationSelectedName;
        },
        getPreviousOrganizationSelectedItemId(state) {
            return state.previousOrganizationSelectedItemId;
        },
        getPreviousOrganizationReferenceFieldChangeStatus(state) {
            return state.previousOrganizationReferenceFieldChangeStatus;
        }
    }
}
