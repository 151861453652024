export default {
    state: {
        lifetimeReceptionData: null,
        lifetimeFieldsFillTransferToReceptionStatus: false,
    },
    actions: {
        async updateLifetimeCaseReceptionStatus({ dispatch, state }) {
            if (!state.lifetimeFieldsFillTransferToReceptionStatus) {// пока что в lifetimeFieldsFillTransferToReceptionStatus временно передается true
                return;
            }
            await dispatch("updateLifetimeCaseStatus", state.lifetimeReceptionData);
        },
        updateLifetimeCaseReceptionInformationDataById() {
            //TODO реализовать экшн, когда добавят апи
        },
        updateLifetimeReceptionData({ commit }, lifetimeReceptionData) {
            commit("changeLifetimeReceptionData", lifetimeReceptionData);
        },
        checkRequiredFieldsForTransferToReceptionStatus({ commit, rootGetters }, validationData) {
            if(validationData !== undefined) {
                if (validationData.biopsy.flasks.length === 0 || validationData.biopsy.biopsy_type_id === null || validationData.biopsy.biopsy_date === null) {
                    commit("changeLifetimeFieldsFillTransferToReceptionStatus", false);
                    return;
                }
                commit("changeLifetimeFieldsFillTransferToReceptionStatus", true);
                return;
            }
            if (rootGetters.returnLifetimeCaseById.biopsy.flasks.length === 0 || rootGetters.returnLifetimeCaseById.biopsy.biopsy_type === null || rootGetters.returnLifetimeCaseById.biopsy.biopsy_date === null) {
                commit("changeLifetimeFieldsFillTransferToReceptionStatus", false);
                return;
            }
            commit("changeLifetimeFieldsFillTransferToReceptionStatus", true);
        },
    },
    mutations: {
        changeLifetimeReceptionData(state, lifetimeReceptionData) {
            state.lifetimeReceptionData = lifetimeReceptionData;
        },
        changeLifetimeFieldsFillTransferToReceptionStatus(state, status) {
            state.lifetimeFieldsFillTransferToReceptionStatus = status;
        },
    },
    getters: {
        getLifetimeReceptionData(state) {
            return state.lifetimeReceptionData;
        },
        getLifetimeFieldsFillTransferToReceptionStatus(state) {
            return state.lifetimeFieldsFillTransferToReceptionStatus;
        },
    },
}