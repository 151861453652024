export default {
    state: {
        mortemAutopsyInformationBtnStatus: false,
        mortemCaseAutopsyDate: null,
        mortemAutopsyHeadDoctorPresenceList: [
            {
                id: 1,
                title: "Да",
            },
            {
                id: 2,
                title: "Нет",
            }
        ],
        mortemCaseAutopsyInformationResponsibleStaffMember: null,
        mortemAutopsyHeadDoctorPresence: null
    },
    actions: {
        updateMortemAutopsyInformationBtnStatus({ commit }, condition) {
            commit("changeMortemAutopsyInformationBtnStatus", condition);
        },
        updateMortemCaseAutopsyDate({ commit }, date) {
            commit("changeMortemCaseAutopsyDate", date);
        },
        updateMortemCaseAutopsyInformationResponsibleStaffMember({ commit }, type) {
            commit("changeMortemCaseAutopsyInformationResponsibleStaffMember", type);
        },
        updateMortemAutopsyHeadDoctorPresence({ commit }, type) {
            commit("changeMortemAutopsyHeadDoctorPresence", type);
        },
    },
    mutations: {
        changeMortemAutopsyInformationBtnStatus(state, condition) {
            state.mortemAutopsyInformationBtnStatus = condition;
        },
        changeMortemCaseAutopsyDate(state, date) {
            state.mortemCaseAutopsyDate = date;
        },
        changeMortemCaseAutopsyInformationResponsibleStaffMember(state, payload) {
            state.mortemCaseAutopsyInformationResponsibleStaffMember = payload;
        },
        changeMortemAutopsyHeadDoctorPresence(state, payload) {
            state.mortemAutopsyHeadDoctorPresence = payload;
        },
    },
    getters: {
        getMortemAutopsyInformationBtnStatus(state) {
            return state.mortemAutopsyInformationBtnStatus;
        },
        getMortemCaseAutopsyDate(state) {
            return state.mortemCaseAutopsyDate;
        },
        getMortemCaseAutopsyInformationResponsibleStaffMember(state) {
            return state.mortemCaseAutopsyInformationResponsibleStaffMember;
        },
        getMortemAutopsyHeadDoctorPresence(state) {
            return state.mortemAutopsyHeadDoctorPresence;
        },
        getMortemAutopsyHeadDoctorPresenceList(state) {
            return state.mortemAutopsyHeadDoctorPresenceList;
        },
    }
}