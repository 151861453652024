export const universalCustomerFields = {
    data() {
        return {
            universalCustomerDescriptionFields: {
                request_organization_title_short: ["Наименование организации:", "request_organization"],
                request_organization_department_short: ["Наименование отделения:", "request_organization"],
                request_organization_doctor_name: ["ФИО врача:", "request_organization"],
                request_organization_doctor_phone: ["Номер телефона:", "request_organization"],
                request_organization_date: ["Дата направления:", "request_organization"]// переименовать когда добавят в базу новое поле
            },
            universalCustomerFormReferenceDescriptionFields: {
                request_organization_title_short: ["Наименование организации", ["row__small", "organization__row"]],
                request_organization_department_short: ["Наименование отделения", ["row__small", "department__row"]],
                request_organization_doctor_name: ["ФИО врача", ["row__small", "doctor__row"]],// переименовать когда добавят в базу новое поле
            },
        }
    },
}