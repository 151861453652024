export const lifetimeMaterialFields = {
    data() {
        return {
            lifetimeMaterialDescriptionFields: {
                biopsy_material_title: ["Биопсийный материал", "checkingStringData"],
                biopsy_type_title: ["Вид биопсии", "checkingStringData"],
                biopsy_date: ["Дата и время взятия", "changeDateTimeFormat"],
                biopsy_10_formalin: ["Не помещен в формалин", "checkingStringData"],
                biopsy_contaminated: ["Материал загрязнен", "checkingStringData"],
                biopsy_container_status: ["Дефект упаковки", "checkingStringData"],
            },
            lifetimeReceptionMaterialDescriptionFields: {
                arrived_date: ["Дата и время поступления:", "changeDateTimeFormat"],
            },
            lifetimeFormReceptionCommentFields: {
                reception_comment: "Комментарий:",
            },
            // lifetimeReceptionInfoDescriptionFields: {
            //     reception_date: ["Дата и время приема:", "changeDateTimeFormat"], //TODO измениеть когда добавят поле на сервере
            //     reception_staff: ["Ответственный сотрудник:", "checkingStringData"], //TODO измениеть когда добавят поле на сервере
            // },
            // lifetimeReceptionInfoFormDescriptionFields:["reception_staff", "Ответственный сотрудник:", []]
        }
    },
}