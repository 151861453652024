export const referenceEmployeePositionsFields = {
    data() {
        return {
            referenceEmployeePositionsDescriptionData: [
                { field: "name", header: "Наименование должности", columnWidth: "200px"},
                { field: "code_nsi", header: "Код в справочнике ФРМР", isSort: true, columnWidth: "200px"},
                { field: "name_nsi", header: "Должность в справочнике ФРМР", columnWidth: "560px"},
                { field: "comment", header: "Комментарий", columnWidth: "200px"},
            ],
        }
    },
}