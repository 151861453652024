import { api } from "@/api";


export default {
    state: {
        settingsReferenceStaffPage: 1,
        settingsReferenceStaffPageSize: 50,
        settingsReferenceStaffs: [],
        settingsReferenceStaffPositions: [],
        settingsReferenceStaffsTotalPages: null,
        isSettingsReferenceStaffsLoading: false,
    },
    actions: {
        async fetchSettingsReferenceStaff({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceStaffsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const staff = await api.getReferenceResponsibleStaffMember(queryParams, token);
                    commit("changeSettingsReferenceStaffs", staff.results);
                    commit("changeSettingsReferenceStaffsTotalPages", staff.total_pages);
                    commit("updateSettingsReferenceStaffsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferenceStaffsLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
                commit("updateSettingsReferenceStaffsLoader", false);
            }
        },
        async fetchSettingsReferenceStaffPosition({ dispatch, commit }) {
            commit("updateSettingsReferenceStaffsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const positions = await api.getReferencePosition(token);
                    commit("changeSettingsReferenceStaffPositions", positions.results);
                    commit("updateSettingsReferenceStaffsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferenceStaffsLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
                commit("updateSettingsReferenceStaffsLoader", false);
            }
        },
        async fetchMoreSettingsReferenceStaffs({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceStaffsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const staff = await api.getReferenceResponsibleStaffMember(queryParams, token);
                    commit("updateMoreSettingsReferenceStaffs", staff.results);
                    commit("updateSettingsReferenceStaffsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferenceStaffsLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("updateSettingsReferenceStaffsLoader", false);
            }

        },
        async addSettingsReferenceStaff({ dispatch, commit }, staff ) {
            const token = await dispatch("getAccessToken");
            if (token) {
                const staffData = await api.postReferenceResponsibleStaffMember(staff, token);
                if (typeof staffData === "object") {
                    commit("addNewSettingsReferenceStaffs", staffData);
                }
            } else {
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                return;
            }
        },
        async editSettingsReferenceStaff({ dispatch, commit }, staff ) {
            const token = await dispatch("getAccessToken");
            if (token) {
                const staffData = await api.patchReferenceResponsibleStaffMember(staff, token);
                if (typeof staffData === "object") {
                    commit("updateSettingsReferenceStaffs", staffData);
                }
            } else {
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                return;
            }
        },
    },
    mutations: {
        changeSettingsReferenceStaffs(state, settingsReferenceStaffs) {
            state.settingsReferenceStaffs = settingsReferenceStaffs;
        },
        changeSettingsReferenceStaffPositions(state, settingsReferenceStaffPositions) {
            state.settingsReferenceStaffPositions = settingsReferenceStaffPositions;
        },
        updateMoreSettingsReferenceStaffs(state, settingsReferenceStaffs) {
            state.settingsReferenceStaffs = [...state.settingsReferenceStaffs, ...settingsReferenceStaffs];
        },
        changeSettingsReferenceStaffsTotalPages(state, settingsReferenceStaffsTotalPages) {
            state.settingsReferenceStaffsTotalPages = settingsReferenceStaffsTotalPages;
        },
        updateSettingsReferenceStaffsLoader(state, condition) {
            state.isSettingsReferenceStaffsLoading = condition;
        },
        updateSettingsReferenceStaffs(state, staff) {
            const index = state.settingsReferenceStaffs.findIndex(item => item.id === staff.id);
            if (index !== -1) {
                const updatedStaff = { ...staff }; // Создаем копию объекта staff
                const uniqueId = Object.values(updatedStaff).join('_'); // Создаем уникальный идентификатор на основе значений объекта
                updatedStaff.uniqueId = uniqueId; // Добавляем новое поле uniqueId к объекту
                state.settingsReferenceStaffs.splice(index, 1, updatedStaff); // Заменяем объект в массиве
            }
        },
        addNewSettingsReferenceStaffs(state, staff) {
            state.settingsReferenceStaffs.unshift(staff);
        },
    },
    getters: {
        getSettingsReferenceStaffs(state) {
            return state.settingsReferenceStaffs;
        },
        getSettingsReferenceStaffPositions(state) {
            return state.settingsReferenceStaffPositions;
        },
        getSettingsReferenceStaffsTotalPages(state) {
            return state.settingsReferenceStaffsTotalPages;
        },
        getSettingsReferenceStaffsLoaderStatus(state) {
            return state.isSettingsReferenceStaffsLoading;
        },
    }
}