export default {
    state: {
        isComponentErrorActive: false,
        isComponentLoaderDnaActive: false,
        isComponentLoaderButtonActive: false,
        isComponentLoaderReferenceListActive: false,
        isComponentLoaderSettingsReferenceActive: false,
        isComponentLoaderLifetimecaseActive: false,
        isComponentLoaderMortemActive: false,
    },

    actions: {
        updateIsComponentErrorActive({ commit }, status) {
            commit("changeIsComponentErrorActive", status);
        },
        updateIsComponentLoaderDnaActive({ commit }, status) {
            commit("changeIsComponentLoaderDnaActive", status);
        },
        updateIsComponentLoaderButtonActive({ commit }, status) {
            commit("changeIsComponentLoaderButtonActive", status);
        },
        updateIsComponentLoaderReferenceListActive({ commit }, status) {
            commit("changeIsComponentLoaderReferenceListActive", status);
        },
        updateIsComponentLoaderSettingsReferenceActive({ commit }, status) {
            commit("changeIsComponentLoaderSettingsReferenceActive", status);
        },
        updateIsComponentLoaderLifetimecaseActive({ commit }, status) {
            commit("changeIsComponentLoaderLifetimecaseActive", status);
        },
        updateIsComponentLoaderMortemActive({ commit }, status) {
            commit("changeIsComponentLoaderMortemActive", status);
        },
    },

    mutations: {
        changeIsComponentErrorActive(state, status) {
            state.isComponentErrorActive = status;
        },
        changeIsComponentLoaderDnaActive(state, status) {
            state.isComponentLoaderDnaActive = status;
        },
        changeIsComponentLoaderButtonActive(state, status) {
            state.isComponentLoaderButtonActive = status;
        },
        changeIsComponentLoaderReferenceListActive(state, status) {
            state.isComponentLoaderReferenceListActive = status;
        },
        changeIsComponentLoaderSettingsReferenceActive(state, status) {
            state.isComponentLoaderSettingsReferenceActive = status;
        },
        changeIsComponentLoaderLifetimecaseActive(state, status) {
            state.isComponentLoaderLifetimecaseActive = status;
        },
        changeIsComponentLoaderMortemActive(state, status) {
            state.isComponentLoaderMortemActive = status;
        },
    },

    getters: {
        getIsComponentErrorActive(state) {
            return state.isComponentErrorActive;
        },
        getIsComponentLoaderDnaActive(state) {
            return state.isComponentLoaderDnaActive;
        },
        getIsComponentLoaderButtonActive(state) {
            return state.isComponentLoaderButtonActive;
        },
        getIsComponentLoaderReferenceListActive(state) {
            return state.isComponentLoaderReferenceListActive;
        },
        getIsComponentLoaderSettingsReferenceActive(state) {
            return state.isComponentLoaderSettingsReferenceActive;
        },
        getIsComponentLoaderLifetimecaseActive(state) {
            return state.isComponentLoaderLifetimecaseActive;
        },
        getIsComponentLoaderMortemActive(state) {
            return state.isComponentLoaderMortemActive;
        },
    }
}