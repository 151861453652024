export default {
    state: {
        mortemAutopsyMusculoskeletalSystemBtnStatus: false,
        mortemAutopsyMusculoskeletalSystemMuscles: null,
        mortemAutopsyMusculoskeletalSystemBones: null,
        mortemAutopsyMusculoskeletalSystemJoints: null,
    },
    actions: {
        updateMortemAutopsyMusculoskeletalSystemBtnStatus({ commit }, condition) {
            commit("changeMortemAutopsyMusculoskeletalSystemBtnStatus", condition);
        },
        updateAutopsyMusculoskeletalSystemField({ commit }, payload) {
            commit('changeAutopsyMusculoskeletalSystemField', payload);
        }
    },
    mutations: {
        changeMortemAutopsyMusculoskeletalSystemBtnStatus(state, condition) {
            state.mortemAutopsyMusculoskeletalSystemBtnStatus = condition;
        },
        changeAutopsyMusculoskeletalSystemField(state, { key, value }) {
            state[key] = value;
        }
    },
    getters: {
        getMortemAutopsyMusculoskeletalSystemBtnStatus(state) {
            return state.mortemAutopsyMusculoskeletalSystemBtnStatus;
        },
        getAutopsyMusculoskeletalSystemField: (state) => (key) => {
            return state[key];
        }
    }
}