export const referenceOrganizationFields = {
    data() {
        return {
            referenceOrganizationTableDescriptionData: [
                { field: "active", header: "Активна", isSort: true, columnWidth: "100px"},
                { field: "short_name", header: "Краткое наименование", isSort: true, columnWidth: "250px"},
                { field: "inn", header: " ИНН", columnWidth: "120px"},
                { field: "oid", header: " OID", columnWidth: "220px"},
                { field: "actual_address", header: "Фактический адрес", columnWidth: "auto"},
                { field: "phone", header: "Контактный телефон", columnWidth: "170px"},
                // { field: "comment", header: "Комментарий", columnWidth: "auto"},
            ],
        }
    },
}