import { loadScrollContent } from "@/mixins";

export const reference = {
    mixins: [loadScrollContent],
    computed: {
        getSetFieldData() {//input and logic
            return (name) => {
                switch (name) {
                    case "responsible_staff":
                    case "responsible_staff_name":
                        return this.responsible_staff = this.getResponsibleStaffMemberSelectedName;
                    case "request_organization_title_short":
                        return this.request_organization_title_short = this.getOrganizationSelectedName;
                    case "request_organization_department_short":
                        return this.request_organization_department_short = this.getOrganizationDepartmentSelectedName;
                    case "request_organization_doctor_name":
                        return this.request_organization_doctor_name = this.getOrganizationDepartmentDoctorSelectedName;
                    case "request_organization_doctor_phone":
                        return this.request_organization_doctor_phone = this.getOrganizationDepartmentDoctorPhone;
                    case "request_organization_date":
                        return this.request_organization_date = this.getLifetimeGeneralData?.arrived_date;
                    case "disease_icd_code":
                    case "disease_icd_code_code":
                        return this.disease_icd_code = this.getIcdCodeReferenceSelectedName;
                    case "disease_diagnosis":
                    case "disease_icd_code_description":
                        return this.disease_diagnosis = this.getIcdCodeReferenceDiagnosis;
                    case "organization_title":
                        return this.organization_title = this.getPreviousOrganizationSelectedName;
                    case "organization_name":
                        switch (true) {
                            case !this.getSettingsReferenceDepartmentAdditionalFormAddBtnStatus:
                                return this.organization_name = this.getSettingsReferenceDepartmentOrganizationSelectedName;
                            case !this.getSettingsReferenceDoctorAdditionalFormAddBtnStatus:
                                return this.organization_name = this.getSettingsReferenceDoctorOrganizationSelectedName;
                            default:
                                return;
                        }
                    case "department":
                        return this.department = this.getSettingsReferenceDoctorDepartmentSelectedName;
                    default:
                        return "";
                }
            }
        },
        getReceiveReferences() {//logic
            return (name) => {
                switch (name) {
                    case "responsible_staff":
                    case "responsible_staff_name":
                        return this.receiveResponsibleStaffMember;
                    case "request_organization_title_short":
                        return this.receiveOrganizations;
                    case "request_organization_department_short":
                        return this.receiveOrganizationDepartments;
                    case "request_organization_doctor_name":
                        return this.receiveOrganizationDepartmentDoctors;
                    case "disease_icd_code":
                    case "disease_icd_code_code":
                        return this.receiveIcdCodes;
                    case "organization_title":
                        return this.receivePreviousOrganizations;
                    case "organization_name":
                        switch (true) {
                            case !this.getSettingsReferenceDepartmentAdditionalFormAddBtnStatus:
                                return this.receiveSettingsReferenceDepartmentOrganizations;
                            case !this.getSettingsReferenceDoctorAdditionalFormAddBtnStatus:
                                return this.receiveSettingsReferenceDoctorOrganizations;
                            default:
                                return;
                        }
                    case "department":
                        return this.receiveSettingsReferenceDoctorDepartments;
                    default:
                        return;
                }
            }
        },
        getReceiveReferencesCancellationCondition() {//logic
            return (name) => {
                switch (name) {
                    case "responsible_staff":
                    case "responsible_staff_name":
                    case "request_organization_title_short":
                    case "request_organization_department_short":
                    case "request_organization_doctor_name":
                    case "disease_icd_code":
                    case "disease_icd_code_code":
                        return;
                    case "request_organization_doctor_phone":
                    case "request_organization_date":
                    case "disease_diagnosis":
                    case "disease_icd_code_description":
                    case "name":
                    case "short_name":
                    case "address":
                    case "phone":
                    case "email":
                    case "ipa":
                        return true;
                    default:
                        return;
                }
            }
        },
        getReferenceNotFoundStatus() {//logic
            return (name) => {
                switch (name) {
                    case "responsible_staff":
                    case "responsible_staff_name":
                        return this.getIsResponsibleStaffMemberNotFound;
                    case "request_organization_title_short":
                        return this.getIsOrganizationNotFound;
                    case "request_organization_department_short":
                        return this.getIsOrganizationDepartmentNotFound;
                    case "request_organization_doctor_name":
                        return this.getIsOrganizationDepartmentDoctorNotFound;
                    case "disease_icd_code":
                    case "disease_icd_code_code":
                        return this.getIcdCodeReferenceNotFound;
                    case "organization_title":
                        return this.getIsPreviousOrganizationNotFound;
                    case "organization_name":
                        switch (true) {
                            case !this.getSettingsReferenceDepartmentAdditionalFormAddBtnStatus:
                                return this.getIsSettingsReferenceDepartmentOrganizationNotFound;
                            case !this.getSettingsReferenceDoctorAdditionalFormAddBtnStatus:
                                return this.getIsSettingsReferenceDoctorOrganizationNotFound;
                            default:
                                return;
                        }
                    case "department":
                        return this.getIsSettingsReferenceDoctorDepartmentNotFound;
                    case "request_organization_doctor_phone":
                    case "request_organization_date":
                    case "disease_diagnosis":
                    case "disease_icd_code_description":
                        break;
                    default:
                        return false;
                }
            }
        },
        getReferenceNotFoundStatusAction() {//logic
            return (name) => {
                switch (name) {
                    case "responsible_staff":
                    case "responsible_staff_name":
                        return this.updateIsResponsibleStaffMemberNotFound;
                    case "request_organization_title_short":
                        return this.updateIsOrganizationNotFound;
                    case "request_organization_department_short":
                        return this.updateIsOrganizationDepartmentNotFound;
                    case "request_organization_doctor_name":
                        return this.updateIsOrganizationDepartmentDoctorNotFound;
                    case "disease_icd_code":
                    case "disease_icd_code_code":
                        return this.updateIcdCodeReferenceNotFound;
                    case "organization_title":
                        return this.updateIsPreviousOrganizationNotFound;
                    case "organization_name":
                        switch (true) {
                            case !this.getSettingsReferenceDepartmentAdditionalFormAddBtnStatus:
                                return this.updateIsSettingsReferenceDepartmentOrganizationNotFound;
                            case !this.getSettingsReferenceDoctorAdditionalFormAddBtnStatus:
                                return this.updateIsSettingsReferenceDoctorOrganizationNotFound;
                            default:
                                return;
                        }
                    case "department":
                        return this.updateIsSettingsReferenceDoctorDepartmentNotFound;
                    case "request_organization_doctor_phone":
                    case "request_organization_date":
                    case "disease_diagnosis":
                    case "disease_icd_code_description":
                        break;
                    default:
                        return;
                }
            }
        },
        getInputFieldNotificationCondition() {//input
            return (name) => {
                switch (name) {
                    case "responsible_staff":
                    case "responsible_staff_name":
                        return this.getIsResponsibleStaffMemberNotFound || this.getResponsibleStaffMemberReferenceListShowStatus;
                    case "request_organization_title_short":
                        return this.getIsOrganizationNotFound || this.getOrganizationReferenceListShowStatus;
                    case "request_organization_department_short":
                        return this.getIsOrganizationDepartmentNotFound || this.getOrganizationDepartmentReferenceListShowStatus;
                    case "request_organization_doctor_name":
                        return this.getIsOrganizationDepartmentDoctorNotFound || this.getOrganizationDepartmentDoctorReferenceListShowStatus;
                    case "disease_icd_code":
                    case "disease_icd_code_code":
                        return this.getIcdCodeReferenceNotFound || this.getIcdCodeReferenceListShowStatus;
                    case "organization_title":
                        return;
                    case "organization_name":
                        switch (true) {
                            case !this.getSettingsReferenceDepartmentAdditionalFormAddBtnStatus:
                                return this.getIsSettingsReferenceDepartmentOrganizationNotFound || this.getSettingsReferenceDepartmentOrganizationListShowStatus;
                            case !this.getSettingsReferenceDoctorAdditionalFormAddBtnStatus:
                                return this.getIsSettingsReferenceDoctorOrganizationNotFound || this.getSettingsReferenceDoctorOrganizationListShowStatus;
                            default:
                                return;
                        }
                    case "department":
                        return this.getIsSettingsReferenceDoctorDepartmentNotFound || this.getSettingsReferenceDoctorDepartmentListShowStatus;
                    case "request_organization_doctor_phone":
                    case "request_organization_date":
                    case "disease_diagnosis":
                    case "disease_icd_code_description":
                        break;
                    default:
                        return false;
                }
            }
        },
        getInputFieldNotificationClasses() {//input
            return (name) => {
                switch (name) {
                    case "responsible_staff":
                    case "responsible_staff_name":
                        return [{"field__message--notification": this.getResponsibleStaffMemberReferenceListShowStatus}];
                    case "request_organization_title_short":
                        return [{"field__message--notification": this.getOrganizationReferenceListShowStatus}];
                    case "request_organization_department_short":
                        return [{"field__message--notification": this.getOrganizationDepartmentReferenceListShowStatus}];
                    case "request_organization_doctor_name":
                        return [{"field__message--notification": this.getOrganizationDepartmentDoctorReferenceListShowStatus}];
                    case "disease_icd_code":
                    case "disease_icd_code_code":
                        return [{"field__message--notification": this.getIcdCodeReferenceListShowStatus}];
                    case "organization_title":
                        return;
                    case "organization_name":
                        switch (true) {
                            case !this.getSettingsReferenceDepartmentAdditionalFormAddBtnStatus:
                                return [{"field__message--notification": this.getSettingsReferenceDepartmentOrganizationListShowStatus}];
                            case !this.getSettingsReferenceDoctorAdditionalFormAddBtnStatus:
                                return [{"field__message--notification": this.getSettingsReferenceDoctorOrganizationListShowStatus}];
                            default:
                                return;
                        }
                    case "department":
                        return [{"field__message--notification": this.getSettingsReferenceDoctorDepartmentListShowStatus}];
                    case "request_organization_doctor_phone":
                    case "request_organization_date":
                    case "disease_diagnosis":
                    case "disease_icd_code_description":
                        break;
                    default:
                        return [""];
                }
            }
        },
        getInputFieldNotification() {//input
            return (name) => {
                switch (name) {
                    case "responsible_staff":
                    case "responsible_staff_name":
                        return this.getFieldHintResponsibleStaffMemberMessage;
                    case "request_organization_title_short":
                        return this.getFieldHintOrganizationMessage;
                    case "request_organization_department_short":
                        return this.getFieldHintOrganizationDepartmentMessage;
                    case "request_organization_doctor_name":
                        return this.getFieldHintOrganizationDepartmentDoctorMessage;
                    case "disease_icd_code":
                    case "disease_icd_code_code":
                        return this.getFieldHintIcdCodeMessage;
                    case "organization_title":
                        return;
                    case "organization_name":
                        switch (true) {
                            case !this.getSettingsReferenceDepartmentAdditionalFormAddBtnStatus:
                                return this.getFieldHintSettingsReferenceDepartmentOrganizationMessage;
                            case !this.getSettingsReferenceDoctorAdditionalFormAddBtnStatus:
                                return this.getFieldHintSettingsReferenceDoctorOrganizationMessage;
                            default:
                                return;
                        }
                    case "department":
                        return this.getFieldHintSettingsReferenceDoctorDepartmentMessage;
                    case "request_organization_doctor_phone":
                    case "request_organization_date":
                    case "disease_diagnosis":
                    case "disease_icd_code_description":
                        break;
                    default:
                        return;
                }
            }
        },
        getInputClasses() {//input
            return (name) => {
                switch (name) {
                    case "responsible_staff":
                    case "responsible_staff_name":
                        return ["form__control", {field__invalid: this.getIsResponsibleStaffMemberNotFound}];
                    case "request_organization_title_short":
                        return ["form__control", {field__invalid: this.getIsOrganizationNotFound}];
                    case "request_organization_department_short":
                        return ["form__control", {field__invalid: this.getIsOrganizationDepartmentNotFound}];
                    case "request_organization_doctor_name":
                        return ["form__control", {field__invalid: this.getIsOrganizationDepartmentDoctorNotFound}];
                    case "request_organization_doctor_phone":
                        return ["form__control", "phone"];
                    case "request_organization_date":
                        return ["form__control", "form__control_date"];
                    case "disease_icd_code":
                    case "disease_icd_code_code":
                        return ["form__control", {field__invalid: this.getIcdCodeReferenceNotFound}];
                    case "organization_title":
                        return ["form__control"];
                    case "organization_name":
                        switch (true) {
                            case !this.getSettingsReferenceDepartmentAdditionalFormAddBtnStatus:
                                return ["form__control", {field__invalid: this.getIsSettingsReferenceDepartmentOrganizationNotFound}];
                            case !this.getSettingsReferenceDoctorAdditionalFormAddBtnStatus:
                                return ["form__control", {field__invalid: this.getIsSettingsReferenceDoctorOrganizationNotFound}];
                            default:
                                return;
                        }
                    case "department":
                        return ["form__control", {field__invalid: this.getIsSettingsReferenceDoctorDepartmentNotFound}];
                    default:
                        return ["form__control"];
                }
            }
        },
        getInputFieldDisabledStatus() {
            return (name) => {
                switch (name) {
                    case "request_organization_department_short":
                        return this.getLifetimeOrganizationDepartmentFieldDisabledStatus;
                    case "request_organization_doctor_name":
                        return this.getLifetimeOrganizationDepartmentDoctorFieldDisabledStatus;
                    case "request_organization_doctor_phone":
                    case "disease_diagnosis":
                    case "disease_icd_code_description":
                        return true;
                    case "department":
                        return this.getSettingsReferenceDoctorDepartmentFieldDisabledStatus;
                    default:
                        return false;
                }
            }
        },
        getReferenceListShowStatus() {//component AppReferenceList
            //if you need to show a button for an addition request, then add the status to the condition "...NotFound"
            return (name) => {
                switch (name) {
                    case "responsible_staff":
                    case "responsible_staff_name":
                        return this.getResponsibleStaffMemberReferenceListShowStatus;
                    case "request_organization_title_short":
                        return this.getOrganizationReferenceListShowStatus;
                    case "request_organization_department_short":
                        return this.getOrganizationDepartmentReferenceListShowStatus;
                    case "request_organization_doctor_name":
                        return this.getOrganizationDepartmentDoctorReferenceListShowStatus;
                    case "disease_icd_code":
                    case "disease_icd_code_code":
                        return this.getIcdCodeReferenceListShowStatus;
                    case "organization_title":
                        return this.getPreviousOrganizationReferenceListShowStatus;
                    case "organization_name":
                        switch (true) {
                            case !this.getSettingsReferenceDepartmentAdditionalFormAddBtnStatus:
                                return this.getSettingsReferenceDepartmentOrganizationListShowStatus;
                            case !this.getSettingsReferenceDoctorAdditionalFormAddBtnStatus:
                                return this.getSettingsReferenceDoctorOrganizationListShowStatus;
                            default:
                                return;
                        }
                    case "department":
                        return this.getSettingsReferenceDoctorDepartmentListShowStatus;
                    case "request_organization_doctor_phone":
                    case "request_organization_date":
                    case "disease_diagnosis":
                    case "disease_icd_code_description":
                        break;
                    default:
                        return false;
                }
            }
        },
        getReferenceListShowStatusAction() {//logic and component AppReferenceList
            return (name) => {
                switch (name) {
                    case "responsible_staff":
                    case "responsible_staff_name":
                        return this.updateResponsibleStaffMemberReferenceListShowStatus;
                    case "request_organization_title_short":
                        return this.updateOrganizationReferenceListShowStatus;
                    case "request_organization_department_short":
                        return this.updateOrganizationDepartmentReferenceListShowStatus;
                    case "request_organization_doctor_name":
                        return this.updateOrganizationDepartmentDoctorReferenceListShowStatus;
                    case "disease_icd_code":
                    case "disease_icd_code_code":
                        return this.updateIcdCodeReferenceListShowStatus;
                    case "organization_title":
                        return this.updatePreviousOrganizationReferenceListShowStatus;
                    case "organization_name":
                        switch (true) {
                            case !this.getSettingsReferenceDepartmentAdditionalFormAddBtnStatus:
                                return this.updateSettingsReferenceDepartmentOrganizationListShowStatus;
                            case !this.getSettingsReferenceDoctorAdditionalFormAddBtnStatus:
                                return this.updateSettingsReferenceDoctorOrganizationListShowStatus;
                            default:
                                return;
                        }
                    case "department":
                        return this.updateSettingsReferenceDoctorDepartmentListShowStatus;
                    case "request_organization_doctor_phone":
                    case "request_organization_date":
                    case "disease_diagnosis":
                    case "disease_icd_code_description":
                        break;
                    default:
                        return;
                }
            }
        },
        getReferenceListData() {//logic and component AppReferenceList
            return (name) => {
                switch (name) {
                    case "responsible_staff":
                    case "responsible_staff_name":
                        return this.getResponsibleStaffMembers.results;
                    case "request_organization_title_short":
                        return this.getOrganizations.results;
                    case "request_organization_department_short":
                        return this.getOrganizationDepartments.results;
                    case "request_organization_doctor_name":
                        return this.getOrganizationDepartmentDoctors.results;
                    case "disease_icd_code":
                    case "disease_icd_code_code":
                        return this.getIcdCodes.results;
                    case "organization_title":
                        return this.getPreviousOrganizations.results;
                    case "organization_name":
                        switch (true) {
                            case !this.getSettingsReferenceDepartmentAdditionalFormAddBtnStatus:
                                return this.getSettingsReferenceDepartmentOrganizations.results;
                            case !this.getSettingsReferenceDoctorAdditionalFormAddBtnStatus:
                                return this.getSettingsReferenceDoctorOrganizations.results;
                            default:
                                return;
                        }
                    case "department":
                        return this.getSettingsReferenceDoctorDepartments.results;
                    case "request_organization_doctor_phone":
                    case "request_organization_date":
                    case "disease_diagnosis":
                    case "disease_icd_code_description":
                        break;
                    default:
                        return;
                }
            }
        },
        getReferenceSelectedName() {//logic
            return (name) => {
                switch (name) {
                    case "responsible_staff":
                    case "responsible_staff_name":
                        return this.getResponsibleStaffMemberSelectedName;
                    case "request_organization_title_short":
                        return this.getOrganizationSelectedName;
                    case "request_organization_department_short":
                        return this.getOrganizationDepartmentSelectedName;
                    case "request_organization_doctor_name":
                        return this.getOrganizationDepartmentDoctorSelectedName;
                    case "disease_icd_code":
                    case "disease_icd_code_code":
                        return this.getIcdCodeReferenceSelectedName;
                    case "organization_title":
                        return this.getPreviousOrganizationSelectedName;
                    case "organization_name":
                        switch (true) {
                            case !this.getSettingsReferenceDepartmentAdditionalFormAddBtnStatus:
                                return this.getSettingsReferenceDepartmentOrganizationSelectedName;
                            case !this.getSettingsReferenceDoctorAdditionalFormAddBtnStatus:
                                return this.getSettingsReferenceDoctorOrganizationSelectedName;
                            default:
                                return;
                        }
                    case "department":
                        return this.getSettingsReferenceDoctorDepartmentSelectedName;
                    case "request_organization_doctor_phone":
                    case "request_organization_date":
                    case "disease_diagnosis":
                    case "disease_icd_code_description":
                        break;
                    default:
                        return;
                }
            }
        },
        getReferenceSelectedNameAction() {//logic
            return (name) => {
                switch (name) {
                    case "responsible_staff":
                    case "responsible_staff_name":
                        return this.updateResponsibleStaffMemberSelectedName;
                    case "request_organization_title_short":
                        return this.updateOrganizationSelectedName;
                    case "request_organization_department_short":
                        return this.updateOrganizationDepartmentSelectedName;
                    case "request_organization_doctor_name":
                        return this.updateOrganizationDepartmentDoctorSelectedName;
                    case "disease_icd_code":
                    case "disease_icd_code_code":
                        return this.updateIcdCodeReferenceSelectedName;
                    case "organization_title":
                        return this.updatePreviousOrganizationSelectedName;
                    case "organization_name":
                        switch (true) {
                            case !this.getSettingsReferenceDepartmentAdditionalFormAddBtnStatus:
                                return this.updateSettingsReferenceDepartmentOrganizationSelectedName;
                            case !this.getSettingsReferenceDoctorAdditionalFormAddBtnStatus:
                                return this.updateSettingsReferenceDoctorOrganizationSelectedName;
                            default:
                                return;
                        }
                    case "department":
                        return this.updateSettingsReferenceDoctorDepartmentSelectedName;
                    case "request_organization_doctor_phone":
                    case "request_organization_date":
                    case "disease_diagnosis":
                    case "disease_icd_code_description":
                        break;
                    default:
                        return;
                }
            }
        },
        getReferenceSelectedItemId() {//logic
            return (name) => {
                switch (name) {
                    case "responsible_staff":
                    case "responsible_staff_name":
                        return this.getResponsibleStaffMemberSelectedItemId;
                    case "request_organization_title_short":
                        return this.getOrganizationSelectedItemId;
                    case "request_organization_department_short":
                        return this.getOrganizationDepartmentSelectedItemId;
                    case "request_organization_doctor_name":
                        return this.getOrganizationDepartmentDoctorSelectedItemId;
                    case "disease_icd_code":
                    case "disease_icd_code_code":
                        return this.getIcdCodeReferenceSelectedItemId;
                    case "organization_title":
                        return this.getPreviousOrganizationSelectedItemId;
                    case "organization_name":
                        switch (true) {
                            case !this.getSettingsReferenceDepartmentAdditionalFormAddBtnStatus:
                                return this.getSettingsReferenceDepartmentOrganizationSelectedItemId;
                            case !this.getSettingsReferenceDoctorAdditionalFormAddBtnStatus:
                                return this.getSettingsReferenceDoctorOrganizationSelectedItemId;
                            default:
                                return;
                        }
                    case "department":
                        return this.getSettingsReferenceDoctorDepartmentSelectedItemId;
                    case "request_organization_doctor_phone":
                    case "request_organization_date":
                    case "disease_diagnosis":
                    case "disease_icd_code_description":
                        break;
                    default:
                        return;
                }
            }
        },
        getReferenceSelectedItemIdAction() {//logic
            return (name) => {
                switch (name) {
                    case "responsible_staff":
                    case "responsible_staff_name":
                        return this.updateResponsibleStaffMemberSelectedItemId;
                    case "request_organization_title_short":
                        return this.updateOrganizationSelectedItemId;
                    case "request_organization_department_short":
                        return this.updateOrganizationDepartmentSelectedItemId;
                    case "request_organization_doctor_name":
                        return this.updateOrganizationDepartmentDoctorSelectedItemId;
                    case "disease_icd_code":
                    case "disease_icd_code_code":
                        return this.updateIcdCodeReferenceSelectedItemId;
                    case "organization_title":
                        return this.updatePreviousOrganizationSelectedItemId;
                    case "organization_name":
                        switch (true) {
                            case !this.getSettingsReferenceDepartmentAdditionalFormAddBtnStatus:
                                return this.updateSettingsReferenceDepartmentOrganizationSelectedItemId;
                            case !this.getSettingsReferenceDoctorAdditionalFormAddBtnStatus:
                                return this.updateSettingsReferenceDoctorOrganizationSelectedItemId;
                            default:
                                return;
                        }
                    case "department":
                        return this.updateSettingsReferenceDoctorDepartmentSelectedItemId;
                    case "request_organization_doctor_phone":
                    case "request_organization_date":
                    case "disease_diagnosis":
                    case "disease_icd_code_description":
                        break;
                    default:
                        return;
                }
            }
        },
        getReferenceCheckFieldDataAction() {//input
            return (name, data, callback = () => this.clearReferenceChildField) => {
                switch (name) {
                    case "responsible_staff":
                    case "responsible_staff_name":
                    case "request_organization_title_short":
                    case "request_organization_department_short":
                    case "request_organization_doctor_name":
                    case "disease_icd_code":
                    case "disease_icd_code_code":
                    case "organization_name":
                    case "organization_short_name":
                    case "department":
                        return this.checkReferenceField(name, data, callback);
                    case "organization_title":
                        return;
                    default:
                        return;
                }
            }
        },
        setReferenceListAdditionalLogic() {
            return (data, name) => {
                switch (name) {
                    case "request_organization_title_short":
                        this.updateLifetimeOrganizationInformationDataFromAdditionalLogic({request_organization_title: data});
                        return;
                    case "request_organization_department_short":
                        this.updateLifetimeOrganizationInformationDataFromAdditionalLogic({request_organization_department: data});
                        return;
                    case "request_organization_doctor_name":
                        this.request_organization_doctor_phone = data;
                        this.updateOrganizationDepartmentDoctorPhone(this.request_organization_doctor_phone);
                        this.$refs["FormCasesCustomerAppInputrequest_organization_doctor_phoneRef"][0].vModelName = data;
                        return;
                    case "disease_icd_code":
                    case "disease_icd_code_code":
                        this.disease_diagnosis = data;
                        this.updateIcdCodeReferenceDiagnosis(this.disease_diagnosis);
                        this.$refs.formLifetimeCasesDiseaseIcdCodeDataRef[0].vModelName = data;
                        return;
                    default:
                        return;
                }
            }
        },
        getReferenceListAdditionalLogicAction() {
            return (name) => {
                switch (name) {
                    case "request_organization_title_short":
                        return {fillInAdditionalDataCallbacks: [this.setReferenceListAdditionalLogic], callbackKeys: ["name"]};
                    case "request_organization_department_short":
                        return {fillInAdditionalDataCallbacks: [this.setReferenceListAdditionalLogic], callbackKeys: ["name"]};
                    case "request_organization_doctor_name":
                        return {fillInAdditionalDataCallbacks: [this.setReferenceListAdditionalLogic], callbackKeys: ["phone"]};
                    case "disease_icd_code":
                    case "disease_icd_code_code":
                        return {fillInAdditionalDataCallbacks: [this.setReferenceListAdditionalLogic], callbackKeys: ["disease_description"]};
                    default:
                        return;
                }
            }
        },
        clearReferenceChildField() {
            return (fieldName) => {
                switch (fieldName) {
                    case "request_organization_title_short":
                    case "request_organization_department_short":
                    case "request_organization_doctor_name":
                        switch (fieldName === "request_organization_title_short" || fieldName === "request_organization_department_short" || fieldName === "request_organization_department_short") {
                            case this.request_organization_department_short !== "":
                                this.request_organization_department_short = "";
                                this.$refs["FormCasesCustomerWidgetReferenceSearchrequest_organization_department_shortRef"][0].$refs["FormCasesCustomerAppInputrequest_organization_department_shortRef"].vModelName = "";
                                break;
                            case this.request_organization_doctor_name !== "":
                                this.request_organization_doctor_name = "";
                                this.$refs["FormCasesCustomerWidgetReferenceSearchrequest_organization_doctor_nameRef"][0].$refs["FormCasesCustomerAppInputrequest_organization_doctor_nameRef"].vModelName = "";
                                this.request_organization_doctor_phone = "";
                                this.$refs["FormCasesCustomerAppInputrequest_organization_doctor_phoneRef"][0].vModelName = "";
                                break;
                            default:
                                break;
                        }
                        break;
                    case "disease_icd_code":
                    case "disease_icd_code_code":
                        this.disease_diagnosis = "";
                        this.$refs.formLifetimeCasesDiseaseIcdCodeDataRef[0].vModelName = "";
                        break;
                    case "organization_short_name":
                        this.department = "";
                        this.$refs[this.appInputRefDoctorDepartment][0].vModelName = "";
                        break;
                    default:
                        break;
                }
            }
        },
        isReferenceFieldInAdditionalForm() {
            return (name) => {
                switch (name) {
                    case "organization_title":
                        return true;
                    default:
                        return;
                }
            }
        },
        getReferenceFieldChangeInAdditionalFormStatusAction() {
            return (name) => {
                switch (name) {
                    case "organization_title":
                        return this.updatePreviousOrganizationReferenceFieldChangeStatus;
                    default:
                        return;
                }
            }
        },
        getReferenceListDataNextAction() {
            return (name) => {
                switch (name) {
                    case "responsible_staff":
                    case "responsible_staff_name":
                        return this.fetchNextResponsibleStaffMembers;
                    case "request_organization_title_short":
                        return this.fetchNextOrganizations;
                    case "request_organization_department_short":
                        return this.fetchNextOrganizationDepartments;
                    case "request_organization_doctor_name":
                        return this.fetchNextOrganizationDepartmentDoctors;
                    case "organization_title":
                        return this.fetchNextPreviousOrganizations;
                    case "organization_name":
                        switch (true) {
                            case !this.getSettingsReferenceDepartmentAdditionalFormAddBtnStatus:
                                return this.fetchNextSettingsReferenceDepartmentOrganizations;
                            case !this.getSettingsReferenceDoctorAdditionalFormAddBtnStatus:
                                return this.fetchNextSettingsReferenceDoctorOrganizations;
                            default:
                                return;
                        }
                    case "department":
                        return this.fetchNextSettingsReferenceDoctorDepartments;
                    case "disease_icd_code":
                    case "disease_icd_code_code":
                        return this.fetchNextIcdCodes;
                    default:
                        return;
                }
            }
        },
        getReferenceListConditionКey() {
            return (fieldName) => {
                switch (fieldName) {
                    case "request_organization_title_short":
                    case "request_organization_department_short":
                        return "short_name";
                    case "disease_icd_code":
                    case "disease_icd_code_code":
                        return "code";
                    case "organization_short_name":
                        return "short_name";
                    case "responsible_staff":
                    case "responsible_staff_name":
                        return "full_name";
                    default:
                        return "name";
                }
            }
        },
    },
    methods: {
        async getReferenceList(fieldName, data) {
            if (this.getReceiveReferencesCancellationCondition(fieldName)) {
                return;
            }
            this.closeReferenceList(fieldName, data);
            await this.getReceiveReferences(fieldName)();
        },
        // fillInSelectedName(fieldName, item) {
        //     switch (true) {
        //         case item.code !== undefined:
        //             this.getReferenceSelectedNameAction(fieldName)(item.code);
        //             break;
        //         default:
        //             this.getReferenceSelectedNameAction(fieldName)(item.name);
        //             break;
        //     }
        // },
        getDataByIdFromReferenceList(id, fieldName, fillInAdditionalDataCallbackLogic) {
            if (!id) {
                return;
            }
            this.getReferenceListData(fieldName).filter((item) => {
                if (item.id !== id) {
                    return;
                }

                this.getReferenceSelectedItemIdAction(fieldName)(item.id);

                if (fillInAdditionalDataCallbackLogic !== undefined) {
                    const {fillInAdditionalDataCallbacks, callbackKeys} = fillInAdditionalDataCallbackLogic;

                    fillInAdditionalDataCallbacks.map((fillInAdditionalDataCallback, index) => {
                        fillInAdditionalDataCallback(item?.[callbackKeys[index]], fieldName);
                    });
                }

                this.getReferenceSelectedNameAction(fieldName)(item[this.getReferenceListConditionКey(fieldName)]);
                // this.fillInSelectedName(fieldName, item);
                this.getReferenceListShowStatusAction(fieldName)(false);
            });
        },
        trackReferenceFieldChangeInAdditionalForm(fieldName, fieldValue) {
            if (!this.isReferenceFieldInAdditionalForm(fieldName)) {
                return;
            }
            if ((fieldValue && fieldValue === "") || this.getSetFieldData(fieldName) === "") {
                this.getReferenceFieldChangeInAdditionalFormStatusAction(fieldName)(true);
                return;
            }
            this.getReferenceFieldChangeInAdditionalFormStatusAction(fieldName)(false);
        },
        closeReferenceList(fieldName, data) {
            this.trackReferenceFieldChangeInAdditionalForm(fieldName);

            if (data?.[fieldName] !== undefined && data?.[fieldName].length !== 0 && data !== null) {
                return;
            }

            this.getReferenceListShowStatusAction(fieldName)(false);

            // if (this.isReferenceFieldInAdditionalForm(fieldName)) {
            //     return;
            // }
            // провоцирует повторное открытие списка после выбранного ранее значения из словаря
            // this.clearReferenceSelectedDataState(fieldName);
        },
        clearReferenceSelectedDataState(fieldName) {
            if ((this.getReferenceSelectedName(fieldName) && this.getReferenceSelectedName(fieldName).length === 0) || this.getReferenceSelectedItemId(fieldName) === null) {
                return;
            }
            this.getReferenceSelectedItemIdAction(fieldName)(null);
            this.getReferenceSelectedNameAction(fieldName)("");
        },
        checkExistenceInDictionaryReference(fieldName) {
            if (!this.getReferenceNotFoundStatus(fieldName)) {
                return;
            }
            this.getReferenceNotFoundStatusAction(fieldName)(false);
        },
        resetReferenceField(fieldName) {
            this.clearReferenceSelectedDataState(fieldName);
            this.checkExistenceInDictionaryReference(fieldName);
        },
        checkReferenceField(fieldName, data, clearChildFieldCallback) {
            return () => {
                switch (true) {
                    case data?.[fieldName] !== undefined && data?.[fieldName].length === 0:
                    case this.getReferenceSelectedName(fieldName) !== data?.[fieldName]:

                        this.trackReferenceFieldChangeInAdditionalForm(fieldName, this[fieldName]);// for reference book fields in additional forms

                        this[fieldName] = "";
                        this.resetReferenceField(fieldName);
                        clearChildFieldCallback()(fieldName);
                        return this[fieldName];
                    default:
                        return;
                }
            }
        },
        getReferenceListNext(fieldName) {
            return (event) => {
                this.getNextDataListAccordingScrollPosition(event, this.getReferenceListDataNextAction(fieldName), false);
            }
        },
    }
};