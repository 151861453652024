import { api } from "@/api";

export default {
    state: {
        productionCalendar: [],
        daysOffInLaw: []
    },
    actions: {
        async fetchProductionCalendar({ commit, dispatch }, queryParams){
            try {
                const token = await dispatch("fetchAccessToken");
                const productionCalendar = await api.getSettingsProductionCalendar(token, queryParams);
                commit("changeProductionCalendar", productionCalendar);
            } catch (e) {
                console.error(e);
            }
        },
        async editProductionCalendar({ commit, dispatch }, productionCalendarData){
            try {
                const token = await dispatch("fetchAccessToken");
                await api.postSettingsProductionCalendar(token, productionCalendarData);
                commit("changeProductionCalendar", productionCalendarData.dates);
            } catch (e) {
                console.error(e);
            }
        },
        async fetchDaysOffInLaw({ commit, dispatch }, year){
            try {
                const token = await dispatch("fetchAccessToken");
                const daysOffInLaw = await api.getSettingsDaysOffInLaw(token, year);
                commit("changeDaysOffInLaw", daysOffInLaw);
            } catch (e) {
                console.error(e);
            }
        },
    },
    mutations: {
        changeProductionCalendar(state, productionCalendar) {
            state.productionCalendar = productionCalendar;
        },
        changeDaysOffInLaw(state, daysOffInLaw) {
            state.daysOffInLaw = daysOffInLaw;
        },
    },
    getters: {
        getProductionCalendar(state) {
            return state.productionCalendar;
        },
        getDaysOffInLaw(state) {
            return state.daysOffInLaw;
        },
    },
}