export default {
    state: {
        lifetimeMicroPreparationsPhotos: [],
    },
    actions: {

    },
    mutations: {

    },
    getters: {
        getLifetimeMicroPreparationsPhotos(state) {
            return state.lifetimeMicroPreparationsPhotos;
        },
    }
}