export default {
    state: {
        inputLifetimeFocusEventStatus: false
    },
    actions: {
        updateInputLifetimeFocusEventStatus({ commit }, status) {
            commit("changeInputLifetimeFocusEventStatus", status);
        },
    },
    mutations: {
        changeInputLifetimeFocusEventStatus(state, status) {
            state.inputLifetimeFocusEventStatus = status;
        },
    },
    getters: {
        getInputLifetimeFocusEventStatus(state) {
            return state.inputLifetimeFocusEventStatus;
        },
    }
}
