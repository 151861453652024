
export default {
    state: {
        formLifetimeGeneralSaveBtnStatus: false,
        formLifetimePatientSaveBtnStatus: false,
        formLifetimeDiseaseSaveBtnStatus: false,
        formLifetimeCommentSaveBtnStatus: false,
        formLifetimeCustomerSaveBtnStatus: false,
        formLifetimeMaterialSaveBtnStatus: false,
        formLifetimeMacroDescriptionPhotoSaveBtnStatus: false,
        formLifetimeMacroDescriptionObjectSaveBtnStatus: false,
        formLifetimeMacroDescriptionConclusionSaveBtnStatus: false,
        formLifetimeMicroDescriptionSaveBtnStatus: false,
    },

    actions: {
        updateAllFormLifetimeSaveBtnStatus({ dispatch }, status) {
            dispatch("updateFormLifetimeGeneralSaveBtnStatus", status);
            dispatch("updateFormLifetimePatientSaveBtnStatus", status);
            dispatch("updateFormLifetimeDiseaseSaveBtnStatus", status);
            dispatch("updateFormLifetimeCommentSaveBtnStatus", status);
            dispatch("updateFormLifetimeCustomerSaveBtnStatus", status);
            dispatch("updateFormLifetimeMaterialSaveBtnStatus", status);
            dispatch("updateFormCaseByIdTabDefectBtnStatus", status);
            dispatch("updateIsDefectGroupDirection", status);
            dispatch("updateIsDefectGroupMaterial", status);
            dispatch("updateFormLifetimeMacroDescriptionPhotoSaveBtnStatus", status);
            dispatch("updateFormLifetimeMacroDescriptionObjectSaveBtnStatus", status);
            dispatch("updateFormLifetimeMacroDescriptionConclusionSaveBtnStatus", status);
            dispatch("updateFormLifetimeMicroDescriptionSaveBtnStatus", status);
            dispatch("updateFormCaseByIdTabReceptionCommentBtnStatus", status);
            dispatch("updateFormCaseByIdTabMacroDescriptionCommentBtnStatus", status);
            dispatch("updateFormCaseByIdTabMacroDescriptionInformationBtnStatus", status);
            dispatch("updateFormCaseByIdTabSampleCommentBtnStatus", status);
            dispatch("updateFormCaseByIdTabMicroDescriptionMicroPreparationsBtnStatus", status);
            dispatch("updateFormCaseByIdTabMicroDescriptionConclusionBtnStatus", status);
            dispatch("updateFormCaseByIdTabMicroDescriptionServicesBtnStatus", status);
            dispatch("updateFormCaseByIdTabMicroDescriptionCommentBtnStatus", status);
            dispatch("updateFormCaseByIdTabMicroDescriptionInfoBtnStatus", status);
        },
        updateFormLifetimeGeneralSaveBtnStatus({ commit }, status) {
            commit("changeFormLifetimeGeneralSaveBtnStatus", status);
        },
        updateFormLifetimePatientSaveBtnStatus({ commit }, status) {
            commit("changeFormLifetimePatientSaveBtnStatus", status);
        },
        updateFormLifetimeDiseaseSaveBtnStatus({ commit }, status) {
            commit("changeFormLifetimeDiseaseSaveBtnStatus", status);
        },
        updateFormLifetimeCommentSaveBtnStatus({ commit }, status) {
            commit("changeFormLifetimeCommentSaveBtnStatus", status);
        },
        updateFormLifetimeCustomerSaveBtnStatus({ commit }, status) {
            commit("changeFormLifetimeCustomerSaveBtnStatus", status);
        },
        updateFormLifetimeMaterialSaveBtnStatus({ commit }, status) {
            commit("changeFormLifetimeMaterialSaveBtnStatus", status);
        },
        updateFormLifetimeMacroDescriptionPhotoSaveBtnStatus({ commit }, status) {
            commit("changeFormLifetimeMacroDescriptionPhotoSaveBtnStatus", status);
        },
        updateFormLifetimeMacroDescriptionObjectSaveBtnStatus({ commit }, status) {
            commit("changeFormLifetimeMacroDescriptionObjectSaveBtnStatus", status);
        },
        updateFormLifetimeMacroDescriptionConclusionSaveBtnStatus({ commit }, status) {
            commit("changeFormLifetimeMacroDescriptionConclusionSaveBtnStatus", status);
        },
        updateFormLifetimeMicroDescriptionSaveBtnStatus({ commit }, status) {
            commit("changeFormLifetimeMicroDescriptionSaveBtnStatus", status);
        },
    },

    mutations: {
        changeFormLifetimeGeneralSaveBtnStatus(state, status) {
            state.formLifetimeGeneralSaveBtnStatus = status;
        },
        changeFormLifetimePatientSaveBtnStatus(state, status) {
            state.formLifetimePatientSaveBtnStatus = status;
        },
        changeFormLifetimeDiseaseSaveBtnStatus(state, status) {
            state.formLifetimeDiseaseSaveBtnStatus = status;
        },
        changeFormLifetimeCommentSaveBtnStatus(state, status) {
            state.formLifetimeCommentSaveBtnStatus = status;
        },
        changeFormLifetimeCustomerSaveBtnStatus(state, status) {
            state.formLifetimeCustomerSaveBtnStatus = status;
        },
        changeFormLifetimeMaterialSaveBtnStatus(state, status) {
            state.formLifetimeMaterialSaveBtnStatus = status;
        },
        changeFormLifetimeMacroDescriptionPhotoSaveBtnStatus(state, status) {
            state.formLifetimeMacroDescriptionPhotoSaveBtnStatus = status;
        },
        changeFormLifetimeMacroDescriptionObjectSaveBtnStatus(state, status) {
            state.formLifetimeMacroDescriptionObjectSaveBtnStatus = status;
        },
        changeFormLifetimeMacroDescriptionConclusionSaveBtnStatus(state, status) {
            state.formLifetimeMacroDescriptionConclusionSaveBtnStatus = status;
        },
        changeFormLifetimeMicroDescriptionSaveBtnStatus(state, status) {
            state.formLifetimeMicroDescriptionSaveBtnStatus = status;
        },
    },

    getters: {
        getFormLifetimeGeneralSaveBtnStatus(state) {
            return state.formLifetimeGeneralSaveBtnStatus;
        },
        getFormLifetimePatientSaveBtnStatus(state) {
            return state.formLifetimePatientSaveBtnStatus;
        },
        getFormLifetimeDiseaseSaveBtnStatus(state) {
            return state.formLifetimeDiseaseSaveBtnStatus;
        },
        getFormLifetimeCommentSaveBtnStatus(state) {
            return state.formLifetimeCommentSaveBtnStatus;
        },
        getFormLifetimeCustomerSaveBtnStatus(state) {
            return state.formLifetimeCustomerSaveBtnStatus;
        },
        getFormLifetimeMaterialSaveBtnStatus(state) {
            return state.formLifetimeMaterialSaveBtnStatus;
        },
        getFormLifetimeMacroDescriptionPhotoSaveBtnStatus(state) {
            return state.formLifetimeMacroDescriptionPhotoSaveBtnStatus;
        },
        getFormLifetimeMacroDescriptionObjectSaveBtnStatus(state) {
            return state.formLifetimeMacroDescriptionObjectSaveBtnStatus;
        },
        getFormLifetimeMacroDescriptionConclusionSaveBtnStatus(state) {
            return state.formLifetimeMacroDescriptionConclusionSaveBtnStatus;
        },
        getFormLifetimeMicroDescriptionSaveBtnStatus(state) {
            return state.formLifetimeMicroDescriptionSaveBtnStatus;
        },
    }
}
