import { getLifetimeCaseLink } from './lifetimeCase'
export const getLifetimeCaseMicroscopyMicropreparationPhoto = (
    lifetimeCaseId,
    microPreparationId = ':microPreparationId',
    isMicroPreparation = 'micropreparation'
) =>
    [
        getLifetimeCaseLink(lifetimeCaseId),
        microPreparationId,
        isMicroPreparation
    ].join('/')
