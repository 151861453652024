export const fieldsFlags = {
    data() {
        return {
            fieldsFlags: {
                authorizationCollection: 'authorizationCollection',
                lifetimeCaseGeneralCollection: 'lifetimeCaseGeneralCollection',
                lifetimeCaseRegistrationInformationCollection: 'lifetimeCaseRegistrationInformationCollection',
                lifetimeRegisterNumberCollection: 'lifetimeRegisterNumberCollection',
                lifetimePreviousBiopsyStudiesTableCollection: 'lifetimePreviousBiopsyStudiesTableCollection',
                lifetimeMaterialTableCollection: 'lifetimeMaterialTableCollection',
                lifetimeCaseJournalCollection: 'lifetimeCaseJournalCollection',
                lifetimeCustomerCollection: 'lifetimeCustomerCollection',
                lifetimeDiseaseCollection: 'lifetimeDiseaseCollection',
                lifetimeDiseaseComment: 'lifetimeDiseaseComment',
                lifetimeMicroDescriptionCollection: 'lifetimeMicroDescriptionCollection',
                lifetimeTransferToReceptionCollection: 'lifetimeTransferToReceptionCollection',
                lifetimeStartReceptionMaterialCollection: 'lifetimeStartReceptionMaterialCollection',
                lifetimeStartClippingCollection: 'lifetimeStartClippingCollection',
                referenceStaffCollection: 'referenceStaffCollection',
                referenceOrganizationCollection: 'referenceOrganizationCollection',
                referenceDepartmentCollection: 'referenceDepartmentCollection',
                referenceDoctorCollection: 'referenceDoctorCollection',
                referenceLocalizationCollection: 'referenceLocalizationCollection',
                lifetimeDefectCollection: 'lifetimeDefectCollection',
                lifetimeRegistrationTabCollection: 'lifetimeRegistrationTabCollection',
                lifetimeReceptionInfoTabCollection: 'lifetimeReceptionInfoTabCollection',
                lifetimePatientCollection: 'lifetimePatientCollection',
                lifetimeBiopsyCollection: 'lifetimeBiopsyCollection',
            },
        }
    }
}