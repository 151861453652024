export const getCountCassette = {
    computed: {
        getValueWithEnding() {
            return (count) => {
                let n = Math.abs(count);
                n %= 100;
                if (n >= 5 && n <= 20) {
                    return `${count} кассет`;
                }
                n %= 10;
                if (n === 1) {
                    return `${count} кассет(а)`;
                }
                if (n >= 2 && n <= 4) {
                    return `${count} кассет(ы)`;
                }
                return `${count} кассет`;
            }
        }
    }
};