export const lifetimePrintFields = {
    data() {
        return {
            lifetimePrintJournalHeaderTitle: "Печать журнала регистрации",
            lifetimePrintJournalProtocolHeaderTitle: "Групповая печать протоколов прижизненных исследований",
            lifetimePrintJournalDirectionHeaderTitle: "Групповая печать направлений на прижизненное исследование",
            lifetimePrintJournalNameButtonRight: "Печать",
            lifetimePrintJournalSelectListData: [
                { title: "Журнал регистрации прижизненных исследований (№ 014-2/у)", action: this.showModalPrintCasesJournal },
                { title: "Протокол прижизненного исследования (№ 014-1/у)", action: this.showModalPrintCasesJournalProtocol },
                { title: "Направление на прижизненное исследование (№ 014/у)", action: this.showModalPrintCasesJournalDirection },
            ],
            lifetimePrintSelectListData: [
                { title: "Протокол прижизненного исследования (№ 014-1/у)", action: this.showModalPrintCasesJournalProtocol },
                { title: "Направление на прижизненное исследование (№ 014/у)", action: this.showModalPrintCasesJournalDirection },
            ]
        };
    }
}