// import moment from "moment";
export const checking = {
    computed: {
        checkingStringData() {
            return (item) => {
                return (item === undefined) || (item === null) || (item === "null") ? "" : item;
            };
        },
        checkingBoolData() {
            return (item) => {
                return (item === null) || (item === undefined) || (typeof item === "string") ? false : item;
            };
        },
        checkingIntegerData() {
            return (item) => {
                if ((item === undefined) || (item === "null") || (item === null)) {
                    return 0;
                }
                if ((typeof item === "string")) {
                    let checkItemForNumber = Math.abs(item.replace(/,/g , '.'));
                    return !isNaN(checkItemForNumber) ? checkItemForNumber : 0;
                }
                return item;
            };
        },
        checkingIntegerServerData() {
            return (item) => {
                if ((item === undefined) || (item === "null")) {
                    return null;
                }
                if ((typeof item === "string")) {
                    return !isNaN(Number(item)) ? Number(item) : null;
                }
                return item;
            };
        },
    },
}