import moment from "moment";
export const changeDateFormatConst = {
    data() {
        return {
            maxDateTimeLimitField: null
        }
    },
    computed: {
        changeDateTimeFormat() {
            return (item, separator) => {
                if ((item === null) || (item === undefined) || (item.length === 0)) {
                    return "";
                }
                if (separator) {
                    return moment(item).format(`DD${separator}MM${separator}YYYY HH:mm`);
                }
                return moment(item).format("DD.MM.YYYY HH:mm");
            }
        },
        changeDateFormat() {
            return (item, separator) => {
                if ((item === null) || (item === undefined) || (item.length === 0)) {
                    return "";
                }
                if (separator) {
                    return moment(item).format(`DD${separator}MM${separator}YYYY`);
                }
                return moment(item).format("DD.MM.YYYY");
            }
        },
        changeTimeFormat() {
            return (item) => {
                if ((item === null) || (item === undefined) || (item.length === 0)) {
                    return "";
                }
                return moment(item, "HH:mm:ssZ").format("HH:mm");
            }
        },
        changeDateFormatInput() {
            return (item) => {
                if ((item === null) || (item === undefined) || (item.length === 0)) {
                    return "";
                }
                return moment(item).format("YYYY-MM-DD");
            }
        },
        changeDateTimeFormatInput() {
            return (item) => {
                if ((item === null) || (item === undefined) || (item.length === 0)) {
                    return "";
                }
                return moment(item).format("YYYY-MM-DD HH:mm");
            }
        },
        changeTimeFormatTz() {
            return (item) => {
                if ((item === null) || (item === undefined) || (item.length === 0)) {
                    return null;
                }
                return moment(item, "HH:mm").format("HH:mm:ssZ");
            }
        },
        changeDateTimeFormatTz() {
            return (item) => {
                if ((item === null) || (item === undefined) || (item.length === 0)) {
                    return null;
                }
                return moment(item).toISOString();
            }
        },
        getMinDateLimit() {
            return this.changeDateFormatInput(moment().date(1).subtract(3, "month"));
        },
        getMaxDateLimit() {
            return this.changeDateFormatInput(moment());
        },
        getMaxDateTimeLimit() {
            return this.changeDateTimeFormatInput(moment());
        },
    },
    methods: {
        updateMaxDateTimeLimitField() {
            return this.maxDateTimeLimitField = this.changeDateTimeFormatInput(moment());
        },
    },
    mounted() {
        this.updateMaxDateTimeLimitField();
    }
};