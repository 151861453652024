export const lifetimeDiseaseFields = {
    data() {
        return {
            lifetimeDiseaseReferenceDescriptionFields: {
                disease_icd_code_code: "Код заболевания по МКБ:",
            },
            lifetimeDiseaseDescriptionFields: {
                disease_icd_code_description: "Диагноз заболевания:",
            },
            lifetimeDiseaseDescriptionDescriptionFields: {
                disease_research_task: ["Задача исследования:", ["row__template_column--one"]],
                disease_additional_clinical_info: ["Дополнительные сведения:", ["row__template_column--one"]],
                disease_clinical_treatment: ["Проведенное предоперационное лечение:", ["row__template_column--one"]],
            },
            lifetimeDiseaseFormReferenceDescriptionFields: {
                disease_icd_code: ["Код МКБ-10", ["row__small_sm3", "icd__row", {relative: this.getIcdCodeReferenceListShowStatus}]],
            },
            lifetimeDiseaseFormDescriptionFields: {
                disease_diagnosis: ["Диагноз заболевания (состояния)", ["row__small_sm66", "diagnosis__row"]],
            },
            lifetimeDiseaseFormDescriptionDescriptionFields: {
                disease_research_task: "Задача исследования",
                disease_additional_clinical_info: "Дополнительные сведения",
                disease_clinical_treatment: "Проведенное предоперационное лечение",
            },
        }
    },
}