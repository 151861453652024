export default {
    state: {
        caseAddModalShowBtnStatus: false,
        caseByIdTabsModalBtnStatus: false,
        caseIntermediateActionModalBtnStatus: false,
        modalCasesJournalPrintBtnStatus: false,
        modalCasesProtocolPrintBtnStatus: false,
        modalCasesDirectionPrintBtnStatus: false,
        modalCaseByIdTabMacroDescriptionPhotoBtnStatus: false,
        modalCaseByIdProtocolPrintBtnStatus: false,
        isCaseAddModalActionConfirmationExitShowStatus: false,
    },
    actions: {
        updateCaseAddModalShowBtnStatus({ commit }, status) {
            commit("changeCaseAddModalShowBtnStatus", status);
        },
        updateCaseByIdTabsModalBtnStatus({ commit }) {
            commit("changeCaseByIdTabsModalBtnStatus");
        },
        updateCaseByIdAllTabModalBtnStatus({ commit }) {
            commit("changeModalCasesJournalPrintBtnStatus", false);
            commit("changeModalCasesProtocolPrintBtnStatus", false);
            commit("changeModalCasesDirectionPrintBtnStatus", false);
            commit("changeModalCaseByIdTabMacroDescriptionPhotoBtnStatus", false);
            commit("changeModalActionConfirmationWithoutSaveBtnStatus", false);
            commit("changeModalCaseByIdProtocolPrintBtnStatus", false);
            commit("changeModalUniversalShowStatus", false);
            commit("changeIsModalContentConfirmActionChangeCheckboxStatus", false);
        },
        updateCaseIntermediateActionModalBtnStatus({ commit }) {
            commit("changeCaseIntermediateActionModalBtnStatus");
        },
        updateModalCasesJournalPrintBtnStatus({ commit }, status) {
            commit("changeModalCasesJournalPrintBtnStatus", status);
        },
        updateModalCasesProtocolPrintBtnStatus({ commit }, status) {
            commit("changeModalCasesProtocolPrintBtnStatus", status);
        },
        updateModalCasesDirectionPrintBtnStatus({ commit }, status) {
            commit("changeModalCasesDirectionPrintBtnStatus", status);
        },
        updateModalCaseByIdTabMacroDescriptionPhotoBtnStatus({ commit }, status) {
            commit("changeModalCaseByIdTabMacroDescriptionPhotoBtnStatus", status);
        },
        updateModalCaseByIdProtocolPrintBtnStatus({ commit }, status) {
            commit("changeModalCaseByIdProtocolPrintBtnStatus", status);
        },
        updateIsCaseAddModalActionConfirmationExitShowStatus({ commit }, status) {
            commit("changeIsCaseAddModalActionConfirmationExitShowStatus", status);
        },
    },
    mutations: {
        changeCaseAddModalShowBtnStatus(state, status) {
            state.caseAddModalShowBtnStatus = status;
        },
        changeCaseByIdTabsModalBtnStatus(state) {
            state.caseByIdTabsModalBtnStatus = !state.caseByIdTabsModalBtnStatus;
        },
        changeCaseIntermediateActionModalBtnStatus(state) {
            state.caseIntermediateActionModalBtnStatus = !state.caseIntermediateActionModalBtnStatus;
        },
        changeModalCasesJournalPrintBtnStatus(state, status) {
            state.modalCasesJournalPrintBtnStatus = status;
        },
        changeModalCasesProtocolPrintBtnStatus(state, status) {
            state.modalCasesProtocolPrintBtnStatus = status;
        },
        changeModalCasesDirectionPrintBtnStatus(state, status) {
            state.modalCasesDirectionPrintBtnStatus = status;
        },
        changeModalCaseByIdTabMacroDescriptionPhotoBtnStatus(state, status) {
            state.modalCaseByIdTabMacroDescriptionPhotoBtnStatus = status;
        },
        changeModalCaseByIdProtocolPrintBtnStatus(state, status) {
            state.modalCaseByIdProtocolPrintBtnStatus = status;
        },
        changeIsCaseAddModalActionConfirmationExitShowStatus(state, status) {
            state.isCaseAddModalActionConfirmationExitShowStatus = status;
        },
    },
    getters: {
        getCaseAddModalShowBtn(state) {
            return state.caseAddModalShowBtnStatus;
        },
        getCaseByIdTabsModalBtnStatus(state) {
            return state.caseByIdTabsModalBtnStatus;
        },
        getCaseIntermediateActionModalBtnStatus(state) {
            return state.caseIntermediateActionModalBtnStatus;
        },
        getModalCasesJournalPrintBtnStatus(state) {
            return state.modalCasesJournalPrintBtnStatus;
        },
        getModalCasesProtocolPrintBtnStatus(state) {
            return state.modalCasesProtocolPrintBtnStatus;
        },
        getModalCasesDirectionPrintBtnStatus(state) {
            return state.modalCasesDirectionPrintBtnStatus;
        },
        getModalCaseByIdTabMacroDescriptionPhotoBtnStatus(state) {
            return state.modalCaseByIdTabMacroDescriptionPhotoBtnStatus;
        },
        getModalCaseByIdProtocolPrintBtnStatus(state) {
            return state.modalCaseByIdProtocolPrintBtnStatus;
        },
        getIsCaseAddModalActionConfirmationExitShowStatus(state) {
            return state.isCaseAddModalActionConfirmationExitShowStatus;
        },
    }
}
