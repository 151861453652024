import { checking } from "@/mixins";

export const fullName = {
    mixins: [checking],
    computed: {
        getFullName() {
            return (objectData) => {
                const surname = this.checkingStringData(objectData?.last_name);
                const name = this.checkingStringData(objectData?.first_name);
                const patronymic = this.checkingStringData(objectData?.patronymic);

                return surname + ' ' + name + ' ' + patronymic;
            }
        },
        fullPatientName() {
            return (patientObjectData) => {
                const surname = this.checkingStringData(patientObjectData?.surname);
                const name = this.checkingStringData(patientObjectData?.name);
                const patronymic = this.checkingStringData(patientObjectData?.patronymic);

                return surname + ' ' + name + ' ' + patronymic;
            }
        },
        fullDoctorName() {
            return (doctorObjectData) => {
                const surname = this.checkingStringData(doctorObjectData?.request_organization?.request_organization_surname);
                const name = this.checkingStringData(doctorObjectData?.request_organization?.request_organization_name);
                const patronymic = this.checkingStringData(doctorObjectData?.request_organization?.request_organization_patronymic);

                return surname + ' ' + name + ' ' + patronymic;
            }
        },
        getFullDiseaseDoctorName() {
            return (doctorObjectData) => {
                const surname = this.checkingStringData(doctorObjectData?.disease?.disease_hospital_doctor_lastname);
                const name = this.checkingStringData(doctorObjectData?.disease?.disease_hospital_doctor_name);
                const patronymic = this.checkingStringData(doctorObjectData?.disease?.disease_hospital_doctor_patronymic);

                return surname + ' ' + name + ' ' + patronymic;
            }
        },
        getFullAutopsyExaminationDoctorName() {
            return (doctorObjectData) => {
                const surname = this.checkingStringData(doctorObjectData?.autopsy_examination?.autopsy_examination_refusal_resp_lastname);
                const name = this.checkingStringData(doctorObjectData?.autopsy_examination?.autopsy_examination_refusal_resp_firstname);
                const patronymic = this.checkingStringData(doctorObjectData?.autopsy_examination?.autopsy_examination_refusal_resp_patronymic);

                return surname + ' ' + name + ' ' + patronymic;
            }
        },
        fullUserName() {
            return (userObjectData) => {
                const surname = this.checkingStringData(userObjectData?.last_name);
                const name = this.checkingStringData(userObjectData?.first_name);
                const patronymic = this.checkingStringData(userObjectData?.patronymic);

                return surname + ' ' + name + ' ' + patronymic;
            }
        },
        getSurnameInitials() {
            return (name) => {
                if (name === undefined || name === null) {
                    return;
                }

                const fullName = name;
                const arrFullName = fullName.split(' ');

                if (arrFullName[0] === "") {
                    return;
                }
                const surname = arrFullName[0];
                const nameInitials = ((arrFullName[1] === null) || (arrFullName[1] === undefined) || (arrFullName[1] === 'null')) ? "" : arrFullName[1][0];
                const patronymicInitials = ((arrFullName[2] === null) || (arrFullName[2] === undefined) || (arrFullName[2] === 'null')) ? "" : arrFullName[2][0];
                const checkSurname = ((surname === null) || (surname === undefined) || (surname === 'null')) ? "" : surname;
                const checkNameInitials = ((nameInitials === null) || (nameInitials === undefined) || (nameInitials === 'null')) ? "" : nameInitials;
                const checkPatronymicInitials = ((patronymicInitials === null) || (patronymicInitials === undefined) || (patronymicInitials === 'null')) ? "" : patronymicInitials;

                return checkSurname + " " + checkNameInitials + ". " + checkPatronymicInitials + ".";
            }
        }
    }
};