export default {
    state: {
        mortemCaseMedicalCardNumber: "",
        mortemPersonName: "",
        mortemPersonSurname: "",
        mortemPersonPatronymic: "",
        mortemPersonDateOfBirth: "",
        mortemPersonDateOfDeath: "",
        mortemPersonsGender: "",
        mortemPersonArea: "",
        mortemPersonPostcode: "",
        mortemPersonRegion: "",
        mortemPersonLocation: "",
        mortemPersonStreet: "",
        mortemPersonHouseNumber: "",
        mortemPersonBuildingNumber: "",
        mortemPersonApartmentNumber: "",
        mortemPersonFamilyStatus: "",
        mortemPersonEducation: "",
        mortemPersonEmployment: "",
        mortemMotherGestationalAge: "",
        mortemMotherChildbirth: "",
        mortemMotherChildbirthNumber: "",
        mortemCaseMedicalCardNumberInputFocus: false,
        mortemPersonalInfoBtnStatus: false
    },
    actions: {
        updateMortemCasePersonField({ commit }, payload) {
            commit("changeMortemCasePersonField", payload)
        },
        updateMortemCaseMedicalCardNumberInputFocus({ commit }, mortemCaseMedicalCardNumberInputFocus) {
            commit("changeMortemCaseMedicalCardNumberInputFocus", mortemCaseMedicalCardNumberInputFocus);
        },
        updateMortemPersonalInfoBtnStatus({ commit }, mortemPersonalInfoBtnStatus) {
            commit("changeMortemPersonalInfoBtnStatus", mortemPersonalInfoBtnStatus);
        },
    },
    mutations: {
        changeMortemCasePersonField(state, { field, value }) {
            state[field] = value;
        },
        changeMortemCaseMedicalCardNumberInputFocus(state, mortemCaseMedicalCardNumberInputFocus) {
            state.mortemCaseMedicalCardNumberInputFocus = mortemCaseMedicalCardNumberInputFocus;
        },
        changeMortemPersonalInfoBtnStatus(state, mortemPersonalInfoBtnStatus) {
            state.mortemPersonalInfoBtnStatus = mortemPersonalInfoBtnStatus;
        },
    },
    getters: {
        getMortemCaseMedicalCardNumber(state) {
            return state.mortemCaseMedicalCardNumber;
        },
        getMortemPersonName(state) {
            return state.mortemPersonName;
        },
        getMortemPersonSurname(state) {
            return state.mortemPersonSurname;
        },
        getMortemPersonPatronymic(state) {
            return state.mortemPersonPatronymic;
        },
        getMortemPersonDateOfBirth(state) {
            return state.mortemPersonDateOfBirth;
        },
        getMortemPersonDateOfDeath(state) {
            return state.mortemPersonDateOfDeath;
        },
        getMortemPersonsGender(state) {
            return state.mortemPersonsGender;
        },
        getMortemPersonArea(state) {
            return state.mortemPersonArea;
        },
        getMortemPersonPostcode(state) {
            return state.mortemPersonPostcode;
        },
        getMortemPersonRegion(state) {
            return state.mortemPersonRegion;
        },
        getMortemPersonLocation(state) {
            return state.mortemPersonLocation;
        },
        getMortemPersonStreet(state) {
            return state.mortemPersonStreet;
        },
        getMortemPersonHouseNumber(state) {
            return state.mortemPersonHouseNumber;
        },
        getMortemPersonBuildingNumber(state) {
            return state.mortemPersonBuildingNumber;
        },
        getMortemPersonApartmentNumber(state) {
            return state.mortemPersonApartmentNumber;
        },
        getMortemPersonFamilyStatus(state) {
            return state.mortemPersonFamilyStatus;
        },
        getMortemPersonEducation(state) {
            return state.mortemPersonEducation;
        },
        getMortemPersonEmployment(state) {
            return state.mortemPersonEmployment;
        },
        getMortemMotherGestationalAge(state) {
            return state.mortemMotherGestationalAge;
        },
        getMortemMotherChildbirth(state) {
            return state.mortemMotherChildbirth;
        },
        getMortemMotherChildbirthNumber(state) {
            return state.mortemMotherChildbirthNumber;
        },
        getMortemCaseMedicalCardNumberInputFocus(state) {
            return state.mortemCaseMedicalCardNumberInputFocus;
        },
        getMortemPersonalInfoBtnStatus(state) {
            return state.mortemPersonalInfoBtnStatus
        }
    }
}