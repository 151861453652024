export const referenceGenderFields = {
    data() {
        return {
            referenceGenderTableDescriptionData: [
                { field: "active", header: "Активна", columnWidth: "75px"},
                { field: "sex", header: "Название", columnWidth: "400px"},
                { field: "comment", header: "Комментарий", columnWidth: "auto"},
            ],
        }
    },
}