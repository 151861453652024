export default {
    state: {
        mortemAutopsyAdditionalResearchBtnStatus: false,
        mortemAutopsyAdditionalResearchDescription: null,
    },
    actions: {
        updateMortemAutopsyAdditionalResearchBtnStatus({ commit }, condition) {
            commit("changeMortemAutopsyAdditionalResearchBtnStatus", condition);
        },
        updateAutopsyAdditionalResearchField({ commit }, payload) {
            commit('changeAutopsyAdditionalResearchField', payload);
        }
    },
    mutations: {
        changeMortemAutopsyAdditionalResearchBtnStatus(state, condition) {
            state.mortemAutopsyAdditionalResearchBtnStatus = condition;
        },
        changeAutopsyAdditionalResearchField(state, { key, value }) {
            state[key] = value;
        }
    },
    getters: {
        getMortemAutopsyAdditionalResearchBtnStatus(state) {
            return state.mortemAutopsyAdditionalResearchBtnStatus;
        },
        getAutopsyAdditionalResearchField: (state) => (key) => {
            return state[key];
        }
    }
}