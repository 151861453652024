import { api, lifetimeCasesTabsApi } from "@/api";

export default {
    state: {
        lifetimeCasesPatientIpa: null,
        lifetimeCasesPatientDate: null,
        lifetimeCasesPatientDataIpaSearch: {},
        lifetimeCasesPatientIpaInputFocus: false,
        isLifetimeCasesPatientDataIpaSearchStatus: false,
        isLifetimeCasesPatientDataIpaSearchNonFound: false,
        isLifetimeCasesPatientDataIpaSearchSuccess: false,
        isLifetimeCasesPatientDataIpaSearchError: false,
        lifetimeCasesPatientFieldMessage: "",
        lifetimeCasesPatientData: {},
        lifetimeCasesDefaultPatientData: {}
    },
    actions: {
        async getLifetimeCasesPatientDataSearchByIPA({ dispatch }, patientIpa) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                if (patientIpa === null || patientIpa === undefined) {
                    return;
                }
                return dispatch("fetchLifetimeCasesPatientDataSearchByIPA", [patientIpa, token]);
            });
        },
        async fetchLifetimeCasesPatientDataSearchByIPA({ dispatch, commit }, [patientIpa, token]) {
            try {
                const page = 1;
                const pageSize = 1;
                const patientDataIpaSearch = await api.getPatientByIPA(page, pageSize, token, patientIpa);
                dispatch("updateFieldMessageShowStatus", patientDataIpaSearch.results[0]);
                if (patientDataIpaSearch === undefined || patientDataIpaSearch.results?.length === 0) {
                    commit("changeIsLifetimeCasesPatientDataIpaSearchNonFound", true);
                    return;
                }
                commit("changeLifetimeCasesPatientDataIpaSearch", patientDataIpaSearch.results[0]);
                dispatch("updateLifetimeBiopsyStudies", [[], "addFromPatient"]);
            } catch (e) {
                console.error(e);
            }
        },
        async fetchLifetimeCasePatientDataById({ dispatch, rootGetters }, [token, updatedPatientData]) {
            try {
                const patientData = await lifetimeCasesTabsApi.putLifetimeCasePatientById(rootGetters.getLifetimeCaseId, updatedPatientData, token);
                if (typeof patientData === "number") {
                    return;
                }
                if (patientData) {
                    dispatch("addToastMessage", {code: 200, message: null});
                    dispatch("fetchLifetimeCaseById", [token, rootGetters.getLifetimeCaseId]);
                }
            } catch (e) {
                console.error(e);
            }
        },
        async updateLifetimeCasePatientDataById({ dispatch, state }) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                dispatch("fetchLifetimeCasePatientDataById", [token, state.lifetimeCasesPatientData]);
            });
        },
        updateLifetimeCasesPatientDate({ commit }, lifetimeCasesPatientDate) {
            commit("changeLifetimeCasesPatientDate", lifetimeCasesPatientDate);
        },
        updateLifetimeCasesPatientIpaInputFocus({ commit }, lifetimeCasesPatientIpaInputFocus) {
            commit("changeLifetimeCasesPatientIpaInputFocus", lifetimeCasesPatientIpaInputFocus);
        },
        updateLifetimeIsPatientDataIpaSearchStatus({ commit }, isPatientDataIpaSearchStatus) {
            commit("changeIsLifetimeCasesPatientDataIpaSearchStatus", isPatientDataIpaSearchStatus);
        },
        updateLifetimeIsPatientDataIpaSearchNonFound({ commit }, isPatientDataIpaSearchNonFound) {
            commit("changeIsLifetimeCasesPatientDataIpaSearchNonFound", isPatientDataIpaSearchNonFound);
        },
        updateLifetimeIsPatientDataIpaSearchSuccess({ commit }, isPatientDataIpaSearchSuccess) {
            commit("changeIsLifetimeCasesPatientDataIpaSearchSuccess", isPatientDataIpaSearchSuccess);
        },
        updateLifetimeIsPatientDataIpaSearchError({ commit }, isPatientDataIpaSearchError) {
            commit("changeIsLifetimeCasesPatientDataIpaSearchError", isPatientDataIpaSearchError);
        },
        updateLifetimeCasesPatientIpa({ commit }, lifetimeCasesPatientIpa) {
            commit("changeLifetimeCasesPatientIpa", lifetimeCasesPatientIpa);
        },
        updateFieldMessageShowStatus({ dispatch }, code) {
            switch (code) {
                case 404:
                    dispatch("closeAllFieldMessageShowStatus");
                    dispatch("updateLifetimeIsPatientDataIpaSearchStatus", true);
                    dispatch("updateLifetimeIsPatientDataIpaSearchNonFound", true);
                    return;
                case code >= 500:
                    dispatch("closeAllFieldMessageShowStatus");
                    dispatch("updateLifetimeIsPatientDataIpaSearchStatus", true);
                    dispatch("updateLifetimeIsPatientDataIpaSearchError", true);
                    return;
                default:
                    dispatch("closeAllFieldMessageShowStatus");
                    if (typeof code === "object" || code < 300) {
                        dispatch("updateLifetimeIsPatientDataIpaSearchStatus", true);
                        dispatch("updateLifetimeIsPatientDataIpaSearchSuccess", true);
                    }
                    return;
            }
        },
        closeAllFieldMessageShowStatus({ dispatch }) {
            dispatch("updateLifetimeIsPatientDataIpaSearchStatus", false);
            dispatch("updateLifetimeIsPatientDataIpaSearchNonFound", false);
            dispatch("updateLifetimeIsPatientDataIpaSearchSuccess", false);
            dispatch("updateLifetimeIsPatientDataIpaSearchError", false);
        },
        clearPatientStore({ dispatch, commit }) {
            commit("changeLifetimeCasesPatientDataIpaSearch", {});
            commit("changeLifetimeCasesPatientData", {});
            dispatch("updateLifetimeCasesPatientDate", null);
            dispatch("closeAllFieldMessageShowStatus");
            dispatch("updateLifetimeCasesPatientIpaInputFocus", false);
        },
        updateLifetimeCasesPatientData({ commit }, lifetimeCasesPatientData) {
            commit("changeLifetimeCasesPatientData", lifetimeCasesPatientData);
        },
        updateLifetimeCasesPatientDataIpaSearch({ commit }, patientDataIpaSearch) {
            commit("changeLifetimeCasesPatientDataIpaSearch", patientDataIpaSearch);
        }
    },
    mutations: {
        changeLifetimeCasesPatientIpa(state, lifetimeCasesPatientIpa) {
            state.lifetimeCasesPatientIpa = lifetimeCasesPatientIpa;
        },
        changeLifetimeCasesPatientDate(state, lifetimeCasesPatientDate) {
            state.lifetimeCasesPatientDate = lifetimeCasesPatientDate;
        },
        changeLifetimeCasesPatientDataIpaSearch(state, patientDataIpaSearch) {
            state.lifetimeCasesPatientDataIpaSearch = patientDataIpaSearch;
        },
        changeIsLifetimeCasesPatientDataIpaSearchStatus(state, isPatientDataIpaSearchStatus) {
            state.isLifetimeCasesPatientDataIpaSearchStatus = isPatientDataIpaSearchStatus;
        },
        changeIsLifetimeCasesPatientDataIpaSearchNonFound(state, isPatientDataIpaSearchNonFound) {
            state.isLifetimeCasesPatientDataIpaSearchNonFound = isPatientDataIpaSearchNonFound;
        },
        changeIsLifetimeCasesPatientDataIpaSearchSuccess(state, isPatientDataIpaSearchSuccess) {
            state.isLifetimeCasesPatientDataIpaSearchSuccess = isPatientDataIpaSearchSuccess;
        },
        changeIsLifetimeCasesPatientDataIpaSearchError(state, isPatientDataIpaSearchError) {
            state.isLifetimeCasesPatientDataIpaSearchError = isPatientDataIpaSearchError;
        },
        changeLifetimeCasesPatientIpaInputFocus(state, lifetimeCasesPatientIpaInputFocus) {
            state.lifetimeCasesPatientIpaInputFocus = lifetimeCasesPatientIpaInputFocus;
        },
        changeLifetimeCasesPatientData(state, lifetimeCasesPatientData) {
            lifetimeCasesPatientData.birthday = state.lifetimeCasesPatientDate;
            state.lifetimeCasesPatientData = lifetimeCasesPatientData;
        }
    },
    getters: {
        getLifetimeCasesPatientDate(state) {
            return state.lifetimeCasesPatientDate;
        },
        getLifetimeCasesPatientIpa(state) {
            return state.lifetimeCasesPatientIpa;
        },
        getLifetimeCasesPatientDataIpaSearch(state) {
            return state.lifetimeCasesPatientDataIpaSearch;
        },
        getIsLifetimeCasesPatientDataIpaSearchStatus(state) {
            return state.isLifetimeCasesPatientDataIpaSearchStatus;
        },
        getIsLifetimeCasesPatientDataIpaSearchNonFound(state) {
            return state.isLifetimeCasesPatientDataIpaSearchNonFound;
        },
        getIsLifetimeCasesPatientDataIpaSearchSuccess(state) {
            return state.isLifetimeCasesPatientDataIpaSearchSuccess;
        },
        getIsLifetimeCasesPatientDataIpaSearchError(state) {
            return state.isLifetimeCasesPatientDataIpaSearchError;
        },
        getLifetimeCasesPatientIpaInputFocus(state) {
            return state.lifetimeCasesPatientIpaInputFocus;
        },
        getLifetimeCasesPatientData(state) {
            return state.lifetimeCasesPatientData;
        },
        getLifetimeCasesDefaultPatientData(state) {
            return state.lifetimeCasesDefaultPatientData;
        },
    }
}