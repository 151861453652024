export default {
    state: {
        mortemAutopsyBodyExaminationBtnStatus: false,
        mortemAutopsyBodyExaminationHeight: null,
        mortemAutopsyBodyExaminationWeight: null,
        mortemCaseBodyType: null,
        mortemCaseBodyTypeList: [
            {
                id: 1,
                title: "Тип 1"
            },
            {
                id: 2,
                title: "Тип 2"
            }
        ],
        mortemCaseNutritionStatus: null,
        mortemCaseNutritionStatusList: [
            {
                id: 1,
                title: "Статус 1"
            },
            {
                id: 2,
                title: "Статус 2"
            }
        ],
        mortemAutopsyBodyExaminationScalp: null,
        mortemCaseMuscularSkeletalCondition: null,
        mortemAutopsyBodyExaminationNeckSkin: null,
        mortemAutopsyBodyExaminationBodySkin: null,
        mortemAutopsyBodyExaminationBreastSkin: null,
        mortemAutopsyBodyExaminationExtremitiesSkin: null,
        mortemCaseCadavericSpots: null,
        mortemAutopsyBodyExaminationRigorMortis: null,
        mortemAutopsyBodyExaminationNaturalHolesState: null,
        mortemAutopsyBodyExaminationExternalGenitalia: null,
        mortemAutopsyBodyExaminationCutLength: null,
        mortemAutopsyBodyExaminationCutNature: null,
        mortemAutopsyBodyExaminationSeams: null,
        mortemAutopsyBodyExaminationWoundDischarge: null,
        mortemAutopsyBodyExaminationInjectionsTraces: null,
    },
    actions: {
        updateMortemAutopsyBodyExaminationBtnStatus({ commit }, condition) {
            commit("changeMortemAutopsyBodyExaminationBtnStatus", condition);
        },
        updateAutopsyBodyExaminationField({ commit }, payload) {
            commit('changeAutopsyBodyExaminationField', payload);
        }
    },
    mutations: {
        changeMortemAutopsyBodyExaminationBtnStatus(state, condition) {
            state.mortemAutopsyBodyExaminationBtnStatus = condition;
        },
        changeAutopsyBodyExaminationField(state, { key, value }) {
            state[key] = value;
        }
    },
    getters: {
        getMortemAutopsyBodyExaminationBtnStatus(state) {
            return state.mortemAutopsyBodyExaminationBtnStatus;
        },
        getAutopsyBodyExaminationtField: (state) => (key) => {
            return state[key];
        }
    }
}