export const lifetimePreviousBiopsyStudiesFields = {
    data() {
        return {
            lifetimePreviousBiopsyStudiesFormDescriptionFields: {
                register_number: ["Регистрационный номер:", ["row__small", "register__number_row"]],
                // completion_date: ["Дата исследования:", ["row__small", "completion__date_row"]],
            },
            lifetimePreviousBiopsyStudiesFormOrganizationTitleField: {
                name: "organization_title",
                value: "Наименование медицинской организации:",
                // rowClasses: {relative: this.getIcdCodeReferenceListShowStatus},
                // fieldClasses: []
            },
            lifetimePreviousBiopsyStudiesFormDiseaseReportField: {
                name: "disease_report",
                value: "Заключение:",
                fieldClasses: ["form__control_textarea", {"form__control_textarea--form": this.getFormCaseByIdTabDiseaseBtnStatus}]
            }
        }
    },
}