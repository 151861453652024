export default {
    state: {
        mortemPerinatalPersonAccompanyingDocument: "",
        mortemPerinatalPersonAccompanyingDocumentNumber: "",
        mortemPerinatalPersonSurname: "",
        mortemPerinatalPersonName: "",
        mortemPersonPerinatalPatronymic: "",
        mortemPerinatalPersonsGender: "",
        mortemPersonPerinatalWeight: "",
        mortemPersonPerinatalLength: "",
        mortemPersonPerinatalWasBorn: "",
        mortemPerinatalPersonDateOfBirth: "",
        mortemPerinatalPersonDateOfDeath: "",
        mortemPerinatalPersonArea: "",
        mortemPerinatalPersonPostcode: "",
        mortemPerinatalPersonRegion: "",
        mortemPerinatalPersonLocation: "",
        mortemPerinatalPersonStreet: "",
        mortemPerinatalPersonHouseNumber: "",
        mortemPerinatalPersonBuildingNumber: "",
        mortemPerinatalPersonApartmentNumber: "",
        mortemPerinatalPersonAccompanyingDocumentNumberInputFocus: false,
        mortemPerinatalPersonalInfoBtnStatus: false
    },
    actions: {
        updateMortemCasePerinatalPersonField({ commit }, payload) {
            commit("changeMortemCasePerinatalPersonField", payload)
        },
        updateMortemPerinatalPersonAccompanyingDocumentNumberInputFocus({ commit }, mortemPerinatalPersonAccompanyingDocumentNumberInputFocus) {
            commit("changeMortemPerinatalPersonAccompanyingDocumentNumberInputFocus", mortemPerinatalPersonAccompanyingDocumentNumberInputFocus);
        },
        updateMortemPerinatalPersonalInfoBtnStatus({ commit }, mortemPerinatalPersonalInfoBtnStatus) {
            commit("changeMortemPerinatalPersonalInfoBtnStatus", mortemPerinatalPersonalInfoBtnStatus);
        },
    },
    mutations: {
        changeMortemCasePerinatalPersonField(state, { field, value }) {
            state[field] = value;
        },
        changeMortemPerinatalPersonAccompanyingDocumentNumberInputFocus(state, mortemPerinatalPersonAccompanyingDocumentNumberInputFocus) {
            state.mortemPerinatalPersonAccompanyingDocumentNumberInputFocus = mortemPerinatalPersonAccompanyingDocumentNumberInputFocus;
        },
        changeMortemPerinatalPersonalInfoBtnStatus(state, mortemPerinatalPersonalInfoBtnStatus) {
            state.mortemPerinatalPersonalInfoBtnStatus = mortemPerinatalPersonalInfoBtnStatus;
        },
    },
    getters: {
        getMortemPerinatalPersonAccompanyingDocument(state) {
            return state.mortemPerinatalPersonAccompanyingDocument;
        },
        getMortemPerinatalPersonAccompanyingDocumentNumber(state) {
            return state.mortemPerinatalPersonAccompanyingDocumentNumber;
        },
        getMortemPerinatalPersonSurname(state) {
            return state.mortemPerinatalPersonSurname;
        },
        getMortemPerinatalPersonName(state) {
            return state.mortemPerinatalPersonName;
        },
        getMortemPersonPerinatalPatronymic(state) {
            return state.mortemPersonPerinatalPatronymic;
        },
        getMortemPersonPerinatalWeight(state) {
            return state.mortemPersonPerinatalWeight;
        },
        getMortemPersonPerinatalLength(state) {
            return state.mortemPersonPerinatalLength;
        },
        getMortemPersonPerinatalWasBorn(state) {
            return state.mortemPersonPerinatalWasBorn;
        },
        getMortemPerinatalPersonDateOfBirth(state) {
            return state.mortemPerinatalPersonDateOfBirth;
        },
        getMortemPerinatalPersonDateOfDeath(state) {
            return state.mortemPerinatalPersonDateOfDeath;
        },
        getMortemPerinatalPersonArea(state) {
            return state.mortemPerinatalPersonArea;
        },
        getMortemPerinatalPersonPostcode(state) {
            return state.mortemPerinatalPersonPostcode;
        },
        getMortemPerinatalPersonRegion(state) {
            return state.mortemPerinatalPersonRegion;
        },
        getMortemPerinatalPersonLocation(state) {
            return state.mortemPerinatalPersonLocation;
        },
        getMortemPerinatalPersonStreet(state) {
            return state.mortemPerinatalPersonStreet;
        },
        getMortemPerinatalPersonHouseNumber(state) {
            return state.mortemPerinatalPersonHouseNumber;
        },
        getMortemPerinatalPersonBuildingNumber(state) {
            return state.mortemPerinatalPersonBuildingNumber;
        },
        getMortemPerinatalPersonApartmentNumber(state) {
            return state.mortemPerinatalPersonApartmentNumber;
        },
        getMortemPerinatalPersonsGender(state) {
            return state.mortemPerinatalPersonsGender;
        },
        getMortemPerinatalPersonAccompanyingDocumentNumberInputFocus(state) {
            return state.mortemPerinatalPersonAccompanyingDocumentNumberInputFocus;
        },
        getMortemPerinatalPersonalInfoBtnStatus(state) {
            return state.mortemPerinatalPersonalInfoBtnStatus;
        },
    }
}