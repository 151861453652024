export const lifetimeConclusionFields = {
    data() {
        return {
            lifetimeConclusionDescriptionFields: {
                report_microscopic: "Микроскопическое описание:",
                report_conclusion: "Заключение:",
                report_icd_code: "Код по МКБ:",
                report_comment: "Комментарии к заключению и рекомендации:",
            },
            lifetimeConclusionWhoPerformedFields: {
                report_laboratory_assistant: ["Фельдшер-лаборант:", "Фельдшер-лаборант (ФИО):"],
                report_pathologist: ["Врач-патологоанатом:", "Врач-патологоанатом (ФИО):"],
                report_consultant: ["Врач-специалист (консультирование):", "Врач-специалист (консультирование) (ФИО):"],
            },
            lifetimeConclusionWhoPerformedResearchHeader: "Прижизненное патолого-анатомическое исследование выполнили:"
        }
    },
}