import { api } from "@/api";

export default {
    state: {
        micropreparationColoringList: [],
    },
    actions: {
        async fetchMicropraparationColoringList({ dispatch, commit }, queryParams) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const coloringList = await api.getSettingsColoringList(queryParams, token);
                    commit("updateMicropreparationColoringList", coloringList.results);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    mutations: {
        updateMicropreparationColoringList(state, coloringList) {
            state.micropreparationColoringList = coloringList;
        },
    },
    getters: {
        getMicropraparationColoringList(state) {
            return state.micropreparationColoringList;
        },
    }
}