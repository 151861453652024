export default {
    state: {
        modalUniversalShowStatus: false,
        showModalStatus: false,
        isModalContentConfirmActionCloseWithoutSave: false,
        isModalContentConfirmActionChangeCheckboxStatus: false,
        isModalContentConfirmActionDeleteDefect: false,
        showLifetimeCaseCheckModalStatus: false
    },

    actions: {
        updateModalUniversalShowStatus({ commit }, status) {
            commit("changeModalUniversalShowStatus", status);
        },
        updateShowModalStatus({ commit }, status) {
            commit("changeShowModalStatus", status);
        },
        updateIsModalContentConfirmActionCloseWithoutSave({ commit }, status) {
            commit("changeIsModalContentConfirmActionCloseWithoutSave", status);
        },
        updateIsModalContentConfirmActionChangeCheckboxStatus({ commit }, status) {
            commit("changeIsModalContentConfirmActionChangeCheckboxStatus", status);
        },
        updateShowLifetimeCaseCheckModalStatus({ commit }, status) {
            commit("changeShowLifetimeCaseCheckModalStatus", status);
        }
        // updateIsModalContentConfirmActionDeleteDefect({ commit }, status) {
        //     commit("changeIsModalContentConfirmActionDeleteDefect", status);
        // },
    },

    mutations: {
        changeModalUniversalShowStatus(state, status) {
            state.modalUniversalShowStatus = status;
        },
        changeShowModalStatus(state, status) {
            state.showModalStatus = status;
        },
        changeIsModalContentConfirmActionCloseWithoutSave(state, status) {
            state.isModalContentConfirmActionCloseWithoutSave = status;
        },
        changeIsModalContentConfirmActionChangeCheckboxStatus(state, status) {
            state.isModalContentConfirmActionChangeCheckboxStatus = status;
        },
        changeShowLifetimeCaseCheckModalStatus(state, status) {
            state.showLifetimeCaseCheckModalStatus = status;
        }
        // changeIsModalContentConfirmActionDeleteDefect(state, status) {
        //     state.isModalContentConfirmActionDeleteDefect = status;
        // },
    },

    getters: {
        getModalUniversalShowStatus(state) {
            return state.modalUniversalShowStatus;
        },
        getShowModalStatus(state) {
            return state.showModalStatus;
        },
        getIsModalContentConfirmActionCloseWithoutSave(state) {
            return state.isModalContentConfirmActionCloseWithoutSave;
        },
        getIsModalContentConfirmActionChangeActiveStatus(state) {
            return state.isModalContentConfirmActionChangeCheckboxStatus;
        },
        getShowLifetimeCaseCheckModalStatus(state) {
            return state.showLifetimeCaseCheckModalStatus;
        },
        // getIsModalContentConfirmActionDeleteDefect(state) {
        //     return state.isModalContentConfirmActionDeleteDefect;
        // }
    }
};