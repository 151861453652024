import { api } from "@/api";


export default {
    state: {
        settingsReferenceMedicalServicesPage: 1,
        settingsReferenceMedicalServicesPageSize: 50,
        settingsReferenceMedicalServices: {},
        settingsReferenceMedicalServicesTotalPages: null,
        isSettingsReferenceMedicalServicesLoading: false,
    },
    actions: {
        async fetchSettingsReferenceMedicalServices({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceMedicalServicesLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const services = await api.getReferenceMedicalServices(queryParams, token);
                    commit("changeSettingsReferenceMedicalServices", services.results);
                    commit("changeSettingsReferenceMedicalServicesTotalPages", services.total_pages);
                    commit("updateSettingsReferenceMedicalServicesLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferenceMedicalServicesLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async fetchMoreSettingsReferenceMedicalServices({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceMedicalServicesLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const services = await api.getReferenceMedicalServices(queryParams, token);
                    commit("updateMoreSettingsReferenceMedicalServices", services.results);
                    commit("updateSettingsReferenceMedicalServicesLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferenceMedicalServicesLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("updateSettingsReferenceMedicalServicesLoader", false);
            }

        },
        async addSettingsReferenceMedicalServices({ dispatch, commit }, medicalService ) {
            const token = await dispatch("getAccessToken");
            if (token) {
                const serviceData = await api.postReferenceMedicalServices(medicalService, token);
                if (typeof serviceData === "object") {
                    commit("addNewSettingsReferenceMedicalServices", serviceData);
                }
            } else {
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                return;
            }
        },
        async editSettingsReferenceMedicalServices({ dispatch, commit }, medicalService ) {
            const token = await dispatch("getAccessToken");
            if (token) {
                const serviceData = await api.patchReferenceMedicalServices(medicalService, token);
                if (typeof serviceData === "object") {
                    commit("updateSettingsReferenceMedicalServices", serviceData);
                }
            } else {
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                return;
            }
        },
    },
    mutations: {
        changeSettingsReferenceMedicalServices(state, settingsReferenceMedicalServices) {
            state.settingsReferenceMedicalServices = settingsReferenceMedicalServices;
        },
        updateMoreSettingsReferenceMedicalServices(state, settingsReferenceMedicalServices) {
            state.settingsReferenceMedicalServices = [...state.settingsReferenceMedicalServices, ...settingsReferenceMedicalServices];
        },
        changeSettingsReferenceMedicalServicesTotalPages(state, settingsReferenceMedicalServicesTotalPages) {
            state.settingsReferenceMedicalServicesTotalPages = settingsReferenceMedicalServicesTotalPages;
        },
        updateSettingsReferenceMedicalServicesLoader(state, condition) {
            state.isSettingsReferenceMedicalServicesLoading = condition;
        },
        updateSettingsReferenceMedicalServices(state, medicalService) {
            const index = state.settingsReferenceMedicalServices.findIndex(item => item.id === medicalService.id);
            if (index !== -1) {
                const updatedMedicalService = { ...medicalService }; // Создаем копию объекта medicalService
                const uniqueId = Object.values(updatedMedicalService).join('_'); // Создаем уникальный идентификатор на основе значений объекта
                updatedMedicalService.uniqueId = uniqueId; // Добавляем новое поле uniqueId к объекту
                state.settingsReferenceMedicalServices.splice(index, 1, updatedMedicalService); // Заменяем объект в массиве
            }
        },
        addNewSettingsReferenceMedicalServices(state, medicalService) {
            state.settingsReferenceMedicalServices.unshift(medicalService);
        },
    },
    getters: {
        getSettingsReferenceMedicalServices(state) {
            return state.settingsReferenceMedicalServices;
        },
        getSettingsReferenceMedicalServicesTotalPages(state) {
            return state.settingsReferenceMedicalServicesTotalPages;
        },
        getSettingsReferenceMedicalServicesLoaderStatus(state) {
            return state.isSettingsReferenceMedicalServicesLoading;
        },
    }
}