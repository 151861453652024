import { api } from "@/api";


export default {
    state: {
        dictionariesNsiList: [],
        dictionariesNsiDepartmentsList: [],
        dictionariesNsiOrganizationsList: [],
        dictionariesNsiPositionsList: [],
        isDictionariesNsiLoading: false,
        dictionariesNsiDepartmentsListTotalPages: null,
    },
    actions: {
        async fetchDictionariesNsiList({ dispatch, commit }, queryParams) {
            commit("upadeDictionariesNsiListLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const nsi = await api.getDictionariesNsi(queryParams, token);
                    commit("changeDictionariesNsiList", nsi.results);
                    commit("upadeDictionariesNsiListLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("upadeDictionariesNsiListLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
                commit("upadeDictionariesNsiListLoader", false);
            }
        },
        async fetchMoreDictionariesNsi({ dispatch, commit }, queryParams) {
            commit("upadeDictionariesNsiListLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const nsi = await api.getDictionariesNsi(queryParams, token);
                    commit("updateMoreDictionariesNsi", nsi.results);
                    commit("upadeDictionariesNsiListLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("upadeDictionariesNsiListLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("upadeDictionariesNsiListLoader", false);
            }

        },
        clearDictionariesNsiList({ commit }) {
            commit("changeDictionariesNsiList", []);
        },

        async fetchDictionariesNsiDepartmentsList({ dispatch, commit }, queryParams) {
            commit("upadeDictionariesNsiListLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const departments = await api.getDictionariesDepartments(queryParams, token);
                    commit("changeDictionariesNsiDepartmentsList", departments.results);
                    commit("changeDictionariesNsiDepartmentsListTotalPages", departments.total_pages);
                    commit("upadeDictionariesNsiListLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("upadeDictionariesNsiListLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
                commit("upadeDictionariesNsiListLoader", false);
            }
        },
        async fetchMoreDictionariesNsiDepartments({ dispatch, commit }, queryParams) {
            commit("upadeDictionariesNsiListLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const departments = await api.getDictionariesDepartments(queryParams, token);
                    commit("updateMoreDictionariesNsiDepartments", departments.results);
                    commit("upadeDictionariesNsiListLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("upadeDictionariesNsiListLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("upadeDictionariesNsiListLoader", false);
            }

        },
        clearDictionariesNsiDepartmentsList({ commit }) {
            commit("changeDictionariesNsiDepartmentsList", []);
        },

        async fetchDictionariesNsiOrganizationsList({ dispatch, commit }, queryParams) {
            commit("upadeDictionariesNsiListLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const organizations = await api.getDictionariesOrganizations(queryParams, token);
                    console.log(organizations);
                    commit("changeDictionariesNsiOrganizationsList", organizations.results);
                    commit("upadeDictionariesNsiListLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("upadeDictionariesNsiListLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
                commit("upadeDictionariesNsiListLoader", false);
            }
        },
        async fetchMoreDictionariesNsiOrganizations({ dispatch, commit }, queryParams) {
            commit("upadeDictionariesNsiListLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const organizations = await api.getDictionariesOrganizations(queryParams, token);
                    commit("updateMoreDictionariesNsiOrganizations", organizations.results);
                    commit("upadeDictionariesNsiListLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("upadeDictionariesNsiListLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("upadeDictionariesNsiListLoader", false);
            }

        },
        clearDictionariesNsiOrganizationsList({ commit }) {
            commit("changeDictionariesNsiOrganizationsList", []);
        },

        async fetchDictionariesNsiPositionsList({ dispatch, commit }, queryParams) {
            commit("upadeDictionariesNsiListLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const positions = await api.getDictionariesPositions(queryParams, token);
                    commit("changeDictionariesNsiPositionsList", positions.results);
                    commit("upadeDictionariesNsiListLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("upadeDictionariesNsiListLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
                commit("upadeDictionariesNsiListLoader", false);
            }
        },
        async fetchMoreDictionariesNsiPositions({ dispatch, commit }, queryParams) {
            commit("upadeDictionariesNsiListLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const positions = await api.getDictionariesPositions(queryParams, token);
                    commit("updateMoreDictionariesNsiPositions", positions.results);
                    commit("upadeDictionariesNsiListLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("upadeDictionariesNsiListLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("upadeDictionariesNsiListLoader", false);
            }

        },
        clearDictionariesNsiPositionsList({ commit }) {
            commit("changeDictionariesNsiPositionsList", []);
        },
    },
    mutations: {
        changeDictionariesNsiList(state, dictionariesNsiList) {
            state.dictionariesNsiList = dictionariesNsiList;
        },
        updateMoreDictionariesNsi(state, dictionariesNsiList) {
            state.dictionariesNsiList = [...state.dictionariesNsiList, ...dictionariesNsiList];
        },

        changeDictionariesNsiDepartmentsList(state, dictionariesNsiDepartmentsList) {
            state.dictionariesNsiDepartmentsList = dictionariesNsiDepartmentsList;
        },
        updateMoreDictionariesNsiDepartments(state, dictionariesNsiDepartmentsList) {
            state.dictionariesNsiDepartmentsList = [...state.dictionariesNsiDepartmentsList, ...dictionariesNsiDepartmentsList];
        },
        changeDictionariesNsiDepartmentsListTotalPages(state, dictionariesNsiDepartmentsListTotalPages) {
            state.dictionariesNsiDepartmentsListTotalPages = dictionariesNsiDepartmentsListTotalPages;
        },

        changeDictionariesNsiOrganizationsList(state, dictionariesNsiOrganizationsList) {
            state.dictionariesNsiOrganizationsList = dictionariesNsiOrganizationsList;
        },
        updateMoreDictionariesNsiOrganizations(state, dictionariesNsiOrganizationsList) {
            state.dictionariesNsiOrganizationsList = [...state.dictionariesNsiOrganizationsList, ...dictionariesNsiOrganizationsList];
        },

        changeDictionariesNsiPositionsList(state, dictionariesNsiPositionsList) {
            state.dictionariesNsiPositionsList = dictionariesNsiPositionsList;
        },
        updateMoreDictionariesNsiPositions(state, dictionariesNsiPositionsList) {
            state.dictionariesNsiPositionsList = [...state.dictionariesNsiPositionsList, ...dictionariesNsiPositionsList];
        },

        upadeDictionariesNsiListLoader(state, condition) {
            state.isDictionariesNsiLoading = condition;
        },
    },
    getters: {
        getDictionariesNsiList(state) {
            return state.dictionariesNsiList;
        },
        getDictionariesNsiDepartmentsList(state) {
            return state.dictionariesNsiDepartmentsList;
        },
        getDictionariesNsiOrganizationsList(state) {
            return state.dictionariesNsiOrganizationsList;
        },
        getDictionariesNsiPositionsList(state) {
            return state.dictionariesNsiPositionsList;
        },
        getDictionariesNsiLoadingStatus(state) {
            return state.isDictionariesNsiLoading;
        },
        getDictionariesNsiDepartmentsListTotalPages(state) {
            return state.dictionariesNsiDepartmentsListTotalPages;
        },
    }
}