export default {
    state: {
        mortemAutopsyPlacentaBtnStatus: false,
        mortemAutopsyPlacentaSize: null,
        mortemAutopsyPlacentaMass: null,
        mortemAutopsyPlacentaDescription: null,
    },
    actions: {
        updateMortemAutopsyPlacentaBtnStatus({ commit }, condition) {
            commit("changeMortemAutopsyPlacentaBtnStatus", condition);
        },
        updateAutopsyPlacentaField({ commit }, payload) {
            commit('changeAutopsyPlacentaField', payload);
        }
    },
    mutations: {
        changeMortemAutopsyPlacentaBtnStatus(state, condition) {
            state.mortemAutopsyPlacentaBtnStatus = condition;
        },
        changeAutopsyPlacentaField(state, { key, value }) {
            state[key] = value;
        }
    },
    getters: {
        getMortemAutopsyPlacentaBtnStatus(state) {
            return state.mortemAutopsyPlacentaBtnStatus;
        },
        getAutopsyPlacentaField: (state) => (key) => {
            return state[key];
        }
    }
}