export const medicalInsurancePolicy = {
    data() {
        return {
            medicalInsurancePolicyTypes: {
                INSURANCE_OMS_NEW_TYPE: "Полис нового образца",
                INSURANCE_OMS_OLD_TYPE: "Полис старого образца",
                INSURANCE_TEMP_TYPE: "Временный полис",
                INSURANCE_NONE: "Нет данных",
            },
        }
    }
}