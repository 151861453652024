export const debounce = {
    data() {
        return {
            debounceTimeoutId: "",
        }
    },
    methods: {
        async getCasesFromSearch(fetchCallback) {
            await fetchCallback();
        },
        debounce(callback, timeoutDelay) {
            return (...rest) => {
                console.log("debounce timeoutId after", this.debounceTimeoutId); //
                clearTimeout(this.debounceTimeoutId);
                this.debounceTimeoutId = setTimeout(() => callback(...rest), timeoutDelay);
                console.log("debounce timeoutId before", this.debounceTimeoutId); //
            };
        }
    },
    beforeUnmount() {
        clearTimeout(this.debounceTimeoutId);
    },
};