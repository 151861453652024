import { checking, fullName, changeDateFormatConst } from "@/mixins";

export const distributorCheckFunctions = {
    mixins: [checking, fullName, changeDateFormatConst],
    computed: {
        getCheckDataFunction() {
            return (type) => {
                switch (type) {
                    case "checkingStringData":
                    case "string":
                        return this.checkingStringData;
                    case "checkingBoolData":
                    case "boolean":
                        return this.checkingBoolData;
                    case "checkingIntegerServerData":
                        return this.checkingIntegerServerData;
                    case "checkingIntegerData":
                    case "integer":
                        return this.checkingIntegerData;
                    case "changeDateFormat":
                    case "date":
                        return this.changeDateFormat;
                    case "changeTimeFormat":
                    case "time":
                        return this.changeTimeFormat;
                    case "changeDateTimeFormat":
                    case "dateTime":
                        return this.changeDateTimeFormat;
                    case "changeDateFormatInput":
                    case "dateInput":
                        return this.changeDateFormatInput;
                    case "changeDateTimeFormatInput":
                    case "dateTimeInput":
                        return this.changeDateTimeFormatInput;
                    case "changeTimeFormatTz":
                    case "timeTimeZone":
                        return this.changeTimeFormatTz;
                    case "changeDateTimeFormatTz":
                    case "dateTimeTimeZone":
                        return this.changeDateTimeFormatTz;
                    case "calcAge":
                        return this.calcAge;
                    case "fullDoctorName":
                        return this.fullDoctorName;
                    default:
                        return;
                }
            };
        },
    },
};