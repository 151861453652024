import { api } from "@/api";


export default {
    state: {
        settingsReferenceDefectTypeMaterials: {},
        isSettingsReferenceDefectTypeMaterialsLoading: false,
    },
    actions: {
        async fetchSettingsReferenceDefectTypeMaterial({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceDefectTypeMaterialsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const defects = await api.getReferenceFlasksDefects(queryParams, token);
                    commit("changeSettingsReferenceDefectTypeMaterials", defects.results);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            } finally {
                commit("updateSettingsReferenceDefectTypeMaterialsLoader", false);
            }
        },
        async addSettingsReferenceDefectTypeMaterials({ dispatch, commit }, defect ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const defectData = await api.postReferenceFlasksDefects(defect, token);
                    if (typeof defectData === "object") {
                        commit("addNewSettingsReferenceDefectTypeMaterials", defectData);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async editSettingsReferenceDefectTypeMaterials({ dispatch, commit }, defect ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const defectData = await api.patchReferenceFlasksDefects(defect, token);
                    if (typeof defectData === "object") {
                        commit("updateSettingsReferenceDefectTypeMaterials", defectData);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    mutations: {
        changeSettingsReferenceDefectTypeMaterials(state, settingsReferenceDefectTypeMaterials) {
            state.settingsReferenceDefectTypeMaterials = settingsReferenceDefectTypeMaterials;
        },
        updateSettingsReferenceDefectTypeMaterialsLoader(state, condition) {
            state.isSettingsReferenceDefectTypeMaterialsLoading = condition;
        },
        updateSettingsReferenceDefectTypeMaterials(state, defectType) {
            const index = state.settingsReferenceDefectTypeMaterials.findIndex(item => item.id === defectType.id);
            if (index !== -1) {
                const updatedDefectType = { ...defectType }; // Создаем копию объекта defectType
                const uniqueId = Object.values(updatedDefectType).join('_'); // Создаем уникальный идентификатор на основе значений объекта
                updatedDefectType.uniqueId = uniqueId; // Добавляем новое поле uniqueId к объекту
                state.settingsReferenceDefectTypeMaterials.splice(index, 1, updatedDefectType); // Заменяем объект в массиве
            }
        },
        addNewSettingsReferenceDefectTypeMaterials(state, defectType) {
            state.settingsReferenceDefectTypeMaterials.unshift(defectType);
        },
    },
    getters: {
        getSettingsReferenceDefectTypeMaterials(state) {
            return state.settingsReferenceDefectTypeMaterials;
        },
        getSettingsReferenceDefectTypeMaterialsLoaderStatus(state) {
            return state.isSettingsReferenceDefectTypeMaterialsLoading;
        },
    }
}