import { api } from "@/api";
import { debounceLoader } from "@/helpers/debounceLoader";

export default {
    state: {
        responsibleStaffMemberReferencePage: 1,
        responsibleStaffMemberReferencePageSize: 50,
        responsibleStaffMembers: [],
        isResponsibleStaffMemberNotFound: false,
        responsibleStaffMemberReferenceListShowStatus: false,
        responsibleStaffMemberSelectedName: "",
        responsibleStaffMemberSelectedItemId: null
    },
    actions: {
        async fetchNextResponsibleStaffMembers({ state, commit, dispatch }) {
            if (state.responsibleStaffMembers?.next === null) {
                return;
            }
            commit("changeResponsibleStaffMemberReferencePage", state.responsibleStaffMemberReferencePage + 1);
            dispatch("receiveResponsibleStaffMember", "next");
        },
        async receiveResponsibleStaffMember({ commit, state, dispatch, rootGetters }, status) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                if (!status) {
                    commit("changeResponsibleStaffMemberReferencePage", 1);
                }
                switch (rootGetters.getLifetimeGeneralData !== null) {
                    case rootGetters.getLifetimeGeneralData?.responsible_staff !== undefined && !rootGetters.getInputLifetimeFocusEventStatus:
                        if (rootGetters.getLifetimeGeneralData?.responsible_staff.length !== 0 && rootGetters.getLifetimeGeneralData?.responsible_staff === state.responsibleStaffMemberSelectedName) {
                            return;
                        }
                        dispatch("fetchResponsibleStaffMembers", [token, state.responsibleStaffMemberReferencePage, state.responsibleStaffMemberReferencePageSize, rootGetters.getLifetimeGeneralData?.responsible_staff, status]);
                        return;
                    case rootGetters.getLifetimeGeneralData?.responsible_staff_name !== undefined &&  !rootGetters.getInputLifetimeFocusEventStatus:
                        if (rootGetters.getLifetimeGeneralData?.responsible_staff_name.length !== 0 && rootGetters.getLifetimeGeneralData?.responsible_staff_name === state.responsibleStaffMemberSelectedName) {
                            return;
                        }
                        dispatch("fetchResponsibleStaffMembers", [token, state.responsibleStaffMemberReferencePage, state.responsibleStaffMemberReferencePageSize, rootGetters.getLifetimeGeneralData?.responsible_staff_name, status]);
                        return;
                    case rootGetters.getLifetimeGeneralData?.responsible_staff !== undefined && rootGetters.getInputLifetimeFocusEventStatus && rootGetters.getLifetimeGeneralData?.responsible_staff.length === 0:
                    case rootGetters.getLifetimeGeneralData?.responsible_staff_name !== undefined && rootGetters.getInputLifetimeFocusEventStatus && rootGetters.getLifetimeGeneralData?.responsible_staff_name.length === 0:
                        dispatch("fetchResponsibleStaffMembers", [token, state.responsibleStaffMemberReferencePage, state.responsibleStaffMemberReferencePageSize, "", status]);
                        return;
                    default:
                        return;
                }
            });
        },
        //TEST
        async receiveResponsibleStaffMemberNew({ state, dispatch }) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                dispatch("fetchResponsibleStaffMembersNew", [token, state.responsibleStaffMemberReferencePage, state.responsibleStaffMemberReferencePageSize, ""]);
            });
        },

        async fetchResponsibleStaffMembersNew({ dispatch }, [token, page, pageSize, responsibleStaffMember]) {
            try {
                const responsibleStaffMembers = await debounceLoader(
                    api.getReferenceResponsibleStaffMember(page, pageSize, token, responsibleStaffMember),
                    "referenceList"
                );

                dispatch("updateResponsibleStaffMembers", responsibleStaffMembers);
            } catch (e) {
                console.error(e);
            }
        },

        async fetchResponsibleStaffMembers({ dispatch, state, rootGetters }, [token, page, pageSize, responsibleStaffMember, status]) {
            try {
                if (!rootGetters.getInputLifetimeFocusEventStatus && responsibleStaffMember.length === 0) {
                    return;
                }
                const responsibleStaffMembers = await debounceLoader(
                    api.getReferenceResponsibleStaffMember(page, pageSize, token, responsibleStaffMember),
                    "referenceList"
                );

                if (responsibleStaffMembers.results.length === 0) {
                    dispatch("updateResponsibleStaffMemberReferenceListShowStatus", false);
                    if (state.isResponsibleStaffMemberNotFound) {
                        return;
                    }
                    dispatch("updateIsResponsibleStaffMemberNotFound", true);
                    return;
                }

                // dispatch("setExceptionAccordingResponseCode", responsibleStaffMembers);

                switch (status) {
                    case "next":
                        dispatch("updateResponsibleStaffMembersByNextStatus", responsibleStaffMembers);
                        break;
                    default:
                        dispatch("updateResponsibleStaffMembers", responsibleStaffMembers);
                        break;
                }

                dispatch("updateIsResponsibleStaffMemberNotFound", false);
                dispatch("updateResponsibleStaffMemberReferenceListShowStatus", true);
            } catch (e) {
                console.error(e);
                // dispatch("setExceptionAccordingResponseCode", e.status);
                dispatch("updateResponsibleStaffMemberReferenceListShowStatus", false);
            }
        },
        updateIsResponsibleStaffMemberNotFound({ commit }, status) {
            commit("changeIsResponsibleStaffMemberNotFound", status);
        },
        updateResponsibleStaffMembers({ commit }, responsibleStaffMembers) {
            commit("changeResponsibleStaffMembers", responsibleStaffMembers);
        },
        updateResponsibleStaffMembersByNextStatus({ commit }, responsibleStaffMembers) {
            commit("changeResponsibleStaffMembersByNextStatus", responsibleStaffMembers);
        },
        updateResponsibleStaffMemberReferenceListShowStatus({ commit }, status) {
            commit("changeResponsibleStaffMemberReferenceListShowStatus", status);
        },
        updateResponsibleStaffMemberSelectedName({ commit }, responsibleStaffMemberSelectedName) {
            commit("changeResponsibleStaffMemberSelectedName", responsibleStaffMemberSelectedName);
        },
        updateResponsibleStaffMemberSelectedItemId({ commit }, responsibleStaffMemberSelectedItemId) {
            commit("changeResponsibleStaffMemberSelectedItemId", responsibleStaffMemberSelectedItemId);
        },
    },
    mutations: {
        changeResponsibleStaffMemberReferencePage(state, responsibleStaffMemberReferencePage) {
            state.responsibleStaffMemberReferencePage = responsibleStaffMemberReferencePage;
        },
        changeIsResponsibleStaffMemberNotFound(state, status) {
            state.isResponsibleStaffMemberNotFound = status;
        },
        changeResponsibleStaffMembers(state, responsibleStaffMembers) {
            state.responsibleStaffMembers = responsibleStaffMembers;
        },
        changeResponsibleStaffMembersByNextStatus(state, responsibleStaffMembers) {
            state.responsibleStaffMembers.results = [...state.responsibleStaffMembers?.results, ...responsibleStaffMembers?.results];
            state.responsibleStaffMembers.next = responsibleStaffMembers?.next;
            state.responsibleStaffMembers.previous = responsibleStaffMembers?.previous;

        },
        changeResponsibleStaffMemberReferenceListShowStatus(state, status) {
            state.responsibleStaffMemberReferenceListShowStatus = status;
        },
        changeResponsibleStaffMemberSelectedName(state, responsibleStaffMemberSelectedName) {
            state.responsibleStaffMemberSelectedName = responsibleStaffMemberSelectedName;
        },
        changeResponsibleStaffMemberSelectedItemId(state, responsibleStaffMemberSelectedItemId) {
            state.responsibleStaffMemberSelectedItemId = responsibleStaffMemberSelectedItemId;
        }
    },
    getters: {
        getIsResponsibleStaffMemberNotFound(state) {
            return state.isResponsibleStaffMemberNotFound;
        },
        getResponsibleStaffMembers(state) {
            return state.responsibleStaffMembers;
        },
        getResponsibleStaffMemberReferenceListShowStatus(state) {
            return state.responsibleStaffMemberReferenceListShowStatus;
        },
        getResponsibleStaffMemberSelectedName(state) {
            return state.responsibleStaffMemberSelectedName;
        },
        getResponsibleStaffMemberSelectedItemId(state) {
            return state.responsibleStaffMemberSelectedItemId;
        },
        getResponsibleStaffMemberReferencePage(state) {
            return state.responsibleStaffMemberReferencePage;
        },
        getResponsibleStaffMemberReferencePageSize(state) {
            return state.responsibleStaffMemberReferencePageSize;
        },
    }
}