import { api } from "@/api";
import { debounceLoader } from "@/helpers/debounceLoader";

export default {
    state: {
        organizationReferencePage: 1,
        organizationReferencePageSize: 50,
        organizationReferenceListShowStatus: false,
        organizations: [],
        isOrganizationNotFound: false,
        organizationSelectedName: "",
        organizationSelectedItemId: null
    },

    actions: {
        async fetchNextOrganizations({ state, commit, dispatch }) {
            if (state.organizations?.next === null) {
                return;
            }
            commit("changeOrganizationReferencePage", state.organizationReferencePage + 1);
            dispatch("receiveOrganizations", "next");
        },
        async receiveOrganizations({ commit, state, dispatch, rootGetters }, status) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                if (!status) {
                    commit("changeOrganizationReferencePage", 1);
                }
                switch (rootGetters.getOrganizationInformationData !== null) {
                    case rootGetters.getOrganizationInformationData?.request_organization_title_short !== undefined && !rootGetters.getInputLifetimeFocusEventStatus:
                        if (rootGetters.getOrganizationInformationData?.request_organization_title_short.length !== 0 && rootGetters.getOrganizationInformationData?.request_organization_title_short === state.organizationSelectedName) {
                            return;
                        }
                        dispatch("fetchOrganizations", [token, state.organizationReferencePage, state.organizationReferencePageSize, rootGetters.getOrganizationInformationData?.request_organization_title_short, status]);
                        return;
                    case rootGetters.getOrganizationInformationData?.request_organization_title_short !== undefined && rootGetters.getInputLifetimeFocusEventStatus && rootGetters.getOrganizationInformationData?.request_organization_title_short.length === 0:
                        dispatch("fetchOrganizations", [token, state.organizationReferencePage, state.organizationReferencePageSize, "", status]);
                        return;
                    default:
                        return;
                }
            });
        },
        async fetchOrganizations({ dispatch, state, rootGetters }, [token, page, pageSize, organization, status]) {
            try {
                if (!rootGetters.getInputLifetimeFocusEventStatus && organization.length === 0) {
                    return;
                }
                const organizations = await debounceLoader(
                    api.getReferenceOrganization(page, pageSize, token, organization),
                    "referenceList"
                );

                if (organizations.results.length === 0) {
                    dispatch("updateOrganizationReferenceListShowStatus", false);
                    if (state.isOrganizationNotFound) {
                        return;
                    }
                    dispatch("updateIsOrganizationNotFound", true);
                    return;
                }

                // dispatch("setExceptionAccordingResponseCode", organizations);

                switch (status) {
                    case "next":
                        dispatch("updateOrganizationsByNextStatus", organizations);
                        break;
                    default:
                        dispatch("updateOrganizations", organizations);
                        break;
                }

                dispatch("updateIsOrganizationNotFound", false);
                dispatch("updateOrganizationReferenceListShowStatus", true);
            } catch (e) {
                console.error(e);
                // dispatch("setExceptionAccordingResponseCode", e.status);
                dispatch("updateOrganizationReferenceListShowStatus", false);
            }
        },
        updateOrganizations({ commit }, organizations) {
            commit("changeOrganizations", organizations);
        },
        updateOrganizationsByNextStatus({ commit }, organizations) {
            commit("changeOrganizationsByNextStatus", organizations);
        },
        updateOrganizationReferenceListShowStatus({ commit }, status) {
            commit("changeOrganizationReferenceListShowStatus", status);
        },
        updateIsOrganizationNotFound({ commit }, status) {
            commit("changeIsOrganizationNotFound", status);
        },
        updateOrganizationSelectedName({ commit, rootGetters, dispatch }, organizationSelectedName) {
            commit("changeOrganizationSelectedName", organizationSelectedName);

            if (organizationSelectedName?.length !== 0 && rootGetters.getOrganizationDepartmentSelectedName?.length === 0) {
                return;
            }
            dispatch("updateOrganizationDepartmentSelectedName", "");
        },
        updateOrganizationSelectedItemId({ commit, dispatch, rootGetters }, organizationSelectedItemId) {
            commit("changeOrganizationSelectedItemId", organizationSelectedItemId);

            if (organizationSelectedItemId === null && rootGetters.getOrganizationDepartmentSelectedItemId !== null) {
                dispatch("updateOrganizationDepartmentSelectedItemId", null);
            }

            if (organizationSelectedItemId === null || organizationSelectedItemId === undefined) {
                dispatch("updateLifetimeOrganizationDepartmentFieldDisabledStatus", true);
                return;
            }
            dispatch("updateLifetimeOrganizationDepartmentFieldDisabledStatus", false);
        },
        clearReferenceOrganizationData({ commit }) {
            commit("changeOrganizationReferencePage", 1);
            commit("changeOrganizations", []);
            commit("changeOrganizationSelectedName", "");
            commit("changeOrganizationSelectedItemId", null);
        }
    },

    mutations: {
        changeOrganizationReferencePage(state, organizationReferencePage) {
            state.organizationReferencePage = organizationReferencePage;
        },
        changeOrganizations(state, organizations) {
            state.organizations = organizations;
        },
        changeOrganizationsByNextStatus(state, organizations) {
            state.organizations.results = [...state.organizations?.results, ...organizations?.results];
            state.organizations.next = organizations?.next;
            state.organizations.previous = organizations?.previous;
        },
        changeOrganizationReferenceListShowStatus(state, status) {
            state.organizationReferenceListShowStatus = status;
        },
        changeIsOrganizationNotFound(state, status) {
            state.isOrganizationNotFound = status;
        },
        changeOrganizationSelectedName(state, organizationSelectedName) {
            state.organizationSelectedName = organizationSelectedName;
        },
        changeOrganizationSelectedItemId(state, organizationSelectedItemId) {
            state.organizationSelectedItemId = organizationSelectedItemId;
        }
    },

    getters: {
        getOrganizations(state) {
            return state.organizations;
        },
        getOrganizationReferenceListShowStatus(state) {
            return state.organizationReferenceListShowStatus;
        },
        getIsOrganizationNotFound(state) {
            return state.isOrganizationNotFound;
        },
        getOrganizationSelectedName(state) {
            return state.organizationSelectedName;
        },
        getOrganizationSelectedItemId(state) {
            return state.organizationSelectedItemId;
        }
    }
}
