import { mapActions } from "vuex";

export const distributorFormFlagActions = {
    computed: {
        getBlockFlagToFormSaveButtonAction() {
            return (flag) => {
                switch (flag) {
                    case "lifetimeGeneral":
                        return this.updateFormLifetimeGeneralSaveBtnStatus;
                    case "lifetimePatient":
                        return this.updateFormLifetimePatientSaveBtnStatus;
                    case "lifetimeDisease":
                        return this.updateFormLifetimeDiseaseSaveBtnStatus;
                    case "lifetimeComment":
                        return this.updateFormLifetimeCommentSaveBtnStatus;
                    case "lifetimeCustomer":
                        return this.updateFormLifetimeCustomerSaveBtnStatus;
                    case "lifetimeMaterial":
                        return this.updateFormLifetimeMaterialSaveBtnStatus;
                    case "lifetimeMacroDescriptionPhoto":
                        return this.updateFormLifetimeMacroDescriptionPhotoSaveBtnStatus;
                    case "lifetimeMacroDescriptionObject":
                        return this.updateFormLifetimeMacroDescriptionObjectSaveBtnStatus;
                    case "lifetimeMacroDescriptionConclusion":
                        return this.updateFormLifetimeMacroDescriptionConclusionSaveBtnStatus;
                    case "lifetimeMicroDescription":
                        return this.updateFormLifetimeMicroDescriptionSaveBtnStatus;
                    default:
                        this.updateAllFormLifetimeSaveBtnStatus(false);
                        return;
                }
            };
        },
    },
    methods: {
        ...mapActions([
            "updateFormLifetimeGeneralSaveBtnStatus",
            "updateFormLifetimePatientSaveBtnStatus",
            "updateFormLifetimeDiseaseSaveBtnStatus",
            "updateFormLifetimeCommentSaveBtnStatus",
            "updateFormLifetimeCustomerSaveBtnStatus",
            "updateFormLifetimeMaterialSaveBtnStatus",
            "updateFormLifetimeMacroDescriptionPhotoSaveBtnStatus",
            "updateFormLifetimeMacroDescriptionObjectSaveBtnStatus",
            "updateFormLifetimeMacroDescriptionConclusionSaveBtnStatus",
            "updateFormLifetimeMicroDescriptionSaveBtnStatus",
            "updateAllFormLifetimeSaveBtnStatus"
        ]),
    },
};