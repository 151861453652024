import { lifetimeApi } from "@/api";


export default {
    state: {
        lifetimeCasesBiopsyMaterials: [],
        lifetimeCasesBiopsyMaterialErrorStatus: "",
        biopsyMaterialsData: [
            {
                "biopsy_material_id": 1,
                "biopsy_material_types": [
                    {
                        "biopsy_type_id": 1,
                        "biopsy_type_title": "Эндоскопическая",
                        "biopsy_type_material": 1
                    },
                    {
                        "biopsy_type_id": 2,
                        "biopsy_type_title": "Пункционная",
                        "biopsy_type_material": 1
                    },
                    {
                        "biopsy_type_id": 3,
                        "biopsy_type_title": "Аспирационная",
                        "biopsy_type_material": 1
                    },
                    {
                        "biopsy_type_id": 4,
                        "biopsy_type_title": "Операционная",
                        "biopsy_type_material": 1
                    },
                    {
                        "biopsy_type_id": 5,
                        "biopsy_type_title": "Операционный материал",
                        "biopsy_type_material": 1
                    },
                    {
                        "biopsy_type_id": 6,
                        "biopsy_type_title": "Самопроизвольно отделившиеся фрагменты",
                        "biopsy_type_material": 1
                    }
                ],
                "biopsy_material_title": "Тканевой материал"
            },
            {
                "biopsy_material_id": 2,
                "biopsy_material_types": [
                    {
                        "biopsy_type_id": 7,
                        "biopsy_type_title": "Экссудат, транссудат",
                        "biopsy_type_material": 2
                    },
                    {
                        "biopsy_type_id": 8,
                        "biopsy_type_title": "Пункционная",
                        "biopsy_type_material": 2
                    },
                    {
                        "biopsy_type_id": 9,
                        "biopsy_type_title": "Брюш",
                        "biopsy_type_material": 2
                    },
                    {
                        "biopsy_type_id": 10,
                        "biopsy_type_title": "Соскоб",
                        "biopsy_type_material": 2
                    },
                    {
                        "biopsy_type_id": 11,
                        "biopsy_type_title": "Отделяемое желез",
                        "biopsy_type_material": 2
                    },
                    {
                        "biopsy_type_id": 12,
                        "biopsy_type_title": "Промывные воды",
                        "biopsy_type_material": 2
                    }
                ],
            "biopsy_material_title": "Клеточный материал"
        },
        {
            "biopsy_material_id": 3,
            "biopsy_material_types": [
                {
                    "biopsy_type_id": 13,
                    "biopsy_type_title": "Интраоперационный",
                    "biopsy_type_material": 3
                },
            ],
            "biopsy_material_title": "Интраоперационный материал"
        },
    ],
        biopsyMaterialId: null
    },

    actions: {
        async fetchLifetimeCasesBiopsyMaterial({ commit, state }) {
            try {
                const token = JSON.parse(localStorage.accessTokenData).access;
                const biopsyMaterialData = await lifetimeApi.getLifetimeCasesBiopsyMaterial(token);
                if (typeof biopsyMaterialData === "number") {
                    commit("addLifetimeCasesBiopsyMaterialErrorStatus", biopsyMaterialData);
                    commit("addLifetimeCasesBiopsyMaterials", state.biopsyMaterialsData);
                    return;
                }
                commit("addLifetimeCasesBiopsyMaterials", biopsyMaterialData);
                commit("addLifetimeCasesBiopsyMaterialErrorStatus", "");
            } catch (e) {
                console.error(e);
                commit("addLifetimeCasesBiopsyMaterialErrorStatus", e.message);
                commit("addLifetimeCasesBiopsyMaterials", state.biopsyMaterialsData);
                return e;
            }
        },
        updateBiopsyMaterialId({ commit }, biopsyMaterialId) {
            commit("changeBiopsyMaterialId", biopsyMaterialId)
        }
    },

    mutations: {
        addLifetimeCasesBiopsyMaterials(state, biopsyMaterials) {
            state.lifetimeCasesBiopsyMaterials = biopsyMaterials;
        },
        addLifetimeCasesBiopsyMaterialErrorStatus(state, error) {
            state.lifetimeCasesBiopsyMaterialErrorStatus = error;
        },
        changeBiopsyMaterialId(state, biopsyMaterialId) {
            state.biopsyMaterialId = biopsyMaterialId;
        },
    },

    getters: {
        getLifetimeCasesBiopsyMaterial(state) {
            return state.lifetimeCasesBiopsyMaterials;
        },
        getLifetimeCasesBiopsyMaterialErrorStatus(state) {
            return state.lifetimeCasesBiopsyMaterialErrorStatus;
        },
        getBiopsyMaterialId(state) {
            return state.biopsyMaterialId;
        },
    }
}