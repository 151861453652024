import { api } from "@/api";


export default {
    state: {
        settingsReferenceWiringProgram: [],
    },
    actions: {
        async fetchSettingsReferenceWiringProgram({ dispatch, commit }, queryParams) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const programs = await api.getSettingsReferenceWiringProgram(queryParams, token);
                    commit("changeSettingsReferenceWiringProgram", programs);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    mutations: {
        changeSettingsReferenceWiringProgram(state, settingsReferenceWiringProgram) {
            state.settingsReferenceWiringProgram = settingsReferenceWiringProgram;
        },
    },
    getters: {
        getWiringProgramsList(state) {
            return state.settingsReferenceWiringProgram;
        },
    }
}