export const lifetimeMaterialMarkingTableFields = {
    data() {
        return {
            lifetimeMaterialMarkingTableDescriptionData: [
                { field: "number", header: "№ фл.", columnWidth: "57px" },
                { field: "localization", header: "Локализация", columnWidth: "258px"},
                { field: "characteristics", header: "Характер патологического процесса", columnWidth: "432px" },
                { field: "pieces_count", header: "Кол-во объектов", columnWidth: "auto" }
            ],
            lifetimeReceptionMaterialMarkingTableDescriptionData: [
                { field: "number", header: "№ фл.", columnWidth: "69px" },
                { field: "localization", header: "Локализация", columnWidth: "185px" },
                { field: "characteristics", header: "Характер патологического процесса", columnWidth: "293px" },
                { field: "pieces_count", header: "Кол-во объектов", columnWidth: "137px" },
                { field: "pieces_count_to_work", header: "Кол-во объектов в работу", columnWidth: "191px" },
                { field: "info_about_defect", header: "Информация о браке", columnWidth: "auto", deepObjectTitle: "title" },
            ],
            lifetimeReceptionMaterialMarkingTableDescriptionAdditionalData: [
                { field: "pieces_count_to_work", header: "Кол-во объектов в работу", columnWidth: "100px" },
                { field: "info_about_defect", header: "Информация о браке", columnWidth: "350px" },
            ],
        };
    },

}