export const settingsEmployeesFields = {
    data() {
        return {
            settingsEmployeesFieldsData: [
                { field: "full_name", header: "ФИО", columnWidth: "230px"},
                { field: "ipa", header: "СНИЛС", columnWidth: "230px"},
                { field: "organization", header: "Организация", columnWidth: "230px"},
                { field: "department", header: "Отделение", columnWidth: "230px"},
                { field: "position", header: "Должность", columnWidth: "230px"},
            ],
        }
    },
}