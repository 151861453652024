export default {
    state: {
        mortemCaseOrganization: null,
        mortemCaseOrganizationDepartment: null,
        mortemCaseDoctor: null,
        mortemCaseDoctorPhone: null,
        mortemCaseReferralDate: null,
        mortemCaseReferralDateInputFocus: false,
        mortemMedicalOrganizationBtnStatus: false,
    },
    actions: {
        updateMortemCaseOrganization({ commit }, organization) {
            commit("changeMortemCaseOrganization", organization);
        },
        updateMortemCaseOrganizationDepartment({ commit }, department) {
            commit("changeMortemCaseOrganizationDepartment", department);
        },
        updateMortemCaseDoctor({ commit }, doctor) {
            commit("changeMortemCaseDoctor", doctor);
        },
        updateMortemCaseDoctorPhone({ commit }, phone) {
            commit("changeMortemCaseDoctorPhone", phone);
        },
        updateMortemCaseReferralDate({ commit }, date) {
            commit("changeMortemCaseReferralDate", date);
        },
        updateMortemCaseReferralDateInputFocus({ commit }, mortemCaseReferralDateInputFocus) {
            commit("changeMortemCaseReferralDateInputFocus", mortemCaseReferralDateInputFocus);
        },
        updateMortemMedicalOrganizationBtnStatus({ commit }, mortemMedicalOrganizationBtnStatus) {
            commit("changeMortemMedicalOrganizationBtnStatus", mortemMedicalOrganizationBtnStatus);
        },
    },
    mutations: {
        changeMortemCaseOrganization(state, organization) {
            state.mortemCaseOrganization = organization;
        },
        changeMortemCaseOrganizationDepartment(state, department) {
            state.mortemCaseOrganizationDepartment = department;
        },
        changeMortemCaseDoctor(state, doctor) {
            state.mortemCaseDoctor = doctor;
        },
        changeMortemCaseDoctorPhone(state, phone) {
            state.mortemCaseDoctorPhone = phone;
        },
        changeMortemCaseReferralDate(state, date) {
            state.mortemCaseReferralDate = date;
        },
        changeMortemCaseReferralDateInputFocus(state, mortemCaseReferralDateInputFocus) {
            state.mortemCaseReferralDateInputFocus = mortemCaseReferralDateInputFocus;
        },
        changeMortemMedicalOrganizationBtnStatus(state, mortemMedicalOrganizationBtnStatus) {
            state.mortemMedicalOrganizationBtnStatus = mortemMedicalOrganizationBtnStatus;
        },
    },
    getters: {
        getMortemCaseOrganization(state) {
            return state.mortemCaseOrganization;
        },
        getMortemCaseOrganizationDepartment(state) {
            return state.mortemCaseOrganizationDepartment;
        },
        getMortemCaseDoctor(state) {
            return state.mortemCaseDoctor;
        },
        getMortemCaseDoctorPhone(state) {
            return state.mortemCaseDoctorPhone;
        },
        getMortemCaseReferralDate(state) {
            return state.mortemCaseReferralDate;
        },
        getMortemCaseReferralDateInputFocus(state) {
            return state.mortemCaseReferralDateInputFocus;
        },
        getMortemMedicalOrganizationBtnStatus(state) {
            return state.mortemMedicalOrganizationBtnStatus
        }
    }
}