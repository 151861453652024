export const area = {
    data() {
        return {
            addressAreas: {
                PATIENT_ADDRESS_URBAN_AREA: 'Городская',
                PATIENT_ADDRESS_RURAL_AREA: 'Сельская',
                PATIENT_ADDRESS_NO_AREA: '-',
            },
            patientAddressAreasSelectOptionList: [
                {id: 'PATIENT_ADDRESS_URBAN_AREA', title: 'Городская'},
                {id: 'PATIENT_ADDRESS_RURAL_AREA', title: 'Сельская'},
            ]
        }
    }
}