import { api } from "@/api";


export default {
    state: {
        settingsReferenceMaterials: {},
        settingsReferenceMaterialsTotalPages: null,
        isSettingsReferenceMaterialsLoading: false,
    },
    actions: {
        async fetchSettingsReferenceMaterial({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceMaterialsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const materials = await api.getSettingsReferenceMaterial(queryParams, token);
                    commit("changeSettingsReferenceMaterials", materials.results);
                    commit("changeSettingsReferenceMaterialsTotalPages", materials.total_pages);
                    commit("updateSettingsReferenceMaterialsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferenceMaterialsLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async fetchMoreSettingsReferenceMaterials({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceMaterialsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const materials = await api.getSettingsReferenceMaterial(queryParams, token);
                    commit("updateMoreSettingsReferenceMaterials", materials.results);
                    commit("updateSettingsReferenceMaterialsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferenceMaterialsLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("updateSettingsReferenceMaterialsLoader", false);
            }

        },
        async addSettingsReferenceMaterials({ dispatch, commit }, material ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const materialData = await api.postSettingsReferenceMaterial(material, token);
                    if (typeof materialData === "object") {
                        commit("addNewSettingsReferenceMaterials", materialData);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async editSettingsReferenceMaterials({ dispatch, commit }, material ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const materialData = await api.patchSettingsReferenceMaterial(material, token);
                    if (typeof materialData === "object") {
                        commit("updateSettingsReferenceMaterials", materialData);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    mutations: {
        changeSettingsReferenceMaterials(state, settingsReferenceMaterials) {
            state.settingsReferenceMaterials = settingsReferenceMaterials;
        },
        updateMoreSettingsReferenceMaterials(state, settingsReferenceMaterials) {
            state.settingsReferenceMaterials = [...state.settingsReferenceMaterials, ...settingsReferenceMaterials];
        },
        changeSettingsReferenceMaterialsTotalPages(state, settingsReferenceMaterialsTotalPages) {
            state.settingsReferenceMaterialsTotalPages = settingsReferenceMaterialsTotalPages;
        },
        updateSettingsReferenceMaterialsLoader(state, condition) {
            state.isSettingsReferenceMaterialsLoading = condition;
        },
        updateSettingsReferenceMaterials(state, material) {
            const index = state.settingsReferenceMaterials.findIndex(item => item.id === material.id);
            if (index !== -1) {
                const updatedMaterial = { ...material }; // Создаем копию объекта material
                const uniqueId = Object.values(updatedMaterial).join('_'); // Создаем уникальный идентификатор на основе значений объекта
                updatedMaterial.uniqueId = uniqueId; // Добавляем новое поле uniqueId к объекту
                state.settingsReferenceMaterials.splice(index, 1, updatedMaterial); // Заменяем объект в массиве
            }
        },
        addNewSettingsReferenceMaterials(state, material) {
            state.settingsReferenceMaterials.unshift(material);
        },
    },
    getters: {
        getSettingsReferenceMaterials(state) {
            return state.settingsReferenceMaterials;
        },
        getSettingsReferenceMaterialsTotalPages(state) {
            return state.settingsReferenceMaterialsTotalPages;
        },
        getSettingsReferenceMaterialsLoaderStatus(state) {
            return state.isSettingsReferenceMaterialsLoading;
        },
    }
}