export const referenceEquipmentFields = {
    data() {
        return {
            referenceEquipmentTableDescriptionData: [
                { field: "active", header: "Активна", isSort: true, columnWidth: "90px"},
                { field: "title", header: "Название оборудования", isSort: true, columnWidth: "210px"},
                { field: "type", header: "Тип оборудования", columnWidth: "190px"},
                { field: "stage_relation", header: "Отношение к этапу", columnWidth: "140px"},
                { field: "serial_number", header: "Серийный номер", columnWidth: "220px"},
                { field: "comment", header: "Комментарий", columnWidth: "300px"},
            ],
        }
    },
}