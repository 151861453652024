import { lifetimeApi } from "@/api";

export default {
    state: {
        lifetimeCasesMicropreparations: [],
        lifetimeCasesMicroscopyFormData: {},
        lifetimeMicroscopyMicropreparationsPhotos: {},
        isLifetimeMicroscopyMicropreparationsPhotoLoading: false,
    },
    actions: {
        async fetchLifetimeCasesMicropreparations({ dispatch, commit }, queryParams) {
            try {
                const token = await dispatch("fetchAccessToken");
                const micropreparations = await lifetimeApi.getLifetimeMicroPreparations(queryParams, token);
                commit("changeLifetimeCasesMicropreparations", micropreparations.results);
            } catch (e) {
                console.error(e);
            }
        },
        updateLifetimeCasesMicroscopyFormData({ commit }, lifetimeCasesMicroscopyFormData) {
            commit("changeLifetimeCasesMicroscopyFormData", lifetimeCasesMicroscopyFormData);
        },
        async fetchLifetimeMicroscopyMicropreparationsPhotos({ dispatch, commit }, [micropreparationId, status, lifetimeMicroscopyMicropreparationsData]) {
            commit("updateLifetimeMicroscopyMicropreparationsPhotoLoader", true);
            try {
                const token = await dispatch("fetchAccessToken");
                switch (status) {
                    case "add":
                        dispatch("addLifetimeMicroscopyMicropreparationsPhotos", [token, micropreparationId, lifetimeMicroscopyMicropreparationsData]);
                        return;
                    case "delete":
                        dispatch("deleteLifetimeMicroscopyMicropreparationsPhotos", [token, micropreparationId, lifetimeMicroscopyMicropreparationsData]);
                        return;
                    default:
                        await dispatch("receiveLifetimeMicroscopyMicropreparationsPhotos", [token, micropreparationId]);
                        return;
                }
            } catch (e) {
                console.error(e);
                commit("updateLifetimeMicroscopyMicropreparationsPhotoLoader", false);
                return e;
            }
        },
        async receiveLifetimeMicroscopyMicropreparationsPhotos({ commit }, [token, micropreparationId]) {
            try {
                const lifetimeMicroscopyMicropreparationsPhotos = await lifetimeApi.getLifetimeCasesMicroscopyMicropreparationsPhotos(micropreparationId, token);
                // commit("changeLifetimeMacroDescriptionHasPhotosToUploadStatus", false);
                // commit("clearLifetimeMicroscopyMicropreparationsPhotos");
                commit("changeLifetimeMicroscopyMicropreparationsPhotos", [lifetimeMicroscopyMicropreparationsPhotos, micropreparationId]);
                commit("updateLifetimeMicroscopyMicropreparationsPhotoLoader", false);
            } catch (e) {
                console.error(e);
            }
        },
        async addLifetimeMicroscopyMicropreparationsPhotos({ commit, dispatch }, [token, micropreparationId, micropreparationsPhotos]) {
            try {
                const lifetimeMicroscopyMicropreparationsPhotos = await lifetimeApi.addLifetimeCasesMicroscopyMicropreparationsPhotos(micropreparationId, token, micropreparationsPhotos);
                commit("changeLifetimeMicroscopyMicropreparationsPhotos", [lifetimeMicroscopyMicropreparationsPhotos, micropreparationId]);
                commit("updateLifetimeMicroscopyMicropreparationsPhotoLoader", false);
                dispatch("updateFormCaseByIdTabsDisableBtnStatus", false);
                dispatch("updateFormCaseByIdTabMicroDescriptionMicroPreparationsBtnStatus", false);
            } catch (e) {
                console.error(e);
            }
        },
        async deleteLifetimeMicroscopyMicropreparationsPhotos({ commit }, [token, micropreparationId, imageId]) {
            try {
                const lifetimeMicroscopyMicropreparationsPhotos = await lifetimeApi.deleteLifetimeCasesMicroscopyMicropreparationsPhotos(micropreparationId, token, imageId);
                if (lifetimeMicroscopyMicropreparationsPhotos.status) {
                    commit("removeLifetimeMicroscopyMicropreparationsPhotos", [micropreparationId, imageId]);
                    commit("updateLifetimeMicroscopyMicropreparationsPhotoLoader", false);
                }
                // commit("changeLifetimeMicroscopyMicropreparationsPhotos", [lifetimeMicroscopyMicropreparationsPhotos, micropreparationId]);
            } catch (e) {
                console.error(e);
            }
        },
    },
    mutations: {
        updateLifetimeMicroscopyMicropreparationsPhotoLoader(state, condition) {
            state.isLifetimeMicroscopyMicropreparationsPhotoLoading = condition;
        },
        changeLifetimeCasesMicropreparations(state, lifetimeCasesMicropreparations) {
            state.lifetimeCasesMicropreparations = lifetimeCasesMicropreparations;
        },
        changeLifetimeCasesMicroscopyFormData(state, lifetimeCasesMicroscopyFormData) {
            state.lifetimeCasesMicroscopyFormData = lifetimeCasesMicroscopyFormData;
        },
        changeLifetimeMicroscopyMicropreparationsPhotos(state, [lifetimeMicroscopyMicropreparationsPhotos, micropreparationId]) {
            state.lifetimeMicroscopyMicropreparationsPhotos = {
                ...state.lifetimeMicroscopyMicropreparationsPhotos,
                [micropreparationId]: lifetimeMicroscopyMicropreparationsPhotos
            };
        },
        removeLifetimeMicroscopyMicropreparationsPhotos(state, [micropreparationId, imageId]) {
            state.lifetimeMicroscopyMicropreparationsPhotos[micropreparationId] = state.lifetimeMicroscopyMicropreparationsPhotos[micropreparationId].filter((image) => {
                return image.id !== imageId;
            })
        }
    },
    getters: {
        getLifetimeMicroscopyMicropreparationsPhotoLoaderStatus(state) {
            return state.isLifetimeMicroscopyMicropreparationsPhotoLoading;
        },
        getLifetimeCasesMicropreparations(state) {
            return state.lifetimeCasesMicropreparations;
        },
        getLifetimeCasesMicroscopyFormData(state) {
            return state.lifetimeCasesMicroscopyFormData;
        },
        getLifetimeMicroscopyMicropreparationsPhotos(state) {
            return state.lifetimeMicroscopyMicropreparationsPhotos;
        },
    }
}