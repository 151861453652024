export const lifetimePreviousBiopsyStudiesTableFields = {
    data() {
        return {
            lifetimePreviousBiopsyStudiesTableDescriptionData: [
                { field: "register_number", header: "Рег. номер", columnWidth: "150px" },
                { field: "completion_date", header: "Дата исследования", columnWidth: "143px" },
                { field: "organization_title", header: "Медицинская организация", columnWidth: "280px" },
                { field: "disease_report", header: "Заключение", columnWidth: "350px" }
            ],
        };
    }
}