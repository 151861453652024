<template>
    <!-- eslint-disable -->
    <label
        v-if="labelTitle"
        :class="labelClass"
        :for="inputId"
    >
        {{ labelTitle }}
        <span
            v-if="isRequiredStyle"
            class="input__field_required"
        >*</span>
    </label>
    <input
        v-maska
        :data-maska=inputMaska
        :id="inputId"
        :ref="inputRef"
        :type="inputType"
        :autocomplete="autocomplete"
        :name="inputName"
        :class="inputClasses"
        v-model.trim="vModelName"
        :disabled="inputDisabled"
        :required="inputRequired"
        :pattern="inputPattern"
        :placeholder="inputPlaceholder"
        :minlength="inputMinlength"
        :size="inputSize"
        :autofocus="inputAutofocus"
        :min="inputMinValue"
        :max="inputMaxValue"
        @blur="getBlurAction"
        @input="collectData"
        @focus="collectData('callByFocus')"
        @keydown.enter.prevent="executeByPressEnterKeyAction"
    />
    <label
        v-if="floatLabelTitle"
        :class="labelClass"
        :for="inputId"
    >
        {{ floatLabelTitle }}
    </label>

    <FormInputFieldNotification
        v-if="inputFieldNotificationCondition"
        :key="inputFieldNotificationKey"
        :fieldNotification="inputFieldNotification"
        :fieldNotificationClasses="inputFieldNotificationClasses"
    />
</template>

<script>
import { mapActions } from "vuex";
import FormInputFieldNotification from "@/components/FormInputFieldNotification.vue";

export default {
    name: "AppInput",
    emits: ["collectComponentData"],
    props: {
        fieldCollectionName: String,
        labelTitle: String,
        floatLabelTitle: String,
        labelClass: Array,
        dataForChangeFromParent: String,
        dataForChangeFromParentStatus: Boolean,
        defaultDataFromParent: [Number, String],
        defaultDataFromParentStatus: Boolean,
        inputType: String,
        autocomplete: String,
        inputId: String,
        inputName: String,
        inputClasses: Array,
        inputRef: String,
        inputDisabled: Boolean,
        inputRequired: Boolean,
        inputPattern: String,
        inputPlaceholder: String,
        inputMinlength: String,
        inputSize: String,
        inputAutofocus: Boolean,
        inputMaska: String,
        fieldDataName: String,
        formCaseByIdTabBtnStatus: Boolean,
        collectDataToStore: Function,
        checkFunctionForDataFromParent: Function,
        inputMinValue: String,
        inputMaxValue: String,
        inputFieldNotificationCondition: Boolean,
        inputFieldNotificationKey: String,
        inputFieldNotification: String,
        inputFieldNotificationClasses: Array,
        inputInvalidStatus: Boolean,
        inputValue: String,
        inputDefaultValue: String,
        checkFieldDataAction: Function,
        getReferenceListDataToAction: Object,
        getReferenceListAction: Function,
        executeByPressEnterKeyAction: Function,
        inputBlurAction: Function,
        isRequiredStyle: Boolean,
        defaultValue: String,
    },
    data() {
        return {
            vModelName: ""
        }
    },
    components: {
        FormInputFieldNotification
    },
    methods: {
        ...mapActions(["updateInputLifetimeFocusEventStatus"]),
        async collectData(flag) {
            if (flag === "callByFocus") {
                this.updateInputLifetimeFocusEventStatus(true);
            }
            this.$emit("collectComponentData", this.fieldDataName, this.vModelName, this.dataForChangeFromParentStatus, this.fieldCollectionName);
            if (!this.collectDataToStore) {
                return;
            }
            this.collectDataToStore();

            if (this.getReferenceListAction) {
                await this.getReferenceListAction(this.fieldDataName, this.getReferenceListDataToAction);
            }

            if (flag === "callByFocus") {
                await this.updateInputLifetimeFocusEventStatus(false);
            }
        },
        assignValueToInput(data) {
            if (!this.checkFunctionForDataFromParent) {
                this.vModelName = data;
                return;
            }
            this.vModelName = this.checkFunctionForDataFromParent(data);
        },
        getBlurAction() {
            if (this.inputBlurAction) {
                this.inputBlurAction();
                return;
            }
            if (!this.checkFieldDataAction || this.checkFieldDataAction(this.fieldDataName) === undefined || this.vModelName.length === 0) {
                return;
            }

            this.assignValueToInput(this.checkFieldDataAction(this.fieldDataName));
        },
        setCustomInputEventHandler(data) {
            this.assignValueToInput(data);
            this.collectData();
        },
        startInitialDataFillIn() {
            switch (true) {
                case this.dataForChangeFromParentStatus:
                case this.formCaseByIdTabBtnStatus:
                    this.assignValueToInput(this.dataForChangeFromParent);
                    return;
                case this.defaultDataFromParentStatus:
                    this.setCustomInputEventHandler(this.defaultDataFromParent);
                    return;
                default:
                    return;
            }
        }
    },
    watch: {
        inputValue: function () {
            if (!this.inputValue) {
                return;
            }
            this.setCustomInputEventHandler(this.inputValue);
        },
        inputDefaultValue: function () {
            this.setCustomInputEventHandler(this.inputDefaultValue);
        },
        vModelName: function () {
            if (this.inputValue || this.getReferenceListAction || this.inputDefaultValue) {
                return;
            }
            this.setCustomInputEventHandler(this.vModelName);
        },
        defaultValue: function () {
            this.assignValueToInput(this.defaultValue);
        },
    },
    mounted() {
        this.startInitialDataFillIn();
    },
}
</script>