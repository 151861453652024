import { api } from "@/api";

export default {
    state: {
        registrationNumbersTemplates: null
    },
    actions: {
        async fetchRegistrationNumbersTemplates({ commit, dispatch }){
            try {
                const token = await dispatch("fetchAccessToken");
                const registrationNumbersTemplates = await api.getSettingsRegistrationNumbersTemplates(token);
                commit("transformRegistrationNumbersTemplates", registrationNumbersTemplates);
            } catch (e) {
                console.error(e);
            }
        },
        async editRegistrationNumbersTemplates({ commit, dispatch }, [numberId, regNumberTemplateData]){
            try {
                const token = await dispatch("fetchAccessToken");
                const registrationNumbersTemplates = await api.patchSettingsRegistrationNumbersTemplates(numberId, regNumberTemplateData, token);
                commit("changeRegistrationNumbersTemplatesByFieldName", registrationNumbersTemplates);
                return registrationNumbersTemplates;
            } catch (e) {
                console.error(e);
                return "error";
            }
        },
        updateRegistrationNumbersTemplates({ commit }, registrationNumbersTemplates) {
            commit("changeRegistrationNumbersTemplates", registrationNumbersTemplates);
        },
    },
    mutations: {
        changeRegistrationNumbersTemplates(state, registrationNumbersTemplates) {
            state.registrationNumbersTemplates = registrationNumbersTemplates;
        },
        changeRegistrationNumbersTemplatesByFieldName(state, registrationNumbersTemplates) {
            state.registrationNumbersTemplates[registrationNumbersTemplates.title] = registrationNumbersTemplates;
        },
        transformRegistrationNumbersTemplates(state, registrationNumbersTemplates) {
            const templatesObject = {};
            registrationNumbersTemplates.forEach((template) => {
                templatesObject[template.title] = template;
            });
            state.registrationNumbersTemplates = templatesObject;
        },
    },
    getters: {
        getRegistrationNumbersTemplates(state) {
            return state.registrationNumbersTemplates;
        },
    },
}