export default {
    state: {
        mortemAutopsyMaterialsBtnStatus: false,
        mortemAutopsyBiopsyMaterial: null,
        mortemAutopsyBiopsyTypeMaterial: null,
        mortemAutopsyMaterialDate: null,
        mortemAutopsyMaterialPackageDefect: false,
        mortemAutopsyMaterialFormalin: false,
        mortemAutopsyMaterialDirty: false,
        mortemAutopsyFlaskNumber: null,
        mortemAutopsyLocalization: null,
        mortemAutopsyForms: null,
        mortemAutopsyCharacteristic: null,
        mortemAutopsyPosition: null,
        mortemAutopsyPiecesCount: null,
        mortemCaseByIdTabAutopsyMaterialsTableData: [],
        openMortemAutopsyMaterialAddFormBtnStatus: false,
        mortemAutopsyMaterialEditingMode: false,
        mortemAutopsyMaterialEditingItemId: null
    },
    actions: {
        updateMortemAutopsyMaterialsBtnStatus({ commit }, condition) {
            commit("changeMortemAutopsyMaterialsBtnStatus", condition);
        },
        updateMortemAutopsyMaterialPackageDefect({ commit }) {
            commit("changeMortemAutopsyMaterialPackageDefect");
        },
        updateMortemAutopsyMaterialFormalin({ commit }) {
            commit("changeMortemAutopsyMaterialFormalin");
        },
        updateMortemAutopsyMaterialDirty({ commit }) {
            commit("changeMortemAutopsyMaterialDirty");
        },
        updateAutopsyMaterialsField({ commit }, payload) {
            commit('changeAutopsyMaterialsField', payload);
        },
        updateMortemCaseByIdTabAutopsyMaterialsTableData({ commit }, payload) {
            commit('changeMortemCaseByIdTabAutopsyMaterialsTableData', payload);
        },
        updateOpenMortemAutopsyMaterialAddFormBtnStatus({ commit }, condition) {
            commit("changeOpenMortemAutopsyMaterialAddFormBtnStatus", condition);
        },
        editAutopsyMaterialsTableField({ commit }, item) {
            commit("editMortemCaseByIdTabAutopsyMaterialsTableData", item);
        },
        updateMortemAutopsyMaterialEditingMode({ commit }, condition) {
            commit("changeMortemAutopsyMaterialEditingMode", condition);
        },
        deleteAutopsyMaterialsTableField({ commit }, id) {
            commit("deleteMortemCaseByIdTabAutopsyMaterialsTableData", id);
        }
    },
    mutations: {
        changeMortemAutopsyMaterialsBtnStatus(state, condition) {
            state.mortemAutopsyMaterialsBtnStatus = condition;
        },
        changeMortemAutopsyMaterialPackageDefect(state) {
            state.mortemAutopsyMaterialPackageDefect = !state.mortemAutopsyMaterialPackageDefect;
        },
        changeMortemAutopsyMaterialFormalin(state) {
            state.mortemAutopsyMaterialFormalin = !state.mortemAutopsyMaterialFormalin;
        },
        changeMortemAutopsyMaterialDirty(state) {
            state.mortemAutopsyMaterialDirty = !state.mortemAutopsyMaterialDirty;
        },
        changeAutopsyMaterialsField(state, { key, value }) {
            state[key] = value;
        },
        changeMortemCaseByIdTabAutopsyMaterialsTableData(state, { payload, action }) {
            if (action === "add") {
                state.mortemCaseByIdTabAutopsyMaterialsTableData.push(payload);
            } else if (action === "edit") {
                state.mortemCaseByIdTabAutopsyMaterialsTableData.splice(state.mortemAutopsyMaterialEditingItemId, 1, payload);
            }
        },
        changeOpenMortemAutopsyMaterialAddFormBtnStatus(state, condition) {
            state.openMortemAutopsyMaterialAddFormBtnStatus = condition;
        },
        changeMortemAutopsyMaterialEditingMode(state, condition) {
            state.mortemAutopsyMaterialEditingMode = condition;
        },
        editMortemCaseByIdTabAutopsyMaterialsTableData(state, item) {
            state.mortemAutopsyMaterialEditingItemId = item;
            state.openMortemAutopsyMaterialAddFormBtnStatus = true;
            state.mortemAutopsyMaterialEditingMode = true;
            state.mortemAutopsyFlaskNumber = state.mortemCaseByIdTabAutopsyMaterialsTableData[item].number;
            state.mortemAutopsyLocalization = state.mortemCaseByIdTabAutopsyMaterialsTableData[item].localization;
            state.mortemAutopsyForms = state.mortemCaseByIdTabAutopsyMaterialsTableData[item].forms;
            state.mortemAutopsyCharacteristic = state.mortemCaseByIdTabAutopsyMaterialsTableData[item].characteristic;
            state.mortemAutopsyPosition = state.mortemCaseByIdTabAutopsyMaterialsTableData[item].position;
            state.mortemAutopsyPiecesCount = state.mortemCaseByIdTabAutopsyMaterialsTableData[item].pieces_count;
        },
        deleteMortemCaseByIdTabAutopsyMaterialsTableData(state, id) {
            state.mortemCaseByIdTabAutopsyMaterialsTableData.splice(id, 1);
        }
    },
    getters: {
        getMortemAutopsyMaterialsBtnStatus(state) {
            return state.mortemAutopsyMaterialsBtnStatus;
        },
        getMortemAutopsyMaterialPackageDefect(state) {
            return state.mortemAutopsyMaterialPackageDefect;
        },
        getMortemAutopsyMaterialFormalin(state) {
            return state.mortemAutopsyMaterialFormalin;
        },
        getMortemAutopsyMaterialDirty(state) {
            return state.mortemAutopsyMaterialDirty;
        },
        getAutopsyMaterialsField: (state) => (key) => {
            return state[key];
        },
        getMortemCaseByIdTabAutopsyMaterialsTableData(state) {
            return state.mortemCaseByIdTabAutopsyMaterialsTableData;
        },
        getOpenMortemAutopsyMaterialAddFormBtnStatus(state) {
            return state.openMortemAutopsyMaterialAddFormBtnStatus;
        },
        getMortemAutopsyMaterialEditingMode(state) {
            return state.mortemAutopsyMaterialEditingMode;
        }
    }
}