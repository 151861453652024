export const referenceDepartmentFields = {
    data() {
        return {
            referenceDepartmentTableDescriptionData: [
                { field: "active", header: "Активна", isSort: true, columnWidth: "90px" },
                { field: "name", header: "Полное наименование", isSort: true, columnWidth: "280px" },
                { field: "short_name", header: "Краткое наименование", isSort: true, columnWidth: "180px" },
                { field: "oid", header: "OID", columnWidth: "auto" },
                { field: "organization_name", header: "Организация", isSort: true, columnWidth: "225px" },
                { field: "address", header: "Фактический адрес", columnWidth: "190px" },
                // { field: "comment", header: "Комментарий", columnWidth: "auto" },
            ],
        }
    },
}