export default {
    state: {
        mortemAutopsyDigestiveOrgansBtnStatus: false,
        mortemCaseDigestiveOrgansTongue: null,
        mortemCaseDigestiveOrgansPalatineTonsils: null,
        mortemCaseDigestiveOrgansEsophagus: null,
        mortemCaseDigestiveOrgansStomach: null,
        mortemCaseDigestiveOrgansSmallIntestine: null,
        mortemCaseDigestiveColon: null,
        mortemCaseDigestiveOrgansLiverSize: null,
        mortemCaseDigestiveOrgansLiverMass: null,
        mortemCaseDigestiveOrgansLiverForm: null,
        mortemCaseDigestiveOrgansLiverConsistency: null,
        mortemCaseDigestiveOrgansLiverColoring: null,
        mortemCaseDigestiveOrgansLiverSurfaceCharacter: null,
        mortemCaseDigestiveOrgansLiverSectionView: null,
        mortemCaseDigestiveOrgansGallbladderDescription: null,
        mortemCaseDigestiveOrgansGallbladderSize: null,
        mortemCaseDigestiveOrgansGallbladderConsistency: null,
        mortemCaseDigestiveOrgansGallbladderWalls: null,
        mortemCaseDigestiveOrgansGallbladderExtrahepatic: null,
        mortemCaseDigestiveOrgansPancreasSize: null,
        mortemCaseDigestiveOrgansPancreasMass: null,
        mortemCaseDigestiveOrgansPancreasConsistency: null,
        mortemCaseDigestiveOrgansPancreasColor: null,
        mortemCaseDigestiveOrgansPancreasFabricPattern: null,
    },
    actions: {
        updateMortemAutopsyDigestiveOrgansBtnStatus({ commit }, condition) {
            commit("changeMortemAutopsyDigestiveOrgansBtnStatus", condition);
        },
        updateAutopsyDigestiveOrgansField({ commit }, payload) {
            commit('changeAutopsyDigestiveOrgansField', payload);
        }
    },
    mutations: {
        changeMortemAutopsyDigestiveOrgansBtnStatus(state, condition) {
            state.mortemAutopsyDigestiveOrgansBtnStatus = condition;
        },
        changeAutopsyDigestiveOrgansField(state, { key, value }) {
            state[key] = value;
        }
    },
    getters: {
        getMortemAutopsyDigestiveOrgansBtnStatus(state) {
            return state.mortemAutopsyDigestiveOrgansBtnStatus;
        },
        getAutopsyDigestiveOrgansField: (state) => (key) => {
            return state[key];
        }
    }
}