export default {
    state: {
        formProfileBtnStatus: false,
        formReferenceStaffBtnStatus: false,
        formReferenceOrganizationBtnStatus: false,
        formReferenceDepartmentBtnStatus: false,
        formReferenceDoctorBtnStatus: false,
        formReferenceLocalizationBtnStatus: false,
        formSettingsLifetimeGeneralBtnStatus: false,
        formLifetimeCasesTransferToReceptionBtnStatus: false,
        formLifetimeCasesStartReceptionMaterialBtnStatus: false,
        formLifetimeCasesStartClippingBtnStatus: false,
        formLifetimeCasesTransferToClippingBtnStatus: false,
        formLifetimeCasesTransferToSamplePreparationBtnStatus: false,
        formLifetimeCasesStartMicroscopyBtnStatus: false,
        formLifetimeCasesEndMicroscopyBtnStatus: false,
    },
    actions: {
        updateFormReferenceStaffBtnStatus({ commit }, status) {
            commit("changeFormReferenceStaffBtnStatus", status);
        },
        updateFormReferenceOrganizationBtnStatus({ commit }, status) {
            commit("changeFormReferenceOrganizationBtnStatus", status);
        },
        updateFormReferenceDepartmentBtnStatus({ commit }, status) {
            commit("changeFormReferenceDepartmentBtnStatus", status);
        },
        updateFormReferenceDoctorBtnStatus({ commit }, status) {
            commit("changeFormReferenceDoctorBtnStatus", status);
        },
        updateFormReferenceLocalizationBtnStatus({ commit }, status) {
            commit("changeFormReferenceLocalizationBtnStatus", status);
        },
        updateFormSettingsLifetimeGeneralBtnStatus({ commit }, status) {
            commit("changeFormSettingsLifetimeGeneralBtnStatus", status);
        },
        updateFormLifetimeCasesTransferToReceptionBtnStatus({ commit }, status) {
            commit("changeFormLifetimeCasesTransferToReceptionBtnStatus", status);
        },
        updateFormLifetimeCasesStartReceptionMaterialBtnStatus({ commit }, status) {
            commit("changeFormLifetimeCasesStartReceptionMaterialBtnStatus", status);
        },
        updateFormLifetimeCasesTransferToClippingBtnStatus({ commit }, status) {
            commit("changeFormLifetimeCasesTransferToClippingBtnStatus", status);
        },
        updateFormLifetimeCasesStartClippingBtnStatus({ commit }, status) {
            commit("changeFormLifetimeCasesStartClippingBtnStatus", status);
        },
        updateFormLifetimeCasesTransferToSamplePreparationBtnStatus({ commit }, status) {
            commit("changeFormLifetimeCasesTransferToSamplePreparationBtnStatus", status);
        },
        updateFormLifetimeCasesStartMicroscopyBtnStatus({ commit }, status) {
            commit("changeFormLifetimeCasesStartMicroscopyBtnStatus", status);
        },
        updateFormLifetimeCasesEndMicroscopyBtnStatus({ commit }, status) {
            commit("changeFormLifetimeCasesEndMicroscopyBtnStatus", status);
        },
    },
    mutations: {
        changeFormProfileBtnStatus(state, status) {
            state.formProfileBtnStatus = status;
        },
        changeFormReferenceStaffBtnStatus(state, status) {
            state.formReferenceStaffBtnStatus = status;
        },
        changeFormReferenceOrganizationBtnStatus(state, status) {
            state.formReferenceOrganizationBtnStatus = status;
        },
        changeFormReferenceDepartmentBtnStatus(state, status) {
            state.formReferenceDepartmentBtnStatus = status;
        },
        changeFormReferenceDoctorBtnStatus(state, status) {
            state.formReferenceDoctorBtnStatus = status;
        },
        changeFormReferenceLocalizationBtnStatus(state, status) {
            state.formReferenceLocalizationBtnStatus = status;
        },
        changeFormSettingsLifetimeGeneralBtnStatus(state, status) {
            state.formSettingsLifetimeGeneralBtnStatus = status;
        },
        changeFormLifetimeCasesTransferToReceptionBtnStatus(state, status) {
            state.formLifetimeCasesTransferToReceptionBtnStatus = status;
        },
        changeFormLifetimeCasesStartReceptionMaterialBtnStatus(state, status) {
            state.formLifetimeCasesStartReceptionMaterialBtnStatus = status;
        },
        changeFormLifetimeCasesTransferToClippingBtnStatus(state, status) {
            state.formLifetimeCasesTransferToClippingBtnStatus = status;
        },
        changeFormLifetimeCasesStartClippingBtnStatus(state, status) {
            state.formLifetimeCasesStartClippingBtnStatus = status;
        },
        changeFormLifetimeCasesTransferToSamplePreparationBtnStatus(state, status) {
            state.formLifetimeCasesTransferToSamplePreparationBtnStatus = status;
        },
        changeFormLifetimeCasesStartMicroscopyBtnStatus(state, status) {
            state.formLifetimeCasesStartMicroscopyBtnStatus = status;
        },
        changeFormLifetimeCasesEndMicroscopyBtnStatus(state, status) {
            state.formLifetimeCasesEndMicroscopyBtnStatus = status;
        },
    },
    getters: {
        getFormProfileBtnStatus(state) {
            return state.formProfileBtnStatus;
        },
        getFormReferenceStaffBtnStatus(state) {
            return state.formReferenceStaffBtnStatus;
        },
        getFormReferenceOrganizationBtnStatus(state) {
            return state.formReferenceOrganizationBtnStatus;
        },
        getFormReferenceDepartmentBtnStatus(state) {
            return state.formReferenceDepartmentBtnStatus;
        },
        getFormReferenceDoctorBtnStatus(state) {
            return state.formReferenceDoctorBtnStatus;
        },
        getFormReferenceLocalizationBtnStatus(state) {
            return state.formReferenceLocalizationBtnStatus;
        },
        getFormSettingsLifetimeGeneralBtnStatus(state) {
            return state.formSettingsLifetimeGeneralBtnStatus;
        },
        getFormLifetimeCasesTransferToReceptionBtnStatus(state) {
            return state.formLifetimeCasesTransferToReceptionBtnStatus;
        },
        getFormLifetimeCasesStartReceptionMaterialBtnStatus(state) {
            return state.formLifetimeCasesStartReceptionMaterialBtnStatus;
        },
        getFormLifetimeCasesStartClippingBtnStatus(state) {
            return state.formLifetimeCasesStartClippingBtnStatus;
        },
        getFormLifetimeCasesTransferToClippingBtnStatus(state) {
            return state.formLifetimeCasesTransferToClippingBtnStatus;
        },
        getFormLifetimeCasesTransferToSamplePreparationBtnStatus(state) {
            return state.formLifetimeCasesTransferToSamplePreparationBtnStatus;
        },
        getFormLifetimeCasesStartMicroscopyBtnStatus(state) {
            return state.formLifetimeCasesStartMicroscopyBtnStatus;
        },
        getFormLifetimeCasesEndMicroscopyBtnStatus(state) {
            return state.formLifetimeCasesEndMicroscopyBtnStatus;
        },
    }
}
