export default {
    state: {
        mortemAutopsyThoracicCavityBtnStatus: false,
        mortemCaseThoracicCavityOrgansLocation: null,
        mortemCaseThoracicCavityFluid: null,
        mortemCaseThoracicCavityLungVolume: null,
        mortemCaseThoracicCavityHeartForm: null,
        mortemCaseThoracicCavityHeartLocation: null,
        mortemCaseThoracicCavityThymusLocation: null,
        mortemCaseThoracicCavityThymusSize: null,
    },
    actions: {
        updateMortemAutopsyThoracicCavityBtnStatus({ commit }, condition) {
            commit("changeMortemAutopsyThoracicCavityBtnStatus", condition);
        },
        updateAutopsyThoracicCavityField({ commit }, payload) {
            commit('changeAutopsyThoracicCavityField', payload);
        }
    },
    mutations: {
        changeMortemAutopsyThoracicCavityBtnStatus(state, condition) {
            state.mortemAutopsyThoracicCavityBtnStatus = condition;
        },
        changeAutopsyThoracicCavityField(state, { key, value }) {
            state[key] = value;
        }
    },
    getters: {
        getMortemAutopsyThoracicCavityBtnStatus(state) {
            return state.mortemAutopsyThoracicCavityBtnStatus;
        },
        getAutopsyThoracicCavityField: (state) => (key) => {
            return state[key];
        }
    }
}