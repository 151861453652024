export default {
    state: {
        mortemAutopsyCirculatoryOrgansBtnStatus: false,
        mortemCaseCirculatoryOrgansHeartSize: null,
        mortemCaseCirculatoryOrgansHeartMass: null,
        mortemCaseCirculatoryOrgansEpicardiumPericardium: null,
        mortemCaseCirculatoryOrgansHeartMuscle: null,
        mortemCaseCirculatoryOrgansBloodFilling: null,
        mortemCaseCirculatoryOrgansBloodClots: null,
        mortemCaseCirculatoryOrgansAtrioventricularOrifices: null,
        mortemCaseCirculatoryOrgansLeftWallThickness: null,
        mortemCaseCirculatoryOrgansRightWallThickness: null,
        mortemCaseCirculatoryOrgansSeptumThickness: null,
        mortemCaseCirculatoryOrgansHeartEndocardium: null,
        mortemCaseCirculatoryOrgansHeartMyocardium: null,
        mortemCaseCirculatoryOrgansHeartValves: null,
        mortemCaseCirculatoryOrgansCoronaryArteries: null,
        mortemCaseCirculatoryOrgansHeartAorta: null,
        mortemCaseCirculatoryOrgansPulmonaryArteries: null,
        mortemCaseCirculatoryOrgansLargeVeins: null,
    },
    actions: {
        updateMortemAutopsyCirculatoryOrgansBtnStatus({ commit }, condition) {
            commit("changeMortemAutopsyCirculatoryOrgansBtnStatus", condition);
        },
        updateAutopsyCirculatoryOrgansField({ commit }, payload) {
            commit('changeAutopsyCirculatoryOrgansField', payload);
        }
    },
    mutations: {
        changeMortemAutopsyCirculatoryOrgansBtnStatus(state, condition) {
            state.mortemAutopsyCirculatoryOrgansBtnStatus = condition;
        },
        changeAutopsyCirculatoryOrgansField(state, { key, value }) {
            state[key] = value;
        }
    },
    getters: {
        getMortemAutopsyCirculatoryOrgansBtnStatus(state) {
            return state.mortemAutopsyCirculatoryOrgansBtnStatus;
        },
        getAutopsyCirculatoryOrgansField: (state) => (key) => {
            return state[key];
        }
    }
}