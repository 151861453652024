import { mapActions, mapGetters } from "vuex";
import { checking, fields } from "@/mixins";

export const emitting = {
    mixins: [checking, fields],
    data() {
        return {
            currentData: new Map(),
            changedData: new Map(),
        }
    },
    computed: {
        ...mapGetters([
            //lifetime
            "returnLifetimeCaseById",
            "getFormCaseByIdTabsDisableBtnStatus",
            "getFormCaseByIdTabCustomerBtnStatus",
            "getFormCaseByIdTabDiseaseBtnStatus",
            "getFormCaseByIdTabCommentBtnStatus",
            "getFormCaseByIdTabMicroDescriptionBtnStatus",
            //mortem
            "returnMortemCaseById",
            "getMortemCaseByIdPatientTabModalBtnStatus",
            "getMortemCaseByIdCustomerTabModalBtnStatus",
            "getMortemCaseByIdAutopsyTabModalBtnStatus",
            "getMortemCaseByIdAutopsyTabBodyModalBtnStatus",
            "getMortemCaseByIdDiseaseTabModalBtnStatus",
            "getMortemCaseByIdObjectTabModalBtnStatus",
            "getMortemCaseByIdConclusionTabModalBtnStatus",
        ]),
        getDataForChangeFromParent() {
            return (name, item, collectionName) => {
                this.currentData.set(name, item);
                return this.getCheckedDataToChangeFromParent(name, item, collectionName);
            }
        },
        getObjDataForPartialChange() {
            return (parentObjKey = false) => {
                let objDataForPartialChange;
                if (!parentObjKey) {
                    return objDataForPartialChange = Object.fromEntries(this.changedData);
                }
                objDataForPartialChange = { 
                    [parentObjKey]: Object.fromEntries(this.changedData)
                };
                return objDataForPartialChange;
            }
        }
    },
    methods: {
        ...mapActions([
            // lifetime
            "updateLifetimePartialCaseDataById",
            "updateFormCaseByIdTabsDisableBtnStatus",
            "updateFormCaseByIdTabCustomerBtnStatus",
            "updateFormCaseByIdTabDiseaseBtnStatus",
            "updateFormCaseByIdTabMicroDescriptionBtnStatus",
            //mortem
            "updateMortemPartialCaseDataById",
            "updateMortemCaseByIdTabsModalBtnStatus",
            "updateMortemCaseByIdAllTabModalBtnStatus",
            "updateMortemCaseByIdPatientTabModalBtnStatus",
            "updateMortemCaseByIdCustomerTabModalBtnStatus",
            "updateMortemCaseByIdDiseaseTabModalBtnStatus",
            "updateMortemCaseByIdDiseaseTab_OPD_FCD_ModalBtnStatus",
            "updateMortemCaseByIdDiseaseTab_PDP_ModalBtnStatus",
            "updateMortemCaseByIdDiseaseTab_PD_ModalBtnStatus",
            "updateMortemCaseByIdDiseaseTab_C_FCD_PD_ModalBtnStatus",
            "updateMortemCaseByIdAutopsyTabModalBtnStatus",
            "updateMortemCaseByIdAutopsyTabBodyModalBtnStatus",
            "updateMortemCaseByIdObjectTabModalBtnStatus",
            "updateMortemCaseByIdConclusionTabModalBtnStatus",
            "updateFormLifetimeCommentSaveBtnStatus"
            // "updateFormLifetimeCustomerSaveBtnStatus"
        ]),
        deleteItemFromChangedDataMap(fieldDataName) {
            if (!this.changedData.has(fieldDataName)) {
                return;
            }
            this.changedData.delete(fieldDataName);
        },
        changeSaveButtonFlag(fieldDataName, collectionName) {
            const saveButtonFlagAction = this.getTabFlagByFieldToBlockSaveButton(fieldDataName, collectionName);
            if (!saveButtonFlagAction) {
                return;
            }
            saveButtonFlagAction(true);
        },
        collectChildComponentData(fieldDataName, itemValue, isModify = false, collectionName) {
            const isRequiredField = this.getIsRequiredField(fieldDataName, collectionName);
            const isReferenceSearchField = this.getIsReferenceSearchField(fieldDataName, collectionName);

            this[fieldDataName] = this.getCheckedDataForServerBeforeChangeFromParent(fieldDataName, itemValue, collectionName);

            const checkFieldDataName = (this[fieldDataName] === undefined || this[fieldDataName] === null);

            switch (true) {
                case checkFieldDataName && isReferenceSearchField:
                case checkFieldDataName && isRequiredField:
                case checkFieldDataName && isRequiredField && isModify:
                case checkFieldDataName && !isModify:
                case this[fieldDataName]?.length === 0 && collectionName === "lifetimePatientCollection":
                case this[fieldDataName] === "NOGENDER" && collectionName === "lifetimePatientCollection":
                case this[fieldDataName] === "PATIENT_ADDRESS_NO_AREA" && collectionName === "lifetimePatientCollection":
                    this.deleteItemFromChangedDataMap(fieldDataName);
                    return;
                default:
                    break;
            }

            this.changedData.set(fieldDataName, itemValue);

            this.changeSaveButtonFlag(fieldDataName, collectionName);
        },

        async savePartialDataCaseById() {
            let status = this.getFormCaseByIdTabsDisableBtnStatus;
            let data;

            const tabModalBtnActions = {
                lifetimeActionCustomer: this.updateFormCaseByIdTabCustomerBtnStatus,
                lifetimeActionDisease: this.updateFormCaseByIdTabDiseaseBtnStatus,
                lifetimeActionComment: this.updateFormCaseByIdTabCommentBtnStatus,
                lifetimeActionMicroDescription: this.updateFormCaseByIdTabMicroDescriptionBtnStatus,
                mortemActionCustomer: this.updateMortemCaseByIdCustomerTabModalBtnStatus,
                mortemActionAutopsy: this.updateMortemCaseByIdAutopsyTabModalBtnStatus,
                mortemActionAutopsyBody: this.updateMortemCaseByIdAutopsyTabBodyModalBtnStatus,
                mortemActionConclusion: this.updateMortemCaseByIdConclusionTabModalBtnStatus,
            };
            const tabModalBtnStatuses = {
                lifetimeStatusCustomer: this.getFormCaseByIdTabCustomerBtnStatus,
                lifetimeStatusDisease: this.getFormCaseByIdTabDiseaseBtnStatus,
                lifetimeStatusComment: this.getFormCaseByIdTabCommentBtnStatus,
                lifetimeStatusMicroDescription: this.getFormCaseByIdTabMicroDescriptionBtnStatus,
                mortemStatusCustomer: this.getMortemCaseByIdCustomerTabModalBtnStatus,
                mortemStatusAutopsyBody: this.getMortemCaseByIdAutopsyTabBodyModalBtnStatus,
                mortemStatusConclusion: this.getMortemCaseByIdConclusionTabModalBtnStatus,
            };

            switch (status) {
                case tabModalBtnStatuses.lifetimeStatusCustomer:
                    data = this.getObjDataForPartialChange("request_organization");
                    console.log(data); //LOG
                    await this.updateLifetimePartialCaseDataById(data);
                    // 1) в этом месте будем проверять статус ответа с сервера. если ОК, очищаем коллекции,
                    // закрываем модалку показываем зеленый виджет, что все ок
                    // 2) если НЕ ОК, данные не очищаем, модалку не закрываем, выводим виджет с телом ошибки с подсказкой, где ошибка
                    this.currentData.clear();
                    this.changedData.clear();
                    this.closeLifetimeTabModal(
                        tabModalBtnActions.lifetimeActionCustomer
                    );
                    this.updateFormLifetimeCustomerSaveBtnStatus(false);//block the save button
                    break;
                case tabModalBtnStatuses.lifetimeStatusDisease:
                    data = this.getObjDataForPartialChange();
                    console.log(data); //LOG
                    await this.updateLifetimePartialCaseDataById(data);
                    // 1) в этом месте будем проверять статус ответа с сервера. если ОК, очищаем коллекции,
                    // закрываем модалку показываем зеленый виджет, что все ок
                    // 2) если НЕ ОК, данные не очищаем, модалку не закрываем, выводим виджет с телом ошибки с подсказкой, где ошибка
                    this.currentData.clear();
                    this.changedData.clear();
                    this.closeLifetimeTabModal(
                        tabModalBtnActions.lifetimeActionDisease
                    );
                    this.updateFormLifetimeDiseaseSaveBtnStatus(false);//block the save button
                    break;
                case tabModalBtnStatuses.lifetimeStatusComment:
                    data = this.getObjDataForPartialChange();
                    console.log(data); //LOG
                    await this.updateLifetimePartialCaseDataById(data);
                    // 1) в этом месте будем проверять статус ответа с сервера. если ОК, очищаем коллекции,
                    // закрываем модалку показываем зеленый виджет, что все ок
                    // 2) если НЕ ОК, данные не очищаем, модалку не закрываем, выводим виджет с телом ошибки с подсказкой, где ошибка
                    this.currentData.clear();
                    this.changedData.clear();
                    this.closeLifetimeTabModal(
                        tabModalBtnActions.lifetimeActionComment
                    );
                    this.updateFormLifetimeCommentSaveBtnStatus(false);//block the save button
                    break;
                case tabModalBtnStatuses.lifetimeStatusMicroDescription:
                    data = this.getObjDataForPartialChange("report");
                    console.log(data); //LOG
                    await this.updateLifetimePartialCaseDataById(data);
                    // 1) в этом месте будем проверять статус ответа с сервера. если ОК, очищаем коллекции,
                    // закрываем модалку показываем зеленый виджет, что все ок
                    // 2) если НЕ ОК, данные не очищаем, модалку не закрываем, выводим виджет с телом ошибки с подсказкой, где ошибка
                    this.currentData.clear();
                    this.changedData.clear();
                    this.closeLifetimeTabModal(
                        tabModalBtnActions.lifetimeActionMicroDescription
                    );
                    break;
                case tabModalBtnStatuses.mortemStatusCustomer:
                    data = this.getObjDataForPartialChange("request_organization");
                    console.log(data); //LOG
                    await this.updateMortemPartialCaseDataById(data);
                    // 1) в этом месте будем проверять статус ответа с сервера. если ОК, очищаем коллекции,
                    // закрываем модалку показываем зеленый виджет, что все ок
                    // 2) если НЕ ОК, данные не очищаем, модалку не закрываем, выводим виджет с телом ошибки с подсказкой, где ошибка
                    this.currentData.clear();
                    this.changedData.clear();
                    this.closeMortemTabModal(
                        tabModalBtnActions.mortemActionCustomer
                    );
                    break;
                case tabModalBtnStatuses.mortemStatusAutopsyBody:
                    data = this.getObjDataForPartialChange("autopsy_examination");
                    console.log(data); //LOG
                    await this.updateMortemPartialCaseDataById(data);
                    // 1) в этом месте будем проверять статус ответа с сервера. если ОК, очищаем коллекции,
                    // закрываем модалку показываем зеленый виджет, что все ок
                    // 2) если НЕ ОК, данные не очищаем, модалку не закрываем, выводим виджет с телом ошибки с подсказкой, где ошибка
                    this.currentData.clear();
                    this.changedData.clear();
                    this.closeMortemTabModal(
                        tabModalBtnActions.mortemActionAutopsyBody,
                        tabModalBtnActions.mortemActionAutopsy
                    );
                    break;
                case tabModalBtnStatuses.mortemStatusConclusion:
                    data = this.getObjDataForPartialChange("conclusion");
                    console.log(data); //LOG
                    await this.updateMortemPartialCaseDataById(data);
                    // 1) в этом месте будем проверять статус ответа с сервера. если ОК, очищаем коллекции,
                    // закрываем модалку показываем зеленый виджет, что все ок
                    // 2) если НЕ ОК, данные не очищаем, модалку не закрываем, выводим виджет с телом ошибки с подсказкой, где ошибка
                    this.currentData.clear();
                    this.changedData.clear();
                    this.closeMortemTabModal(
                        tabModalBtnActions.mortemActionConclusion
                    );
                    break;
                default:
                    data;
                    break;
            }
        },
        closeLifetimeTabModal(updateModalStatus, updateCategoryModalStatus = "none") {
            this.updateFormCaseByIdTabsDisableBtnStatus(false);
            if (updateCategoryModalStatus !== "none"){
                updateCategoryModalStatus(false);
            }
            updateModalStatus(false);
        },
        closeMortemTabModal(updateModalStatus, updateCategoryModalStatus = "none") {
            this.updateMortemCaseByIdTabsModalBtnStatus();
            if (updateCategoryModalStatus !== "none"){
                updateCategoryModalStatus(false);
            }
            updateModalStatus(false);
        },
    }
} 