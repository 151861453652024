<template>
    <input
        :id="checkboxId"
        v-model.trim="vModelName"
        type="checkbox"
        :class="checkboxClasses"
        :name="checkboxName"
        :value="checkboxValue"
        @change.stop.prevent="collectData"
        @click.stop.prevent="collectDataToCheckboxClick"
    />
    <label
        :for="checkboxId"
        :class="checkboxLabelClasses"
    >
        {{ checkboxLabelTitle }}
    </label>
</template>
<script>
export default {
    name: "AppCheckbox",
    emits: ["collectComponentData", "actionByCheckboxClick"],
    props: {
        checkboxId: String,
        checkboxClasses: Array,
        checkboxName: String,
        checkboxValue: Boolean,
        isSelectAllCheckboxes: Boolean,
        checkboxClickAction: Function,
        checkboxLabelClasses: Array,
        checkboxLabelTitle: String,
        collectDataToStore: Function,
        actionByCheckboxClick: Function,
        fieldCollectionName: String,
        dataForChangeFromParent: Boolean,
        dataForChangeFromParentStatus: Boolean,
        checkFunctionForDataFromParent: Function,
    },
    data() {
        return {
            vModelName: false
        }
    },
    methods: {
        collectData() {
            this.$emit("collectComponentData", this.checkboxName, this.vModelName, this.dataForChangeFromParentStatus, this.fieldCollectionName);

            if (!this.collectDataToStore) {
                return;
            }
            this.collectDataToStore();
        },
        collectDataToCheckboxClick() {
            this.$emit("actionByCheckboxClick");
        },
        startInitialDataFillIn() {
            if (!this.dataForChangeFromParentStatus) {
                return;
            }
            this.assignValueToInput(this.dataForChangeFromParent);
        },
        assignValueToInput(data) {
            if (!this.checkFunctionForDataFromParent) {
                this.vModelName = data;
                return;
            }
            this.vModelName = this.checkFunctionForDataFromParent(data);
        },
    },
    watch: {
        isSelectAllCheckboxes: function() {
            this.vModelName = this.isSelectAllCheckboxes;
            this.collectData();
        }
    },
    mounted() {
        this.startInitialDataFillIn();
    },
}
</script>