export default {
    state: {
        mortemCaseByIdTabsDisableBtnStatus: false,
        autopsyConfirmDialogCondition: false
    },
    actions: {
        updateMortemCaseByIdTabsDisableBtnStatus({ commit }, mortemCaseByIdTabsDisableBtnStatus) {
            commit("changeMortemCaseByIdTabsDisableBtnStatus", mortemCaseByIdTabsDisableBtnStatus);
        },
        updateAutopsyConfirmDialogCondition({ commit }, autopsyConfirmDialogCondition) {
            commit("changeAutopsyConfirmDialogCondition", autopsyConfirmDialogCondition);
        }
    },
    mutations: {
        changeMortemCaseByIdTabsDisableBtnStatus(state, mortemCaseByIdTabsDisableBtnStatus) {
            state.mortemCaseByIdTabsDisableBtnStatus = mortemCaseByIdTabsDisableBtnStatus;
        },
        changeAutopsyConfirmDialogCondition(state, autopsyConfirmDialogCondition) {
            state.autopsyConfirmDialogCondition = autopsyConfirmDialogCondition;
        }
    },
    getters: {
        getMortemCaseByIdTabsDisableBtnStatus(state) {
            return state.mortemCaseByIdTabsDisableBtnStatus;
        },
        getAutopsyConfirmDialogCondition(state) {
            return state.autopsyConfirmDialogCondition;
        }
    }
}