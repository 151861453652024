export default {
    state: {
        mortemAutopsyBloodformingOrgansBtnStatus: false,
        mortemCaseBloodformingOrgansSpleenSize: null,
        mortemCaseBloodformingOrgansSpleenMass: null,
        mortemCaseBloodformingOrgansSpleenConsistency: null,
        mortemCaseBloodformingOrgansSpleenExteriorView: null,
        mortemCaseBloodformingOrgansSpleenSectionView: null,
        mortemCaseBloodformingOrgansSpleenScrapingCharacter: null,
        mortemCaseBloodformingOrgansLymphMudflakes: null,
        mortemCaseBloodformingOrgansLymphMediastinum: null,
        mortemCaseBloodformingOrgansNeckLymph: null,
        mortemCaseBloodformingOrgansBoneMarrow: null,
    },
    actions: {
        updateMortemAutopsyBloodformingOrgansBtnStatus({ commit }, condition) {
            commit("changeMortemAutopsyBloodformingOrgansBtnStatus", condition);
        },
        updateAutopsyBloodformingOrgansField({ commit }, payload) {
            commit('changeAutopsyBloodformingOrgansField', payload);
        }
    },
    mutations: {
        changeMortemAutopsyBloodformingOrgansBtnStatus(state, condition) {
            state.mortemAutopsyBloodformingOrgansBtnStatus = condition;
        },
        changeAutopsyBloodformingOrgansField(state, { key, value }) {
            state[key] = value;
        }
    },
    getters: {
        getMortemAutopsyBloodformingOrgansBtnStatus(state) {
            return state.mortemAutopsyBloodformingOrgansBtnStatus;
        },
        getAutopsyBloodformingOrgansField: (state) => (key) => {
            return state[key];
        }
    }
}