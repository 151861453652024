export const referenceBiopsyTypeFields = {
    data() {
        return {
            referenceBiopsyTypeTableDescriptionData: [
                { field: "active", header: "Активна", columnWidth: "66px"},
                { field: "biopsy_type_title", header: "Название", columnWidth: "400px"},
                { field: "biopsy_type_material", header: "Биопсийный материал", columnWidth: "400px"},
                { field: "comment", header: "Комментарий", columnWidth: "auto"},
            ],
        }
    },
}