export const referenceDefectTypeCaseFields = {
    data() {
        return {
            referenceDefectTypeCaseTableDescriptionData: [
                { field: "active", header: "Активна", columnWidth: "66px"},
                { field: "title", header: "Название", columnWidth: "300px"},
                { field: "type_of_defect", header: "Тип брака", columnWidth: "100px"},
                { field: "defect_object", header: "Объект", columnWidth: "100px"},
                { field: "joint_choice", header: "Совместный выбор", columnWidth: "150px"},
                { field: "comment", header: "Комментарий", columnWidth: "auto"},
            ],
        }
    },
}