import { lifetimeApi } from "@/api";

export default {
    state: {
        lifetimeArchiveCases: [],
        lifetimeArchivePage: 1,
        lifetimeArchivePageSearch: 1,
        lifetimeArchivePageSize: 50,
        lifetimeArchiveCasesSearchData: [],
        lifetimeArchiveCasesSearchInputData: "",
        lifetimeArchiveCasesInputSortDateData: null,
    },
    actions: {
        async getNextArchiveLifetimeCases({ state, dispatch }) {
            if ((state.lifetimeArchiveCases?.next === null && state.lifetimeArchiveCasesSearchData?.next === undefined) || state.lifetimeArchiveCasesSearchData?.next === null) {
                return;
            }
            await dispatch({type: "getLifetimeArchiveCases", payload: true});
        },
        async getLifetimeArchiveCases({ dispatch, state }, nextStatus = false, lifetimeArchiveSearchParams = state.lifetimeArchiveCasesSearchInputData) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                if (state.lifetimeArchiveCasesSearchInputData?.length !== 0) {
                    if (nextStatus) {
                        dispatch("updateLifetimeArchivePageSearch", state.lifetimeArchivePageSearch + 1);
                    }
                    dispatch("fetchLifetimeArchiveCasesSearchData", [nextStatus, token, state.lifetimeArchivePageSearch, lifetimeArchiveSearchParams]);
                    return;
                }
                if (nextStatus) {
                    dispatch("updateLifetimeArchivePage", state.lifetimeArchivePage + 1);
                }
                dispatch("fetchLifetimeArchiveCases", [nextStatus, token, state.lifetimeArchivePage, lifetimeArchiveSearchParams]);
            });
        },
        async fetchLifetimeArchiveCases({ dispatch, state }, [nextStatus, token, lifetimeArchivePage, lifetimeArchiveSearchParams]) {
            try {
                localStorage.removeItem("vuex");

                const archiveCases = await lifetimeApi.getLifetimeArchiveCases(lifetimeArchivePage, state.lifetimeArchivePageSize, token, lifetimeArchiveSearchParams);

                // dispatch("setExceptionAccordingResponseCode", archiveCases);
                dispatch("updateLifetimeArchiveCases", [archiveCases, nextStatus]);
            } catch (e) {
                console.error(e);
                // dispatch("setExceptionAccordingResponseCode", e.status);
            }
        },
        async fetchLifetimeArchiveCasesSearchData({ state, dispatch }, [nextStatus, token, lifetimeArchivePage, lifetimeArchiveSearchParams]) {
            if (lifetimeArchiveSearchParams === undefined || (lifetimeArchiveSearchParams?.length === 0 && state.lifetimeArchiveCasesSearchInputData?.length === 0)) {
                dispatch("updateLifetimeArchiveCasesSearchData", [[], nextStatus]);
                return;
            }
            try {
                localStorage.removeItem("vuex");

                const archiveCases = await lifetimeApi.getLifetimeArchiveCases(lifetimeArchivePage, state.lifetimeArchivePageSize, token, lifetimeArchiveSearchParams);

                // dispatch("setExceptionAccordingResponseCode", archiveCases);

                if (archiveCases?.results !== undefined && archiveCases?.count === 0) {
                    dispatch("updateLifetimeArchiveCasesSearchData", [[], nextStatus]);
                    return;
                }

                dispatch("updateLifetimeArchiveCasesSearchData", [archiveCases, nextStatus]);
            } catch (e) {
                console.error(e);
                // dispatch("setExceptionAccordingResponseCode", e.status);
            }
        },
        updateLifetimeArchivePage({ commit }, page){
            commit("changeLifetimeArchivePage", page);
        },
        updateLifetimeArchiveCases({ commit }, archiveCases) {
            commit("changeLifetimeArchiveCases", archiveCases);
        },
        updateLifetimeArchivePageSearch({ commit }, page) {
            commit("changeLifetimeArchivePageSearch", page);
        },
        updateLifetimeArchiveCasesSearchData({ commit }, lifetimeArchiveCasesSearchData) {
            commit("changeLifetimeArchiveCasesSearchData", lifetimeArchiveCasesSearchData);
        },
        updateLifetimeArchiveCasesSearchInputData({ commit }, lifetimeArchiveCasesSearchInputData) {
            commit("changeLifetimeArchiveCasesSearchInputData", lifetimeArchiveCasesSearchInputData);
        },
        updateLifetimeArchiveCasesInputSortDateData({ commit }, lifetimeArchiveCasesInputSortDateData) {
            commit("changeLifetimeArchiveCasesInputSortDateData", lifetimeArchiveCasesInputSortDateData);
        }
    },
    mutations: {
        changeLifetimeArchiveCases(state, [archiveCases, nextStatus]) {
            if (state.lifetimeArchiveCases?.length === 0) {
                state.lifetimeArchiveCases = archiveCases;
                return;
            }
            if (!nextStatus) {
                return;
            }
            state.lifetimeArchiveCases.next = archiveCases?.next;
            state.lifetimeArchiveCases.previous = archiveCases?.previous;
            state.lifetimeArchiveCases.results = [...state.lifetimeArchiveCases?.results, ...archiveCases?.results];
        },
        changeLifetimeArchivePage(state, page) {
            state.lifetimeArchivePage = page;
        },
        changeLifetimeArchiveCasesSearchData(state, [lifetimeArchiveCasesSearchData, nextStatus]) {
            if (state.lifetimeArchiveCasesSearchData?.length === 0 || state.lifetimeArchiveCasesSearchInputData?.length === 0 || state.lifetimeArchiveCasesSearchData?.next === null) {
                state.lifetimeArchiveCasesSearchData = lifetimeArchiveCasesSearchData;
                return;
            }
            if (!nextStatus) {
                state.lifetimeArchiveCasesSearchData = lifetimeArchiveCasesSearchData;
                return;
            }
            state.lifetimeArchiveCasesSearchData.next = lifetimeArchiveCasesSearchData?.next;
            state.lifetimeArchiveCasesSearchData.previous = lifetimeArchiveCasesSearchData?.previous;
            state.lifetimeArchiveCasesSearchData.results = [...state.lifetimeArchiveCasesSearchData?.results, ...lifetimeArchiveCasesSearchData?.results];
        },
        changeLifetimeArchivePageSearch(state, page) {
            state.lifetimeArchivePageSearch = page;
        },
        changeLifetimeArchiveCasesSearchInputData(state, lifetimeArchiveCasesSearchInputData) {
            state.lifetimeArchiveCasesSearchInputData = lifetimeArchiveCasesSearchInputData;
        },
        changeLifetimeArchiveCasesInputSortDateData(state, lifetimeArchiveCasesInputSortDateData) {
            state.lifetimeArchiveCasesInputSortDateData = {...state.lifetimeArchiveCasesInputSortDateData, ...lifetimeArchiveCasesInputSortDateData};
        }
    },
    getters: {
        allLifetimeArchiveCases(state) {
            return state.lifetimeArchiveCases;
        },
        getLifetimeArchivePage(state) {
            return state.lifetimeArchivePage;
        },
        getLifetimeArchiveCasesSearchData(state) {
            return state.lifetimeArchiveCasesSearchData;
        },
        getLifetimeArchiveCasesSearchInputData(state) {
            return state.lifetimeArchiveCasesSearchInputData;
        },
        getLifetimeArchiveCasesInputSortDateData(state) {
            return state.lifetimeArchiveCasesInputSortDateData;
        },
    }
}