import {
    putLifetimeCasePatientByIdPath,
    putLifetimeCaseBiopsyByIdPath,
    putLifetimeCaseDiseaseByIdPath,
    putLifetimeCaseCommentByIdPath,
    putLifetimeCaseRequestOrganizationByIdPath,
    putLifetimeCaseInformationByIdPath,
} from "@/api/endpoints";

import { executeRequestToServer } from "@/api/services/performers";

export const lifetimeCasesTabsApi = {
    // PATIENT
    putLifetimeCasePatientById: async (caseId, updatedPatientData, token) => executeRequestToServer(putLifetimeCasePatientByIdPath(caseId), "PUT", token, updatedPatientData),

    // BIOPSY
    putLifetimeCaseBiopsyById: async (caseId, updatedBiopsyData, token) => executeRequestToServer(putLifetimeCaseBiopsyByIdPath(caseId), "PUT", token, updatedBiopsyData),

    // DISEASE
    putLifetimeCaseDiseaseById: async (caseId, updatedDiseaseData, token) => executeRequestToServer(putLifetimeCaseDiseaseByIdPath(caseId), "PUT", token, updatedDiseaseData),

    // COMMENTS
    putLifetimeCaseCommentById: async (caseId, updatedCommentData, token) => executeRequestToServer(putLifetimeCaseCommentByIdPath(caseId), "PUT", token, updatedCommentData),

    // CUSTOMER
    putLifetimeCaseRequestOrganizationById: async (caseId, updatedRequestOrganizationData, token) => executeRequestToServer(putLifetimeCaseRequestOrganizationByIdPath(caseId), "PUT", token, updatedRequestOrganizationData),

    // REGISTRATION INFORMATION
    putLifetimeCaseInformationById: async (caseId, updatedInformationData, token) => executeRequestToServer(putLifetimeCaseInformationByIdPath(caseId), "PUT", token, updatedInformationData),
}