export default {
    state: {
        mortemAutopsyEndocrineGlandsBtnStatus: false,
        mortemCaseEndocrineGlandsThyroidSize: null,
        mortemCaseEndocrineGlandsThyroidMass: null,
        mortemCaseEndocrineGlandsThyroidConsistency: null,
        mortemCaseEndocrineGlandsThyroidExteriorView: null,
        mortemCaseEndocrineGlandsThyroidSectionView: null,
        mortemCaseEndocrineGlandsAdrenalForm: null,
        mortemCaseEndocrineGlandsAdrenalColor: null,
        mortemCaseEndocrineGlandsAdrenalPicture: null,
        mortemCaseEndocrineGlandsPituitary: null,
        mortemCaseEndocrineGlandsParathyroid: null,
    },
    actions: {
        updateMortemAutopsyEndocrineGlandsBtnStatus({ commit }, condition) {
            commit("changeMortemAutopsyEndocrineGlandsBtnStatus", condition);
        },
        updateAutopsyEndocrineGlandsField({ commit }, payload) {
            commit('changeAutopsyEndocrineGlandsField', payload);
        }
    },
    mutations: {
        changeMortemAutopsyEndocrineGlandsBtnStatus(state, condition) {
            state.mortemAutopsyEndocrineGlandsBtnStatus = condition;
        },
        changeAutopsyEndocrineGlandsField(state, { key, value }) {
            state[key] = value;
        }
    },
    getters: {
        getMortemAutopsyEndocrineGlandsBtnStatus(state) {
            return state.mortemAutopsyEndocrineGlandsBtnStatus;
        },
        getAutopsyEndocrineGlandsField: (state) => (key) => {
            return state[key];
        }
    }
}