import { mapGetters } from "vuex";

export const widgetSortDateFromToFieldNotification = {
    computed: {
        ...mapGetters([
            "getLifetimeCasesInputSortDateFromPrintFillValidStatus",
            "getLifetimeCasesInputSortDateToPrintFillValidStatus",
            "getLifetimeCasesInputSortDateValidStatus",
            "getLifetimeCasesInputSortDateFromFillValidStatus",
            "getLifetimeCasesInputSortDateToFillValidStatus"
        ]),
        getWidgetSortDateFromToFieldNotificationConditionInfluencePanel() {
            return (fieldName) => {
                switch (fieldName) {
                    case "dateFrom":
                        switch (true) {
                            case !this.getLifetimeCasesInputSortDateValidStatus && (this.getLifetimeCasesInputSortDateFromFillValidStatus && this.getLifetimeCasesInputSortDateToFillValidStatus):
                            case this.getLifetimeCasesInputSortDateValidStatus && (!this.getLifetimeCasesInputSortDateFromFillValidStatus && this.getLifetimeCasesInputSortDateToFillValidStatus):
                                return true;
                            default:
                                return false;
                        }
                    case "dateTo":
                        switch (true) {
                            case this.getLifetimeCasesInputSortDateValidStatus && (this.getLifetimeCasesInputSortDateFromFillValidStatus && !this.getLifetimeCasesInputSortDateToFillValidStatus):
                                return true;
                            case !this.getLifetimeCasesInputSortDateValidStatus && (this.getLifetimeCasesInputSortDateFromFillValidStatus && this.getLifetimeCasesInputSortDateToFillValidStatus):
                            case this.getLifetimeCasesInputSortDateValidStatus && (!this.getLifetimeCasesInputSortDateFromFillValidStatus && this.getLifetimeCasesInputSortDateToFillValidStatus):
                                return false;
                            default:
                                return "";
                        }
                    default:
                        return false;
                }
            }
        },
        getWidgetInputFieldNotificationClasses() {
            return (fieldName) => {
                switch (fieldName) {
                    case "dateFrom":
                        switch (true) {
                            case !this.getLifetimeCasesInputSortDatePrintValidStatus && (this.getLifetimeCasesInputSortDateFromPrintFillValidStatus && this.getLifetimeCasesInputSortDateToPrintFillValidStatus):
                                return "field__invalid";
                            case !this.getLifetimeCasesInputSortDateFromPrintFillValidStatus:
                                return "field__hint";
                            default:
                                return "";
                        }
                    case "dateTo":
                        switch (true) {
                            case !this.getLifetimeCasesInputSortDateToPrintFillValidStatus:
                                return "field__hint";
                            default:
                                return "";
                        }
                    default:
                        return "";
                }
            }
        },
        getWidgetSortDateFromToFieldNotificationClassesInfluencePanel() {
            return (fieldName) => {
                switch (fieldName) {
                    case "dateFrom":
                        switch (true) {
                            case !this.getLifetimeCasesInputSortDateValidStatus && (this.getLifetimeCasesInputSortDateFromFillValidStatus && this.getLifetimeCasesInputSortDateToFillValidStatus):
                            case this.getLifetimeCasesInputSortDateValidStatus && (!this.getLifetimeCasesInputSortDateFromFillValidStatus && this.getLifetimeCasesInputSortDateToFillValidStatus):
                                return "field__invalid";
                            default:
                                return "";
                        }
                    case "dateTo":
                        switch (true) {
                            case this.getLifetimeCasesInputSortDateValidStatus && (this.getLifetimeCasesInputSortDateFromFillValidStatus && !this.getLifetimeCasesInputSortDateToFillValidStatus):
                                return "field__invalid";
                            default:
                                return "";
                        }
                    default:
                        return "";
                }
            }
        },
    }
}