<template>
    <span
        class="field__invalid_message"
        :class="fieldNotificationClasses"
    >
        {{ fieldNotification }}
    </span>
</template>

<script>
export default {
    name: "FormInputFieldNotification",
    props: {
        fieldNotification: String,
        fieldNotificationClasses: Array
    }
};
</script>
