import { api } from "@/api";
import { debounceLoader } from "@/helpers/debounceLoader";


export default {
    state: {
        settingsReferenceLocalizationPage: 1,
        settingsReferenceLocalizationPageSize: 50,
        settingsReferenceLocalizations: {},
        settingsReferenceLocalizationsTotalPages: null,
        isSettingsReferenceLocalizationsLoading: false,
    },
    actions: {
        async fetchSettingsReferenceLocalization({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceLocalizationsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const localizations = await debounceLoader(
                        api.getSettingsReferenceLocalization(queryParams, token),
                        "settingsReference"
                    );
                    commit("changeSettingsReferenceLocalizations", localizations.results);
                    commit("changeSettingsReferenceLocalizationsTotalPages", localizations.total_pages);
                    commit("updateSettingsReferenceLocalizationsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferenceLocalizationsLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
                commit("updateSettingsReferenceLocalizationsLoader", false);
            }
        },
        async fetchMoreSettingsReferenceLocalizations({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceLocalizationsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const localizations = await api.getSettingsReferenceLocalization(queryParams, token);
                    commit("updateMoreSettingsReferenceLocalizations", localizations.results);
                    commit("updateSettingsReferenceLocalizationsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferenceLocalizationsLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("updateSettingsReferenceLocalizationsLoader", false);
            }

        },
        async addSettingsReferenceLocalizations({ dispatch, commit }, localization ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const localizationData = await api.postSettingsReferenceLocalization(localization, token);
                    if (typeof localizationData === "object") {
                        commit("addNewSettingsReferenceLocalizations", localizationData);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },

        async editSettingsReferenceLocalizations({ dispatch, commit }, localization ) {
            const token = await dispatch("getAccessToken");
            if (token) {
                const localizationData = await api.patchSettingsReferenceLocalization(localization, token);
                if (typeof localizationData === "object") {
                    commit("updateSettingsReferenceLocalizations", localizationData);
                }
            } else {
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                return;
            }
        },
    },
    mutations: {
        changeSettingsReferenceLocalizations(state, settingsReferenceLocalizations) {
            state.settingsReferenceLocalizations = settingsReferenceLocalizations;
        },
        updateMoreSettingsReferenceLocalizations(state, settingsReferenceLocalizations) {
            state.settingsReferenceLocalizations = [...state.settingsReferenceLocalizations, ...settingsReferenceLocalizations];
        },
        changeSettingsReferenceLocalizationsTotalPages(state, settingsReferenceLocalizationsTotalPages) {
            state.settingsReferenceLocalizationsTotalPages = settingsReferenceLocalizationsTotalPages;
        },
        updateSettingsReferenceLocalizationsLoader(state, condition) {
            state.isSettingsReferenceLocalizationsLoading = condition;
        },
        updateSettingsReferenceLocalizations(state, localization) {
            const index = state.settingsReferenceLocalizations.findIndex(item => item.id === localization.id);
            if (index !== -1) {
                const updatedLocalization = { ...localization }; // Создаем копию объекта localization
                const uniqueId = Object.values(updatedLocalization).join('_'); // Создаем уникальный идентификатор на основе значений объекта
                updatedLocalization.uniqueId = uniqueId; // Добавляем новое поле uniqueId к объекту
                state.settingsReferenceLocalizations.splice(index, 1, updatedLocalization); // Заменяем объект в массиве
            }
        },
        addNewSettingsReferenceLocalizations(state, localization) {
            state.settingsReferenceLocalizations.unshift(localization);
        },
    },
    getters: {
        getSettingsReferenceLocalizations(state) {
            return state.settingsReferenceLocalizations;
        },
        getSettingsReferenceLocalizationsTotalPages(state) {
            return state.settingsReferenceLocalizationsTotalPages;
        },
        getSettingsReferenceLocalizationsLoaderStatus(state) {
            return state.isSettingsReferenceLocalizationsLoading;
        },
    }
}