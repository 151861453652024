export const referenceDefectTypeMaterialFields = {
    data() {
        return {
            referenceDefectTypeMaterialTableDescriptionData: [
                { field: "active", header: "Активна", columnWidth: "66px"},
                { field: "title", header: "Название", columnWidth: "400px"},
                { field: "default_value", header: "Значение", columnWidth: "400px"},
                { field: "comment", header: "Комментарий", columnWidth: "auto"},
            ],
        }
    },
}