export const referenceSamplePreparationDefectsFields = {
    data() {
        return {
            referenceSamplePreparationDefectsTableDescriptionData: [
                { field: "active", header: "Активна", columnWidth: "100px"},
                { field: "title", header: "Название", columnWidth: "485px"},
                { field: "type_of_defect", header: "Тип брака", columnWidth: "145px"},
                { field: "defect_object_ru", header: "Объект", columnWidth: "120px"},
                { field: "comment", header: "Комментарий", columnWidth: "auto"},
            ],
        }
    },
}