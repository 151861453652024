import { lifetimeApi } from "@/api";

export default {
    state: {
        samplePreparationsFiltersButtonStatus: false,
        samplePreparationFilterFromDate: null,
        samplePreparationFilterToDate: null,
        samplePreparationsSortByFilterValue: null,
        samplePreparationsSelectOptionResponsibleData: null,
        samplePreparationsSelectOptionMethodsData: null,
        samplePreparationsSelectOptionSeriesNumberData: null,
        samplePreparationStageFilter: null,
        samplePreparationSelectedCassette: [],
        samplePreparationSelectedMicro: [],
        samplePreparationCassettes: [],
        samplePreparationTotalPages: null,
        samplePreparationTotalCount: null,
        isSamplePreparationCassettesLoading: false,
    },
    actions: {
        async fetchSamplePreparationCassettes({ dispatch, commit }, [queryParams, isFetchByWebSocket = false]) {
            if (!isFetchByWebSocket) {
                commit("updateSamplePreparationCassettesLoader", true);
            }
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const samplePreparationCassettes = await lifetimeApi.getSamplePreparationCassette(queryParams, token);
                    if (typeof samplePreparationCassettes === "object") {
                        commit("updateSamplePreparationCassettes", samplePreparationCassettes.results);
                        commit("updateSamplePreparationTotalPages", samplePreparationCassettes.total_pages);
                        commit("updateSamplePreparationTotalCount", samplePreparationCassettes.count);
                        commit("updateSamplePreparationCassettesLoader", false);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSamplePreparationCassettesLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("updateSamplePreparationCassettesLoader", false);
            }

        },
        async fetchMoreSamplePreparationCassettes({ dispatch, commit }, queryParams) {
            commit("updateSamplePreparationCassettesLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const samplePreparationCassettes = await lifetimeApi.getSamplePreparationCassette(queryParams, token);
                    if (typeof samplePreparationCassettes === "object") {
                        commit("updateMoreSamplePreparationCassettes", samplePreparationCassettes.results);
                        commit("updateSamplePreparationCassettesLoader", false);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSamplePreparationCassettesLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("updateSamplePreparationCassettesLoader", false);
            }

        },
        updateSamplePreparationStageFilter({ commit }, samplePreparationStageFilter) {
            commit("changeSamplePreparationStageFilter", samplePreparationStageFilter);
        },
        updateSamplePreparationsFiltersButtonStatus({ commit }, samplePreparationsFiltersButtonStatus) {
            commit("changeSamplePreparationsFiltersButtonStatus", samplePreparationsFiltersButtonStatus);
        },
        updateSamplePreparationsSortByFilterValue({ commit }, samplePreparationsSortByFilterValue) {
            commit("changeSamplePreparationsSortByFilterValue", samplePreparationsSortByFilterValue);
        },
        updateSamplePreparationFilterFromDate({ commit }, samplePreparationsSortByFilterValue) {
            commit("changeSamplePreparationFilterFromDate", samplePreparationsSortByFilterValue);
        },
        updateSamplePreparationFilterToDate({ commit }, samplePreparationFilterToDate) {
            commit("changeSamplePreparationFilterToDate", samplePreparationFilterToDate);
        },
        updateSamplePreparationsSelectOptionResponsibleData({ commit }, samplePreparationsSelectOptionResponsibleData) {
            commit("changeSamplePreparationsSelectOptionResponsibleData", samplePreparationsSelectOptionResponsibleData);
        },
        updateSamplePreparationsSelectOptionMethodsData({ commit }, samplePreparationsSelectOptionMethodsData) {
            commit("changeSamplePreparationsSelectOptionMethodsData", samplePreparationsSelectOptionMethodsData);
        },
        updateSamplePreparationsSelectOptionSeriesData({ commit }, samplePreparationsSelectOptionSeriesNumberData) {
            commit("changeSamplePreparationsSelectOptionSeriesNumberData", samplePreparationsSelectOptionSeriesNumberData);
        },
        clearSamplePreparationsFilter({ dispatch }) {
            dispatch("updateSamplePreparationsSelectOptionMethodsData", "Все методы");
            dispatch("updateSamplePreparationsSelectOptionMethodsData", "Все серии");
            dispatch("updateSamplePreparationsSelectOptionResponsibleData", "Все ответственные");
            dispatch("updateSamplePreparationsSortByFilterValue", {id: "arrived_date", title: "По дате поступления"})
            dispatch("updateSamplePreparationFilterFromDate", null);
            dispatch("updateSamplePreparationFilterToDate", null);
        },
        updateSamplePreparationSelectedCassette({ commit }, samplePreparationSelectedCassette) {
            commit("changeSamplePreparationSelectedCassette", samplePreparationSelectedCassette);
        },
        updateSamplePreparationSelectedMicro({ commit }, samplePreparationSelectedMicro) {
            commit("changeSamplePreparationSelectedMicro", samplePreparationSelectedMicro);
        },
    },
    mutations: {
        updateSamplePreparationCassettes(state, samplePreparationCassettes) {
            state.samplePreparationCassettes = samplePreparationCassettes;
        },
        updateSamplePreparationCassettesLoader(state, condition) {
            state.isSamplePreparationCassettesLoading = condition;
        },
        updateMoreSamplePreparationCassettes(state, samplePreparationCassettes) {
            state.samplePreparationCassettes = [...state.samplePreparationCassettes, ...samplePreparationCassettes];
        },
        updateSamplePreparationTotalPages(state, samplePreparationTotalPages) {
            state.samplePreparationTotalPages = samplePreparationTotalPages;
        },
        updateSamplePreparationTotalCount(state, samplePreparationTotalCount) {
            state.samplePreparationTotalCount = samplePreparationTotalCount;
        },
        changeSamplePreparationStageFilter(state, samplePreparationStageFilter) {
            state.samplePreparationStageFilter = samplePreparationStageFilter;
        },
        changeSamplePreparationsFiltersButtonStatus(state, samplePreparationsFiltersButtonStatus) {
            state.samplePreparationsFiltersButtonStatus = samplePreparationsFiltersButtonStatus;
        },
        changeSamplePreparationsSortByFilterValue(state, samplePreparationsSortByFilterValue) {
            state.samplePreparationsSortByFilterValue = samplePreparationsSortByFilterValue;
        },
        changeSamplePreparationFilterFromDate(state, samplePreparationFilterFromDate) {
            state.samplePreparationFilterFromDate = samplePreparationFilterFromDate;
        },
        changeSamplePreparationFilterToDate(state, samplePreparationFilterToDate) {
            state.samplePreparationFilterToDate = samplePreparationFilterToDate;
        },
        changeSamplePreparationsSelectOptionResponsibleData(state, samplePreparationsSelectOptionResponsibleData) {
            state.samplePreparationsSelectOptionResponsibleData = samplePreparationsSelectOptionResponsibleData;
        },
        changeSamplePreparationsSelectOptionMethodsData(state, samplePreparationsSelectOptionMethodsData) {
            state.samplePreparationsSelectOptionMethodsData = samplePreparationsSelectOptionMethodsData;
        },
        changeSamplePreparationsSelectOptionSeriesNumberData(state, samplePreparationsSelectOptionSeriesNumberData) {
            state.samplePreparationsSelectOptionSeriesNumberData = samplePreparationsSelectOptionSeriesNumberData;
        },
        changeSamplePreparationSelectedCassette(state, samplePreparationSelectedCassette) {
            state.samplePreparationSelectedCassette = samplePreparationSelectedCassette;
        },
        changeSamplePreparationSelectedMicro(state, samplePreparationSelectedMicro) {
            state.samplePreparationSelectedMicro = samplePreparationSelectedMicro;
        },
    },
    getters: {
        getSamplePreparationCassettes(state) {
            return state.samplePreparationCassettes;
        },
        getSamplePreparationLoaderStatus(state) {
            return state.isSamplePreparationCassettesLoading;
        },
        getSamplePreparationTotalPages(state) {
            return state.samplePreparationTotalPages;
        },
        getSamplePreparationTotalCount(state) {
            return state.samplePreparationTotalCount;
        },
        getSamplePreparationStageFilter(state) {
            return state.samplePreparationStageFilter;
        },
        getSamplePreparationsFiltersButtonStatus(state) {
            return state.samplePreparationsFiltersButtonStatus;
        },
        getSamplePreparationsSortByFilterValue(state) {
            return state.samplePreparationsSortByFilterValue;
        },
        getSamplePreparationFilterFromDate(state) {
            return state.samplePreparationFilterFromDate;
        },
        getSamplePreparationFilterToDate(state) {
            return state.samplePreparationFilterToDate;
        },
        getSamplePreparationsSelectOptionResponsibleData(state) {
            return state.samplePreparationsSelectOptionResponsibleData;
        },
        getSamplePreparationsSelectOptionMethodsData(state) {
            return state.samplePreparationsSelectOptionMethodsData;
        },
        getSamplePreparationsSelectOptionSeriesNumberData(state) {
            return state.samplePreparationsSelectOptionSeriesNumberData;
        },
        getSamplePreparationSelectedCassette(state) {
            return state.samplePreparationSelectedCassette;
        },
        getSamplePreparationSelectedMicro(state) {
            return state.samplePreparationSelectedMicro;
        },
    }
}